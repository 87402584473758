/* eslint-disable no-case-declarations */
import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const initialState = {
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  editingDriver: { data: {} },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const driverReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_DRIVERS:
  case TYPES.GETTING_DRIVER:
  case TYPES.INSERTING_DRIVER:
  case TYPES.UPDATING_DRIVER:
  case TYPES.DELETING_DRIVER:
    draft.isFetching = true
    break

  case TYPES.GET_DRIVERS_SUCCESS: {
    let data = action.payload.data
    if (data === null) { data = [] }
    draft.items = data && data.length && data.length > 0 ? data : []
    draft.meta = {
      current: action.payload.metadata.page,
      total: action.payload.metadata.total,
      pageSize: action.payload.metadata.page_size
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_DRIVER_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = false
    draft.editingDriver = action.payload
    break
  }

  case TYPES.INSERT_DRIVER_SUCCESS:
  case TYPES.UPDATE_DRIVER_SUCCESS:
  case TYPES.DELETE_DRIVER_SUCCESS:
  case TYPES.GET_DRIVER_FAILURE:
  case TYPES.INSERT_DRIVER_FAILURE:
  case TYPES.UPDATE_DRIVER_FAILURE:
  case TYPES.DELETE_DRIVER_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_DRIVERS_FAILURE: {
    draft.items = []
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

export default driverReducer
