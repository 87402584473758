/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Icon, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import { DRIVER_STATUS } from '../../../constants/enum'
import WithLoading from '../../../hoc/loading'
import router from '../../../constants/router'

const columns = (onView, onEdit, onResetPassword) => [
  {
    title: 'Mã lái xe',
    dataIndex: 'code',
    width: 160,
    render: value => (
      <Link to={router.DRIVER.EDIT.replace(':id', value)}>
        {value}
      </Link>
    ),
  },
  {
    title: 'Họ tên',
    dataIndex: 'name',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Trạng thái',
    key: 'state',
    width: 150,
    render: (text, record) => DRIVER_STATUS[record.status === 'present' ? 0 : record.status === 'absent' ? 1 : 2
    ],
  },
  {
    title: 'Reset Mật khẩu',
    width: 110,
    align: 'center',
    render: (text, record) => (
      <Popconfirm title="Đặt lại mật khẩu?" okText="Yes" cancelText="No" onConfirm={() => onResetPassword({ userId: record.userId, newPassword: '123456' })}>
        <Button type="primary" shape="circle">
          <Icon type="sync" />
        </Button>
      </Popconfirm>
    ),
  },
]

const DriverList = ({
  drivers,
  meta: { current, pageSize, total },
  onEdit,
  onView,
  onChangePage,
  onChangeSize,
  onResetPassword,
}) => (
  <Table
    dataSource={drivers}
    size="small"
    scroll={{ x: 'max-content' }}
    pagination={{
      current,
      pageSize,
      total,
      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} lái xe`,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40', '80'],
      onShowSizeChange: onChangeSize,
      onChange: onChangePage,
    }}
    style={{ marginTop: '10px' }}
    columns={columns(onView, onEdit, onResetPassword)}
    rowKey="code"
    rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
  />
)

DriverList.propTypes = {
  drivers: PropTypes.array,
  meta: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeSize: PropTypes.func,
  onResetPassword: PropTypes.func,
}

DriverList.defaultProps = {
  drivers: [],
  meta: {},
  onEdit: () => { },
  onView: () => { },
  onChangePage: () => { },
  onChangeSize: () => { },
  onResetPassword: () => { },
}

export default WithLoading(DriverList)
