import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const initialState = {
  items: [],
  meta: {
    total: 0,
    current: 1,
    pageSize: 10,
  },
  editingVehicle: { MaxVolume: [] },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const vehicleReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_VEHICLES:
  case TYPES.GETTING_VEHICLE:
  case TYPES.INSERTING_VEHICLE:
  case TYPES.UPDATING_VEHICLE:
  case TYPES.DELETING_VEHICLE:
    draft.isFetching = true
    break

  case TYPES.GET_VEHICLES_SUCCESS: {
    draft.items = action.payload.data
    draft.meta = {
      total: action.payload.metadata.total,
      current: action.payload.metadata.page,
      pageSize: action.payload.metadata.page_size,
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_VEHICLE_SUCCESS: {
    draft.editingVehicle = action.payload
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INSERT_VEHICLE_SUCCESS:
  case TYPES.UPDATE_VEHICLE_SUCCESS:
  case TYPES.DELETE_VEHICLE_SUCCESS:
  case TYPES.INSERT_VEHICLE_FAILURE:
  case TYPES.UPDATE_VEHICLE_FAILURE:
  case TYPES.DELETE_VEHICLE_FAILURE:
  case TYPES.GET_VEHICLE_FAILURE:
  case TYPES.GET_VEHICLES_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  default:
    return draft
  }
})

export default vehicleReducer
