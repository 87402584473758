import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ROUTER from '../../constants/router'

import ManagerPage from './Manager'

const Manager = () => (
  <Switch>
    <Route path={ROUTER.MANAGA.INDEX} component={ManagerPage} exact />
  </Switch>
)

export default Manager
