import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

import WithLoadingHOC from '../../../hoc/loading'

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
})

const columns = () => [
  {
    title: <b>Ngày Giao Hàng</b>,
    dataIndex: 'create_at',
    render: (value, record) => (
      <p>{moment(record.create_at).format('DD-MM-YYYY')}</p>
    )
  },
  {
    title: <b>Mã đơn hàng</b>,
    dataIndex: 'order_code',
    align: 'center',
  },
  {
    title: <b>Khách hàng</b>,
    dataIndex: 'customer_name',
    render: (value, record) => (
      <>
        <p>{record.customer_name}</p>
        <small>{record.customer_code}</small>
      </>
    )
  },
  {
    title: <b>Mã sản phẩm</b>,
    dataIndex: 'product_code',
    align: 'center',
  },
  {
    title: <b>Đơn giá</b>,
    dataIndex: 'price',
    align: 'right',
    render: (value, record) => (
      <p>
        {`${formatter.format(record.price)}`}
      </p>
    )
  },
  {
    title: <b>Số lượng</b>,
    dataIndex: 'quantity',
    align: 'right'
  },
  // {
  //   title: <b>Khuyến mại</b>,
  //   dataIndex: 'promotion',
  //   align: 'right',
  //   render: (value, record) => (
  //     <p>
  //       {`${formatter.format(record.promotion)}`}
  //     </p>
  //   )
  // },
  {
    title: <b>Thành tiền</b>,
    dataIndex: 'payments',
    align: 'right',
    render: (value, record) => (
      <p>
        {`${formatter.format(record.payments)}`}
      </p>
    )
  }
]

const SynDataTable = ({
  dataSource,
  meta: { total },
}) => (
  <Table
    className="tableCode"
    columns={columns(
      // truywn vao column cac action
    )}
    dataSource={dataSource}
    rowKey={(index, id) => id}
    size="small"
    scroll={{ x: 'max-content' }}
    rowClassName={(r, idx) => (idx % 2 === 0 ? 'even' : '')}
    pagination={{
      total,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40'],
      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
    }}
  />
)

export default WithLoadingHOC(SynDataTable)
