import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const initialState = {
  nodePool: [],
  solution: {},
  drivers: [],
  nodes: [],
  routes: [],
  nodesInvoice: [],
  mapConfig: {
    zoom: 12,
    center: {
      lat: 20.995271,
      lng: 105.780953,
    },
  },
  time: '',
  isFetching: false,
}

const routingReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.SELECT_DRIVERS_FOR_VRP:
    draft.drivers = action.payload
    break

  case TYPES.SELECT_NODES_FOR_VRP:
    draft.nodes = action.payload
    break

  case TYPES.GET_NODE_FOR_SCHEDULE_SUCCESS:
    draft.nodesInvoice = action.payload
    break

  case TYPES.SELECT_TIME_FOR_VRP:
    draft.time = action.payload
    break

  case TYPES.SOLVING_VRP:
    draft.isFetching = true
    break

  case TYPES.SOLVE_VRP_SUCCESS:
    draft.routes = action.payload
    draft.isFetching = false
    break

  case TYPES.SELECT_SAMPLE_SCHEDULE: {
    draft.solution = action.payload ? action.payload : {}
    draft.mapConfig = {
      zoom: 13,
      center: {
        lat: 20.995271,
        lng: 105.780953,
      }
    }
    draft.isFetching = false
    break
  }

  case TYPES.SOLVE_VRP_FAILURE:
    draft.isFetching = false
    break

  case TYPES.UPDATE_ROUTES:
    draft.solution = { routes: action.payload }
    draft.isFetching = false
    break

  case TYPES.SET_NODE_POOL:
    draft.nodePool = action.payload
    break

  case TYPES.SHOW_NODE_INFO: {
    draft.solution.routes.forEach(route => {
      route.nodes.forEach(node => {
        if (node.id === action.payload.id) {
          node.showInfo = !node.showInfo
        }
        return node.showInfo
      })
    })
    draft.mapConfig = {
      center: { lat: action.payload.lat, lng: action.payload.lng },
      zoom: 13,
    }
    // draft.solution = {
    //   routes: state
    //     .get('solution')
    //     .get('routes')
    //     .map(route => {
    //       const updatedNodes = route.get('nodes').map(node => node.merge({
    //         showInfo: node.get('id') === action.payload.id
    //                                   ? !node.get('showInfo')
    //                                   : node.get('showInfo'),
    //       }))
    //       return route.merge({ nodes: updatedNodes })
    //     }),
    // }
    // draft.mapConfig = {
    //   center: { lat: action.payload.lat, lng: action.payload.lng },
    //   zoom: 13,
    // }
    break
  }
  case TYPES.UPDATE_NODE_INFO: {
    draft.solution.routes.forEach(route => {
      route.nodes.forEach(node => {
        if (node.id === action.payload.id) {
          node.driverRole = action.payload.driverRole
        }
        return node.driverRole
      })
    })
    draft.mapConfig = {
      zoom: 13,
      center: { lat: action.payload.lat, lng: action.payload.lng },
    }
    break
  }

  case TYPES.SORT_DRIVERS:
    draft.drivers = action.payload
    break

  case TYPES.STOP_LOADING:
    draft.isFetching = false
    break

  case TYPES.UPDATE_DRIVER_NOTE: {
    draft.drivers.forEach(driver => {
      if (driver.ID === action.payload.driver.ID) {
        driver.note = action.payload.note
      }
      return driver
    })
    break
  }

  case TYPES.INSERTING_SOLUTION:
    draft.isFetching = true
    break

  case TYPES.INSERT_SOLUTION_SUCCESS:
    return initialState

  case TYPES.INSERT_SOLUTION_FAILURE:
    draft.isFetching = false
    break

  case TYPES.RESET_NODES_AND_DRIVERS_SUCCESS:
    draft.solution = {}
    draft.nodes = []
    draft.drivers = []
    break

  case TYPES.RESET_MODE_ROUTING:
    draft.solution = {}
    draft.nodePool = []
    draft.routes = []
    break

  case TYPES.RESET_ROUTING_SAMPLE:
    return initialState

  default:
    return draft
  }
})

export default routingReducer
