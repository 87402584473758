/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Button, Card } from 'antd'
import ItemList from './ItemList'
import UploadForm from '../excel-modal'

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
})
class OrderForm extends Component {
  state = { visible: false }

  toggleUpload = () => {
    this.setState(state => ({ visible: !state.visible }))
  }

  render() {
    const {
      order,
      products,
      onChangeQuantity,
      onAddItem,
      onAddItems,
      onRemoveItem,
    } = this.props
    return (
      <React.Fragment>
        <Card
          title="Đặt hàng"
          extra={(
            <Button style={{ float: 'right' }} disabled={!products.length > 0} onClick={this.toggleUpload}>Thêm từ Excel</Button>
          )}
        >
          <ItemList
            dataSource={order.Items}
            products={products}
            onRemoveItem={onRemoveItem}
            onChangeQuantity={onChangeQuantity}
            onAddItem={onAddItem}
          />
          <Row gutter={24} style={{ marginTop: 10 }}>
            <Button ghost disabled style={{ color: 'red', border: 'none', fontSize: 20, float: 'right' }}>
              {`Tạm tính: ${formatter.format(order.Items.map(item => item.totalPrice).reduce((a, b) => a + b, 0))}`}
            </Button>
          </Row>
        </Card>
        <UploadForm
          visible={this.state.visible}
          products={products}
          onCloseModal={this.toggleUpload}
          onSubmit={onAddItems}
        />
      </React.Fragment>
    )
  }
}

OrderForm.propTypes = {
  order: PropTypes.object,
  products: PropTypes.array,
  onChangeQuantity: PropTypes.func,
  onAddItem: PropTypes.func,
  onAddItems: PropTypes.func,
  onRemoveItem: PropTypes.func,
}

OrderForm.defaultProps = {
  order: { Items: [] },
  products: [],
  onChangeQuantity: () => {},
  onAddItems: () => {},
  onAddItem: () => {},
  onRemoveItem: () => {},
}

export default Form.create()(OrderForm)
