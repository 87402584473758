import React, { Component } from 'react'
import { Card, Button, Icon, Input, Form, Row, Col } from 'antd'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import DriverList from '../routing-table/driver-list'
import RouteList from '../routing-table/route-list'
import SampleSolutionForm from '../select-sample-form'
import NodePool from '../routing-table/node-pool'
import WithLoading from '../../../../hoc/loading'
import { isBelowBreakpoint } from '../../../../util/windows'

class EditRouteCard extends Component {
  handleSubmit = values => this.props.onSelectSampleSolution(values.code);

  onSolve = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { maxNodesPerRoute, maxRouteDuration, maxRunTime, vehicleCapacity } = values
        this.props.onSolve(Number.parseInt(maxNodesPerRoute, 10), maxRouteDuration * 60, Number.parseInt(maxRunTime, 10), Number.parseInt(vehicleCapacity, 10))
      }
    })
  }

  render() {
    const {
      drivers,
      solution,
      onShowNodeInfo,
      onSortDrivers,
      onChangeNodePosition,
      onSwapNodes,
      sampleSolutions,
      nodePool,
      isFetching,
      isVrp,
      removeSampleSolution,
      form: { getFieldDecorator }
    } = this.props
    return (
      <DndProvider backend={Backend}>
        <Card
          title={
            !isVrp ? (
              <SampleSolutionForm disabled={isFetching} sampleSolutions={sampleSolutions} onSubmit={this.handleSubmit} removeSampleSolution={removeSampleSolution} />
            ) : (
              'Bảng điều khiển'
            )
          }
          extra={
            !isVrp ? null : (
              <Button
                style={{ marginRight: isBelowBreakpoint() ? 0 : 10 }}
                type="primary"
                ghost="ghost"
                onClick={this.onSolve}
              >
                <Icon type="check" />
              Tìm đường
              </Button>
            )
          }
          style={{ width: '100%' }}
        >
          {isVrp ? (
            <Form className="ant-advanced-search-form">
              <Row gutter={24}>
                <Col span={24} lg={6}>
                  <Form.Item label="Số điểm giao hàng tối đa của 1 lái xe">
                    {getFieldDecorator('maxNodesPerRoute', {
                      rules: [
                        {
                          required: true,
                          message: 'Không được để trống!',
                        },
                      ],
                      initialValue: 4,
                    })(<Input placeholder="Số điểm giao hàng tối đa của 1 lái xe" suffix="điểm" />)}
                  </Form.Item>
                </Col>
                <Col span={24} lg={6}>
                  <Form.Item label="Thời gian tối đa di chuyển của 1 lái xe">
                    {getFieldDecorator('maxRouteDuration', {
                      rules: [
                        {
                          required: true,
                          message: 'Không được để trống!',
                        },
                      ],
                      initialValue: 120,
                    })(<Input placeholder="Thời gian tối đa di chuyển của 1 lái xe" suffix="phút" />)}
                  </Form.Item>
                </Col>
                <Col span={24} lg={6}>
                  <Form.Item label="Thời gian tối đa đợi chạy tối ưu">
                    {getFieldDecorator('maxRunTime', {
                      rules: [
                        {
                          required: true,
                          message: 'Không được để trống!',
                        },
                      ],
                      initialValue: 30,
                    })(<Input placeholder="Thời gian tối đa đợi chạy tối ưu" suffix="giây" />)}
                  </Form.Item>
                </Col>
                <Col span={24} lg={6}>
                  <Form.Item label="Tải trọng của xe">
                    {getFieldDecorator('vehicleCapacity', {
                      rules: [
                        {
                          required: true,
                          message: 'Không được để trống!',
                        },
                      ],
                      initialValue: 100,
                    })(<Input placeholder="Tải trọng của xe" suffix="kg" />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : null}
          {nodePool ? (
            <NodePool
              routeIndex={-1}
              nodePool={nodePool}
              onSwapNodes={onSwapNodes}
              onShowNodeInfo={onShowNodeInfo}
              onChangeNodePosition={onChangeNodePosition}
            />
          ) : null}
          <Form>
            <Row gutter={24}>
              <Col span={8} lg={6}>
                <div style={{ width: '100%' }}>
                  <DriverList
                    drivers={drivers}
                    onSortDrivers={onSortDrivers}
                    onChangeDriverNote={this.props.onChangeDriverNote}
                  />
                </div>
              </Col>
              <Col span={16} lg={18}>
                <div style={{ width: '100%' }}>
                  <RouteList
                    onChangeNodePosition={onChangeNodePosition}
                    onSwapNodes={onSwapNodes}
                    routes={solution.routes}
                    onShowNodeInfo={onShowNodeInfo}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </DndProvider>
    )
  }
}

export default WithLoading((Form.create()(EditRouteCard)))
