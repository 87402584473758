import React from 'react'
import { Row, Col, Card, Avatar } from 'antd'

const { Meta } = Card

const CountData = ({ count }) => (
  <Row type="flex" justify="center">
    <Col span={24} lg={5}>
      <Card hoverable>
        <Meta
          avatar={(
            <Avatar
              size={48}
              style={{ backgroundColor: 'red' }}
            >
              {count.countAccount}

            </Avatar>
          )}
          title={<h2>Tài khoản</h2>}
        />
      </Card>
    </Col>
    <Col span={24} lg={5}>
      <Card hoverable>
        <Meta
          avatar={(
            <Avatar
              size={48}
              style={{ backgroundColor: 'green' }}
            >
              {count.countProductUnit}

            </Avatar>
          )}
          title={<h2>Đơn vị tính</h2>}
        />
      </Card>
    </Col>
  </Row>
)


export default CountData
