/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Button, Form, DatePicker } from 'antd'
import moment from 'moment'

import ROUTER from '../../../constants/router'

const { Item } = Form
const dateFormat = 'YYYY-MM-DD'

class FilterSynData extends React.Component {
  state = { keyword: [] }

  static getDerivedStateFromProps(props) {
    const { search } = props.history.location
    const result = search.replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ').split(' ').splice(1)

    return { keyword: result }
  }

  handleSubmit = e => {
    e.preventDefault()
    const c_params = this.props.filterOptions === undefined ? {} : JSON.parse(this.props.filterOptions)
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let orderDate
        if (values.orderDate) {
          orderDate = moment(values.orderDate).format('YYYY-MM-DD')
          values = { ...values, orderDate }
        }
        const urlParameters = []
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '') {
            urlParameters.push(e.join('='))
          }
          return null
        })
        if (urlParameters[0] !== false) {
          this.props.history.push(ROUTER.SYNDATA.INDEX.concat(`?${urlParameters.join('&')}`))
        }
      } else {
        console.log(err)
      }
    })
  };

  handleReset = () => {
    this.props.form.resetFields()
    this.props.history.push(ROUTER.SYNDATA.INDEX)
  }

  render() {
    const { disabled, form: { getFieldDecorator } } = this.props
    const { keyword } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16} className="filter-syn">
          <Col span={6}>
            <Item label="Mã khách hàng">
              {
                getFieldDecorator('customerCode', { initialValue: keyword.indexOf('customerCode') === -1 ? '' : keyword[keyword.indexOf('customerCode') + 1] })(
                  <Input
                    allowClear
                    disabled={disabled}
                    placeholder="Mã khách hàng"
                  />,
                )
              }
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Mã sản phẩm">
              {
                getFieldDecorator('productCode', { initialValue: keyword.indexOf('productCode') === -1 ? '' : keyword[keyword.indexOf('productCode') + 1] })(
                  <Input
                    allowClear
                    disabled={disabled}
                    placeholder="Mã sản phẩm"
                  />,
                )
              }
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Ngày giao hàng">
              {
                getFieldDecorator('orderDate', { initialValue: keyword.indexOf('orderDate') === -1 ? '' : moment(`${keyword[keyword.indexOf('orderDate') + 1]}`, dateFormat) })(
                  <DatePicker
                    allowClear
                    style={{ width: '100%' }}
                    disabled={disabled}
                    placeholder="Ngày giao hàng"
                    format="DD/MM/YYYY"
                  />,
                )
              }
            </Item>
          </Col>
          <Col span={6} />
        </Row>
        <Row gutter={8} className="filter-syn">
          <Col span={6}>
            <Col span={12} lg={12}>
              <Button icon="filter" disabled={disabled} type="primary" htmlType="submit" style={{ width: '100%' }}>
                {'Lọc'}
              </Button>
            </Col>
            <Col span={12} lg={12}>
              <Button icon="close" disabled={disabled} onClick={this.handleReset} style={{ width: '100%' }}>
                {'Bỏ lọc'}
              </Button>
            </Col>
          </Col>
        </Row>
      </Form>
    )
  }
}

FilterSynData.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

FilterSynData.defaultProps = { disabled: false }

export default Form.create()(FilterSynData)
