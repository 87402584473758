/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Row, Col, Modal, Select, Icon, Checkbox, notification } from 'antd';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { changeAlias } from '../../../../util/formatText';
import AdminForm from '../../../General/components/AdminForm';
import router from '../../../../constants/router';
import { backpage } from '../../../../util/windows';
import { apiCall } from '../../../../util/apiCall';
import { API } from '../../../../constants/api';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueCode: '',
      cartId: '',
      // statuses: [
      //   { name: 'Ngừng phục vụ', val: false },
      //   { name: 'Phục vụ', val: true }
      // ],
      visible: false,
      userId: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.state !== undefined && props.location.state.cartId) {
      return { ...state, cartId: props.location.state.cartId };
    }
    return { ...state, cartId: '' };
  }

  onDelete = name => {
    Modal.confirm({
      okText: 'Xoá khách hàng',
      okButtonProps: { type: 'danger' },
      onOk: this.props.onDelete,
      cancelText: 'Huỷ',
      cancelButtonProps: { type: 'primary' },
      title: `Bạn chắc chắn xoá khách hàng ${name}`,
      content: 'Tài khoản và mọi thông tin liên quan sẽ bị xoá khỏi hệ thống !',
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      values.code = this.state.valueCode;
      if (values.paymentMethod === undefined) {
        values.paymentMethod = '';
      }
      if (values.isWholesale && !values.phone) {
        form.setFields({
          phone: {
            value: '',
            errors: [new Error('Khách buôn cần số điện thoại!')],
          },
        });
      } else if (!err && !form.getFieldError('phone')) {
        this.props.onSubmit(values);
      }
    });
  };

  changeId = e => {
    const valueinput = e.target.value;
    const part = /\s/g;
    const value = valueinput.replace(part, '');
    this.setState({ valueCode: value });
  };

  toggleAccForm = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  handleRegister = values => {
    this.props.createAccount(values, {
      onSuccess: id => {
        notification.success({ message: 'Tạo tài khoản thành công' });
        this.toggleAccForm();
        this.props.form.setFieldsValue({ userId: id });
      },
      onError: mes => {
        notification.error({ message: `Tạo tài khoản thất bại. ${mes}` });
      },
    });
  };

  onChangePhone = e => {
    const phone = e.target.value;
    const { form } = this.props;
    form.setFieldsValue({ phone });

    const phonePattern = new RegExp(/^(0)[1-9]([0-9]{8}[0-9]?)\b/);
    if (phone && !phone.match(phonePattern)) {
      form.setFields({
        phone: {
          value: phone,
          errors: [new Error('Số điện thoại không đúng định dạng!')],
        },
      });
    } else if (form.getFieldValue('isWholesale')) {
      if (!phone) {
        form.setFields({
          phone: {
            value: phone,
            errors: [new Error('Khách buôn cần số điện thoại!')],
          },
        });
      } else {
        this.checkExistedPhoneNumber(phone);
      }
    }
  };

  // requiredPhoneForWholesale = (rule, value, callback) => {
  //   const { form } = this.props
  //   if (form.getFieldValue('isWholesale')) {
  //     if (!value) {
  //       callback('Khách buôn cần số điện thoại')
  //     }
  //   }
  //   callback()
  // };

  checkExistedPhoneNumber = async phone => {
    const api = API.CUSTOMER.getUboCustomerPhone();
    const { response, error } = await apiCall({
      ...api,
      params: { customerPhone: phone },
    });

    const { form } = this.props;
    if (form.getFieldValue('phone') === phone) {
      // the phone has not been updated
      if (error || response.status !== 200 || response?.data?.exist) {
        form.setFields({
          phone: {
            value: phone,
            errors: [new Error('Số điện thoại đã tồn tại!')],
          },
        });
      }
    }
  };

  render() {
    const {
      customer,
      editMode,
      form,
      form: { getFieldDecorator },
      carts,
      accounts,
      groups,
    } = this.props;
    const { cartId, visible, userId } = this.state;
    return (
      <React.Fragment>
        <Form>
          <Col>
            <Row gutter={24}>
              <Col span={24} lg={12}>
                <FormItem label="Mã khách hàng">
                  {getFieldDecorator('code', {
                    initialValue: editMode ? customer.code : '',
                    rules: [
                      {
                        required: true,
                        message: 'Hãy nhập mã khách hàng',
                      },
                    ],
                  })(<Input placeholder="Mã khách hàng" onChange={this.changeId} disabled={editMode} />)}
                </FormItem>
              </Col>
              <Col span={24} lg={12}>
                <FormItem label="Tên khách hàng">
                  {getFieldDecorator('name', {
                    initialValue: editMode ? customer.name : '',
                    rules: [
                      {
                        required: true,
                        message: 'Hãy nhập tên khách hàng',
                      },
                    ],
                  })(<Input placeholder="Tên khách hàng" />)}
                </FormItem>
              </Col>
              <Col span={24} lg={12}>
                <FormItem label="Nhóm khách hàng">
                  {getFieldDecorator('customerGroupId', {
                    initialValue: editMode ? customer.groupId : '',
                    rules: [
                      {
                        required: true,
                        message: 'Hãy nhập nhóm khách hàng',
                      },
                    ],
                  })(
                    <Select
                      placeholder="Nhóm khách hàng"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        changeAlias(option.props.children.toLowerCase()).indexOf(changeAlias(input.toLowerCase())) >= 0
                      }
                    >
                      {groups.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                  {editMode ? (
                    <Link to={router.CUSTOMER_GROUP.DETAIL.replace(':id', customer.groupId)} style={{ float: 'right' }}>
                      Xem chi tiết nhóm
                      <Icon type="right" style={{ marginLeft: '3px' }} />
                    </Link>
                  ) : null}
                </FormItem>
              </Col>
              <Col span={24} lg={12}>
                <FormItem label="Giỏ hàng">
                  {getFieldDecorator('cartId', {
                    // eslint-disable-next-line no-nested-ternary
                    initialValue: editMode ? customer.cartId : cartId,
                    rules: [
                      {
                        required: true,
                        message: 'Hãy nhập giỏ hàng',
                      },
                    ],
                  })(
                    <Select
                      placeholder="Giỏ hàng"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        changeAlias(option.props.children.toLowerCase()).indexOf(changeAlias(input.toLowerCase())) >= 0
                      }
                    >
                      {carts
                        .filter(e => e.active === true)
                        .map(item => (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} lg={12}>
                <FormItem label="Tài khoản mua hàng">
                  {getFieldDecorator('userId', {
                    initialValue: editMode ? customer.userId : userId,
                    rules: [
                      {
                        required: true,
                        message: 'Hãy chọn tài khoản',
                      },
                    ],
                  })(
                    <Select
                      placeholder="Tài khoản"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        changeAlias(option.props.children.toLowerCase()).indexOf(changeAlias(input.toLowerCase())) >= 0
                      }
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Button type="link" onMouseDown={this.toggleAccForm}>
                            + Thêm mới
                          </Button>
                        </div>
                      )}
                    >
                      {accounts
                        .filter(e => e.role === 'customer')
                        .map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={12} lg={6}>
                <Form.Item label="Khách buôn">
                  {getFieldDecorator('isWholesale', { initialValue: editMode ? !!customer.isWholesale : false })(
                    <Checkbox checked={!!form.getFieldValue('isWholesale')} />,
                  )}
                </Form.Item>
              </Col>
              <Col span={12} lg={6}>
                <Form.Item label="Số điện thoại">
                  {getFieldDecorator('phone', {
                    initialValue: editMode ? customer.phone : '',
                    rules: [
                      // {
                      //   // required: true,
                      //   // message: 'Hãy nhập số điện thoại',
                      //   pattern: new RegExp(/^(0)[1-9]([0-9]{8}[0-9]?)\b/),
                      //   message: form.getFieldValue('phone') ? 'Sai định dạng số điện thoại' : 'Hãy nhập số điện thoại',
                      // },
                    ],
                  })(
                    <Input
                      placeholder="Số điện thoại"
                      autoComplete="off"
                      disabled={editMode && form.getFieldValue('phone')}
                      onChange={this.onChangePhone}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} lg={12}>
                <FormItem label="Nhân viên quản lý">
                  {getFieldDecorator('saleInChargeId', {
                    initialValue: editMode ? customer.saleInChargeId : undefined,
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: 'Hãy chọn nhân viên quản lý',
                    //   }]
                  })(
                    <Select
                      placeholder="Nhân viên quản lý"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        changeAlias(option.props.children.toLowerCase()).indexOf(changeAlias(input.toLowerCase())) >= 0
                      }
                      // dropdownRender={menu => (
                      //   <div>
                      //     {menu}
                      //     <Button type="link" onMouseDown={this.toggleAccForm}>+ Thêm mới</Button>
                      //   </div>
                      // )}
                    >
                      {accounts
                        .filter(e => e.role === 'sale')
                        .map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={24} lg={12}>
                <FormItem label="Hình thức thanh toán">
                  {getFieldDecorator('paymentMethod', {
                    initialValue: editMode ? customer.paymentMethod : '',
                    rules: [
                      {
                        required: false,
                        message: 'Hãy chọn hình thức thanh toán',
                      },
                    ],
                  })(
                    <Select placeholder="Hình thức thanh toán" showSearch allowClear>
                      <Option key={1} value="Theo hợp đồng">
                        Theo hợp đồng hàng tháng
                      </Option>
                      <Option key={2} value="Thanh toán trực tiếp">
                        Thanh toán trực tiếp
                      </Option>
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24} lg={24}>
                <FormItem label="Ghi chú">
                  {getFieldDecorator('note', { initialValue: editMode ? customer.note : '' })(
                    <TextArea type="text" placeholder="Thông tin thêm ..." />,
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
          <FormItem>
            {editMode ? (
              <React.Fragment>
                <Button ghost type="danger" onClick={() => backpage()}>
                  Quay lại
                </Button>
                <Button style={{ float: 'right' }} type="primary" onClick={this.handleSubmit}>
                  Hoàn thành
                </Button>
              </React.Fragment>
            ) : (
              <Button style={{ float: 'right' }} type="primary" onClick={this.handleSubmit}>
                Thêm khách hàng
              </Button>
            )}
          </FormItem>
        </Form>
        <AdminForm
          onRegister={this.handleRegister}
          accounts={accounts}
          visible={visible}
          toggleModal={this.toggleAccForm}
          customer
        />
      </React.Fragment>
    );
  }
}

CustomerForm.propTypes = {
  editMode: PropTypes.bool,
  customer: PropTypes.object,
  carts: PropTypes.array,
  accounts: PropTypes.array,
  groups: PropTypes.array,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  registerAdmin: PropTypes.func,
};

CustomerForm.defaultProps = {
  editMode: false,
  customer: {},
  carts: [],
  accounts: [],
  groups: [],
  registerAdmin: () => {},
};

export default withRouter(Form.create()(CustomerForm));
