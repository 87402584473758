/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, notification, Button, Alert, Divider } from 'antd'
import * as XLSX from 'xlsx'
import moment from 'moment'

// import { element } from 'prop-types'
import Solution from './steps/solve'

import { importScheduleSample, getCartCustomers, insertSchedule } from './actions'
import { getDrivers } from '../Driver/actions'

import WithPageHOC from '../../hoc/page'
import select from '../../util/select'
import ROUTER from '../../constants/router'

class SampleManage extends Component {
  constructor() {
    super()
    this.state = {
      isSampleManage: true,
      errorFile: false,
      dataDrivers: [],
      excelName: '',
      simpleNodes: [],
    }
    this.fileUpload = React.createRef()
  }

  componentDidMount() {
    this.props.getDrivers({ pageSize: -1 })
  }

  importExcel = file => {
    const fileReader = new FileReader()
    let newData = []
    const newDrivers = []
    const driversInfo = this.props.drivers
    const newDataNode = []
    const driversOfNode = []

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let startAt = 0
        const { result } = event.target
        const workbook = XLSX.read(result, { type: 'binary' })
        const Sheet = workbook.Sheets[workbook.SheetNames[0]]
        let data = XLSX.utils.sheet_to_json(Sheet)
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`]
          if (flagCell !== undefined) {
            if (flagCell.v === 'STT') {
              startAt = i
              // 1
              break
            }
          }
        }

        if (startAt === 0) {
          notification.error({ message: 'Tải lên thất bại!' })
          this.setState({ dataDrivers: [], driversOfNode, excelName: '' })
        } else {
          data = XLSX.utils.sheet_to_json(Sheet, { range: startAt, header: 1, raw: true })
          // remove element is empty
          newData = data.filter(value => Object.keys(value).length !== 0)
          // get drivers info
          const errorDrivers = []
          newData.forEach(element => {
            const result = driversInfo.find(item => item.code === element[1])
            if (!result) {
              errorDrivers.push(element[1])
            } else {
              newDrivers.push({
                code: result.code,
                id: result.id,
                name: result.name,
                userId: result.userId
              })
            }
          })
          if (errorDrivers.length) {
            notification.error({ message: `Có lái xe chưa có trong hệ thống: ${errorDrivers.join(', ')}` })
            this.fileUpload.value = ''
            return
          }

          newData.forEach(element => {
            newDataNode.push(element.splice(4, element.length))
          })
          // console.log(newDataNode[index].join())
          const sampleCartCodes = []
          for (let i = 0; i < newDataNode.length; i++) {
            const tempNodes = newDataNode[i]
            tempNodes.forEach((n, index) => {
              driversOfNode[n] = driversOfNode[n] || []
              driversOfNode[n].push({
                ...newDrivers[i],
                position: index,
              })
              const cartCode = `${n}`
              if (!sampleCartCodes.includes(cartCode)) {
                sampleCartCodes.push(cartCode)
              }
            })
          }

          // const sampleCartCodes = [...new Set(newDataNode_2)]
          if (sampleCartCodes && sampleCartCodes.length) {
            this.props.getCartCustomers({ list_cart_code: sampleCartCodes.join() }, {
              onSuccess: cartCustomers => {
                const filteredCartCustomers = cartCustomers.filter(item => item.lat !== 0 && item.lng !== 0)
                const missingCart = sampleCartCodes
                && sampleCartCodes.length
                && cartCustomers
                && filteredCartCustomers.length < sampleCartCodes.length
                if (missingCart) {
                  const missingCartCodes = sampleCartCodes.filter(code => !filteredCartCustomers.find(item => item.code === code))
                  notification.error({ message: `Có giỏ hàng chưa có trong hệ thống hoặc thiếu thông tin tọa độ: ${missingCartCodes.join(', ')}` })
                  this.fileUpload.value = ''
                } else if (sampleCartCodes
                && sampleCartCodes.length
                && cartCustomers
                && cartCustomers.length) {
                  notification.success({ message: 'Đã kiểm tra file, có thể Tạo lịch mẫu!' })
                }
              },

              onError: () => notification.error({ message: 'Có lỗi khi lấy dữ liệu giỏ hàng, hãy refresh và thử lại!' }),
            })
          } else {
            notification.error({ message: 'File rỗng hoặc không hợp lệ.' })
            this.fileUpload.value = ''
          }

          for (let i = 0; i < newDataNode.length; i++) {
            this.state.simpleNodes.push(newDataNode[i])
          }

          let excelName = workbook.SheetNames[0]
          if (excelName === 'TenTuyenMau') {
            // create a new name when it's the default
            excelName = `Tuyen Mau ${moment().format('DD-MM-YYYY')}`
          }
          this.setState({ dataDrivers: newDrivers, driversOfNode, excelName })
        }
      } catch (e) {
        notification.error({ message: 'Có lỗi khi đọc file, hãy kiểm tra lại!' })
        this.fileUpload.value = ''
      }
    }
    fileReader.readAsBinaryString(file)
  }

  onImportExcel = event => {
    const { files } = event.target
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(files[0])
    }
  }

  onUpload = newCartCustomers => {
    this.props.insertSchedule({
      drivers: this.state.dataDrivers,
      // "executionTime": "string",
      is_sample: true,
      name: this.state.excelName,
      nodes: newCartCustomers,
      routes: [
        {
          totalDistance: 0,
          totalTime: 0,
          totalWeight: 0
        }
      ],
    }, {
      onSuccess: () => {
        notification.success({ message: 'Thêm lịch mẫu thành công' })
        this.props.history.push(ROUTER.ROUTING.SAMPLE)
      },

      onError: () => notification.error({ message: 'Thêm lịch mẫu thất bại' }),
    })
  }

  // handleUpload = async ({ current: { files } }) => {
  //   this.setState({ isFetching: true })
  //   const formData = new FormData()
  //   formData.append('fileName', files[0])
  //   this.props.importScheduleSample(formData, {
  //     onSuccess: () => {
  //       notification.success({ message: 'Thêm lịch mẫu thành công' })
  //       this.setState({ isFetching: false })
  //     },
  //     onError: () => {
  //       notification.error({ message: 'Thêm lịch mẫu thất bại' })
  //       this.setState({ isFetching: false })
  //     }
  //   })
  // };

  render() {
    const { errorFile, driversOfNode } = this.state
    const newCartCustomers = []
    const { cartCustomers, history, missingCart } = this.props

    if (driversOfNode) {
      cartCustomers.forEach(cart => {
        if (cart.lat !== 0 && cart.lng !== 0 && driversOfNode[cart.code]) {
          newCartCustomers.push({
            carriers: driversOfNode[cart.code],
            cart: {
              address: cart.address,
              cartCode: cart.code,
              cartId: cart.id,
              latitude: `${cart.lat}`,
              longitude: `${cart.lng}`,
              orders: [],
              phone: cart.phoneNumber
            },
            confirmers: driversOfNode[cart.code],
            weight: 0,
          })
        }
      })
    }

    return (
      <div>
        <Card>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/spreadsheets/d/1jI8K8U39K1LUcREFPvghVy57uIKxcSQFlHz6uiQ8VaY/edit?usp=sharing"
          >
              Mẫu file excel
          </a>
          <Divider dashed />
          <input
            id="fileUpload"
            type="file"
            ref={v => {
              this.fileUpload = v
            }}
            accept=".xlsx, .xls"
            onChange={this.onImportExcel}
          />

          {errorFile && <Alert style={{ marginTop: 10 }} type="error" showIcon message="Chọn file để upload" />}
          <Button onClick={() => this.onUpload(newCartCustomers)} disabled={!newCartCustomers.length || missingCart}>Tạo lịch mẫu từ File</Button>
        </Card>
        <Card
          style={{
            width: '100%',
            margin: '10px 0',
          }}
        >
          <Solution isSampleManage={this.state.isSampleManage} history={history} />
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const cartCustomers = state.customerReducer.customer.cartCustomer.list || []
  const cartCodesParams = state.customerReducer.customer.cartCustomer.params || {}
  const filteredCartCustomers = cartCustomers.filter(item => item.lat !== 0 && item.lng !== 0)
  const missingCart = cartCodesParams
     && cartCodesParams?.list_cart_code
     && cartCustomers
     && cartCustomers.length
     && filteredCartCustomers.length < cartCodesParams.list_cart_code.split(',').length

  return {
    drivers: select(state, 'driverReducer', 'items'),
    nodes: select(state, 'routingReducer', 'nodes'),
    cartCustomers: filteredCartCustomers,
    cartCodesParams,
    missingCart,
  }
}

const mapDispatchToProps = dispatch => ({
  importScheduleSample: (payload, meta) => dispatch(importScheduleSample(payload, meta)),
  getDrivers: params => dispatch(getDrivers(params)),
  getCartCustomers: (params, meta) => dispatch(getCartCustomers(params, meta)),
  insertSchedule: (payload, meta) => dispatch(insertSchedule(payload, meta)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithPageHOC('routing-sample', 'routing')(SampleManage))
