import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, DatePicker, Row, Col, Button, Card, Popconfirm, Input, Divider } from 'antd'
import moment from 'moment'
import ItemList from './ItemList'
import AddProductModal from './AddProductModal'

class PriceItemsForm extends Component {
  constructor() {
    super()

    this.state = { addProductModalVisible: false }
  }

  showAddProductModal = () => {
    this.setState({ addProductModalVisible: true })
  }

  hideAddProductModal = () => {
    this.setState({ addProductModalVisible: false })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { priceTableName } = values
        let { applyDate } = values
        applyDate = moment(applyDate).format('YYYY-MM-DD')
        const priceItems = this.props.prices.map(item => ({
          price: item.price,
          productId: item.product.id
        }))

        this.props.onSubmit({
          applyDate,
          priceItems,
          priceTableName
        })
      }
    })
  };

  render() {
    const {
      prices,
      priceItemCodes,
      products,
      removeProduct,
      onChangePrice,
      onCancel,
      onAddNewProducts,
      form: { getFieldDecorator },
    } = this.props
    const { addProductModalVisible } = this.state
    const newProducts = products.filter(p => !priceItemCodes.includes(p.code))
    return (
      <Card
        title="Thông tin bảng giá"
        extra={(
          <Popconfirm title="Hủy bảng giá này?" onConfirm={onCancel} okText="Có" cancelText="Không">
            <Button type="danger">
              Hủy
            </Button>
          </Popconfirm>
        )}
      >
        <Form>
          <Row gutter={24}>
            <Col span={24} lg={12}>
              {(
                <Form.Item label="Tên bảng giá" required>
                  {getFieldDecorator('priceTableName', {
                    rules: [
                      {
                        required: true,
                        message: 'Nhập tên bảng giá',
                      },
                    ],
                  })(<Input placeholder="Nhập tên bảng giá" />)}
                </Form.Item>
              )}
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Ngày áp dụng">
                {getFieldDecorator('applyDate', {
                  initialValue:
                      moment()
                        .add(1, 'd'),
                  // .hour(6)
                  // .minute(0),
                  rules: [
                    {
                      required: true,
                      message: 'Chọn ngày áp dụng bảng giá',
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder="Chọn ngày"
                    format="DD/MM/YYYY"
                    disabledDate={current => (
                      current
                      && current
                          < moment()
                            .add(1, 'd')
                            .hour(0)
                            .valueOf()
                    )}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div style={{ marginTop: 10 }}>
            <h3>
              {'Bảng giá'}
              <Button
                type="primary"
                htmlType="button"
                style={{ float: 'right' }}
                onClick={this.showAddProductModal}
                disabled={products.length === prices.length}
              >
                  Thêm sản phẩm mới
              </Button>
            </h3>
          </div>
          <ItemList
            dataSource={prices}
            removeProduct={removeProduct}
            onChangePrice={onChangePrice}
          />
          <Divider dashed />
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <Popconfirm title="Tạo bảng giá này?" onConfirm={this.handleSubmit} okText="Có" cancelText="Không">
                <Button
                  type="primary"
                  htmlType="button"
                  style={{ display: 'block', width: '200px', marginLeft: 'auto', marginRight: 'auto' }}
                  disabled={
                    (
                      this.props.form.getFieldValue('applyDate')
                        && this.props.form.getFieldValue('applyDate').dayOfYear() === moment().add(0, 'day').dayOfYear()
                    )}
                >
                  Tạo bảng giá
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Form>
        <AddProductModal
          dataSource={newProducts}
          visible={addProductModalVisible}
          onCancel={this.hideAddProductModal}
          onSubmit={onAddNewProducts}
        />
      </Card>
    )
  }
}

PriceItemsForm.propTypes = {
  form: PropTypes.object.isRequired,
  prices: PropTypes.array,
  editMode: PropTypes.bool,
  onChangePrice: PropTypes.func,
  removeProduct: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  cartId: PropTypes.array
}

PriceItemsForm.defaultProps = {
  prices: [],
  editMode: false,
  cartId: [],
  removeProduct: () => {},
  onChangePrice: () => {},
  onSubmit: () => {},
  onCancel: () => {}
}

export default Form.create()(PriceItemsForm)
