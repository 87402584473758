/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Row, Col, notification, message } from 'antd'
import moment from 'moment'

import select from '../../util/select'
import {
  removeItem,
  addItem,
  insertPriceTable,
  importPriceTables,
  cancelCreatePrice,
  importPriceItems,
  addItems,
} from './actions'
import { getCustomers } from '../Customer/actions'
import { getProducts } from '../Product/actions'
import { changeAlias } from '../../util/formatText'
import OrderForm from './components/OrderForm'
// import UploadForm from './components/Form'
import router from '../../constants/router'

const isExist = (allProducts, item, orderItems) => {
  for (let i = 0; i < allProducts.length; i++) {
    if (item.productCode === allProducts[i].code) {
      const newItem = {
        product: allProducts[i],
        price: item.pricePerUnit
      }
      orderItems.push(newItem)
      break
    }
  }
}

const clonePriceTable = (allProducts, items) => {
  let orderItems = []
  items.forEach(item => {
    isExist(allProducts, item, orderItems)
  })
  return orderItems
}
class OrderPage extends Component {
  state = {
    products: [],
    filterText: '',
    isBatch: false,
    isImporting: false,
    filterOptions: {},
    orderItems: []
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { products } = nextProps
    let orderItems = []
    if (nextProps.importExist === true) {
      orderItems = clonePriceTable(nextProps.products, nextProps.priceTable.priceItem)
      nextProps.importPriceItems(orderItems)
      nextProps.importDone()
    }
    return {
      ...prevState,
      // orderItems,
      products: products.filter(
        item => changeAlias(item.name.toLowerCase()).indexOf(changeAlias(prevState.filterText.toLowerCase()))
          >= 0,
      ),
    }
  }

  onAddNewProducts = products => {
    const prices = products.map(product => ({
      price: 0,
      product,
    }))
    this.props.addItems(prices)
  }

  onChangeInsertType = ({ target: { value } }) => this.setState(() => ({ isBatch: value }))

  // eslint-disable-next-line arrow-body-style
  onChangePrice = (value, product) => {
    const { prices } = this.props
    let item = prices.filter(e => e.product.id === product.id)
    item = { ...item[0], price: value }
    this.props.addItem(item)
  }

  onAddItem = (product, price) => {
    const item = {
      product,
      price
    }
    this.props.addItem(item)
  }

  removeProduct = id => {
    const { prices } = this.props
    const item = prices.filter(e => e.product.id === id)
    this.props.removeItem(item[0].product.id)
  }

  onFilterProduct = ({ target: { value } }) => this.setState(() => ({ filterText: value }));

  handleChangeCustomer = value => {
    this.props.getProducts(value)
  }

  handleSubmit = payload => {
    if (payload.priceItems.length > 0) {
      this.props.insertPriceTable(
        { ...payload, customerGroupId: this.props.group.id },
        {
          onSuccess: () => {
            notification.success({ message: 'Đã tạo bảng giá mới' })
          },
          onError: () => notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại !' }),
        },
      )
    } else {
      message.warning('Bạn chưa chọn sản phẩm nào')
    }
  }

  handleUpload = async ({ current: { files } }, date) => {
    this.setState({ isImporting: true })
    const formData = new FormData()
    formData.append('file', files[0])
    this.props.importPriceTables(
      {
        payload: formData,
        params: { date: moment(date).toISOString() },
      },
      {
        onSuccess: data => {
          notification.success({
            message: 'Chi tiết',
            description: (
              <div>
                <div>{`${data.Success && data.Success.length} đơn hàng được thêm`}</div>
                <div>{`${data.CustomerFail.length} mã khách hàng lỗi: ${data.CustomerFail.join(' , ')}`}</div>
                <div>{`${data.ProductFail.length} mã sản phẩm lỗi: ${data.ProductFail.join(' , ')}`}</div>
              </div>
            ),
            duration: 15,
          })
          this.setState({ isImporting: false })
          this.props.history.push(router.INVOICE.INDEX)
          this.props.getInvoices({
            page: 1,
            page_size: 10,
          })
        },
        onError: error => {
          notification.error({ message: `${error} - Thêm thất bại ! Vui lòng thử lại !` })
        },
      },
    )
  }

  onChangePage = (page, pageSize) => this.props.getProducts({
    ...this.state.filterOptions,
    page,
    pageSize,
  })

  handleFilter = filterOptions => {
    this.setState({ filterOptions })
    this.props.getProducts({
      ...filterOptions,
      page: 1,
      pageSize: 10,
    })
  }

  render() {
    const { prices, priceItemCodes, isFetching, cancelCreatePrice, priceTable } = this.props
    const { products } = this.state
    return (
      <React.Fragment>
        <Row gutter={24}>
          <Col span={24} style={{ marginTop: 10 }}>
            <OrderForm
              products={products}
              onAddItem={this.onAddItem}
              prices={prices}
              priceItemCodes={priceItemCodes}
              onAddNewProducts={this.onAddNewProducts}
              disabled={isFetching}
              onChangePrice={this.onChangePrice}
              removeProduct={this.removeProduct}
              onChangeCustomer={this.handleChangeCustomer}
              onSubmit={this.handleSubmit}
              onCancel={cancelCreatePrice}
              priceTable={priceTable}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const prices = select(state, 'priceReducer', 'items')
  const priceItemCodes = prices && prices.length ? prices.map(item => item.product.code) : []
  return ({
    categories: select(state, ['categoryReducer', 'list'], 'items'),
    products: select(state, ['productReducer', 'list'], 'items'),
    allProducts: select(state, ['productReducer', 'list'], 'itemsForReview'),
    meta: select(state, ['productReducer', 'list'], 'meta'),
    prices,
    priceItemCodes,
    isFetching: select(state, ['productReducer', 'list'], 'isFetching'),
    cartId: select(state, ['customerReducer', 'priceTable'], 'cartId'),
    priceTable: select(state, ['priceTableReducer', 'exist'], 'item'),
  })
}

const mapDispatchToProps = dispatch => ({
  getProducts: params => dispatch(getProducts(params)),
  getCustomers: () => dispatch(getCustomers()),
  addItem: payload => dispatch(addItem(payload)),
  removeItem: id => dispatch(removeItem(id)),
  cancelCreatePrice: () => dispatch(cancelCreatePrice()),
  insertPriceTable: (payload, meta) => dispatch(insertPriceTable(payload, meta)),
  importPriceTables: (payload, meta) => dispatch(importPriceTables(payload, meta)),
  importPriceItems: data => dispatch(importPriceItems(data)),
  addItems: data => dispatch(addItems(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OrderPage))
