import React, { Component } from 'react'
import { Select, List, Button, Icon, Row, Col, Modal } from 'antd'
import { changeAlias } from '../../../../util/formatText'

const { Option } = Select

class NewProductModal extends Component {
  constructor(props) {
    super(props)
    this.state = { listProducts: [], listProductCodes: [] }
  }

  filterOption = (input, option) => changeAlias(option.props.children.toLowerCase()).indexOf(changeAlias(input.toLowerCase())) >= 0

  onAddProduct = productCode => {
    const { dataSource } = this.props
    const { listProducts, listProductCodes } = this.state
    const product = dataSource.find(item => item.code === productCode)
    if (product) {
      listProducts.push(product)
      listProductCodes.push(productCode)
      this.setState({ listProducts, listProductCodes })
    }
  }

  onAddAllProducts = () => {
    const { dataSource } = this.props
    const listProducts = [...dataSource]
    const listProductCodes = listProducts.map(item => item.code)
    this.setState({ listProducts, listProductCodes })
  }

  onRemoveProduct = productCode => {
    const { listProducts, listProductCodes } = this.state
    const newListProducts = listProducts.filter(item => item.code !== productCode)
    const newListProductCodes = listProductCodes.filter(item => item !== productCode)
    this.setState({ listProducts: newListProducts, listProductCodes: newListProductCodes })
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.listProducts)
    this.props.onCancel()
    this.setState({ listProducts: [], listProductCodes: [] })
  }

  render() {
    const { dataSource, onCancel, visible } = this.props
    const { listProducts, listProductCodes } = this.state

    const listOptions = []
    if (dataSource && dataSource.length) {
      dataSource.forEach(item => {
        if (listOptions.length < 20 && !listProductCodes.includes(item.code)) {
          listOptions.push(item)
        }
      })
    }
    return (
      <Modal
        onCancel={onCancel}
        onOk={this.onSubmit}
        style={{ width: '100px', maxWidth: '90%' }}
        width="90%"
        title="Thêm sản phẩm"
        visible={visible}
      >
        <List
          size="small"
          header={(
            <React.Fragment>
              <div style={{ height: '50px' }}>
                <b style={{ fontSize: '15' }}>Tìm sản phẩm</b>
                <Button onClick={this.onAddAllProducts} style={{ float: 'right' }}>Thêm tất cả</Button>
              </div>
              <Select
                placeholder="Chọn mặt hàng"
                style={{ width: '100%' }}
                showSearch
                // onSearch={this.onSearchProduct}
                filterOption={this.filterOption}
                onSelect={product => this.onAddProduct(product)}
                // ref={this.textSelect}
                showAction={['click', 'focus']}
              >
                {listOptions && listOptions.length
                  ? listOptions.map(item => (<Option key={item.code} value={item.code}>{item.name}</Option>))
                  : null}
              </Select>
            </React.Fragment>
          )}
          dataSource={listProducts}
          pagination={{
            showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '40', '80'],
          }}
          renderItem={(record, index) => (
            <List.Item
              key={index}
              actions={[
                <Button
                  key="delete"
                  shape="circle"
                  type="danger"
                  onClick={() => this.onRemoveProduct(record.code)}
                >
                  <Icon type="delete" />
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={(
                  <Row gutter={10}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 6 }}
                      lg={{ span: 6 }}
                      style={{ textAlign: 'left' }}
                    >
                      {record.code}
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 6 }}
                      lg={{ span: 6 }}
                      style={{ textAlign: 'left' }}
                    >
                      {<b>{record.name}</b>}
                    </Col>
                  </Row>
                )}
              />
            </List.Item>
          )}
        />
      </Modal>
    )
  }
}

export default NewProductModal
