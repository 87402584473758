import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'

// API
const gettingAPIs = () => ({ type: TYPES.GETTING_API_FOR_MANAGA })

const getAPIsSuccess = payload => ({
  type: TYPES.GET_API_FOR_MANAGA_SUCCESS,
  payload,
})

const getAPIsFailure = () => ({ type: TYPES.GET_ALL_PRODUCTS_FAILURE })

export const getAPIs = params => async dispatch => {
  const api = API.MANAGA.getAPIManaga()

  dispatch(gettingAPIs())
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    const { data, metadata } = response.data
    dispatch(getAPIsSuccess({ apis: data, meta: metadata }))
  } else {
    dispatch(getAPIsFailure())
  }
}

const creatingAPI = () => ({ type: TYPES.CREATING_API })
const createAPISuccess = () => ({ type: TYPES.CREATE_API_SUCCESS })
const createAPIFailure = () => ({ type: TYPES.CREATE_API_FAILURE })

export const createAPI = (payload, meta, pageSizeAPI) => async dispatch => {
  const api = API.MANAGA.createAPIManaga()
  dispatch(creatingAPI())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(createAPISuccess())
    dispatch(getAPIs({ page: -1, pageSize: pageSizeAPI }))
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data.data.id)
    }
  } else if (error && error.status === 409) {
    dispatch(createAPIFailure())
    if (meta && meta.onError) {
      meta.onError('API đã tồn tại')
    }
  } else {
    dispatch(createAPIFailure())
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

const updatingAPI = () => ({ type: TYPES.UPDATING_API })
const updateAPISuccess = (id, payload) => ({ type: TYPES.UPDATE_API_SUCCESS, id, payload })
const updateAPIFailure = () => ({ type: TYPES.UPDATE_API_FAILURE })
const changeStatusAPISuccess = (id, payload) => ({ type: TYPES.CHANGE_STATUS_API_SUCCESS, id, payload })

export const changeStatusAPI = (id, payload, meta) => async dispatch => {
  const api = API.MANAGA.changeStatusAPIManaga(id)
  dispatch(updatingAPI())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    if (meta && meta.onSuccess) {
      dispatch(changeStatusAPISuccess(id, payload))
      meta.onSuccess()
    }
  } else {
    dispatch(updateAPIFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const updateAPI = (id, payload, meta, pageSizeAPI) => async dispatch => {
  const api = API.MANAGA.updateAPIManaga(id)
  dispatch(updatingAPI())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    if (meta && meta.onSuccess) {
      dispatch(getAPIs({ page: -1, pageSize: pageSizeAPI }))
      dispatch(updateAPISuccess(id, payload))
      meta.onSuccess()
    }
  } else {
    dispatch(updateAPIFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

// Role
const gettingRoles = () => ({ type: TYPES.GETTING_ROLE_FOR_MANAGA })
const getRolesSuccess = payload => ({
  type: TYPES.GET_ROLE_FOR_MANAGA_SUCCESS,
  payload,
})
const getRolesFailure = () => ({ type: TYPES.GET_ROLE_FOR_MANAGA_FAILURE })

export const getRoles = params => async dispatch => {
  const api = API.MANAGA.getRoleManaga()

  dispatch(gettingRoles())
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    const { data, metadata } = response.data
    dispatch(getRolesSuccess({ roles: data, meta: metadata }))
  } else {
    dispatch(getRolesFailure())
  }
}

const creatingRole = () => ({ type: TYPES.CREATING_ROLE })
const createRoleSuccess = () => ({ type: TYPES.CREATE_ROLE_SUCCESS })
const createRoleFailure = () => ({ type: TYPES.CREATE_ROLE_FAILURE })

export const createRole = (payload, meta, pageSizeRole) => async dispatch => {
  const api = API.MANAGA.createRoleManaga()
  dispatch(creatingRole())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(createRoleSuccess())
    dispatch(getRoles({ pageSize: -1 }))
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data.data.id)
    }
  } else if (response.data.success === false) {
    dispatch(createRoleFailure())
    if (meta && meta.onError) {
      meta.onError('Vai trò đã tồn tại')
    }
  }
}
// update
const editingRole = () => ({ type: TYPES.EDITING_ROLE })
const editRoleSuccess = (id, payload) => ({ type: TYPES.EDIT_ROLE_SUCCESS, id, payload })
const editRoleFailure = () => ({ type: TYPES.EDIT_ROLE_FAILURE })

export const editRole = (id, payload, meta) => async dispatch => {
  const api = API.MANAGA.editRoleManaga(id)
  dispatch(editingRole())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    if (meta && meta.onSuccess) {
      dispatch(editRoleSuccess(id, payload))
      dispatch(getRoles({ pageSize: -1 }))
      meta.onSuccess()
    }
  } else {
    dispatch(editRoleFailure())
    if (meta && meta.onError) {
      meta.onError('Vai trò đã tồn tại trong hệ thống')
    }
  }
}

const activeRoleSuccess = id => ({ type: TYPES.ACTIVE_ROLE_SUCCESS, id })

export const activeRole = (id, meta) => async dispatch => {
  const api = API.MANAGA.activateRoleManaga(id)
  dispatch(editingRole())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    if (meta && meta.onSuccess) {
      dispatch(activeRoleSuccess(id))
      meta.onSuccess()
    }
  } else {
    dispatch(editRoleFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

const deactiveRoleSuccess = id => ({ type: TYPES.DEACTIVE_ROLE_SUCCESS, id })

export const deactiveRole = (id, meta) => async dispatch => {
  const api = API.MANAGA.deactivateRoleManaga(id)
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    if (meta && meta.onSuccess) {
      dispatch(deactiveRoleSuccess(id))
      meta.onSuccess()
    }
  } else {
    dispatch(editRoleFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

// delete
const deletingApiOfRole = () => ({ type: TYPES.DELETING_API_OF_ROLE })
const deleteApiOfRoleSuccess = (roleId, apiId) => ({ type: TYPES.DELETE_API_OF_ROLE_SUCCESS, roleId, apiId })
const deleteApiOfRoleFailure = () => ({ type: TYPES.DETELE_API_OF_ROLE_FAILURE })


export const deleteApiOfRole = (roleId, apiId, meta) => async dispatch => {
  const api = API.MANAGA.deleteApiOfRoleManaga(roleId, apiId)
  dispatch(deletingApiOfRole())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(deleteApiOfRoleSuccess(roleId, apiId))
    dispatch(getRoles({ pageSize: -1 }))
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(deleteApiOfRoleFailure())
    if (meta && meta.onSuccess) {
      meta.onError()
    }
  }
}

const deleteListApiOfRoleSuccess = payload => ({ type: TYPES.DELETE_LIST_API_OF_ROLE_SUCCESS, payload })

export const deleteListApiOfRole = (payload, meta) => async dispatch => {
  const api = API.MANAGA.deleteListApiOfRoleManaga()
  dispatch(deletingApiOfRole())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(deleteListApiOfRoleSuccess(payload))
    dispatch(getRoles({ pageSize: -1 }))
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(deleteApiOfRoleFailure())
    if (meta && meta.onSuccess) {
      meta.onError()
    }
  }
}

const creatingApiOfRole = () => ({ type: TYPES.CREATING_API_OF_ROLE })
const createApiOfRoleSuccess = payload => ({ type: TYPES.CREATE_API_OF_ROLE_SUCCESS, payload })
const createApiOfRoleFailure = () => ({ type: TYPES.CREATE_API_OF_ROLE_FAILURE })

export const createApiOfRole = (payload, meta) => async dispatch => {
  const api = API.MANAGA.addListApiOfRoleManaga()
  dispatch(creatingApiOfRole())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(createApiOfRoleSuccess(payload))
    dispatch(getRoles({ pageSize: -1 }))
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(createApiOfRoleFailure())
    if (meta && meta.onSuccess) {
      meta.onError()
    }
  }
}

export const addApiOfRoleFromExcel = (payload, meta) => async dispatch => {
  const api = API.MANAGA.addApiOfRoleFromExcelManaga()
  const param = { roleApis: payload }
  dispatch(creatingApiOfRole())
  const { response, error } = await apiCall({ ...api, payload: param })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(createApiOfRoleSuccess())
    dispatch(getRoles({ pageSize: -1 }))
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(createApiOfRoleFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}
