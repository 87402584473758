import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Table, Icon, Button, notification, Modal } from 'antd'
import WithLoading from '../../../hoc/loading'
import ROUTER from '../../../constants/router'

const { confirm } = Modal
class ScheduleList extends Component {
  onDelete = (e, scheduleCode) => {
    e.preventDefault()
    confirm({
      title: 'Bạn có muốn xóa',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk: () => {
        // this.getAppConfig()
        this.props.onDelete(scheduleCode)
      },
    })
    // this.props.onDelete(scheduleCode)
  };

  render() {
    const {
      schedules,
      onChange,
      onShowSizeChange,
      meta: { pageSize, current, total },
    } = this.props
    const columns = [
      {
        title: 'Tên lịch',
        dataIndex: 'name',
      },
      {
        title: 'Ngày giao',
        dataIndex: 'executionTime',
        render: (text, record) => moment(record.executionTime).format('DD/MM/YYYY'),
      },
      {
        title: 'Số lượng lái xe',
        dataIndex: 'NumberOfCustomers',
        align: 'center',
        render: (text, record) => record.drivers.length,
      },
      {
        title: 'Chỉnh sửa',
        dataIndex: 'code',
        render: (text, record) => (
          <div>
            <Link to={ROUTER.SCHEDULE.DETAIL.replace(':id', record.code)}>
              <Button shape="circle">
                <Icon type="info" />
              </Button>
              {' '}
            </Link>
            <Button shape="circle" type="danger" onClick={e => this.onDelete(e, record.code)}>
              <Icon type="delete" />
            </Button>
          </div>
        ),
      },
    ]
    return (
      <Table
        size="small"
        style={{ marginTop: 10 }}
        pagination={{
          current,
          pageSize,
          total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} schedule`,
          onChange,
          showSizeChanger: true,
          onShowSizeChange,
          pageSizeOptions: ['5', '10'],
        }}
        columns={columns}
        rowKey={(index, id) => id}
        rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
        scroll={{ x: 'max-content' }}
        dataSource={schedules}
      />
    )
  }
}

ScheduleList.propTypes = {
  schedules: PropTypes.array,
  meta: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onShowSizeChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

ScheduleList.defaultProps = {
  schedules: [],
  meta: {},
}

export default WithLoading(ScheduleList)
