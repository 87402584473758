/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Row, Col, Card, Modal, DatePicker, message } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'

import { changeAlias } from '../../../util/formatText'
import router from '../../../constants/router'

const { confirm } = Modal
class CustomerInfo extends Component {
  constructor(props) {
    super(props)
    this.searchProduct = debounce(this.searchProduct, 300)

    this.state = {
      customer: null,
      hasCustomer: false,
      date: '',
      currentPriceTableId: null,
      dataCustomerWhenSearch: [],
      orderDate: moment().add(1, 'd'),
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.customer !== state.customer) {
      return { ...state, customer: props.customer }
    }
    return { ...state }
  }

  componentDidMount() {
    const date1 = this.state.orderDate
    this.props.onGetRequireTimeTo(moment(date1).utc().format())
    this.setState({ customer: {} })
  }

  getCustomerInfo = code => {
    if (code !== this.props.customer.code) {
      this.setState({ hasCustomer: true })
      this.removeAllProduct()
      this.props.form.resetFields('cartCode')
      const currentDate = this.state.orderDate
      this.props.onChangeCustomer(code)
      if (currentDate) {
        this.props.onGetProducts(
          {
            customerCode: code,
            orderDate: currentDate.format('YYYY-MM-DD').replace('+07:00', 'Z')
          },
          {
            onSuccess: newPriceTableId => {
              this.setState({ currentPriceTableId: newPriceTableId })
              this.props.onGetPriceTable(newPriceTableId, { pageSize: -1 })
            },
            onError: error => {
              message.warning(error)
            }
          }
        )
      }
    }
  }

  removeAllProduct = () => {
    const order = { Items: [] }
    this.props.onAddItem(order)
  }

  handleCancel = currentDate => {
    this.setState({ orderDate: currentDate })
    this.props.onChangeRequireTime(currentDate)
    this.props.form.setFieldsValue({ orderDate: currentDate })
  }

  handleOk = (requireTime, priceTableId) => {
    this.removeAllProduct()
    this.setState({ orderDate: requireTime })
    this.props.form.setFieldsValue({ orderDate: requireTime })
    this.props.onChangeRequireTime(requireTime)

    this.props.onGetPriceTable(priceTableId, { pageSize: -1 })
  }

  // onChangeorderDate = value => {
  //   this.setState({ orderDate: moment(value).format('YYYY-MM-DD') })
  // }

  checkNewPriceTable = value => {
    this.props.form.validateFields(err => {
      if (!err) {
        const currentDate = this.state.orderDate
        const selectedDate = moment(value).format('YYYY-MM-DD')
        const tmp_selectedDate = moment(value)
        // const requireTime = moment(value).format().replace('+07:00', 'Z')
        if (this.props.customer.code) {
          this.props.onGetRequireTimeTo(moment(value).utc().format())
          this.props.onGetProducts(
            {
              customerCode: this.props.customer.code,
              orderDate: selectedDate
            },
            {
              onSuccess: newPriceTableId => {
                const { currentPriceTableId } = this.state
                if (currentPriceTableId && currentPriceTableId !== newPriceTableId) {
                  this.showConfirm(currentDate, tmp_selectedDate, newPriceTableId)
                }
                this.setState({ currentPriceTableId: newPriceTableId })
              },
              onError: error => {
                message.warning(error)
                this.setState({ orderDate: tmp_selectedDate })
                this.props.onChangeorderDate(tmp_selectedDate)
              }
            }
          )
        }
      } else {
        console.log(err)
      }
    })
  }

  checkNewPriceTable2 = value => {
    this.props.form.validateFields(err => {
      if (!err) {
        const currentDate = this.state.date
        const selectedDate = moment(value).format('YYYY-MM-DD')
        const tmp_selectedDate = moment(value)
        // const requireTime = moment(value).format().replace('+07:00', 'Z')

        this.setState({ date: tmp_selectedDate })
        this.props.onChangeRequireTime(tmp_selectedDate)
      }
    })
  }

  showConfirm = (currentDate, requireTime, priceTableId) => {
    confirm({
      title: (
        <React.Fragment>
          <p>Có bảng giá mới.</p>
          <p>Các sản phẩm đã thêm sẽ bị xóa.</p>
          <p>Bạn chắn chắn muốn đổi ngày giao hàng?</p>
        </React.Fragment>
      ),
      onOk: () => this.handleOk(requireTime, priceTableId),
      onCancel: () => this.handleCancel(currentDate)
    })
  }

  searchProduct = value => {
    const { customers } = this.props

    const data = []
    for (let i = 0; i < customers.length; i++) {
      if (
        (customers[i].cartCode.toLowerCase().indexOf(value.toLowerCase()) !== -1
          || changeAlias(customers[i].name.toLowerCase()).indexOf(changeAlias(value.toLowerCase())) !== -1) && data.length <= 50
      ) {
        data.push(customers[i])
      }
    }

    this.setState({ dataCustomerWhenSearch: data })
  }

  render() {
    const { form: { getFieldDecorator }, customers } = this.props
    const { customer, hasCustomer, date, dataCustomerWhenSearch, orderDate } = this.state
    const dataCustomer = dataCustomerWhenSearch.length ? dataCustomerWhenSearch : customers.slice(0, 30)

    return (
      <React.Fragment>
        <Card
          title="Thông tin đơn hàng"
        >
          <Row gutter={24}>
            <Col span={24} lg={12}>
              <Form.Item label="Chọn khách hàng" required>
                {getFieldDecorator('customerId', {
                  rules: [
                    {
                      required: true,
                      message: 'Chọn khách hàng',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="Nhập tên khách hàng"
                    onChange={values => this.getCustomerInfo(values)}
                    onSearch={this.searchProduct}
                    filterOption={false}
                  >
                    {
                      dataCustomer.map((item, index) => (
                        <Select.Option key={`customer${index + 1}`} value={item.code}>
                          {item.name}
                        </Select.Option>
                      ))
                    }
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Ngày đơn hàng">
                {getFieldDecorator('orderDate', {
                  initialValue: orderDate,
                  rules: [
                    {
                      required: true,
                      message: 'Chọn ngày đơn hàng',
                    },
                  ],
                })(
                  <DatePicker
                    allowClear={false}
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    onChange={value => this.checkNewPriceTable(value)}
                    disabledDate={current => (
                      current
                      && current < Date.now() - 86400000
                    )}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={24} lg={12}>
              <Form.Item label="Ngày giao hàng">
                {getFieldDecorator('date', {
                  initialValue: date,
                  // rules: [
                  //   { message: 'Chọn ngày giao hàng', },
                  // ],
                })(
                  <DatePicker
                    placeholder="để trống nếu giống với ngày đơn hàng"
                    allowClear={false}
                    style={{ width: '100%' }}
                    format="DD/MM/YYYY"
                    onChange={value => this.checkNewPriceTable2(value)}
                    disabledDate={current => (
                      current
                      && current < Date.now() - 86400000
                    )}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              Mã khách hàng:
              {' '}
            </Col>
            <Col span={12}><b>{hasCustomer && customer.code}</b></Col>
          </Row>
          <Row>
            <Col span={12}>
              Mã giỏ hàng:
              {' '}
            </Col>
            <Col span={12}><b>{hasCustomer && (customer.cartCode !== '' ? customer.cartCode : (<span style={{ color: 'red' }}>Chưa có giỏ hàng! Thêm khách hàng vào giỏ hàng để đặt hàng</span>))}</b></Col>
          </Row>
          <Row>
            <Col span={12}>
              Nhóm KH:
              {' '}
            </Col>
            <Col span={12}>
              {hasCustomer && customer.groupName ? (
                <Link to={router.CUSTOMER_GROUP.DETAIL.replace(':id', customer.groupId)}>
                  {customer.groupName}
                </Link>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              Địa chỉ:
              {' '}
            </Col>
            <Col span={18}><b>{hasCustomer && customer.cartAddress}</b></Col>
          </Row>
          <Row>
            <Col span={6}>
              SĐT:
              {' '}
            </Col>
            <Col span={18}><b>{hasCustomer && customer.phoneNumber}</b></Col>
          </Row>
        </Card>
      </React.Fragment>
    )
  }
}

CustomerInfo.propTypes = {
  customers: PropTypes.array,
  customer: PropTypes.object,
  form: PropTypes.object.isRequired,
  onAddItem: PropTypes.func,
  onChangeCustomer: PropTypes.func,
  onGetProducts: PropTypes.func,
  onGetPriceTable: PropTypes.func,
  onChangeRequireTime: PropTypes.func,
  onGetRequireTimeTo: PropTypes.func,
  onChangeorderDate: PropTypes.func,
}

CustomerInfo.defaultProps = {
  customers: [],
  customer: {},
  onAddItem: () => { },
  onChangeCustomer: () => { },
  onGetPriceTable: () => { },
  onGetProducts: () => { },
  onChangeRequireTime: () => { },
  onGetRequireTimeTo: () => { },
  onChangeorderDate: () => { },
}

export default Form.create()(CustomerInfo)
