import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button } from 'antd'
import moment from 'moment'

const FormItem = Form.Item

class SaveRouteForm extends Component {
    handleSubmit = event => {
      event.preventDefault()
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit(values)
        }
      })
    };

    render() {
      const { nodes, form, time } = this.props
      const { getFieldDecorator } = form
      return (
        <Form onSubmit={this.handleSubmit}>
          <FormItem label="Tên">
            {getFieldDecorator('name', {
              initialValue: moment(time).format('DD-MM-YYYY'),
              rules: [
                {
                  required: true,
                  message: 'Hãy nhập tên!',
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem label="Số khách hàng">
            {getFieldDecorator('numberOfCustomers', { initialValue: nodes.length })(<Input disabled />)}
          </FormItem>
          <FormItem>
            <Button htmlType="submit" type="primary">
              Lưu
            </Button>
          </FormItem>
        </Form>
      )
    }
}

SaveRouteForm.propTypes = {
  form: PropTypes.object.isRequired,
  time: PropTypes.string,
  nodes: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
}

SaveRouteForm.defaultProps = {
  time: moment().toISOString(),
  nodes: [],
}

export default Form.create()(SaveRouteForm)
