/* eslint-disable semi */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, notification, Row, Col, Modal, Select, Tag } from 'antd';
import LocationMap from './Map';
import { backpage } from '../../../../util/windows';
import { formatAddress } from '../../../../util/formatAddress';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const IndexAddressField = ['country', 'city', 'district', 'street', 'streetNumber'];

class CartForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueCode: '',
      statuses: [
        { name: 'Ngừng phục vụ', val: 'false' },
        { name: 'Phục vụ', val: 'true' },
      ],
    };
  }

  onDelete = name => {
    Modal.confirm({
      okText: 'Xoá khách hàng',
      okButtonProps: { type: 'danger' },
      onOk: this.props.onDelete,
      cancelText: 'Huỷ',
      cancelButtonProps: { type: 'primary' },
      title: `Bạn chắc chắn xoá khách hàng ${name}`,
      content: 'Tài khoản và mọi thông tin liên quan sẽ bị xoá khỏi hệ thống !',
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      values.code = this.state.valueCode;
      if (!err) {
        const { streetNumber, street, district, city, country, lat, lng } = values;
        const obj = { streetNumber, street, district, city, country };
        const address = formatAddress(obj);
        const newLat = Number.parseFloat(lat);
        const newLng = Number.parseFloat(lng);
        this.props.onSubmit({ ...values, address, lat: newLat, lng: newLng });
      }
    });
  };

  changeId = e => {
    const valueinput = e.target.value;
    const part = /\s/g;
    const value = valueinput.replace(part, '');
    this.setState({ valueCode: value });
  };

  handleSearch = ({ lat, lng }, address) => {
    this.props.form.setFieldsValue({ lat, lng });
    notification.info({ message: 'Đã thiết lập tọa độ.' });
    if (address !== undefined) {
      IndexAddressField.forEach(item => this.props.form.setFieldsValue({ [item]: '' }));
      let tailAddress = '';
      address.reverse().forEach((item, index) => {
        if (index > 3) {
          tailAddress = `${item.long_name} ${tailAddress}`;
        } else {
          this.props.form.setFieldsValue({ [IndexAddressField[index]]: item.long_name });
        }
      });
      this.props.form.setFieldsValue({ streetNumber: tailAddress });
    }
  };

  render() {
    const {
      cart,
      editMode,
      form: { getFieldDecorator },
    } = this.props;
    const lat = cart && cart.lat !== undefined ? cart.lat : null;
    const lng = cart && cart.lng !== undefined ? cart.lng : null;
    return (
      <Form>
        <FormItem>
          <LocationMap onSearch={this.handleSearch} center={lat && lng ? { lat, lng } : null} />
        </FormItem>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Row gutter={24}>
              <Col span={12} lg={12}>
                <FormItem label="Mã giỏ">
                  {editMode ? (
                    <Tag style={{ fontSize: '24px' }}>{cart && cart.code}</Tag>
                  ) : (
                    getFieldDecorator('code', {
                      rules: [
                        {
                          required: true,
                          message: ' Nhập mã giỏ !',
                        },
                      ],
                    })(<Input style={{ width: '100%' }} onChange={this.changeId} placeholder="Mã giỏ" allowClear />)
                  )}
                </FormItem>
              </Col>
              <Col span={12} lg={12}>
                {editMode && (
                  <FormItem label="Trạng thái">
                    {getFieldDecorator('active', {
                      initialValue: editMode ? (cart && cart.active ? 'true' : 'false') : undefined,
                    })(
                      <Select placeholder="Trạng thái" allowClear disabled={editMode}>
                        {this.state.statuses.map((item, index) => (
                          <Option value={item.val} key={`${index + 1}`}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                )}
              </Col>
              <Col span={12} lg={12}>
                <FormItem label="Tên giỏ">
                  {getFieldDecorator('name', {
                    initialValue: editMode ? cart.name : '',
                    rules: [
                      {
                        required: true,
                        message: 'Hãy nhập tên giỏ hàng',
                      },
                    ],
                  })(<Input style={{ width: '100%' }} placeholder="Tên giỏ" allowClear />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12} lg={12}>
                <FormItem label="Số điện thoại">
                  {getFieldDecorator('phoneNumber', {
                    initialValue: editMode ? cart.phoneNumber : '',
                    rules: [
                      // {
                      //   required: true,
                      //   message: 'Nhập số điện thoại',
                      // },
                      {
                        pattern: new RegExp(/^(0)[1-9]([0-9]{8}[0-9]?)\b/),
                        message: 'Sai định dạng số điện thoại',
                      },
                    ],
                  })(<Input type="text" style={{ width: '100%' }} placeholder="Số điện thoại" allowClear />)}
                </FormItem>
              </Col>
              <Col span={12} lg={12}>
                <FormItem label="Email">
                  {getFieldDecorator('email', {
                    initialValue: editMode ? cart.email : '',
                    rules: [
                      {
                        type: 'email',
                        message: 'Địa chỉ email không chinh xác',
                      },
                      // {
                      //   required: true,
                      //   message: 'Hãy nhập email',
                      // },
                    ],
                  })(<Input style={{ width: '100%' }} placeholder="Email" allowClear />)}
                </FormItem>
              </Col>
            </Row>
            <FormItem label="Ghi chú">
              {getFieldDecorator('note', { initialValue: editMode ? cart.note : '' })(
                <TextArea type="text" placeholder="Thông tin thêm ..." />,
              )}
            </FormItem>
          </Col>
          <Col span={24} lg={12}>
            <Row gutter={24}>
              <Col span={12} lg={12}>
                <FormItem label="Vĩ độ">
                  {getFieldDecorator('lat', {
                    initialValue: editMode ? cart.lat : null,
                    rules: [
                      {
                        required: true,
                        message: 'Tọa độ không chính xác !',
                      },
                    ],
                  })(<Input placeholder="Nhập vĩ độ" allowClear />)}
                </FormItem>
              </Col>
              <Col span={12} lg={12}>
                <FormItem label="Kinh độ">
                  {getFieldDecorator('lng', {
                    initialValue: editMode ? cart.lng : null,
                    rules: [
                      {
                        required: true,
                        message: 'Tọa độ không chính xác !',
                      },
                    ],
                  })(<Input placeholder="Nhập kinh độ" allowClear />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12} lg={12}>
                <FormItem label="Số nhà">
                  {getFieldDecorator('streetNumber', { initialValue: editMode ? cart.streetNumber : '' })(
                    <Input type="text" placeholder="Số nhà" allowClear />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12} lg={12}>
                <FormItem label="Đường/phố">
                  {getFieldDecorator('street', { initialValue: editMode ? cart.street : '' })(
                    <Input type="text" placeholder="Phố" allowClear />,
                  )}
                </FormItem>
              </Col>
              <Col span={12} lg={12}>
                <FormItem label="Quận/huyện">
                  {getFieldDecorator('district', { initialValue: editMode ? cart.district : '' })(
                    <Input type="text" placeholder="Quận/huyện" allowClear />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} lg={12}>
                <FormItem label="Tỉnh/thành phố">
                  {getFieldDecorator('city', { initialValue: editMode ? cart.city : 'Hà Nội' })(
                    <Input type="text" allowClear />,
                  )}
                </FormItem>
              </Col>
              <Col span={24} lg={12}>
                <FormItem label="Quốc gia">
                  {getFieldDecorator('country', { initialValue: editMode ? cart.country : 'Việt Nam' })(
                    <Input type="text" allowClear />,
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        <FormItem>
          <Button ghost style={{ border: 'none' }} type="danger" onClick={() => backpage()}>
            Quay lại
          </Button>

          <Button style={{ float: 'right' }} type="primary" onClick={this.handleSubmit}>
            {editMode ? 'Hoàn thành' : 'Thêm giỏ hàng'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

CartForm.propTypes = {
  editMode: PropTypes.bool,
  cart: PropTypes.object,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

CartForm.defaultProps = {
  editMode: false,
  cart: {},
};

export default Form.create()(CartForm);
