import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Table } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ORDER_STATUS } from '../../../constants/enum'
import './index.css'
import router from '../../../constants/router'

const columns = toggleHistory => [
  {
    title: 'Mã đơn',
    dataIndex: 'orderCode',
    width: '10%',
    render: value => (
      <Link onClick={toggleHistory} to={router.INVOICE.DETAIL.replace(':id', value)}>{value}</Link>
    )
  },
  {
    title: 'Giỏ hàng',
    dataIndex: 'cartName',
    width: '10%',
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'address',
    width: '20%',
  },
  {
    title: 'Ngày đặt hàng',
    dataIndex: 'createdAt',
    width: '20%',
    render: value => moment(value.replace('Z', '+07:00')).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    title: 'Ngày giao hàng',
    dataIndex: 'requireTime',
    width: '20%',
    render: value => moment(value.replace('Z', '+07:00')).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    align: 'center',
    width: '20%',
  }
]

const HistoryOrder = ({ visible, toggleHistory, invoices, isFetching }) => (
  <Modal
    visible={visible}
    title="Lịch sử đặt hàng"
    closable
    maskClosable
    centered
    width="1000px"
    onCancel={toggleHistory}
    footer={[
      <Button key="back" onClick={toggleHistory}>
            Đóng
      </Button>,
    ]}
  >
    <Table
      columns={columns(toggleHistory)}
      dataSource={invoices}
      loading={isFetching}
      rowClassName={record => `invoiceStatus${ORDER_STATUS.indexOf(record.status)}`}
      scroll={{ y: 480 }}
      pagination={{ pageSize: 10 }}
    />
  </Modal>
)

HistoryOrder.propTypes = {
  invoices: PropTypes.array,
  toggleHistory: PropTypes.func,
  visible: PropTypes.bool,
  isFetching: PropTypes.bool,
}

HistoryOrder.defaultProps = {
  toggleHistory: () => {},
  invoices: [],
  visible: false,
  isFetching: false,
}

export default Form.create()(HistoryOrder)
