/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, DatePicker, Button, Form } from 'antd'
import { isBelowBreakpoint } from '../../../../util/windows'


const { Item } = Form
class FilterRow extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { forCustomer, customerCode } = this.props
        const { date } = values
        if (date === undefined) {
          if (forCustomer) {
            this.props.onFilter({ customerCode })
          } else {
            this.props.onFilter({ groupID: this.props.groupID })
          }
        } else {
          if (forCustomer) {
            this.props.onFilter({ customerCode, date: date.format('YYYY-MM-DD') })
          } else {
            this.props.onFilter({ groupID: this.props.groupID, date: date.format('YYYY-MM-DD') })
          }
        }
      }
    })
  };

  handleReset = () => {
    this.props.form.resetFields()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { forCustomer, customerCode } = this.props
        if (forCustomer) {
          this.props.onFilter({ ...values, customerCode })
        } else {
          this.props.onFilter({ ...values, groupID: this.props.groupID })
        }
      }
    })
  };

  render() {
    const { form: { getFieldDecorator } } = this.props
    return (
      <Form onSubmit={this.handleSubmit}>
        <Col span={24} lg={8}>
          <Item label="Chọn ngày">
            {
              getFieldDecorator('date')(
                <DatePicker
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Chọn một ngày"
                  format="DD/MM/YYYY"
                />,
              )
            }
          </Item>
        </Col>
        <Col span={12} lg={4} style={{ marginTop: isBelowBreakpoint() ? 1 : 40 }}>
          <Button icon="filter" type="primary" htmlType="submit" style={{ width: '100%' }}>
            {'Lọc'}
          </Button>
        </Col>
        <Col span={12} lg={4} style={{ marginTop: isBelowBreakpoint() ? 1 : 40 }}>
          <Button icon="close" onClick={this.handleReset} style={{ width: '100%' }}>
            {'Bỏ lọc'}
          </Button>
        </Col>
      </Form>
    )
  }
}

FilterRow.propTypes = {
  onFilter: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
}

FilterRow.defaultProps = { carts: [] }

export default Form.create()(FilterRow)
