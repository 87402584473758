import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, DatePicker, Button } from 'antd'
import moment from 'moment'

const { Item } = Form

class NodeFilterForm extends Component {
  componentDidMount() {
    this.onSubmit()
  }

  onSubmit = isUbo => this.props.form.validateFields((err, values) => {
    if (!err) {
      this.props.onSubmit(
        values.require_time
          .hour(7)
          .minute(0)
          .second(0)
          .millisecond(0),
        isUbo
      )
    }
  });

  handleChangeTime = value => {
    const { form, setFilterTime, defaultTime } = this.props
    form.setFieldsValue({ require_time: value })
    setFilterTime(value ? value.toISOString() : defaultTime)
  }

  handleSubmit = event => {
    event.preventDefault()
    this.onSubmit()
  };

  render() {
    const { form: { getFieldDecorator }, defaultTime } = this.props
    return (
      <Form onSubmit={this.handleSubmit} layout="inline">
        <Item label="Ngày giao hàng">
          {getFieldDecorator('require_time', {
            rules: [{ required: true, message: 'Hãy chọn ngày!' }],
            initialValue: defaultTime ? moment(defaultTime) : moment(),
          })(<DatePicker placeholder="Hãy chọn ngày" format="DD/MM/YYYY" onChange={this.handleChangeTime} />)}
        </Item>
        <Item>
          <Button icon="filter" type="primary" htmlType="submit">
            {'Lọc'}
          </Button>
        </Item>
        <Item>
          <Button icon="filter" type="danger" onClick={() => this.onSubmit(true)}>
            {'Đơn Ubo'}
          </Button>
        </Item>
      </Form>
    )
  }
}

NodeFilterForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Form.create()(NodeFilterForm)
