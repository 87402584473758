/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React from 'react'
import { Button, Form, Modal, Row, Select, Col } from 'antd'
import { changeAlias } from '../../../../util/formatText'
import WithLoading from '../../../../hoc/loading'

const { Item } = Form
const { Option } = Select

// eslint-disable-next-line prefer-const
class MultiEditCustomer extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values)
      }
    })
  }

  resetForm = () => {
    this.props.form.resetFields()
  }

  onClose = () => {
    const { onCloseModal } = this.props
    this.resetForm()
    onCloseModal()
  }

  render() {
    const { form, visible, accounts, isFetching } = this.props
    const { getFieldDecorator } = form
    return (
      <Modal
        title="Sửa Khách hàng đã chọn"
        visible={visible}
        closable={false}
        width="90%"
        footer={[
          <Button
            type="primary"
            icon="plus"
            key="submit"
            loading={isFetching}
            onClick={this.handleSubmit}
          >
            {'Sửa'}
          </Button>,
          <Button
            key="close"
            icon="close"
            onClick={this.onClose}
            disabled={isFetching}
          >
              Đóng cửa sổ
          </Button>,
        ]}
      >
        {
          (
            <Form>
              <Row gutter={24}>
                <Col span={12} lg={6}>
                  <Item label="Hình thức thanh toán">
                    {getFieldDecorator('paymentMethod')(
                      <Select
                        placeholder="Không thay đổi"
                        allowClear
                        filterOption={(input, option) => changeAlias(option.props.children.toLowerCase()).indexOf(
                          changeAlias(input.toLowerCase()),
                        ) >= 0}
                      >
                        <Option key={1} value="Theo hợp đồng">Theo hợp đồng</Option>
                        <Option key={2} value="Thanh toán trực tiếp">Thanh toán trực tiếp</Option>
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col span={12} lg={6}>
                  <Item label="Nhân viên quản lý">
                    {getFieldDecorator('saleInChargeId')(
                      <Select
                        placeholder="Không thay đổi"
                        allowClear
                      >
                        {accounts.filter(e => e.role === 'sale').map(item => (
                          <Option key={item.id} value={item.id}>{item.name}</Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
            </Form>
          )
        }
      </Modal>
    )
  }
}

export default WithLoading(Form.create()(MultiEditCustomer))
