/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Modal, Row, Col, Button, notification, Divider } from 'antd'

import WithPageHOC from '../../hoc/page'
import CartList from './components/List'
import CartCard from './components/Card'
import FilterRow from './components/Filter'

import { getCarts, importCarts, updateCart, changeCartStatus } from './actions'

import ROUTER from '../../constants/router'

import select from '../../util/select'

class CartsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cart: {},
      showCartModal: false,
      filterOptions: {}
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location
    let params
    if (search !== state.search) {
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
        props.getCarts(JSON.parse(params))
      } else {
        props.getCarts()
      }
      return { ...state, search, filterOptions: params }
    }
    return state
  }

  handleAdd = () => this.props.history.push(ROUTER.CART.ADD);

  handleFilter = filterOptions => {
    this.setState({ filterOptions })
    this.props.getCarts({
      ...filterOptions,
      page: this.props.meta.current,
      pageSize: this.props.meta.pageSize,
    })
  };

  viewCart = (e, cart) => {
    e.preventDefault()
    this.setState({ cart, showCartModal: true })
  };

  handleCancel = () => this.setState({ showCartModal: false });

  editCart = cart => {
    this.props.history.push(ROUTER.CART.EDIT.replace(':id', cart.code))
  };

  changeStatus = (id, payload) => {
    this.props.changeStatus(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật trạng thái' })
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    })
  }

  onChangePage = (page, pageSize) => {
    const { search } = this.props.history.location
    if (search !== '') {
      let params
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      }
      const obj = JSON.parse(params)
      params = { ...obj, page, pageSize }

      const urlParameters = []
      Object.entries(params).map(e => {
        if (e[1] !== undefined && e[1] !== '') {
          urlParameters.push(e.join('='))
        }
        return null
      })
      this.props.history.push(ROUTER.CART.INDEX.concat(`?${urlParameters.join('&')}`))
    } else {
      this.props.history.push(ROUTER.CART.INDEX.concat(`?page=${page}&pageSize=${pageSize}`))
    }
  }

  render() {
    const { showCartModal, cart, filterOptions } = this.state
    const { carts, meta, isFetching, history } = this.props
    return (
      <Fragment>
        <Row gutter={24}>
          <FilterRow
            onFilter={this.handleFilter}
            disabled={isFetching}
            filterOptions={filterOptions}
            history={history}
          />
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <Button icon="plus" onClick={this.handleAdd}>
              Thêm giỏ hàng
            </Button>
            <CartList
              style={{ marginTop: 10 }}
              carts={carts}
              isFetching={isFetching}
              meta={meta}
              onView={this.viewCart}
              onEdit={this.editCart}
              onChangeStatus={this.changeStatus}
              onChangePage={this.onChangePage}
              onChangeSize={this.onChangePage}
            />
          </Col>
        </Row>

        <Modal
          title="Chi tiết khách hàng"
          visible={showCartModal}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closable={false}
          footer={[
            <Button key="edit" type="primary" onClick={() => this.editCart(cart)}>
              Cập nhật
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Đóng
            </Button>,
          ]}
        >
          <CartCard cart={cart} />
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  carts: select(state, ['cartReducer', 'cart'], 'items'),
  meta: select(state, ['cartReducer', 'cart'], 'meta'),
  isFetching: select(state, ['cartReducer', 'cart'], 'isFetching'),
})

const mapDispatchToProps = dispatch => ({
  getCarts: params => dispatch(getCarts(params)),
  updateCart: (id, payload, meta) => dispatch(updateCart(id, payload, meta)),
  changeStatus: (id, payload, meta) => dispatch(changeCartStatus(id, payload, meta)),
  importCarts: (payload, meta) => dispatch(importCarts(payload, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithPageHOC('cart', 'data')(CartsPage))
