import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Row, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import WithLoading from '../../../hoc/loading';
import { ORDER_STATUS } from '../../../constants/enum';
import router from '../../../constants/router';
// import { formatAddress } from '../../../util/formatAddress'
import { isBelowBreakpoint } from '../../../util/windows';

const columns = removeInvoiceOrder => [
  {
    title: 'Mã đơn',
    dataIndex: 'orderCode',
    render: value => <Link to={router.INVOICE.DETAIL.replace(':id', value)}>{value}</Link>,
  },
  {
    title: 'Mã giỏ hàng',
    dataIndex: 'cartCode',
  },
  {
    title: 'Mã KH',
    dataIndex: 'customerCode',
    render: value => <Link to={router.CUSTOMER.EDIT.replace(':id', value)}>{value}</Link>,
  },
  {
    title: 'Tên KH',
    dataIndex: 'customerName',
  },
  {
    title: 'Nhân viên quản lý',
    dataIndex: 'saleInChargeName',
    align: 'center',
    render: (_value, record) => (record.saleInChargeName === null ? '' : record.saleInChargeName),
  },
  {
    title: 'Ngày tạo đơn',
    dataIndex: 'createdAt',
    render: value => moment(value).format('DD/MM/YYYY HH:mm'),
  },
  {
    title: 'Ngày giao hàng',
    dataIndex: 'requireTime',
    render: value => moment(value).format('DD/MM/YYYY'),
  },
  {
    title: 'Ngày đơn hàng',
    dataIndex: 'orderDate',
    render: value => moment(value).format('DD/MM/YYYY'),
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: 'Xóa',
    render: (value, record) => (
      <Popconfirm
        placement="top"
        title={`Xóa đơn hàng ${record.orderCode}`}
        onConfirm={() => removeInvoiceOrder(record.orderId)}
        okText="Có"
        cancelText="Không"
      >
        <Button key="del" icon="delete" shape="circle" type="danger" disabled={record.status !== 'Đặt Hàng'} />
      </Popconfirm>
    ),
  },
];

class InvoiceList extends Component {
  render() {
    const {
      invoices,
      meta: { current, pageSize, total },
      onView,
      onChangePage,
      onChangeSize,
      onAdd,
      // initSampleData,
      onCheckOrdered,
      onShowDetail,
      setSelectkeys,
      selectKeys,
      removeInvoiceOrder,
    } = this.props;

    const onSelectChange = selectedRowKeys => {
      setSelectkeys(selectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys: selectKeys,
      onChange: onSelectChange,
      getCheckboxProps: record => ({ disabled: record.status === 'Hủy' }),
    };

    return (
      <Table
        title={() => (
          <Row>
            <Button icon="plus" onClick={onAdd} style={{ marginBottom: isBelowBreakpoint() ? 5 : 0 }}>
              Thêm đơn hàng
            </Button>
            {/* {process.env.REACT_APP_SOLVER_API && process.env.REACT_APP_SOLVER_API.includes('dev')
              ? (
                <Button type="danger" icon="plus" onClick={initSampleData} style={{ marginLeft: 15, marginBottom: isBelowBreakpoint() ? 5 : 0 }}>
                  {'Init sample data'}
                </Button>
              ) : null} */}
            <Button
              icon="clock-circle"
              style={{ float: isBelowBreakpoint() ? 'left' : 'right' }}
              onClick={onCheckOrdered}
            >
              Khách hàng chưa đặt
            </Button>
          </Row>
        )}
        size="small"
        scroll={{ x: 'max-content' }}
        pagination={{
          current,
          pageSize,
          total,
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} đơn hàng`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
          onShowSizeChange: onChangeSize,
          onChange: onChangePage,
        }}
        style={{ marginTop: '10px' }}
        columns={columns(removeInvoiceOrder, onView, onShowDetail)}
        rowSelection={rowSelection}
        rowKey={record => record.orderCode}
        rowClassName={record => `invoiceStatus${ORDER_STATUS.indexOf(record.status)}`}
        dataSource={invoices}
      />
    );
  }
}

InvoiceList.propTypes = {
  selectKeys: PropTypes.array,
  key: PropTypes.array,
  invoices: PropTypes.array,
  meta: PropTypes.object,
  onAdd: PropTypes.func,
  // initSampleData: PropTypes.func,
  onCheckOrdered: PropTypes.func,
  onView: PropTypes.func,
  setSelectkeys: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeSize: PropTypes.func,
  onShowDetail: PropTypes.func,
  removeInvoiceOrder: PropTypes.func,
  // onReport: PropTypes.func.isRequired,
};

InvoiceList.defaultProps = {
  key: [],
  invoices: [],
  selectKeys: [],
  meta: {},
  setSelectkeys: () => {},
  onAdd: () => {},
  // initSampleData: () => {},
  onCheckOrdered: () => {},
  onView: () => {},
  onChangePage: () => {},
  onChangeSize: () => {},
  onShowDetail: () => {},
  removeInvoiceOrder: () => {},
};

export default WithLoading(InvoiceList);
