/* eslint-disable no-param-reassign */
import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Col } from 'antd'
import ItemTypes from './ItemTypes'
import Avatar from './Avatar'
import CardTitleImage from '../Card/index'

const style = {
  padding: '5px 7px 2px 7px',
  marginBottom: '15px',
  backgroundColor: '#efefef',
  cursor: 'move',
}

const Card = ({ id, title, src, index, moveCard, del }) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const getIndex = () => index
  const delCard = () => {
    del(getIndex())
  }

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <Col span={24} lg={6}>
      <div ref={ref} style={{ ...style, opacity }} key={id}>
        <CardTitleImage hoverable hasDelete onDelete={() => delCard()}>
          <Avatar src={src} />
        </CardTitleImage>
      </div>
    </Col>
  )
}

export default Card
