import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const initialState = {
  countVehicleType: 0,
  countProductUnit: 0,
  countProductCondition: 0,
  countAccount: 0,
  account: [
    { name: 'Employee', value: 0 },
    { name: 'Driver', value: 0 },
    { name: 'Customer', value: 0 },
  ],
  invoices: [
    { name: 'completed', value: 0 },
    { name: 'pending', value: 0 },
  ],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const dashboardReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GET_VEHICLE_TYPES_SUCCESS:
    draft.countVehicleType = action.payload.data.length
    break

  case TYPES.GET_PRODUCT_CONDITIONS_SUCCESS:
    draft.countProductCondition = action.payload.data.length
    break

  case TYPES.GET_PRODUCT_UNITS_SUCCESS:
    draft.countProductUnit = action.payload.data.length
    break

  case TYPES.GET_ACCOUNT_SUCCESS: {
    if (action.payload.accounts !== null) {
      draft.countAccount = action.payload.accounts.length
      draft.account = [
        { name: 'Employee', value: action.payload.accounts.filter(_ => _.role === 'employee').length },
        { name: 'Driver', value: action.payload.accounts.filter(_ => _.role === 'driver').length },
        { name: 'Customer', value: action.payload.accounts.filter(_ => _.role === 'customer').length },
      ]
    }
    return draft
  }

  case TYPES.GET_INVOICES_SUCCESS: {
    if (action.payload.data !== null) {
      draft.invoice = [
        { name: 'new', value: action.payload.data.filter(_ => _.status === 'Đặt Hàng').length },
        { name: 'completed', value: action.payload.data.filter(_ => _.status === 'Hoàn Thành').length },
        { name: 'scheduled', value: action.payload.data.filter(_ => _.status === 'Đã Lập Lịch').length },
        { name: 'processing', value: action.payload.data.filter(_ => _.status === 'Đang Xử Lý').length },
        { name: 'shipping', value: action.payload.data.filter(_ => _.status === 'Đang Vận Chuyển').length },
        { name: 'cancel', value: action.payload.data.filter(_ => _.status === 'Hủy').length },
        { name: 'trouble', value: action.payload.data.filter(_ => _.status === 'Sự Cố').length },
        { name: 'arrived', value: action.payload.data.filter(_ => _.status === 'Đến Nơi').length },
      ]
    }
    return draft
  }

  default:
    return draft
  }
})

export default dashboardReducer
