import { API } from '../../constants/api'
import { apiCall } from '../../util/apiCall'
import TYPES from '../../constants/actionTypes'
import select from '../../util/select'

// Vrp

const solvingVrp = () => ({ type: TYPES.SOLVING_VRP })

const solveVrpSuccess = payload => ({ type: TYPES.SOLVE_VRP_SUCCESS, payload })

const solveVrpFailure = () => ({ type: TYPES.SOLVE_VRP_FAILURE })

export const solveVrp = (payload, meta) => async dispatch => {
  dispatch(solvingVrp())
  const api = API.ROUTING.solve()
  const { response, error } = await apiCall({
    ...api,
    payload,
  })
  if (!error && response.status === 200) {
    dispatch(solveVrpSuccess(response.data))
    meta.onSuccess(response.data)
  } else {
    dispatch(solveVrpFailure())
    meta.onError()
  }
}

export const calcSolution = (payload, meta) => async dispatch => {
  const api = API.ROUTING.calc()
  const { response, error } = await apiCall({
    ...api,
    payload,
  })
  if (!error && response.status === 200) {
    meta.onSuccess(response.data)
    dispatch(solveVrpSuccess(response.data.routes))
  } else {
    meta.onError()
  }
}

export const selectDriversForVrp = payload => ({
  type: TYPES.SELECT_DRIVERS_FOR_VRP,
  payload,
})

export const selectNodesForVrp = payload => ({
  type: TYPES.SELECT_NODES_FOR_VRP,
  payload,
})

export const updateSolution = payload => ({
  type: TYPES.SELECT_SAMPLE_SCHEDULE,
  payload,
})

export const setFilterTime = payload => ({
  type: TYPES.SELECT_TIME_FOR_VRP,
  payload,
})

export const setNodePool = payload => ({
  type: TYPES.SET_NODE_POOL,
  payload,
})

export const updateRoutes = payload => ({
  type: TYPES.UPDATE_ROUTES,
  payload,
})

export const showNodeInfo = node => ({
  type: TYPES.SHOW_NODE_INFO,
  payload: node,
})

export const updateNodeInfo = node => ({
  type: TYPES.UPDATE_NODE_INFO,
  payload: node,
})

export const sortDrivers = drivers => ({
  type: TYPES.SORT_DRIVERS,
  payload: drivers,
})


export const updateDriverNote = (driver, note) => ({
  type: TYPES.UPDATE_DRIVER_NOTE,
  payload: {
    driver,
    note,
  },
})

export const stopLoading = () => ({ type: TYPES.STOP_LOADING })


// get sample schedule

const gettingSchedulesSample = () => ({ type: TYPES.GETTING_SAMPLE_SCHEDULES })
const getSchedulesSampleSuccess = payload => ({
  type: TYPES.GET_SCHEDULES_SAMPLE_SUCCESS,
  payload,
})
const getSchedulesSampleFailure = () => ({ type: TYPES.GET_SCHEDULES_SAMPLE_FAILURE })
export const resetNodesAndDriversSuccess = () => ({ type: TYPES.RESET_NODES_AND_DRIVERS_SUCCESS })
export const resetModeRouting = () => ({ type: TYPES.RESET_MODE_ROUTING })
export const resetRoutingSample = () => ({ type: TYPES.RESET_ROUTING_SAMPLE })
const importSampleScheduleSuccess = () => ({ type: TYPES.IMPORT_SAMPLE_SCHEDULES_SUCCESS })

export const getSchedulesSample = params => async dispatch => {
  const api = API.SCHEDULE.getSchedulesSample()

  dispatch(gettingSchedulesSample())
  const { response, error } = await apiCall({
    ...api,
    params
  })
  if (!error && response.status === 200) {
    dispatch(getSchedulesSampleSuccess(response.data))
    dispatch(importSampleScheduleSuccess())
  } else {
    dispatch(getSchedulesSampleFailure())
  }
}

// Get List Schedules

const gettingSchedules = () => ({ type: TYPES.GETTING_SCHEDULES })

const getSchedulesSuccess = payload => ({
  type: TYPES.GET_SCHEDULES_SUCCESS,
  payload,
})

const getSchedulesFailure = () => ({ type: TYPES.GET_SCHEDULES_FAILURE })

export const getSchedules = params => async dispatch => {
  const api = API.SCHEDULE.getSchedules()
  dispatch(gettingSchedules())
  const { response, error } = await apiCall({
    ...api,
    params,
  })
  if (!error && response.status === 200) {
    dispatch(getSchedulesSuccess(response.data))
  } else {
    dispatch(getSchedulesFailure())
  }
}

export const getSchedulesIfNeed = params => (dispatch, getState) => {
  const state = getState()
  const isFetching = select(state, ['scheduleReducer', 'list'], 'isFetching')
  const didInvalidate = select(state, ['scheduleReducer', 'list'], 'didInvalidate')
  if (!isFetching && didInvalidate) {
    dispatch(getSchedules(params))
  }
}

// Get Schedule Detail

const gettingSchedule = () => ({ type: TYPES.GETTING_SCHEDULE })

const getScheduleSuccess = payload => ({
  type: TYPES.GET_SCHEDULE_SUCCESS,
  payload,
})

const getScheduleFailure = () => ({ type: TYPES.GET_SCHEDULE_FAILURE })

export const getSchedule = (scheduleCode, isSampleManage) => async dispatch => {
  const api = API.SCHEDULE.getSchedule(scheduleCode)
  dispatch(gettingSchedule())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200) {
    const nodes = []
    if (response.data?.data?.routes && response.data?.data?.routes?.length) {
      response.data.data.routes.forEach(route => {
        if (route?.nodes?.length) {
          route.nodes.forEach(node => {
            node.carriers.forEach(carrier => {
              nodes.push({
                ...node,
                carrier
              })
            })
          })
        }
      })
    }
    response.data.data.nodes = nodes
    if (isSampleManage) {
      dispatch(selectDriversForVrp(response.data.data.drivers))
      dispatch(selectNodesForVrp(nodes))
    }
    dispatch(getScheduleSuccess(response.data))
  } else {
    dispatch(getScheduleFailure())
  }
}

// completed route
export const completedRoute = (payload, meta) => async () => {
  const api = API.SCHEDULE.completedRoute()
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200) {
    meta.onSuccess()
  } else {
    meta.onError()
  }
}

// Update Schedule

const updatingSchedule = () => ({ type: TYPES.UPDATING_SCHEDULE })

const updateScheduleSuccess = () => ({ type: TYPES.UPDATE_SCHEDULE_SUCCESS })

const updateScheduleFailure = () => ({ type: TYPES.UPDATE_SCHEDULE_FAILURE })

export const updateSchedule = (scheduleId, payload) => async dispatch => {
  const api = API.SCHEDULE.updateSchedule(scheduleId)
  dispatch(updatingSchedule())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200) {
    dispatch(updateScheduleSuccess())
  } else {
    dispatch(updateScheduleFailure())
  }
}

export const deleteSchedule = (scheduleId, meta) => async dispatch => {
  const api = API.SCHEDULE.deleteSchedule(scheduleId)
  dispatch(updatingSchedule())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200) {
    dispatch(updateScheduleSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch(updateScheduleFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}


export const importSchedule = (payload, meta) => async dispatch => {
  const api = API.SCHEDULE.importFromExcel()
  dispatch(updatingSchedule())
  const { response, error } = await apiCall({
    ...api,
    payload,
  })
  if (!error && response.status === 200) {
    dispatch(updateScheduleSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch(updateScheduleFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const importScheduleSample = (payload, meta) => async dispatch => {
  const api = API.SCHEDULE.importSampleFromExcel()
  const { response, error } = await apiCall({
    ...api,
    payload,
  })
  if (!error && response.status === 200 && response.data.success) {
    dispatch(getSchedulesSample())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(updateScheduleFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

// get cart

const getNodesForScheduleSuccess = payload => ({
  type: TYPES.GET_NODE_FOR_SCHEDULE_SUCCESS,
  payload,
})
const getNodesForScheduleFailure = () => ({ type: TYPES.GET_NODE_FOR_SCHEDULE_FAILURE })

export const changeDataNode = data => dispatch => {
  dispatch(getNodesForScheduleSuccess(data))
}

export const getNodesForVrp = (args, isUbo = false) => async dispatch => {
  const api = isUbo ? API.SCHEDULE.getUboNodesForSchedule() : API.SCHEDULE.getNodesForSchedule()
  const params = isUbo ? ({
    delivery_date: args.requireTime,
    page: -1,
    page_size: -1,
  }) : args
  const { response, error } = await apiCall({
    ...api,
    params,
  })

  if (!error && response.status === 200 && response.data && response.data.data) {
    const newData = response.data.data.map(item => ({
      cart: {
        orders: item.orders,
        cartCode: item.cartCode,
        cartId: item.cartId,
        contact: {
          address: item.address,
          phoneNumber: item.phoneNumber,
          latitude: item.latitude,
          longitude: item.longitude,
        },
        address: item.address,
        phone: item.phoneNumber,
        latitude: `${item.latitude}`,
        longitude: `${item.longitude}`,
        occupiedSpace: item.occupiedSpace || 0,
      },
      cartCode: item.cartCode,
      phoneNumber: item.phoneNumber,
      address: item.address,
      totalWeight: item.orders.reduce((total, item) => total + item.weight, 0) * 100 / 100,
      version: 0,
    }))
    dispatch(getNodesForScheduleSuccess(newData))
  } else {
    dispatch(getNodesForScheduleFailure())
  }
}

export const insertSchedule = (payload, meta) => async dispatch => {
  const api = API.SCHEDULE.insertSchedule()
  const { response, error } = await apiCall({
    ...api,
    payload,
  })
  if (!error && response && response.status === 200 && response.data && response.data.success && response.data.data) {
    dispatch(getSchedulesSample())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (meta && meta.onError) {
    meta.onError(error)
  }
}

const gettingCartCustomers = () => ({ type: TYPES.GETTING_CART_CUSTOMERS })

const getCartCustomersSuccess = (payload, params) => ({
  type: TYPES.GET_CART_CUSTOMERS_SUCCESS,
  payload,
  params,
})

const getCartCustomersFailure = () => ({ type: TYPES.GET_CART_CUSTOMERS_FAILURE })

export const getCartCustomers = (params, meta) => async dispatch => {
  const api = API.CUSTOMER.getCartCustomers()
  dispatch(gettingCartCustomers())
  const { response } = await apiCall({
    ...api,
    params,
  })
  if (response.data.success === true) {
    dispatch(getCartCustomersSuccess(response.data, params))
    if (meta && meta.onSuccess) {
      meta.onSuccess(response?.data?.data)
    }
  } else {
    dispatch(getCartCustomersFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}
