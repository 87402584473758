import React from 'react'

import { Modal, Button, Row, Col, Select } from 'antd'

const { Option } = Select

const NodeModal = ({ visible, drivers, node, onCancel, onUpdate }) => {
  let confirmers = []
  const onSelectChange = value => {
    confirmers = value
  }
  const defaultConfirmers = node.confirmers?.length > 0 ? node.confirmers.map(item => item.code) : [node.carrier?.code]
  return (
    <Modal
      title="Cập nhật thông tin"
      visible={visible}
      centered
      closable={false}
      footer={node.routeIndex >= 0 ? [
        <Button key="update" onClick={() => onUpdate(confirmers)}>Cập nhật</Button>,
        <Button key="close" onClick={onCancel}>Đóng cửa sổ</Button>,
      ]
        : [
          <Button key="close" onClick={onCancel}>Đóng cửa sổ</Button>,
        ]
      }
    >
      {
        node.routeIndex >= 0 ? (
          <Row gutter={24}>
            <Col span={6}>Người giao</Col>
            <Col span={18}>{`${node.carrier?.name} (${node.carrier?.phoneNumber})`}</Col>
          </Row>
        ) : ('')
      }
      <Row gutter={24}>
        <Col span={6}>Giỏ hàng</Col>
        <Col span={18}>{`${node.cart?.cartCode}`}</Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>Cân nặng</Col>
        <Col span={18}>{`${node.totalWeight}`}</Col>
      </Row>
      <Row gutter={24}>
        <Col span={6}>Địa chỉ</Col>
        <Col span={18}>{`${node.cart?.contact?.address}`}</Col>
      </Row>
      {
        node.routeIndex >= 0 ? (
          <Row gutter={24}>
            <Col span={6}>Người xác nhận</Col>
            <Col span={18}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Chọn danh sách người xác nhận"
                defaultValue={defaultConfirmers}
                optionLabelProp="label"
                onChange={onSelectChange}
              >
                {
                  drivers.map(driver => (
                    <Option value={driver.code} label={driver.name} key={driver.code}>
                      <span role="img" aria-label={driver.name}>
                        {driver.code}
                        {' '}
-
                      </span>
                      {driver.name}
                      {' '}
(
                      {driver.phoneNumber}
)
                    </Option>
                  ))
                }
              </Select>
            </Col>
          </Row>
        ) : ('')
      }
    </Modal>
  )
}

export default NodeModal
