import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button, notification, message, Row, Col, Modal } from 'antd'
import PriceForm from './price-form'
import DetailPriceTable from './detail'
import FilterRow from './Filter'
import ListPriceTable from './list'
import './index.css'

class PriceTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPriceModal: false,
      editMode: false,
      priceTableId: null,
      price: {},
      isAdding: false
    }
  }

  onOk=() => {
    this.priceFrom.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let { pricePerUnit, product } = values
        product = JSON.parse(product)
        const payload = { pricePerUnit, priceTableId: this.state.priceTableId, productId: product.productId }
        if (pricePerUnit <= 0) {
          message.warning('Vui lòng nhập giá lớn hơn 0 !')
        } else if (this.isInPriceTable(product) === false) {
          this.setState({ isAdding: true })
          this.props.addItem(payload, {
            onSuccess: () => {
              this.togglePriceModal()
              this.setState({ isAdding: false })
              notification.success({ message: 'Thêm thành công' })
            },
            onError: () => {
              this.togglePriceModal()
              this.setState({ isAdding: false })
              notification.error({ message: 'Cập nhật thất bại' })
            }
          })
        } else {
          notification.error({ message: 'Mẳt hàng đã tồn tại' })
        }
      } else {
        notification.error({ message: `Cập nhật thất bại - ${err}` })
      }
    })
  }

  priceFormRef = ref => {
    this.priceFrom = ref
  }

  togglePriceModal = priceTableId => {
    this.setState(prevState => ({
      editMode: prevState.showPriceModal ? false : prevState.editMode,
      priceTableId,
      showPriceModal: !prevState.showPriceModal,
    }))
  }

  findIndex = (list, code) => {
    let result = -1
    list.forEach((item, index) => {
      if (item.productCode === code) {
        result = index
      }
    })
    return result
  }

  isInPriceTable = product => {
    const priceTable = {}
    Object.assign(priceTable, this.props.priceTable)
    if (!priceTable.priceItem) {
      return false
    }
    if (this.findIndex(priceTable.priceItem, product.productCode) !== -1) {
      return true
    }
    return false
  }

  onEdit = price => {
    this.setState({ price, editMode: true })
    this.togglePriceModal()
  };

  onDelete = price => {
    this.props.removeItem(
      price.priceItemId,
      {
        onSuccess: () => {
          notification.success({ message: 'Cập nhật thành công' })
        },
        onError: () => {
          notification.success({ message: 'Cập nhật thất bại' })
        },
      },
    )
  }

  render() {
    const {
      products,
      getPriceTable,
      getPriceTables,
      getExistPriceTable,
      priceTables,
      priceTable,
      meta,
      groupID,
      isFetching,
      forCustomer,
      customerCode,
      addItem
    } = this.props
    const { showPriceModal, editMode, price, priceTableId, isAdding } = this.state
    return (
      <React.Fragment>
        <Row gutter={24}>
          <FilterRow
            groupID={groupID}
            forCustomer={forCustomer}
            customerCode={customerCode}
            onFilter={getPriceTables}
          />
        </Row>
        <Row gutter={24} style={{ marginTop: '20px' }}>
          <Col span={0} lg={6}>
            <ListPriceTable
              data={priceTables !== null ? priceTables : []}
              getPriceTable={getPriceTable}
              getExistPriceTable={getExistPriceTable}
              isFetching={isFetching}
            />
          </Col>
          <Col span={24} lg={18}>
            <DetailPriceTable
              groupID={groupID}
              forCustomer={forCustomer}
              priceTable={priceTable}
              addItem={addItem}
              meta={meta}
              products={products}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              updatePriceTable={this.onUpdate}
              toggleModal={this.togglePriceModal}
            />
          </Col>
        </Row>

        {showPriceModal && (
          <Modal
            title="Sửa báo giá"
            visible={showPriceModal}
            closable={false}
            centered
            footer={[
              <Button form="priceItemForm" key="submit" onClick={this.onOk} type="primary" htmlType="submit" loading={isAdding}>
                {'Thêm'}
              </Button>,
              <Button key="close" onClick={this.togglePriceModal}>
                {'Đóng cửa sổ'}
              </Button>,
            ]}
          >
            <PriceForm
              wrappedComponentRef={this.priceFormRef}
              products={products}
              editMode={editMode}
              priceTableId={priceTableId}
              price={price}
            />
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

PriceTable.propTypes = {
  forCustomer: PropTypes.bool,
  isFetching: PropTypes.bool,
  priceTables: PropTypes.array,
  priceTable: PropTypes.object,
  products: PropTypes.array,
  addItem: PropTypes.func,
  updateItem: PropTypes.func,
  removeItem: PropTypes.func,
  onUpdate: PropTypes.func,
  meta: PropTypes.object,
  groupID: PropTypes.string,
  customerCode: PropTypes.string,
  onOpenImportModal: PropTypes.func,
  getPriceTables: PropTypes.func,
  getPriceTable: PropTypes.func,
  getExistPriceTable: PropTypes.func
}

PriceTable.defaultProps = {
  forCustomer: false,
  isFetching: false,
  priceTables: [],
  priceTable: {},
  products: [],
  meta: {},
  groupID: '',
  customerCode: '',
  addItem: () => {},
  updateItem: () => {},
  removeItem: () => {},
  onOpenImportModal: () => {},
  getPriceTables: () => {},
  getPriceTable: () => {},
  onUpdate: () => {},
  getExistPriceTable: () => {}
}

export default (PriceTable)
