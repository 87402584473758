import { Form, Button, Input, Icon } from 'antd';
import React, { Component } from 'react';

import ImageUploadFormItem from '../../../components/ImageUploadFormItem';

const FormItem = Form.Item;

class CriteriaForm extends Component {
  onReset = () => {
    this.props.form.resetFields();
  };

  imageUploadFormItemOnChange = value => {
    this.props.form.setFieldsValue({ image_url: value });
  };

  render() {
    const { form, slug, onAdd } = this.props;
    const { getFieldDecorator } = form;

    const onSubmit = e => {
      e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          onAdd(values);
          this.onReset();
        }
      });
    };

    return (
      <Form onSubmit={onSubmit}>
        <FormItem
          name="image_url"
          rules={[
            {
              required: true,
              message: 'Ảnh của Giấy chứng nhận không được để trống!',
            },
          ]}
        >
          {getFieldDecorator('image_url', {
            rules: [
              {
                required: true,
                message: 'Ảnh của Giấy chứng nhận không được để trống!',
              },
            ],
          })(
            <ImageUploadFormItem
              width="216px"
              height="216px"
              outputWidth={0}
              outputHeight={0}
              slug={slug}
              onChange={value => this.imageUploadFormItemOnChange(value)}
            />,
          )}
        </FormItem>
        <FormItem name="name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Hãy nhập tên giấy chứng nhận',
              },
            ],
          })(<Input placeholder="Hãy điền tên giấy chứng nhận" />)}
        </FormItem>
        <FormItem style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" shape="round">
            <Icon type="save_outline" />
            &nbsp;Lưu
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(CriteriaForm);
