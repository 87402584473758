import { apiCall } from '../../util/apiCall';
import { API } from '../../constants/api';
import TYPES from '../../constants/actionTypes';

// Product Action
const gettingProducts = () => ({ type: TYPES.GETTING_PRODUCTS });
const getProductsSuccess = payload => ({
  type: TYPES.GET_PRODUCTS_SUCCESS,
  payload,
});
const getProductsFailure = () => ({ type: TYPES.GET_PRODUCTS_FAILURE });

export const getProducts = params => async dispatch => {
  const api = API.PRODUCT.getProducts();
  dispatch(gettingProducts());
  const { response, error } = await apiCall({ ...api, params });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getProductsSuccess(response.data));
  } else {
    dispatch(getProductsFailure());
  }
};

export const getAllProducts = params => async dispatch => {
  const api = API.PRODUCT.getProducts();
  dispatch({ type: TYPES.GETTING_ALL_PRODUCTS });
  const { response, error } = await apiCall({ ...api, params });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_ALL_PRODUCTS_SUCCESS,
      payload: response.data,
    });
  } else {
    dispatch({ type: TYPES.GET_ALL_PRODUCTS_FAILURE });
  }
};

const gettingProduct = () => ({ type: TYPES.GETTING_PRODUCT });
const getProductSuccess = payload => ({
  type: TYPES.GET_PRODUCT_SUCCESS,
  payload,
});
const getProductFailure = () => ({ type: TYPES.GET_PRODUCT_FAILURE });

export const getProduct = code => async dispatch => {
  const api = API.PRODUCT.getProduct(code);
  dispatch(gettingProduct());
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getProductSuccess(response.data));
  } else {
    dispatch(getProductFailure());
  }
};

const insertingProduct = () => ({ type: TYPES.INSERTING_PRODUCT });
const insertProductSuccess = () => ({ type: TYPES.INSERT_PRODUCT_SUCCESS });
const insertProductFailure = () => ({ type: TYPES.INSERT_PRODUCT_FAILURE });

export const insertProduct = (payload, meta) => async dispatch => {
  const api = API.PRODUCT.insertProduct();
  dispatch(insertingProduct());
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertProductSuccess());
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data.data);
    }
  } else {
    dispatch(insertProductFailure());
    if (meta && meta.onError) {
      meta.onError('Sản phẩm bị trùng tên hoặc mã');
    }
  }
};

export const importProducts = (payload, meta) => async dispatch => {
  const pay = { products: payload };
  const api = API.PRODUCT.importFromExcel();
  dispatch(insertingProduct());
  const { response, error } = await apiCall({ ...api, payload: pay });
  if (!error && response.status === 200 && response.data.success === true) {
    const total = response.data.success_total;
    dispatch(insertProductSuccess());
    if (meta && meta.onSuccess) {
      meta.onSuccess(`${total} sản phẩm đã được thêm`);
    }
  } else if (error && error.status === 406) {
    const response = JSON.parse(error.response);
    const data = response.data;
    const keys = Object.keys(data);
    dispatch(insertProductSuccess());
    if (meta && meta.onSuccess) {
      meta.onSuccess(`${keys.length} sản phẩm Không thêm được`);
    }
  } else {
    dispatch(insertProductFailure());
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};

const updatingProduct = () => ({ type: TYPES.UPDATING_PRODUCT });
const updateProductSuccess = () => ({ type: TYPES.UPDATE_PRODUCT_SUCCESS });
const updateProductFailure = () => ({ type: TYPES.UPDATE_PRODUCT_FAILURE });

export const updateProduct = (id, payload, meta) => async dispatch => {
  const api = API.PRODUCT.updateProduct(id);
  dispatch(updatingProduct());
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(updateProductSuccess());
    if (meta && meta.onSuccess) {
      meta.onSuccess();
    }
  } else {
    dispatch(updateProductFailure());
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};

const deletingProduct = () => ({ type: TYPES.DELETING_PRODUCT });
const deleteProductSuccess = () => ({ type: TYPES.DELETE_PRODUCT_SUCCESS });
const deleteProductFailure = () => ({ type: TYPES.DELETE_PRODUCT_FAILURE });

export const deleteProduct = (id, meta) => async dispatch => {
  const api = API.PRODUCT.deleteProduct(id);
  dispatch(deletingProduct());
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(deleteProductSuccess());
    if (meta && meta.onSuccess) {
      meta.onSuccess();
    }
  } else {
    dispatch(deleteProductFailure());
    if (meta && meta.onSuccess) {
      meta.onError();
    }
  }
};

const changeProductStatus = (id, value) => ({
  type: TYPES.CHANGE_PRODUCT_STATUS,
  id,
  value,
});

export const actChangeStatus = (id, value) => async dispatch => {
  dispatch(changeProductStatus(id, value));
};

// Request

export const getRequests = params => async dispatch => {
  const api = API.REQUEST.getRequests();
  dispatch({ type: TYPES.GETTING_REQUESTS });
  const { response, error } = await apiCall({ ...api, params });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.GET_REQUESTS_SUCCESS, payload: response.data.Data });
  } else {
    dispatch({ type: TYPES.GET_REQUESTS_FAILURE });
  }
};

export const replyRequest = (id, payload) => async dispatch => {
  const api = API.REQUEST.replyRequest(id);
  dispatch({ type: TYPES.REPLYING_REQUEST });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.REPLY_REQUEST_SUCCESS });
  } else {
    dispatch({ type: TYPES.REPLY_REQUEST_FAILURE });
  }
};
// const deletingProduct = () => ({ type: TYPES.DELETING_PRODUCT })
// const deleteProductSuccess = () => ({ type: TYPES.DELETE_PRODUCT_SUCCESS })
// const deleteProductFailure = () => ({ type: TYPES.DELETE_PRODUCT_FAILURE })

const updatingProductImages = () => ({ type: TYPES.UPDATE_PRODUCT_IMAGES });
const updateProductImagesSuccess = () => ({ type: TYPES.UPDATE_PRODUCT_IMAGES_SUCCESS });
const updateProductInfoFailure = () => ({ type: TYPES.UPDATE_PRODUCT_IMAGES_FAILURE });

// const updateProductImagesSuccess = {
//   type: TYPES.UPDATE_PRODUCT_IMAGES,
// };
// const updateProductInfoFailure = {
//   type: TYPES.UPDATE_PRODUCT_IMAGES,
//   meta: {
//     prefix: [PRODUCT_INFO, API_CALLED_FAILURE],
//   },
// };

export const updateProductImages = (product, meta) => async dispatch => {
  const payload = { images: product.images };
  const id = product.code;
  const api = API.PRODUCT.updateProductImages(id);
  dispatch(updatingProductImages);
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200) {
    dispatch(updateProductImagesSuccess);
    if (meta && meta.onSuccess) {
      meta.onSuccess();
    }
  } else {
    dispatch(updateProductInfoFailure);
    if (meta && meta.onError) {
      meta.onError(error);
    }
  }
};

const updatingProductCriteria = () => ({ type: TYPES.UPDATING_PRODUCT_CRITERIA });
const updateProductCriteriaSuccess = payload => ({ type: TYPES.UPDATE_PRODUCT_CRITERIA_SUCCESS, payload });
const updateProductCriteriaFailure = () => ({ type: TYPES.UPDATE_PRODUCT_CRITERIA_FAILURE });

export const updateProductCriteria = (code, payload, meta) => async dispatch => {
  const api = API.PRODUCT.updateProductCriteria(code);
  dispatch(updatingProductCriteria());
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(updateProductCriteriaSuccess(response.data));
    dispatch(getProduct(code));
    if (meta && meta.onSuccess) {
      meta.onSuccess();
    }
  } else {
    dispatch(updateProductCriteriaFailure());
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};
