import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select, Form, Button, notification } from 'antd'
import { changeAlias } from '../../../util/formatText'
import { isBelowBreakpoint } from '../../../util/windows'

class SelectCustomerForm extends Component {
    state = { selectedCustomers: [] };

    onChangeSelected = selectedCustomers => this.setState({ selectedCustomers });

    onSubmit = () => {
      if (this.state.selectedCustomers.length > 0) {
        this.props.onSubmit(this.state.selectedCustomers)
        this.setState({ selectedCustomers: [] })
      } else {
        notification.error({ message: 'Chưa có khách hàng nào được chọn' })
      }
    }

    render() {
      const { selectedCustomers } = this.state
      const { customers } = this.props
      return (
        <Form layout="inline">
          <Form.Item label="Khách hàng">
            <Select
              mode="multiple"
              showSearch
              style={{ width: isBelowBreakpoint() ? 210 : 400 }}
              onChange={this.onChangeSelected}
              value={selectedCustomers}
              filterOption={(input, option) => changeAlias(option.props.children.toLowerCase()).indexOf(
                changeAlias(input.toLowerCase()),
              ) >= 0
              }
            >
              {customers.map((item, index) => (
                <Select.Option
                  key={`cus${index + 1}`}
                  value={item.id}
                >
                  {`(${item.code}) ${item.name}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.onSubmit}>Đăng kí</Button>
          </Form.Item>
        </Form>
      )
    }
}

SelectCustomerForm.propTypes = {
  customers: PropTypes.array,
  onSubmit: PropTypes.func,
}

SelectCustomerForm.defaultProps = {
  customers: [],
  onSubmit: () => {},
}

export default SelectCustomerForm
