import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, List, Button, Icon, Row, Col, InputNumber, message } from 'antd'
import { changeAlias } from '../../../../util/formatText'

class ItemList extends Component {
  constructor(props) {
    super(props)
    this.state = { pageSize: 10, filterText: '' }
    this.textInput = []
    this.focusTextInput = this.focusTextInput.bind(this)
  }

    checkPrice = (value, id) => {
      if (Number(value) > 0) {
        this.props.onChangePrice(value, id)
      } else {
        message.warning('Vui lòng nhập số lượng lớn hơn 0')
        // this.props.onChangePrice(0, id)
      }
    }

    toNext = (e, index) => {
      if (e.keyCode === 13) {
        this.focusTextInput(index)
      }
    }

    onFilter = e => {
      this.setState({ filterText: e.target.value })
    }

    focusTextInput(index) {
      if (index + 1 < this.state.pageSize) {
        this.textInput[index + 1].focus()
      } else if (index + 1 === this.state.pageSize) {
        this.textInput[0].focus()
      }
    }


    render() {
      const { dataSource, removeProduct } = this.props
      const { filterText } = this.state
      const prices = dataSource.filter(
        item => changeAlias(item.product.name.toLowerCase()).indexOf(changeAlias(filterText.toLowerCase())) >= 0
        || changeAlias(item.product.code.toLowerCase()).indexOf(changeAlias(filterText.toLowerCase())) >= 0
      )
      return (
        <React.Fragment>
          <List
            size="small"
            header={(
              <React.Fragment>
                <b style={{ fontSize: '15' }}>Lọc sản phẩm</b>
                <Input value={filterText} onChange={this.onFilter} placeholder="Lọc theo tên / mã hàng hóa" />
              </React.Fragment>
            )}
            dataSource={prices}
            pagination={{
              showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '40', '80'],
              onShowSizeChange: (current, size) => {
                this.setState({ pageSize: size })
              },
            }}
            renderItem={(record, index) => (
              <List.Item
                key={index}
                actions={[
                  <Button
                    key="delete"
                    shape="circle"
                    type="danger"
                    onClick={() => removeProduct(record.product.id)}
                  >
                    <Icon type="delete" />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={(
                    <Row gutter={10}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}
                        style={{ textAlign: 'left' }}
                      >
                        {record.product.code}
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}
                        style={{ textAlign: 'left' }}
                      >
                        {<b>{record.product.name}</b>}
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          max={10000000}
                          step={100}
                          value={record.price}
                          formatter={value => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/đ\s?|đ |(,*)/g, '')}
                          onChange={value => this.checkPrice(value, record.product)}
                          onKeyDown={e => this.toNext(e, index)}
                          ref={ref => { this.textInput[index] = ref }}
                        />
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 6 }}
                        lg={{ span: 6 }}
                        style={{ textAlign: 'center' }}
                      >
                        {`/ ${record.product.unitName}`}
                      </Col>
                    </Row>
                  )}
                />
              </List.Item>
            )}
          />
        </React.Fragment>
      )
    }
}

ItemList.propTypes = {
  dataSource: PropTypes.array,
  removeProduct: PropTypes.func,
  onChangePrice: PropTypes.func,
  title: PropTypes.func,
  isConfirm: PropTypes.bool,
}

ItemList.defaultProps = {
  dataSource: [],
  removeProduct: () => {},
  onChangePrice: () => {},
  title: () => {},
  isConfirm: false,
}

export default ItemList
