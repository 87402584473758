import { apiCall } from '../../util/apiCall';
import { API } from '../../constants/api';
import TYPES from '../../constants/actionTypes';

// Supplier Action
const gettingSuppliers = () => ({ type: TYPES.GETTING_SUPPLIERS });
const getSuppliersSuccess = payload => ({
  type: TYPES.GET_SUPPLIERS_SUCCESS,
  payload,
});
const getSuppliersFailure = () => ({ type: TYPES.GET_SUPPLIERS_FAILURE });

export const getSuppliers = params => async dispatch => {
  const api = API.SUPPLIER.getSuppliers();
  dispatch(gettingSuppliers());
  const { response, error } = await apiCall({ ...api, params });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getSuppliersSuccess(response.data));
  } else {
    dispatch(getSuppliersFailure());
  }
};

const gettingSupplier = () => ({ type: TYPES.GETTING_SUPPLIER });
const getSupplierSuccess = payload => ({
  type: TYPES.GET_SUPPLIER_SUCCESS,
  payload,
});
const getSupplierFailure = () => ({ type: TYPES.GET_SUPPLIER_FAILURE });

export const getSupplier = id => async dispatch => {
  const api = API.SUPPLIER.getSupplier(id);
  dispatch(gettingSupplier());
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getSupplierSuccess(response.data));
  } else {
    dispatch(getSupplierFailure());
  }
};

const insertingSupplier = () => ({ type: TYPES.INSERTING_SUPPLIER });
const insertSupplierSuccess = payload => ({ type: TYPES.INSERT_SUPPLIER_SUCCESS, payload });
const insertSupplierFailure = () => ({ type: TYPES.INSERT_SUPPLIER_FAILURE });

export const insertSupplier = (payload, meta) => async dispatch => {
  const api = API.SUPPLIER.insertSupplier();
  dispatch(insertingSupplier());
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertSupplierSuccess(response.data));
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data.data);
    }
  } else {
    dispatch(insertSupplierFailure());
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};

export const importSuppliers = (payload, meta) => async dispatch => {
  const api = API.SUPPLIER.importFromExcel();
  dispatch(insertingSupplier());
  const { response, error } = await apiCall({
    ...api,
    payload,
  });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertSupplierSuccess());
    if (meta && meta.onSuccess) {
      meta.onSuccess();
    }
  } else {
    dispatch(insertSupplierFailure());
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};

const updatingSupplier = () => ({ type: TYPES.UPDATING_SUPPLIER });
const updateSupplierSuccess = payload => ({ type: TYPES.UPDATE_SUPPLIER_SUCCESS, payload });
const updateSupplierFailure = () => ({ type: TYPES.UPDATE_SUPPLIER_FAILURE });

export const updateSupplier = (code, payload, meta) => async dispatch => {
  const api = API.SUPPLIER.updateSupplier(code);
  dispatch(updatingSupplier());
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(updateSupplierSuccess(response.data));
    dispatch(getSupplier(code));
    if (meta && meta.onSuccess) {
      meta.onSuccess();
    }
  } else {
    dispatch(updateSupplierFailure());
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};

const updatingSupplierCriteria = () => ({ type: TYPES.UPDATING_SUPPLIER_CRITERIA });
const updateSupplierCriteriaSuccess = payload => ({ type: TYPES.UPDATE_SUPPLIER_CRITERIA_SUCCESS, payload });
const updateSupplierCriteriaFailure = () => ({ type: TYPES.UPDATE_SUPPLIER_CRITERIA_FAILURE });

export const updateSupplierCriteria = (code, payload, meta) => async dispatch => {
  const api = API.SUPPLIER.updateSupplierCriteria(code);
  dispatch(updatingSupplierCriteria());
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(updateSupplierCriteriaSuccess(response.data));
    dispatch(getSupplier(code));
    if (meta && meta.onSuccess) {
      meta.onSuccess();
    }
  } else {
    dispatch(updateSupplierCriteriaFailure());
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};

const changeSupplierStatus = (id, value) => ({
  type: TYPES.CHANGE_SUPPLIER_STATUS,
  id,
  value,
});

export const actChangeStatus = (id, value) => async dispatch => {
  dispatch(changeSupplierStatus(id, value));
};

const deletingSupplier = () => ({ type: TYPES.DELETING_SUPPLIER });
const deleteSupplierSuccess = () => ({ type: TYPES.DELETE_SUPPLIER_SUCCESS });
const deleteSupplierFailure = () => ({ type: TYPES.DELETE_SUPPLIER_FAILURE });

export const deleteSupplier = (id, meta) => async dispatch => {
  const api = API.SUPPLIER.deleteSupplier(id);
  dispatch(deletingSupplier());
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(deleteSupplierSuccess());
    if (meta && meta.onSuccess) {
      meta.onSuccess();
    }
  } else {
    dispatch(deleteSupplierFailure());
    if (meta && meta.onError) {
      meta.onError();
    }
  }
};
