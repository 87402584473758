/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React from 'react'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import { Button, Form, Tabs, Alert, Modal, Row, Table, Select, notification, Col, DatePicker, Input, message } from 'antd'
import { json2excel } from 'js2excel'
import moment from 'moment'
import WithLoading from '../../../../hoc/loading'
import { formatCurrency } from '../../../../util/formatCurrency'

const { TabPane } = Tabs
const { Item } = Form
const { Option } = Select
const columns = onChangePrice => ([
  {
    title: 'STT',
    dataIndex: 'row',
    key: 'row',
    width: 150
  },
  {
    title: 'Mã SP',
    dataIndex: 'code',
    key: 'code',
    width: 150
  },
  {
    title: 'TÊN HÀNG HÓA',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'ĐVT',
    dataIndex: 'unit',
    key: 'unit',
    width: 100
  },
  {
    title: 'ĐƠN GIÁ (VND)',
    dataIndex: 'price',
    key: 'price',
    width: 150,
    align: 'right',
    render: (price, record) => {
      if (record.arrPrice && onChangePrice) {
        return (
          <Select key={`${record.code}-select-price`} value={price} onChange={value => onChangePrice(record, value)}>
            {record.arrPrice.map(aPrice => (
              <Select.Option key={`${record.code}-price-${aPrice}`} value={aPrice}>
                {formatCurrency(aPrice)}
              </Select.Option>
            ))}
          </Select>
        )
      }

      return formatCurrency(price)
    }
  },
  {
    title: 'GHI CHÚ',
    dataIndex: 'note',
    key: 'note',
    width: 150
  }
])
// eslint-disable-next-line prefer-const
class UploadForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorFile: false,
      dataNew: [],
      dataPresent: [],
      dataError: [],
      dataPayload: [],
      applyDate: moment().add(1, 'd'),
      priceTableName: '',
      price_table_id: 0,
      tabKey: 'present',
      fileName: '',
      isFetching: false
    }
    this.fileUpload = React.createRef()
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ isFetching: true })
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dataPayload, price_table_id } = this.state
        if (dataPayload.length > 0) {
          let { applyDate, priceTableName } = values
          applyDate = moment(applyDate).format('YYYY-MM-DD')
          const payload = {
            applyDate,
            customerGroupId: Number.parseInt(this.props.group.id, 10),
            priceItems: dataPayload,
            priceTableName,
            price_table_id: price_table_id === 0 ? undefined : price_table_id
          }
          this.props.onSubmit(payload, () => {
            this.resetForm()
            this.setState({ isFetching: false })
            this.setState({ dataPresent: [] })
            this.setState({ dataNew: [] })
          })
        } else {
          message.warning('Chưa có sản phẩm nào')
          this.setState({ isFetching: false })
        }
      } else {
        this.setState({ isFetching: false })
      }
    })
  }

  isUpperCase = letter => {
    for (let i = 0; i < letter.length; i++) {
      if (letter[i] !== letter[i].toUpperCase()) {
        return false
      }
    }
    return true
  }

  isNote = item => {
    if (item.row !== undefined && Number.isNaN(item.row)) {
      return true
    }
    return false
  }

  isCategory = item => {
    if (item.code === undefined
      && item.unit === undefined
      && item.price === undefined
      && item.name !== undefined
      && this.isUpperCase(item.name)) {
      return true
    }
    return false
  }

  isItemError = item => {
    if ((item.code === undefined || item.price === undefined || item.name === undefined)) {
      return true
    }
    return false
  }

  findExistProduct = item => {
    const { products } = this.props
    return products.find(product => product.code === item.code)
  }

  importExcel = file => {
    const fileReader = new FileReader() // eslint-disable-line no-undef

    const dataPayload = []
    const dataNew = []
    const dataPresent = []
    const dataError = []

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let startAt = 0
        const fileData = new Uint8Array(event.target.result)
        const workbook = XLSX.read(fileData, { type: 'array', cellStyles: true })
        const Sheet = workbook.Sheets[workbook.SheetNames[0]]
        let data = XLSX.utils.sheet_to_json(Sheet)
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`]
          if (flagCell !== undefined) {
            if (flagCell.v === 'STT') {
              startAt = i
              break
            }
          }
        }
        if (startAt === 0) {
          notification.error({ message: 'Thêm thất bại!' })
          this.setState({ isFetching: false })
        } else {
          this.setState({ isFetching: true })
          data = XLSX.utils.sheet_to_json(Sheet, { range: startAt, header: ['row', 'code', 'name', 'unit', 'price', 'note'] })

          let arrProductCode = []
          let endAt = data.length
          /** read each row */
          data.forEach((item, index) => {
            // check hidden row
            // eslint-disable-next-line no-underscore-dangle
            if (Sheet['!rows'] && Sheet['!rows'][item.__rowNum__] && Sheet['!rows'][item.__rowNum__].hidden) {
              return
            }
            if (!this.isNote(item) && index < endAt) {
              /** format empty cell */
              if (item.row !== undefined) {
                item.row = (item.row.toString().trim() === '') ? undefined : item.row.toString().trim()
              }
              if (item.code !== undefined) {
                item.code = (item.code.toString().trim() === '') ? undefined : item.code.toString().trim()
              }
              if (item.name !== undefined) {
                item.name = (item.name.toString().trim() === '') ? undefined : item.name.toString().trim()
              }
              if (item.unit !== undefined) {
                item.unit = (item.unit.toString().trim() === '') ? undefined : item.unit.toString().trim()
              }
              if (item.price !== undefined) {
                item.price = (item.price.toString().trim() === '') ? undefined : item.price.toString().trim()
              }
              if (item.note !== undefined) {
                item.note = (item.note.toString().trim() === '') ? undefined : item.note.toString().trim()
              }
              if (this.isItemError(item)) {
                dataError.push(item)
              } else {
                const foundProduct = this.findExistProduct(item)
                if (foundProduct) {
                  if (arrProductCode.includes(item.code)) {
                    let prevItem = dataPresent.find(preItem => preItem.code === item.code)
                    if (prevItem && prevItem.price !== item.price) {
                      prevItem.arrPrice = prevItem.arrPrice ? prevItem.arrPrice : [prevItem.price]
                      prevItem.arrPrice.push(item.price)
                    }
                  } else {
                    arrProductCode.push(item.code)
                    dataPayload.push({
                      productId: foundProduct.id,
                      price: Number.parseInt(item.price, 10)
                    })
                    item.productId = foundProduct.id
                    dataPresent.push(item)
                  }
                } else {
                  dataNew.push(item)
                }
              }
            } else if (this.isNote(item)) {
              endAt = index
            }
          })

          notification.success({ message: 'Tải lên thành công!' })
          this.setState({ dataNew, dataError, dataPresent, dataPayload, isFetching: false })
        }
      } catch (e) {
        notification.error({ message: e })
        this.setState({ isFetching: false })
      }
    }
    fileReader.readAsArrayBuffer(file)
  }

  onImportExcel = event => {
    const { files } = event.target
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.setState({ isFetching: true })
      this.importExcel(files[0])
    }
  }

  exportExcel = () => {
    const { dataError, dataNew, dataPresent, tabKey } = this.state
    // eslint-disable-next-line no-nested-ternary
    let data = []
    if (tabKey === 'present') {
      data = dataPresent
    } else {
      data = (tabKey === 'new') ? dataNew : dataError
    }
    data = data.map(item => {
      const newItem = {}
      newItem.STT = item.row
      newItem['MÃ SP'] = item.code
      newItem['TÊN HÀNG HÓA'] = item.name
      newItem['ĐVT'] = item.unit
      newItem['ĐƠN GIÁ (VND)'] = item.price
      newItem['GHI CHÚ'] = item.note
      return newItem
    })
    try {
      json2excel({
        data,
        name: tabKey,
        formateDate: 'yyyy/mm/dd',
      })
    } catch (e) {
      notification.error({ message: 'Lỗi! Có thể trình duyệt của bạn không hỗ trợ! Vui lòng liên hệ nhà phát triển !' })
    }
  };

  onChangePrice = (item, price) => {
    item.price = price
    const { dataPresent, dataPayload } = this.state
    const findPresentIndex = dataPresent.findIndex(product => product.code === item.code)
    if (findPresentIndex >= 0) {
      dataPresent[findPresentIndex].price = Number.parseInt(price, 10)
    }
    const findPayloadIndex = dataPayload.findIndex(product => product.productId === item.productId)
    if (findPayloadIndex >= 0) {
      dataPayload[findPayloadIndex].price = Number.parseInt(price, 10)
    }

    this.setState({ dataPresent, dataPayload })
  }

  onChangeApplyDate = value => {
    const { group, form } = this.props
    const groupName = group && group.name ? group.name : ''
    const priceTableName = groupName ? `${groupName} ${value.format('DD/MM/YYYY')}` : value.format('DD/MM/YYYY')
    this.setState({ applyDate: value, priceTableName })
    form.setFieldsValue({ applyDate: value, priceTableName })
  }

  onChangePriceTable = value => {
    this.setState({ price_table_id: value })
  }

  onChangePriceTableName = value => {
    const { form } = this.props
    this.setState({ priceTableName: value })
    form.setFieldsValue({ priceTableName: value })
  }

  resetForm = () => {
    this.setState({ priceTableName: '', applyDate: moment().add(1, 'd'), price_table_id: undefined })
    this.props.form.resetFields()
  }

  onClose = () => {
    const { onCloseModal } = this.props
    this.resetForm()
    onCloseModal()
  }

  render() {
    const { form, visible, priceTables } = this.props
    const { getFieldDecorator } = form
    const { errorFile, dataNew, dataPresent, dataError, priceTableName, applyDate, isFetching, tabKey } = this.state
    const groupName = this.props.group?.name
    return (
      <Modal
        title={`Nhóm: ${groupName}`}
        visible={visible}
        closable={false}
        width="90%"
        footer={[
          <Button
            type="primary"
            icon="plus"
            key="submit"
            disabled={tabKey !== 'present'}
            loading={isFetching}
            onClick={this.handleSubmit}
          >
            {'Thêm báo giá'}
          </Button>,
          <Button
            key="close"
            icon="close"
            onClick={this.onClose}
            disabled={isFetching}
          >
              Đóng cửa sổ
          </Button>,
        ]}
      >
        {
          (
            <Form>
              <Row gutter={24}>
                <Col span={24} lg={5}>
                  <input
                    disabled={isFetching}
                    id="fileUpload"
                    type="file"
                    ref={v => {
                      this.fileUpload = v
                    }}
                    value={this.state.fileName}
                    accept=".xlsx, .xls"
                    onChange={this.onImportExcel}
                  />
                  {errorFile && <Alert style={{ marginTop: 10 }} type="error" showIcon message="Chọn file để upload" />}
                </Col>
                <Col span={5}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.google.com/spreadsheets/d/12RWhwb36SnF-5JSY0Lpa9MPqcrAIT0LXdSJkar4ysGc/edit?usp=sharing"
                  >
                    Mẫu file excel
                  </a>
                </Col>
                <Col span={12} lg={6}>
                  <Item label="Tên bảng giá">
                    {getFieldDecorator('priceTableName', {
                      initialValue: priceTableName || `${groupName} ${applyDate.format('DD-MM-YYYY')}`,
                      rules: [
                        {
                          required: true,
                          message: 'Nhập tên bảng giá',
                        },
                      ],
                    })(<Input
                      onChange={this.onChangePriceTableName}
                      placeholder="Tên bảng giá"
                    />)}
                  </Item>
                </Col>
                <Col span={12} lg={6}>
                  <Item label="Ngày áp dụng">
                    {getFieldDecorator('applyDate', {
                      initialValue: applyDate,
                      rules: [
                        {
                          required: true,
                          message: 'Chọn ngày áp dụng bảng giá',
                        },
                      ],
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        onChange={this.onChangeApplyDate}
                        disabledDate={current => (
                          current && moment(current).isBefore(moment().subtract(1, 'month'))
                        )}
                      />,
                    )}
                  </Item>
                </Col>
                <Col span={4} style={{ marginTop: '-60px', marginBottom: '15px' }}>
                  <Item label="Thêm báo giá từ">
                    {getFieldDecorator('price_table_id', {
                      rules: [
                        {
                          required: false,
                          message: 'Chọn bảng giá mẫu',
                        },
                      ],
                    })(
                      <Select
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Chọn bảng giá"
                        onChange={this.onChangePriceTable}
                      >
                        {priceTables.map(item => (
                          <Option key={item.priceTableId} value={item.priceTableId}>{item.priceTableName}</Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Tabs defaultActiveKey="present" onChange={key => this.setState({ tabKey: key })}>
                  <TabPane tab={`Sản phẩm hợp lệ (${dataPresent.length})`} key="present">
                    <Button onClick={this.exportExcel}>Tải xuống</Button>
                    <Table
                      size="small"
                      dataSource={dataPresent}
                      columns={columns(this.onChangePrice)}
                      loading={isFetching}
                      rowKey={(record, idx) => `${record.code}-${record.price}-${idx}`}
                      rowClassName={(r, idx) => `${r.arrPrice ? 'row-warning ' : ''}${(idx % 2 ? 'whitesmoke' : '')}`}
                      pagination={{
                        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40', '80'],
                      }}
                      scroll={{ x: 'max-content', y: 320 }}
                    />
                  </TabPane>
                  <TabPane tab={`Sản phẩm chưa có (${dataNew.length})`} key="new">
                    <Button onClick={this.exportExcel}>Tải xuống</Button>
                    <Table
                      size="small"
                      dataSource={dataNew}
                      columns={columns()}
                      loading={isFetching}
                      rowKey={(record, idx) => `${JSON.stringify(record)}-${idx}`}
                      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
                      pagination={{
                        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40', '80'],
                      }}
                      scroll={{ x: 'max-content', y: 320 }}
                    />
                  </TabPane>
                  <TabPane tab={`Sản phẩm lỗi (${dataError.length})`} key="error">
                    <Button onClick={this.exportExcel}>Tải xuống</Button>
                    <Table
                      size="small"
                      dataSource={dataError}
                      columns={columns()}
                      loading={isFetching}
                      rowKey={(record, idx) => `${JSON.stringify(record)}-${idx}`}
                      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
                      pagination={{
                        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40', '80'],
                      }}
                      scroll={{ x: 'max-content', y: 320 }}
                    />
                  </TabPane>
                </Tabs>
              </Row>
            </Form>
          )
        }
      </Modal>
    )
  }
}

UploadForm.propTypes = {
  form: PropTypes.object,
  group: PropTypes.object,
  products: PropTypes.array,
  priceTables: PropTypes.array,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  // isFetching: PropTypes.bool,
}

UploadForm.defaultProps = {
  form: {},
  group: {},
  products: [],
  priceTables: [],
  onSubmit: () => {},
}

export default WithLoading(Form.create()(UploadForm))
