/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'antd'
import moment from 'moment'

const { Item } = List
class ListPriceTables extends React.Component {
  constructor(props) {
    super(props)
    this.state = { id: null }
  }

  render() {
    const { data, getPriceTable, isFetching, getExistPriceTable } = this.props
    return (
      <List
        itemLayout="horizontal"
        dataSource={data}
        split
        loading={isFetching}
        renderItem={item => (
          <Item
            key={item.priceTableId}
            onClick={() => {
              getPriceTable(item.priceTableId)
              getExistPriceTable(item.priceTableId, { pageSize: -1 })
              this.setState({ id: item.priceTableId })
            }}
            className="item-price-table"
            style={item.priceTableId === this.state.id ? { backgroundColor: '#E1EAE6' } : {}}
          >
            <Item.Meta
              title={<b>{item.priceTableName}</b>}
              description={`Bắt đầu từ ${moment(item.applyDate.replace('Z', '+07:00')).format('DD-MM-YYYY HH:mm:ss')}`}
            />
          </Item>
        )
        }
      />
    )
  }
}

ListPriceTables.propTypes = {
  getPriceTable: PropTypes.func,
  getExistPriceTable: PropTypes.func,
  data: PropTypes.array,
  isFetching: PropTypes.bool
}

ListPriceTables.defaultProps = {
  getPriceTable: () => {},
  getExistPriceTable: () => {},
  data: []
}

export default ListPriceTables
