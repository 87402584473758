import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'

const FormItem = Form.Item

class ResetForm extends React.Component {
  render() {
    const {
      form: { getFieldDecorator },
      driver,
    } = this.props
    return (
      <Form>
        <FormItem label="Mã lái xe">
          {getFieldDecorator('code', { initialValue: driver.code })(<Input />)}
        </FormItem>
        <FormItem label="Tên lái xe">
          {getFieldDecorator('name', { initialValue: driver.name })(<Input />)}
        </FormItem>
        <FormItem label="Số điện thoại">
          {getFieldDecorator('phone_number', { initialValue: driver.phone_number })(<Input />)}
        </FormItem>
        <FormItem label="Mật khẩu mới">
          {getFieldDecorator('user.password', { initialValue: '123456' })(<Input />)}
        </FormItem>
      </Form>
    )
  }
}

ResetForm.propTypes = {
  form: PropTypes.object.isRequired,
  driver: PropTypes.object.isRequired,
}

export default Form.create()(ResetForm)
