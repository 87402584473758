/* eslint-disable react/no-unused-state */
/* eslint react/prop-types: 0 */
import React from 'react'
import { Row, Col, Card, notification, Tabs } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import WithPageHOC from '../../hoc/page'
import CartForm from './components/cart-form'
import CustomerList from './components/customer/list'
// import { backpage } from '../../util/windows'
import select from '../../util/select'
import {
  updateCart,
  deleteCart,
  getCart
} from './actions'
import ROUTER from '../../constants/router'

class EditCartPage extends React.Component {
  state = { code: null }

  static getDerivedStateFromProps(props, state) {
    if (state.code !== props.match.params.id) {
      props.getCart(props.match.params.id)
    }
    if (props.cart.lat !== undefined && props.cart.lng !== undefined) {
      return { code: props.cart.code }
    }
    return null
  }

  onChangePage = (page, pageSize) => this.props.getPriceTable(this.props.cart.Code, { page, pageSize })

  handleSubmit = payload => {
    this.props.updateCart(this.props.cart.id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Sửa thông tin giỏ hàng thành công' })
        this.props.history.push(ROUTER.CART.INDEX)
      },
      onError: error => notification.error({ message: `${error} - Sửa thông tin giỏ hàng thất bại` }),
    })
  }

  handleDelete = () => {
    this.props.history.push(ROUTER.CART.INDEX)
    this.props.deleteCart(this.props.match.params.id, {
      onSuccess: () => {
        notification.success({ message: 'Xoá giỏ hàng thành công' })
      },
      onError: error => notification.error({ message: `${error} - Xóa giỏ hàng thất bại` }),
    })
  }

  render() {
    const { isFetching, cart } = this.props
    return (
      <div>
        <Row>
          <Col span={24}>
            <Tabs>
              <Tabs.TabPane key="cart" tab="Thông tin">
                <Card title="Sửa thông tin giỏ hàng">
                  <CartForm
                    isFetching={isFetching}
                    editMode
                    cart={cart}
                    onSubmit={this.handleSubmit}
                    onDelete={this.handleDelete}
                  />
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key="customer" tab="Khách hàng">
                <Card
                  title="Quản lý khách hàng"
                  extra={
                    <Link to={{ pathname: ROUTER.CUSTOMER.ADD, state: { cartId: cart && cart.id } }}>Thêm mới</Link>
                  }
                >
                  <CustomerList
                    cart={cart}
                    customers={cart && cart.Customers}
                  />
                </Card>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  cart: select(state, ['cartReducer', 'cart'], 'editingCart'),
  isFetching: select(state, ['cartReducer', 'cart'], 'isFetching'),
})

const mapDispatchToProps = dispatch => ({
  getCart: id => dispatch(getCart(id)),
  updateCart: (id, payload, meta) => dispatch(updateCart(id, payload, meta)),
  deleteCart: (id, meta) => dispatch(deleteCart(id, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithPageHOC('cart', 'data')(EditCartPage))
