import React from 'react'
// import PropTypes from 'prop-types';

const Avatar = ({ src, borderRadius }) => (
  <div>
    <img
      src={src}
      alt=""
      style={{
        width: '100%',
        border: '1px solid #ddd',
        padding: '5px',
        borderRadius,
      }}
    />
  </div>
)

// Avatar.propTypes = {
//   src: PropTypes.string,
//   borderRadius: PropTypes.string,
// };

export default Avatar
