/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button, notification } from 'antd';
import WithPageHOC from '../../hoc/page';
import select from '../../util/select';
import ProductList from './components/List';
import FilterRow from './components/Filter';
import ROUTER from '../../constants/router';
import { API } from '../../constants/api';
import { apiCall } from '../../util/apiCall';

import {
  getProducts,
  getRequests,
  replyRequest,
  getProduct,
  deleteProduct,
  updateProduct,
  actChangeStatus,
} from './actions';
import { getProductConditionsIfNeed } from '../General/actions';
import { getCategories } from '../Category/actions';
import { isBelowBreakpoint } from '../../util/windows';

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filterOptions: {} };
  }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location;
    let params;
    if (search !== state.search) {
      const filterOptions = search.split('?')[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
        props.getProducts(JSON.parse(params));
      } else {
        props.getProducts();
      }
      return { ...state, search, filterOptions: params };
    }
    return state;
  }

  componentDidMount() {
    this.props.getProductCategories();
  }

  handleAdd = () => this.props.history.push(ROUTER.PRODUCT.ADD);

  handleFilter = filterOptions => {
    this.setState({ filterOptions });
    this.props.getProducts({
      ...filterOptions,
      page: 1,
      pageSize: 10,
    });
  };

  onSyncUboProducts = async () => {
    const { time } = this.props;
    const api = API.SCHEDULE.syncUboProducts();
    const { response, error } = await apiCall({
      ...api,
      params: { delivery_date: time },
    });

    if (!error && response.status === 200) {
      notification.success({ message: 'Đồng bộ dữ liệu thành công' });
    } else {
      notification.error({ message: 'Đồng bộ dữ liệu thất bại' });
    }
  };

  editProduct = id => this.props.history.push(ROUTER.PRODUCT.EDIT.replace(':id', id));

  deleteProduct = (name, id) => {
    Modal.confirm({
      okText: 'Xoá mặt hàng',
      okButtonProps: { type: 'danger' },
      onOk: () => this.onDelete(id),
      cancelText: 'Huỷ',
      cancelButtonProps: { type: 'primary' },
      title: `Bạn chắc chắn xoá mặt hàng này? ${name}`,
      content: 'Mọi thông tin liên quan sẽ bị xoá khỏi hệ thống !',
    });
  };

  onDelete = id => {
    this.props.deleteProduct(id, {
      onSuccess: () => {
        notification.success({ message: 'Xóa mặt hàng thành công' });
        this.props.getProducts();
      },
      onError: error => notification.error({ message: `${error} - Xóa mặt hàng thất bại` }),
    });
  };

  changeStatus = (id, value) => {
    this.props.updateProduct(
      id,
      { status: !value },
      {
        onSuccess: () => {
          this.props.changeStatus(id, value);
          notification.success({ message: 'Đã cập nhật trạng thái' });
        },
        onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
      },
    );
  };

  onChangePage = (page, pageSize) => {
    const { search } = this.props.history.location;
    if (search !== '') {
      let params;
      const filterOptions = search.split('?')[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, pageSize };

      const urlParameters = [];
      Object.entries(params).map(e => {
        if (e[1] !== undefined && e[1] !== '') {
          urlParameters.push(e.join('='));
        }
        return null;
      });
      this.props.history.push(ROUTER.PRODUCT.INDEX.concat(`?${urlParameters.join('&')}`));
    } else {
      this.props.history.push(ROUTER.PRODUCT.INDEX.concat(`?page=${page}&pageSize=${pageSize}`));
    }
  };

  onFilterRequest = params => this.props.getRequests(params);

  onResolve = (id, payload) => this.props.replyRequest(id, payload);

  render() {
    const { filterOptions } = this.state;
    const { products, meta, isFetching, conditions, categories, history } = this.props;
    return (
      <Fragment>
        <Row gutter={24}>
          <Col span={24} lg={6}>
            <FilterRow
              history={history}
              filterOptions={filterOptions}
              conditions={conditions}
              disabled={isFetching}
              categories={categories}
            />
          </Col>
          <Col span={24} lg={18}>
            <Row>
              <Button
                icon="plus"
                disabled={isFetching}
                onClick={this.handleAdd}
                style={{ marginTop: isBelowBreakpoint() ? 20 : 0 }}
              >
                Thêm hàng hóa
              </Button>
              {/* <Button type="danger" onClick={this.onSyncUboProducts} style={isBelowBreakpoint() ? { marginTop: 10 } : { float: 'right' }}>
                Đồng bộ Ubo
              </Button> */}
            </Row>
            <ProductList
              style={{ marginTop: 10 }}
              products={products}
              meta={meta}
              isFetching={isFetching}
              onEdit={this.editProduct}
              onView={this.viewProduct}
              onDelete={this.deleteProduct}
              onChangeStatus={this.changeStatus}
              onChangePage={this.onChangePage}
              onChangeSize={this.onChangePage}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: select(state, ['productReducer', 'list'], 'items'),
  product: select(state, ['productReducer', 'detail'], 'item'),
  conditions: select(state, ['generalReducer', 'productCondition'], 'items'),
  meta: select(state, ['productReducer', 'list'], 'meta'),
  isFetching: select(state, ['productReducer', 'list'], 'isFetching'),
  requests: select(state, ['productReducer', 'request'], 'items'),
  categories: select(state, ['categoryReducer', 'list'], 'items'),
});

const mapDispatchToProps = dispatch => ({
  getProducts: params => dispatch(getProducts(params)),
  getProduct: params => dispatch(getProduct(params)),
  getProductConditionsIfNeed: () => dispatch(getProductConditionsIfNeed()),
  getRequests: params => dispatch(getRequests(params)),
  replyRequest: (id, payload) => dispatch(replyRequest(id, payload)),
  getProductCategories: () => dispatch(getCategories()),
  deleteProduct: (id, meta) => dispatch(deleteProduct(id, meta)),
  updateProduct: (id, payload, meta) => dispatch(updateProduct(id, payload, meta)),
  changeStatus: (id, value) => dispatch(actChangeStatus(id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithPageHOC('product', 'data')(ProductsPage));
