/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Select, DatePicker, Button, Form, Input } from 'antd'
import moment from 'moment'
import { ORDER_STATUS } from '../../../constants/enum'
import ROUTER from '../../../constants/router'
import { changeAlias } from '../../../util/formatText'


const { Option } = Select
const { Item } = Form

const dateFormat = 'YYYY-MM-DD'

class FilterRow extends React.Component {
  state = { keyword: [] }

  static getDerivedStateFromProps(props) {
    const { search } = props.history.location
    const params = new URLSearchParams(search)
    // const result = params.get('status') // search.replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ').split(' ').splice(1)
    return { keyword: params }
  }

  handleSubmit = e => {
    e.preventDefault()
    const c_params = this.props.filterOptions === undefined ? {} : JSON.parse(this.props.filterOptions)
    c_params.page = '1'
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let require_time
        if (values.require_time) {
          require_time = moment(values.require_time).format('YYYY-MM-DD')
          values = { ...values, require_time }
        }
        let order_date
        if (values.order_date) {
          order_date = moment(values.order_date).format('YYYY-MM-DD')
          values = { ...values, order_date }
        }
        const urlParameters = []
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '' && e[1] !== null) {
            urlParameters.push(e.join('='))
          }
        })
        if (urlParameters[0] !== false) {
          this.props.history.push(ROUTER.INVOICE.INDEX.concat(`?${urlParameters.join('&')}`))
        }
      } else {
        console.log(err)
      }
    })
  };

  handleReset = () => {
    this.props.form.resetFields()
    this.props.history.push(ROUTER.INVOICE.INDEX)
  }

  render() {
    const { disabled, form: { getFieldDecorator }, accounts, user } = this.props
    const { keyword } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Col span={24} lg={6}>
          <Item label="Ngày giao hàng">
            {
              getFieldDecorator('require_time', { initialValue: keyword.get('require_time') ? moment(keyword.get('require_time'), dateFormat) : undefined })(
                <DatePicker
                  allowClear
                  style={{ width: '100%' }}
                  disabled={disabled}
                  placeholder="Ngày giao hàng"
                  format="DD/MM/YYYY"
                />
                ,
              )
            }
          </Item>
        </Col>
        <Col span={24} lg={6}>
          <Item label="Ngày đơn hàng">
            {
              getFieldDecorator('order_date', { initialValue: keyword.get('order_date') ? moment(keyword.get('order_date'), dateFormat) : undefined })(
                <DatePicker
                  allowClear
                  style={{ width: '100%' }}
                  disabled={disabled}
                  placeholder="Ngày đơn hàng"
                  format="DD/MM/YYYY"
                />
                ,
              )
            }
          </Item>
        </Col>
        <Col span={12} lg={6}>
          <Item label="Mã đơn hàng">
            {
              getFieldDecorator('order_code', { initialValue: keyword.get('order_code') ? keyword.get('order_code') : '' })(
                // có thể viết tắt || '' thay vì ? keyword.get('order_code') : ''
                <Input
                  allowClear
                  style={{ width: '100%' }}
                  disabled={disabled}
                  placeholder="Mã đơn hàng"
                />,
              )
            }
          </Item>
        </Col>
        <Col span={12} lg={6}>
          <Item label="Mã giỏ hàng">
            {
              getFieldDecorator('cart_code', { initialValue: keyword.get('cart_code') ? keyword.get('cart_code') : '' })(
                <Input
                  allowClear
                  style={{ width: '100%' }}
                  disabled={disabled}
                  placeholder="Mã giỏ hàng"
                />,
              )
            }
          </Item>
        </Col>
        <Col span={12} lg={4}>
          <Item label="Mã khách hàng">
            {
              getFieldDecorator('customer_code', { initialValue: keyword.get('customer_code') ? keyword.get('customer_code') : '' })(
                <Input
                  allowClear
                  style={{ width: '100%' }}
                  disabled={disabled}
                  placeholder="Mã khách hàng"
                />,
              )
            }
          </Item>
        </Col>
        <Col span={12} lg={4}>
          <Item label="Trạng thái">
            {
              getFieldDecorator('status', { initialValue: keyword.get('status') ? keyword.get('status') : '' })(
                <Select
                  allowClear
                  disabled={disabled}
                  style={{ width: '100%' }}
                  placeholder="Chọn trạng thái"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                >
                  {ORDER_STATUS ? ORDER_STATUS.map(item => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  )) : ''}
                </Select>,
              )
            }
          </Item>
        </Col>
        <Col span={12} lg={6}>
          <Item label="Nhân viên quản lý">
            {
              getFieldDecorator('sale_in_charge_id', { initialValue: user?.role === 'sale' ? user.id : keyword.get('sale_in_charge_id') ? keyword.get('sale_in_charge_id') : '' })(
                // có thể viết tắt || '' thay vì ? keyword.get('order_code') : ''
                <Select
                  placeholder="Nhân viên quản lý"
                  showSearch
                  allowClear
                  filterOption={(input, option) => changeAlias(option.props.children.toLowerCase()).indexOf(
                    changeAlias(input.toLowerCase()),
                  ) >= 0}
                // defaultValue={user ? user.id : ''}
                >
                  {accounts.filter(e => e.role === 'sale').map(item => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              )
            }
          </Item>
        </Col>
        {/* <Col span={16} lg={16} /> */}
        <Col span={12} lg={4}>
          <Button icon="filter" disabled={disabled} type="primary" htmlType="submit" style={{ width: '100%', marginTop: '42px' }}>
            {'Lọc'}
          </Button>
        </Col>
        <Col span={12} lg={4}>
          <Button icon="close" disabled={disabled} onClick={this.handleReset} style={{ width: '100%', marginTop: '42px' }}>
            {'Bỏ lọc'}
          </Button>
        </Col>
      </Form>
    )
  }
}

FilterRow.propTypes = {
  // onFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
}

export default Form.create()(FilterRow)
