/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import { Row, Button, Table, Icon, Select, Col, Divider, Input, DatePicker, message } from 'antd'
import { connect } from 'react-redux'
import { css } from '@emotion/core'
import moment from 'moment'
import { getOrderReportExcel, getOrderReportPreview } from '../actions'
import WithPageHOC from '../../../hoc/page'
import select from '../../../util/select'
import { isBelowBreakpoint } from '../../../util/windows'

const { Option } = Select
const tableCSS = css({ backgroundColor: 'green' })
class DailyReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = { require_time: moment(), categoryCode: 0 }
  }

  expandedRowProductRender = record => {
    const columns = [
      {
        title: 'Mã sản phẩm',
        dataIndex: 'productCode',
        key: 'productCode',
      },
      {
        title: 'Tên sản phẩm',
        dataIndex: 'productName',
        key: 'productName',
        ...this.getColumnSearchProps('productName', 'Sản phẩm'),
      },
      {
        title: 'Đơn vị',
        dataIndex: 'unitName',
        key: 'unitName',
      },
      {
        title: 'Số lượng',
        dataIndex: 'totalQuantity',
        key: 'totalQuantity',
      },
    ]
    return (
      <Table
        size="middle"
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={record.products}
        pagination={false}
        rowKey="productCode"
        rowClassName={(r, idx) => (idx % 2 === 0 ? 'even' : '')}
        css={tableCSS}
        expandedRowRender={this.expandedRowCartRender}
        style={{ marginLeft: '-30px' }}
      />
    )
  }

  expandedRowCartRender = record => {
    const columns = [
      {
        title: 'Mã  khách hàng',
        dataIndex: 'customerCode',
        key: 'customerCode',
      },
      {
        title: 'Tên khách hàng',
        dataIndex: 'CustomerName',
        key: 'CustomerName',
        ...this.getColumnSearchProps('CustomerName', 'Khách hàng'),
      },
      {
        title: 'Số lượng',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ]

    return (
      <Table
        size="middle"
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={record.customers}
        pagination={false}
        rowKey="OrderItemCode"
        rowClassName={record => `status-${record.IsShipped ? 'all' : 'none'}`}
        style={{ marginLeft: '20px' }}
        className="hide-checkbox-header"
      />
    )
  }

  getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Tìm ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          className="buttonSearch"
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Tìm
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Bỏ lọc
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type="search"
        style={{
          color: filtered ? '#1890ff' : undefined,
          position: 'relative',
          display: 'inline',
          marginLeft: '15px',
          paddingTop: '5px'
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: text => (text
      // <Highlighter
      //   highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      //   searchWords={[this.state.searchText]}
      //   autoEscape
      //   textToHighlight={text.toString()}
      // />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm()
  };

  handleReset = clearFilters => {
    clearFilters()
  }

  columns = () => [
    {
      title: 'Loại sản phẩm',
      dataIndex: 'categoryName',
      key: 'categoryName',
      ...this.getColumnSearchProps('categoryName', 'Loại sản phẩm'),
    },
    {
      title: 'Mã loại sản phẩm',
      dataIndex: 'categoryCode',
      key: 'categoryCode',
    },
  ]

  downloadExcel = () => {
    const { categoryCode } = this.state
    this.props.getReportExcel(
      { require_time: moment(this.state.require_time).format('YYYY-MM-DD'), categoryCode: categoryCode === 0 ? undefined : categoryCode },
      {
        onSuccess: url => {
          window.open(url, 'download_report')
        },
        onError: () => {
          message.warning('Không có báo cáo')
        }
      }
    )
  }

  setRequireTime = date => {
    this.setState({ require_time: date })
  }

  onChangeCategory = value => {
    this.setState({ categoryCode: value })
  }

  render() {
    const { isFetching, data, getReportPreview } = this.props
    const { require_time } = this.state
    return (
      <div>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <h1>Báo cáo đơn hàng trong ngày</h1>
          </Col>
        </Row>
        <Divider />
        <Row>
          <DatePicker
            allowClear={false}
            placeholder="Ngày giao hàng"
            format="DD/MM/YYYY"
            value={require_time}
            onChange={value => this.setRequireTime(value)}
            style={{ marginRight: '10px', marginBottom: isBelowBreakpoint() ? 5 : 0 }}
          />
          <Button
            type="primary"
            style={{ marginRight: '10px' }}
            onClick={() => getReportPreview(
              { require_time: moment(require_time).format('YYYY-MM-DD') },
              {
                onSuccess: () => {

                },
                onError: () => {
                  message.warning('Không có báo cáo')
                }
              }
            )}
          >
            {'Lọc'}
          </Button>
          <Select
            style={{ width: '200px' }}
            allowClear
            placeholder="Chọn nhóm hàng"
            onChange={this.onChangeCategory}
          >
            {data.map(item => (
              <Option key={item.categoryCode} value={item.categoryCode}>{item.categoryName}</Option>
            ))}
          </Select>
          <Button type="primary" onClick={this.downloadExcel}>
            {'Tải về'}
          </Button>
        </Row>
        <Divider dashed />
        <Table
          size="small"
          rowClassName={(r, idx) => (idx % 2 === 0 ? 'even' : '')}
          scroll={{ x: 'max-content' }}
          loading={isFetching}
          columns={this.columns()}
          dataSource={data}
          pagination={false}
          expandedRowRender={this.expandedRowProductRender}
          rowKey="categoryCode"
          className="table-daily-summary-order"
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  data: select(state, ['reportReducer', 'orderInDay'], 'data'),
  isFetching: select(state, ['reportReducer', 'orderInDay'], 'isFetching'),
  excel: select(state, ['reportReducer', 'orderInDay'], 'excel'),
})

const mapDispatchToProps = dispatch => ({
  getReportExcel: (params, meta) => dispatch(getOrderReportExcel(params, meta)),
  getReportPreview: (params, meta) => dispatch(getOrderReportPreview(params, meta))
})

export default connect(mapStateToProps, mapDispatchToProps)(WithPageHOC()(DailyReport))
