/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Select, Input, Button, Form } from 'antd'
import ROUTER from '../../../constants/router'

const { Option } = Select
const { Item } = Form

class FilterRow extends React.Component {
  state = { keyword: [] }

  static getDerivedStateFromProps(props) {
    const { search } = props.history.location
    const result = search.replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ').split(' ').splice(1)

    return { keyword: result }
  }

  handleSubmit = e => {
    e.preventDefault()
    const c_params = this.props.filterOptions === undefined ? {} : JSON.parse(this.props.filterOptions)
    c_params.page = '1'
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const urlParameters = []
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '') {
            urlParameters.push(e.join('='))
          }
          return null
        })
        if (urlParameters[0] !== false) {
          this.props.history.push(ROUTER.DRIVER.INDEX.concat(`?${urlParameters.join('&')}`))
        }
      } else {
        console.log(err)
      }
    })
  };

  handleReset = () => {
    this.props.form.resetFields()
    this.props.history.push(ROUTER.DRIVER.INDEX)
  }

  render() {
    const { disabled, form: { getFieldDecorator } } = this.props
    const { keyword } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Item label="Mã lái xe">
          {
            getFieldDecorator('code', { initialValue: keyword.indexOf('code') === -1 ? '' : keyword[keyword.indexOf('code') + 1] })(
              <Input
                allowClear
                disabled={disabled}
                placeholder="Mã lái xe"
              />,
            )
          }
        </Item>
        <Item label="Tên lái xe">
          {
            getFieldDecorator('name', { initialValue: keyword.indexOf('name') === -1 ? '' : keyword[keyword.indexOf('name') + 1] })(
              <Input
                allowClear
                disabled={disabled}
                placeholder="Tên lái xe"
              />,
            )
          }
        </Item>
        <Item label="Số điện thoại">
          {
            getFieldDecorator('phoneNumber', { initialValue: keyword.indexOf('phoneNumber') === -1 ? '' : keyword[keyword.indexOf('phoneNumber') + 1] })(
              <Input
                allowClear
                disabled={disabled}
                placeholder="Số điện thoại"
              />,
            )
          }
        </Item>
        {/* <Item label="Phương tiện có thể lái">
          {
            getFieldDecorator('id')(
              <Select
                disabled={disabled}
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="Phương tiện có thể lái"
              >
                {vehicleTypes.map((item,index) => (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                ))}
              </Select>,
            )
          }
        </Item> */}
        {/* <Item label="Ngày sinh">
          {
            getFieldDecorator('DoB')(
              <DatePicker
                disabled={disabled}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
                placeholder="Ngày sinh"
              />,
            )
          }
        </Item> */}
        <Item label="Giới tính">
          {
            getFieldDecorator('gender', { initialValue: keyword.indexOf('gender') === -1 ? '' : keyword[keyword.indexOf('gender') + 1] })(
              <Select
                allowClear
                disabled={disabled}
                style={{ width: '100%' }}
                placeholder="Giới tính"
              >
                <Option value="male" key="male">
                  {'Nam'}
                </Option>
                <Option value="female" key="female">
                  {'Nữ'}
                </Option>
              </Select>,
            )
          }
        </Item>
        <Row gutter={24}>
          <Col span={12} lg={12}>
            <Button icon="filter" disabled={disabled} type="primary" htmlType="submit" style={{ width: '100%' }}>
              {'Lọc'}
            </Button>
          </Col>
          <Col span={12} lg={12}>
            <Button icon="close" disabled={disabled} onClick={this.handleReset} style={{ width: '100%' }}>
              {'Bỏ lọc'}
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

FilterRow.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

FilterRow.defaultProps = { disabled: false }

export default Form.create()(FilterRow)
