/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Card, notification, Tabs } from 'antd';

import WithLoadingHOC from '../../hoc/loading';
import WithPageHOC from '../../hoc/page';
import select from '../../util/select';
import { getSupplier, updateSupplier, deleteSupplier } from './actions';
import SupplierForm from './components/Form';
import SupplierCriteria from './SupplierCriteria';
import ROUTER from '../../constants/router';

const { TabPane } = Tabs;
class EditSupplierPage extends React.Component {
  componentDidMount() {
    this.props.getSupplier(this.props.match.params.id);
  }

  onDelete = id => {
    this.props.deleteSupplier(id, {
      onSuccess: () => {
        this.props.history.push(ROUTER.SUPPLIER.INDEX);
        notification.success({ message: 'Xoá nhà cung cấp thành công' });
      },
      onError: error => notification.error({ message: `${error}, "Cập nhật gặp lỗi !"` }),
    });
  };

  handleSubmit = payload => {
    const supplier = this.props.supplier;
    const code = supplier.code;
    this.props.updateSupplier(
      code,
      {
        ...payload,
        criteria: supplier.criteria,
        contract: supplier.contract,
      },
      {
        onSuccess: () => notification.success({ message: 'Sửa nhà cung cấp thành công' }),
        onError: error => notification.error({ message: `${error} - Sửa nhà cung cấp thất bại` }),
      },
    );
  };

  render() {
    const { supplier } = this.props;
    return (
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Sửa thông tin" key="1">
            <Card>
              <SupplierForm editMode supplier={supplier} onSubmit={this.handleSubmit} onDelete={this.onDelete} />
            </Card>
          </TabPane>
          <TabPane tab="Giấy chứng nhận" key="2">
            <Card>
              <SupplierCriteria supplierCode={supplier.code} />
            </Card>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  supplier: select(state, ['supplierReducer', 'detail'], 'item'),
  isFetching: select(state, ['supplierReducer', 'detail'], 'isFetching'),
});

const mapDispatchToProps = dispatch => ({
  getSupplier: id => dispatch(getSupplier(id)),
  updateSupplier: (id, payload, meta) => dispatch(updateSupplier(id, payload, meta)),
  deleteSupplier: (id, meta) => dispatch(deleteSupplier(id, meta)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithLoadingHOC(WithPageHOC('supplier', 'data')(EditSupplierPage)));
