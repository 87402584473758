/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notification } from 'antd'
import _ from 'lodash'

import SolutionMap from '../components/solution-map'
import EditRouteCard from '../components/edit-route-card'
import NodeModal from '../components/node-modal'

import {
  solveVrp,
  calcSolution,
  showNodeInfo,
  sortDrivers,
  updateRoutes,
  updateDriverNote,
  setNodePool,
  updateSolution,
  getSchedulesSample,
  getSchedule,
  resetNodesAndDriversSuccess,
  resetRoutingSample,
  resetModeRouting,
  deleteSchedule,

} from '../actions'
import { moveElement, removeElement, addElement } from '../../../util/array'
import select from '../../../util/select'

class SolutionStep extends Component {
  state = {
    mapData: {
      depot: {
        lat: 20.995271,
        lng: 105.780953,
      },
      nodes: [],
      routes: [],
    },
    node: {},
    showNodeModal: false,
  };

  componentDidMount() {
    if (this.props.history.location.pathname === '/routing-sample') {
      this.props.resetRoutingSample()
    }

    this.props.resetModeRouting()

    if (!this.props.isVrp) {
      this.props.getSchedulesSample()
    }
  }

  solve = (maxNodesPerRoute, maxRouteDuration, maxRunTime, vehicleCapacity) => {
    const depot = {
      location: {
        address: 'Trung Văn',
        latitude: 20.995271,
        longitude: 105.780953,
      },
      nbVehicles: this.props.drivers.length,
      vehicleCapacity: vehicleCapacity || 100,
    }
    const customers = []
    this.props.selectedNodes.forEach(node => {
      customers.push({
        code: node.cart.cartCode,
        deliveryAt: {
          endTime: this.props.time,
          startTime: this.props.time
        },
        demand: node.totalWeight,
        location: {
          address: node.cart.contact.address,
          latitude: node.cart.contact.latitude,
          longitude: node.cart.contact.longitude
        },
        occupied_space: node.cart.occupiedSpace || 0,
      })
    })
    const payload = {
      customers,
      depot,
      maxNodesPerRoute,
      maxRouteDuration,
      maxRunTime
    }
    this.props.solveVrp(payload, {
      onSuccess: data => {
        const solution = { nodes: [] }
        for (let i = 0; i < data.solution.routes.length; i++) {
          const j = data.solution.routes.length - 1 - i
          data.solution.routes[j].nodes.forEach((node, index) => {
            solution.nodes.push({
              // need position to correct the node order
              carrier: { code: this.props.drivers[i].code, position: index },
              cart: { cartCode: node.customerCode }
            })
          })
        }
        // console.log('solveVrp solution', solution)
        this.collectMapData(solution)
      },
      onError: () => {
        notification.success({ message: 'Tối ưu lịch không thành công. Bạn vui lòng thử lại với tuyến mẫu.' })
      }
    })
  }

  collectMapData = solution => {
    const { selectedNodes, drivers } = this.props
    const mapData = {
      depot: {
        lat: 20.995271,
        lng: 105.780953,
      },
      nodes: [],
      routes: [],
    }
    if (!_.isEmpty(solution)) {
      mapData.depot = {
        lat: 20.995271,
        lng: 105.780953,
      }
      const listNodePool = [...selectedNodes]
      mapData.routes = drivers.map((driver, index) => {
        const cartCodes = {}
        solution.nodes.filter(e => driver.code === e.carrier.code).forEach(item => {
          cartCodes[item.cart.cartCode] = item
        })
        const routeNodes = []
        for (let i = listNodePool.length - 1; i >= 0; i--) {
          if (Object.keys(cartCodes).includes(listNodePool[i].cart.cartCode)) {
            const item = { ...listNodePool[i] }
            if (!item.carrier && cartCodes[item.cart.cartCode].carrier) {
              item.carrier = { ...cartCodes[item.cart.cartCode].carrier }
            }
            routeNodes.push(item)
            if (item.carrier && item.carrier.code !== driver.code) {
              // when the carrier is different from the driver ==> find the correct driver on the carriers list
              // eslint-disable-next-line no-loop-func
              const fixedCarrier = item.carriers.find(dr => dr.code === driver.code)
              if (fixedCarrier) {
                item.carrier = fixedCarrier
              }
            }
            // cartCodes.splice(cartCodes.indexOf(item.cart.cartCode), 1)
            delete cartCodes[item.cart.cartCode]
            listNodePool.splice(i, 1)
          }
        }

        routeNodes.sort((a, b) => a.carrier.position - b.carrier.position)
        // console.log('sort routeNodes', routeNodes)
        return {
          distance: 0,
          duration: 0,
          weight: 0,
          numberOfOrder: index,
          driverId: driver.code,
          nodes: routeNodes.map(item => ({
            cart: item.cart,
            version: item.version,
            weight: item.totalWeight,
            lat: parseFloat(item.cart.contact.latitude),
            lng: parseFloat(item.cart.contact.longitude),
          })),
        }
      })
      mapData.nodes = selectedNodes.map(item => ({
        cart: item.cart,
        version: item.version,
        weight: item.totalWeight,
        lat: parseFloat(item.cart.contact.latitude),
        lng: parseFloat(item.cart.contact.longitude),
      }))

      this.props.setNodePool(
        listNodePool.map(item => ({
          lat: parseFloat(item.cart.contact.latitude),
          lng: parseFloat(item.cart.contact.longitude),
          weight: item.totalWeight,
          version: item.version,
          cart: item.cart,
        })),
      )
      this.calcAndUpdateSolution(mapData.routes)
    }
    this.setState({ mapData })
  };

  calcAndUpdateSolution = updateRoutes => {
    const payloadCalc = {
      routes: updateRoutes.map(item => ({
        depot: {
          location: {
            address: 'Trung Văn',
            latitude: 20.995271,
            longitude: 105.780953,
          },
        },
        driverId: item.driverId,
        nodes: item.nodes.map(itemNode => ({
          load: itemNode.weight,
          location: {
            latitude: itemNode.lat,
            longitude: itemNode.lng
          }
        }))
      }))
    }

    this.props.calcSolution(payloadCalc, {
      onSuccess: data => {
        const routes = updateRoutes.map(item => {
          const route = data.routes.find(e => e.driverId === item.driverId)
          return {
            ...item,
            weight: route ? route.totalDemand : 0,
            distance: route ? route.totalDistance : 0,
            duration: route ? route.totalDuration : 0,
          }
        })
        this.props.updateSolution({ routes })
      },
      onError: () => {
        notification.error({ message: 'Tính toán khoảng cách, thời gian, của các tuyến không thành công.' })
        this.props.updateSolution({ routes: updateRoutes })
      }
    })
  }

  onChangeNodePosition = (node, source, target) => {
    const { solution } = this.props
    const nodePool = this.props.nodePool
    const { routes } = solution
    let updatedRoutes = []
    if (target.routeIndex === -1) {
      let tempNode
      updatedRoutes = routes.map((route, routeIndex) => {
        if (routeIndex === source.routeIndex) {
          tempNode = route.nodes[source.nodeIndex]
          return {
            ...route,
            nodes: removeElement(route.nodes, source.nodeIndex),
          }
        }
        return route
      })
      this.props.setNodePool(nodePool.concat(tempNode))
    } else if (source.routeIndex === -1) {
      updatedRoutes = routes.map((route, routeIndex) => {
        if (routeIndex === target.routeIndex) {
          return {
            ...route,
            nodes: addElement(route.nodes, target.nodeIndex, node),
          }
        }
        return route
      })
      this.props.setNodePool(nodePool.filter(item => item.cart.cartCode !== node.cart.cartCode || item.version !== node.version))
    } else if (source.routeIndex === target.routeIndex) {
      updatedRoutes = routes.map((route, routeIndex) => {
        if (routeIndex === source.routeIndex) {
          return {
            ...route,
            nodes: moveElement(route.nodes, source.nodeIndex, target.nodeIndex),
          }
        }
        return route
      })
    } else {
      updatedRoutes = routes.map((route, routeIndex) => {
        if (routeIndex === source.routeIndex) {
          return {
            ...route,
            nodes: removeElement(route.nodes, source.nodeIndex),
          }
        }
        if (routeIndex === target.routeIndex) {
          return {
            ...route,
            nodes: addElement(route.nodes, target.nodeIndex, node),
          }
        }
        return route
      })
    }
    this.calcAndUpdateSolution(updatedRoutes)
    this.setState(prevState => ({ mapData: { ...prevState.mapData, routes: updatedRoutes } }))
  };

  onSwapNodes = (source, target) => {
    if (target.routeIndex !== -1 && source.routeIndex !== -1) {
      const { routes } = this.props.solution
      const resultRoutes = []
      routes.forEach(route => {
        const resultNodes = []
        route.nodes.forEach(node => {
          if (node.cart.cartCode === source.node.cart.cartCode && node.version === source.node.version) {
            resultNodes.push(target.node)
          } else if (node.cart.cartCode === target.node.cart.cartCode && node.version === target.node.version) {
            resultNodes.push(source.node)
          } else {
            resultNodes.push(node)
          }
        })
        resultRoutes.push({ ...route, nodes: resultNodes })
      })

      this.calcAndUpdateSolution(resultRoutes)
      this.setState(prevState => ({ mapData: { ...prevState.mapData, routes: resultRoutes } }))
    }
  };

  selectSampleSolution = async code => {
    await this.props.getSchedule(code, this.props.isSampleManage)
    this.collectMapData(this.props.sampleSolution)
  };

  onShowNodeInfo = (node, routeIndex) => {
    const nodeCarrier = { ...node }
    nodeCarrier.carrier = this.props.drivers[routeIndex]
    this.setState({
      node: { ...nodeCarrier, routeIndex },
      showNodeModal: true,
    })
  };

  closeNodeModal = () => {
    this.setState({
      node: {},
      showNodeModal: false,
    })
  };

  updateNode = confirmers => {
    const { solution, drivers } = this.props
    const { routes } = solution
    const { node } = this.state
    routes[node.routeIndex].nodes.forEach(itemNode => {
      if (itemNode.cart.cartCode === node.cart.cartCode) {
        itemNode.confirmers = drivers.filter(item => confirmers.includes(item.code))
      }
    })
    this.props.updateSolution({ routes })
    this.closeNodeModal()
  };

  onSortDrivers = updatedDrivers => {
    this.props.sortDrivers(updatedDrivers)
  };

  onChangeDriverNote = (driver, note) => {
    this.props.updateDriverNote(driver, note)
  };

  removeSampleSolution = id => {
    this.props.deleteSchedule(id, {
      onSuccess: () => {
        notification.success({ message: 'Xóa tuyến mẫu thành công' })
        this.props.resetRoutingSample()
        this.props.resetModeRouting()
        this.props.getSchedulesSample()
      },
      onError: error => notification.error({ message: `Xóa tuyến mẫu không thành công - ${error}` }),
    })
  }

  render() {
    const { drivers, solution, mapConfig, isFetching, sampleSolutions, nodePool, isVrp } = this.props
    const { mapData, node, showNodeModal } = this.state

    return (
      <div>
        <SolutionMap
          data={mapData}
          mapConfig={mapConfig}
        />
        <EditRouteCard
          drivers={drivers}
          nodePool={nodePool}
          sampleSolutions={sampleSolutions}
          isFetching={isFetching}
          solution={solution}
          onSelectSampleSolution={this.selectSampleSolution}
          onShowNodeInfo={this.onShowNodeInfo}
          onSortDrivers={this.onSortDrivers}
          onChangeNodePosition={this.onChangeNodePosition}
          onSwapNodes={this.onSwapNodes}
          onChangeDriverNote={this.onChangeDriverNote}
          isVrp={isVrp}
          onSolve={this.solve}
          removeSampleSolution={this.removeSampleSolution}
        />
        <NodeModal
          visible={showNodeModal}
          node={node}
          drivers={drivers}
          onCancel={this.closeNodeModal}
          onUpdate={this.updateNode}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  nodePool: select(state, 'routingReducer', 'nodePool'),
  solution: select(state, 'routingReducer', 'solution'),
  drivers: select(state, 'routingReducer', 'drivers'),
  selectedNodes: select(state, 'routingReducer', 'nodes'),
  isFetching: select(state, 'routingReducer', 'isFetching'),
  mapConfig: select(state, 'routingReducer', 'mapConfig'),
  time: select(state, 'routingReducer', 'time'),
  sampleSolutions: select(state, ['scheduleReducer', 'sample'], 'items'),
  sampleSolution: select(state, ['scheduleReducer', 'detail'], 'item'),
})

const mapDispatchToProps = dispatch => ({
  showNodeInfo: node => dispatch(showNodeInfo(node)),
  sortDrivers: updatedDrivers => dispatch(sortDrivers(updatedDrivers)),
  updateRoutes: updatedRoutes => dispatch(updateRoutes(updatedRoutes)),
  updateDriverNote: (driver, note) => dispatch(updateDriverNote(driver, note)),
  setNodePool: payload => dispatch(setNodePool(payload)),
  solveVrp: (payload, meta) => dispatch(solveVrp(payload, meta)),
  calcSolution: (payload, meta) => dispatch(calcSolution(payload, meta)),
  updateSolution: payload => dispatch(updateSolution(payload)),
  getSchedulesSample: () => dispatch(getSchedulesSample()),
  getSchedule: (scheduleCode, isSampleManage) => dispatch(getSchedule(scheduleCode, isSampleManage)),
  resetNodesAndDriversSuccess: () => dispatch(resetNodesAndDriversSuccess()),
  resetRoutingSample: () => dispatch(resetRoutingSample()),
  resetModeRouting: () => dispatch(resetModeRouting()),
  deleteSchedule: (id, meta) => dispatch(deleteSchedule(id, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SolutionStep)
