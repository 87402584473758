/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import React from 'react'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import { Button, Form, Tabs, Alert, Modal, Row, Table, notification, Col, DatePicker, Input, message } from 'antd'
import { json2excel } from 'js2excel'
import moment from 'moment'
import WithLoading from '../../../../hoc/loading'

const { TabPane } = Tabs
const { Item } = Form
const findId = (products, productCode) => {
  let result = -1
  products.forEach(item => {
    if (item.code === productCode) {
      result = item.id
    }
  })
  return result
}
const findIndex = (list, code) => {
  let result = -1
  list.forEach((item, index) => {
    if (item.productCode === code) {
      result = index
    }
  })
  return result
}
const columns = [
  {
    title: 'STT',
    dataIndex: 'row',
    key: 'row',
    width: 150
  },
  {
    title: 'Mã SP',
    dataIndex: 'productCode',
    key: 'code',
    width: 150
  },
  {
    title: 'TÊN HÀNG HÓA',
    dataIndex: 'productName',
    key: 'name',
  },
  {
    title: 'ĐVT',
    dataIndex: 'unitName',
    key: 'unit',
    width: 100
  },
  {
    title: 'ĐƠN GIÁ (VND)',
    dataIndex: 'pricePerUnit',
    key: 'price',
    width: 150
  },
  {
    title: 'GHI CHÚ',
    dataIndex: 'note',
    key: 'note',
    width: 150
  }
]
// eslint-disable-next-line prefer-const
class UploadForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorFile: false,
      dataNew: [],
      dataOld: [],
      dataPresent: [],
      dataError: [],
      dataNotExist: [],
      tabKey: 'present',
      fileName: '',
      isFetching: false
    }
    this.fileUpload = React.createRef()
  }

  componentDidMount() {
    this.props.getExistPriceTable(this.props.priceTableId, { pageSize: -1 })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dataPresent } = this.state
        if (dataPresent.length > 0) {
          let { applyDate, priceTableName } = values
          applyDate = moment(applyDate).format('YYYY-MM-DD')
          const payload = {
            applyDate,
            priceItems: dataPresent.map(item => {
              let tmp = {
                priceItemId: item.priceItemId,
                newPrice: parseInt(item.pricePerUnit, 0)
              }
              return tmp
            }),
            priceTableName
          }
          this.props.onSubmit(
            this.props.priceTableId,
            payload,
            {
              onSuccess: () => {
                notification.success({ message: 'Cập nhật thành công' })
              },
              onError: () => {
                notification.error({ message: 'Cập nhật thất bại' })
              }
            }
          )
        } else {
          message.warning('Chưa có sản phẩm nào để cập nhật')
        }
      }
    })
  }

  onAddNewItems = () => {
    const { dataNew } = this.state
    const newItems = dataNew.map(item => {
      let tmp = {
        productId: item.productId,
        price: parseInt(item.pricePerUnit, 0)
      }
      return tmp
    })
    if (newItems.length <= 0) {
      message.warning('Không có sản phẩm mới nào')
    } else {
      this.props.addItem(
        this.props.priceTableId,
        { items: newItems },
        {
          onSuccess: () => {
            notification.success({ message: 'Thêm sẩn phẩm mới thành công' })
          },
          onError: () => {
            notification.error({ message: 'Thêm sẩn phẩm mới thất bại' })
          }
        }
      )
    }
  }

  isUpperCase = letter => {
    for (let i = 0; i < letter.length; i++) {
      if (letter[i] !== letter[i].toUpperCase()) {
        return false
      }
    }
    return true
  }

  isNote = item => {
    if (item.row !== undefined && Number.isNaN(item.row)) {
      return true
    }
    return false
  }

  isCategory = item => {
    if (item.productCode === undefined
      && item.unitName === undefined
      && item.pricePerUnit === undefined
      && item.productName !== undefined
      && this.isUpperCase(item.productName)) {
      return true
    }
    return false
  }

  isItemError = item => {
    if ((item.productCode === undefined || item.pricePerUnit === undefined || item.productName === undefined)) {
      return true
    }
    return false
  }

  isOld = item => {
    let result = false
    const currentPrices = this.props.priceTable.priceItem
    for (let i = 0; i < currentPrices.length; i++) {
      if (item.productCode === currentPrices[i].productCode) {
        result = true
        break
      }
    }
    return result
  }

  isExist = item => {
    let result = -1
    const currentPrices = this.props.priceTable.priceItem
    for (let i = 0; i < currentPrices.length; i++) {
      if (item.productCode === currentPrices[i].productCode) {
        result = currentPrices[i].priceItemId
        break
      }
    }
    return result
  }

  importExcel = file => {
    const fileReader = new FileReader()
    let currentPrices = this.props.priceTable.priceItem
    const { products } = this.props
    let dataNew = []
    let tmpDataNew = []
    let dataOld = []
    let dataPresent = []
    let dataError = []
    let dataNotExist = []

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let startAt = 0
        let endAt = 0
        const { result } = event.target
        const workbook = XLSX.read(result, { type: 'binary' })
        const Sheet = workbook.Sheets[workbook.SheetNames[0]]
        let data = XLSX.utils.sheet_to_json(Sheet)
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`]
          if (flagCell !== undefined) {
            if (flagCell.v === 'STT') {
              startAt = i
              // 13
              break
            }
          }
        }
        if (startAt === 0) {
          notification.error({ message: 'Tải lên thất bại!' })
          this.setState({ isFetching: false })
        } else {
          this.setState({ isFetching: true })
          data = XLSX.utils.sheet_to_json(Sheet, { range: startAt, header: ['row', 'productCode', 'productName', 'unitName', 'pricePerUnit', 'note'] })
          /** format empty cell */
          data.forEach((item, index) => {
            if (item.row !== undefined) {
              item.row = (item.row.toString().trim() === '') ? undefined : item.row.toString().trim()
            }
            if (item.productCode !== undefined) {
              item.productCode = (item.productCode.toString().trim() === '') ? undefined : item.productCode.toString().trim()
            }
            if (item.productName !== undefined) {
              item.productName = (item.productName.toString().trim() === '') ? undefined : item.productName.toString().trim()
            }
            if (item.unitName !== undefined) {
              item.unitName = (item.unitName.toString().trim() === '') ? undefined : item.unitName.toString().trim()
            }
            if (item.pricePerUnit !== undefined) {
              item.pricePerUnit = (item.pricePerUnit.toString().trim() === '') ? undefined : item.pricePerUnit.toString().trim()
            }
            if (item.note !== undefined) {
              item.note = (item.note.toString().trim() === '') ? undefined : item.note.toString().trim()
            }
            if (this.isNote(item)) {
              endAt = index
            }
          })
          /** read each row */
          data.forEach((item, index) => {
            if (!this.isNote(item) && index < (endAt > 0 ? endAt : (data.length + 1))) {
              if (this.isItemError(item)) {
                dataError.push(item)
              } else {
                let priceItemId = this.isExist(item)
                if (priceItemId > 0) {
                  dataPresent.push({ ...item, priceItemId })
                } else {
                  dataNew.push(item)
                }
              }
            }
          })

          dataNew.forEach(item => {
            let productId = findId(products, item.productCode)
            if (productId < 0) {
              dataNotExist.push(item)
            } else {
              tmpDataNew.push({ ...item, productId })
            }
          })

          currentPrices.forEach(item => {
            let idx = findIndex(dataPresent, item.productCode)
            if (idx < 0) {
              dataOld.push(item)
            }
          })
          notification.success({ message: 'Tải lên thành công!' })
          this.setState({ dataNew: tmpDataNew, dataOld, dataError, dataPresent, dataNotExist, isFetching: false })
        }
      } catch (e) {
        notification.error({ message: e })
        this.setState({ isFetching: false })
      }
    }
    fileReader.readAsBinaryString(file)
  }

  onImportExcel = event => {
    const { files } = event.target
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.setState({ isFetching: true })
      this.importExcel(files[0])
    }
  }

  exportExcel = () => {
    const { dataError, dataNew, dataOld, dataPresent, tabKey, dataNotExist } = this.state
    // eslint-disable-next-line no-nested-ternary
    let data = []
    if (tabKey === 'present') {
      data = dataPresent
    } else {
      data = (tabKey === 'new') ? dataNew : (tabKey === 'error' ? dataError : (tabKey === 'old' ? dataOld : dataNotExist))
    }
    data = data.map(item => {
      const newItem = {}
      newItem.STT = item.row
      newItem['MÃ SP'] = item.productCode
      newItem['TÊN HÀNG HÓA'] = item.productName
      newItem['ĐVT'] = item.unitName
      newItem['ĐƠN GIÁ (VND)'] = item.pricePerUnit
      newItem['GHI CHÚ'] = item.note
      return newItem
    })
    try {
      json2excel({
        data,
        name: tabKey,
        formateDate: 'yyyy/mm/dd',
      })
    } catch (e) {
      notification.error({ message: 'Lỗi ! Có thể trình duyệt của bạn không hỗ trợ ! Vui lòng liên hệ nhà phát triển !' })
    }
  }

  render() {
    const { form: { getFieldDecorator }, visible, onCloseModal, priceTable } = this.props
    const { errorFile, dataNew, dataOld, dataPresent, dataError, dataNotExist, isFetching, tabKey } = this.state
    return (
      <Modal
        visible={visible}
        closable={false}
        width="90%"
        footer={[
          <Button
            type="primary"
            icon="plus"
            key="submit"
            disabled={tabKey !== 'present'}
            loading={isFetching}
            onClick={this.handleSubmit}
          >
            {'Cập nhật bảng giá'}
          </Button>,
          <Button
            type="primary"
            icon="plus"
            key="add"
            disabled={tabKey !== 'new'}
            loading={isFetching}
            onClick={this.onAddNewItems}
          >
            {'Thêm các sản phẩm mới'}
          </Button>,
          <Button
            key="close"
            icon="close"
            onClick={onCloseModal}
            disabled={isFetching}
          >
            {'Đóng cửa sổ'}
          </Button>,
        ]}
      >
        {
          (
            <Form>
              <Row gutter={24}>
                <Col span={12}>
                  <input
                    disabled={isFetching}
                    id="fileUpload"
                    type="file"
                    ref={v => {
                      this.fileUpload = v
                    }}
                    value={this.state.fileName}
                    accept=".xlsx, .xls"
                    onChange={this.onImportExcel}
                  />
                  {errorFile && <Alert style={{ marginTop: 10 }} type="error" showIcon message="Chọn file để upload" />}
                </Col>
                <Col span={6}>
                  <Item label="Tên bảng giá">
                    {getFieldDecorator('priceTableName', {
                      initialValue: priceTable.priceTableName,
                      rules: [
                        {
                          required: true,
                          message: 'Nhập tên bảng giá',
                        },
                      ],
                    })(<Input placeholder="Tên bảng giá" />)}
                  </Item>
                </Col>
                <Col span={6}>
                  <Item label="Ngày áp dụng">
                    {getFieldDecorator('applyDate', {
                      initialValue:
                      moment(priceTable.applyDate)
                        .add(1, 'd'),
                      // .hour(6)
                      // .minute(0),
                      rules: [
                        {
                          required: true,
                          message: 'Chọn ngày áp dụng bảng giá',
                        },
                      ],
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        disabledDate={current => (
                          current
                      && current
                          < moment()
                            .add(1, 'd')
                            .hour(0)
                            .valueOf()
                        )}
                      />,
                    )}
                  </Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Tabs defaultActiveKey="present" onChange={key => this.setState({ tabKey: key })}>
                  <TabPane tab={`Sản phẩm đã có sẵn (${dataPresent.length})`} key="present">
                    <Button onClick={this.exportExcel}>Tải xuống</Button>
                    <Table
                      dataSource={dataPresent}
                      columns={columns}
                      loading={isFetching}
                      rowKey={record => record.productCode}
                      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
                      pagination={{
                        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40', '80'],
                      }}
                      scroll={{ x: 'max-content', y: 320 }}
                    />
                  </TabPane>
                  <TabPane tab={`Sản phẩm mới (${dataNew.length})`} key="new">
                    <Button onClick={this.exportExcel}>Tải xuống</Button>
                    <Table
                      dataSource={dataNew}
                      columns={columns}
                      loading={isFetching}
                      rowKey={record => record.productCode}
                      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
                      pagination={{
                        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40', '80'],
                      }}
                      scroll={{ x: 'max-content', y: 320 }}
                    />
                  </TabPane>
                  <TabPane tab={`Sản phẩm cũ (${dataOld.length})`} key="old">
                    <Button onClick={this.exportExcel}>Tải xuống</Button>
                    <Table
                      size="small"
                      dataSource={dataOld}
                      columns={columns}
                      loading={isFetching}
                      rowKey={record => record.productCode}
                      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
                      pagination={{
                        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40', '80'],
                      }}
                      scroll={{ x: 'max-content', y: 320 }}
                    />
                  </TabPane>
                  <TabPane tab={`Sản phẩm không tồn tại (${dataNotExist.length})`} key="not-exist">
                    <Button onClick={this.exportExcel}>Tải xuống</Button>
                    <Table
                      size="small"
                      dataSource={dataNotExist}
                      columns={columns}
                      loading={isFetching}
                      rowKey={record => record.productCode}
                      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
                      pagination={{
                        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40', '80'],
                      }}
                      scroll={{ y: 320 }}
                    />
                  </TabPane>
                  <TabPane tab={`Sản phẩm lỗi (${dataError.length})`} key="error">
                    <Button onClick={this.exportExcel}>Tải xuống</Button>
                    <Table
                      size="small"
                      dataSource={dataError}
                      columns={columns}
                      loading={isFetching}
                      rowKey={record => record.productCode}
                      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
                      pagination={{
                        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40', '80'],
                      }}
                      scroll={{ y: 320 }}
                    />
                  </TabPane>
                </Tabs>
              </Row>
            </Form>
          )
        }
      </Modal>
    )
  }
}

UploadForm.propTypes = {
  groupID: PropTypes.string,
  priceTable: PropTypes.object,
  products: PropTypes.array,
  form: PropTypes.object,
  onUpload: PropTypes.func,
  onCloseModal: PropTypes.func,
  getExistPriceTable: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
}

UploadForm.defaultProps = {
  form: {},
  products: [],
  priceTable: {},
  onUpload: () => {},
  getExistPriceTable: () => {}
}

export default WithLoading(Form.create()(UploadForm))
