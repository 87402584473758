import { Avatar, Button, Card, Col, Row } from 'antd';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import CriteriaForm from './Form';

const CriteriaList = props => {
  const { dataSource, onAdd } = props;
  const [lightboxOpened, setLightboxOpened] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = idx => {
    setLightboxOpened(true);
    setPhotoIndex(idx);
  };

  const closeLightbox = () => {
    setLightboxOpened(false);
    setPhotoIndex(0);
  };

  return (
    <Row gutter={15}>
      <Col span={6}>
        <Card>
          <CriteriaForm onAdd={onAdd} />
        </Card>
      </Col>
      {dataSource.map((criteria, idx) => (
        <Col span={6} key={criteria.image_url}>
          <Card
            extra={<Button icon="delete" type="danger" onClick={() => props.onRemove(criteria)} size="small" />}
            style={{ textAlign: 'center' }}
          >
            <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => openLightbox(idx)}>
              <Avatar size={120} shape="square" src={criteria.image_url} />
              <h3
                style={{
                  marginTop: '10px',
                }}
              >
                {criteria.name}
              </h3>
            </div>
          </Card>
        </Col>
      ))}
      {lightboxOpened ? (
        <Lightbox
          onCloseRequest={closeLightbox}
          mainSrc={dataSource[photoIndex].image_url}
          imageCaption={dataSource[photoIndex].name}
          nextSrc={dataSource[(photoIndex + 1) % dataSource.length].image_url}
          prevSrc={dataSource[(photoIndex + dataSource.length - 1) % dataSource.length].image_url}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + dataSource.length - 1) % dataSource.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % dataSource.length)}
        />
      ) : null}
    </Row>
  );
};

export default CriteriaList;
