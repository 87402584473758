const HEADERS = {
  DEFAULT_HEADER: { 'Content-Type': 'application/json; charset=UTF-8' },
  JWT_HEADER: () => ({
    'Content-Type': 'application/json; charset=UTF-8',
    Authorization: localStorage.getItem('aaJwt'),
  }),
  file_header: () => ({
    'Content-Type': 'multipart/form-data',
    Authorization: localStorage.getItem('aaJwt'),
  }),
};

export const API = {
  AUTH: {
    login: () => ({
      endPoint: '/api/v2/be/access/login',
      method: 'POST',
      headers: HEADERS.DEFAULT_HEADER,
    }),
    loginWithToken: () => ({
      endPoint: '/api/v2/be/access/login/token',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  ACCOUNT: {
    createAccount: () => ({
      endPoint: '/api/v2/be/account/create',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    getAccounts: () => ({
      endPoint: '/api/v2/be/account/accounts',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    updatePassword: () => ({
      endPoint: '/api/v2/be/account/change-password',
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    resetPassword: () => ({
      endPoint: '/api/v2/be/account/reset-password',
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateUser: accountId => ({
      endPoint: `/api/v2/be/account/${accountId}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    getUser: userId => ({
      endPoint: `/api/v2/be/account/${userId}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    removeUser: userId => ({
      endPoint: `/api/v2/be/account/${userId}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateStatusUser: () => ({
      endPoint: '/api/v2/be/account/status',
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  NOTIFICATION: {
    subscribeOrderEvent: () => ({
      endPoint: '/order/sub',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getNotifications: () => ({
      endPoint: '/api/account/v1/notifications',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateNotificationStatus: id => ({
      endPoint: `/api/account/v1/notifications/${id}`,
      method: 'PATCH',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  VEHICLE: {
    getVehicles: () => ({
      endPoint: '/api/v2/be/vehicle',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getVehicle: id => ({
      endPoint: `/api/v2/be/vehicle/${id}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertVehicle: () => ({
      endPoint: '/api/v2/be/vehicle',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateVehicle: id => ({
      endPoint: `/api/v2/be/vehicle/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteVehicle: id => ({
      endPoint: `/api/v2/be/vehicle/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    importFromExcel: () => ({
      endPoint: '/api/vehicle/v1/vehicles/import/by_excel',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  VEHICLE_TYPE: {
    getVehicleTypes: () => ({
      endPoint: '/api/v2/be/vehicle/vehicle-type',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertVehicleType: () => ({
      endPoint: '/api/v2/be/vehicle/vehicle-type',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateVehicleType: id => ({
      endPoint: `/api/vehicle/v1/vehicles-type/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteVehicleType: id => ({
      endPoint: `/api/v2/be/vehicle/vehicle-type/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  CART: {
    getCarts: () => ({
      endPoint: '/api/v2/be/cart/filter',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getCart: code => ({
      endPoint: `/api/v2/be/cart/${code}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertCart: () => ({
      endPoint: '/api/v2/be/cart',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateCart: id => ({
      endPoint: `/api/v2/be/cart/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteCart: id => ({
      endPoint: `/api/v2/be/cart/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    changeStatusCart: id => ({
      endPoint: `/api/v2/be/cart/active/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    importFromExcel: () => ({
      endPoint: '/api/v2/be/cart/carts/import',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  CUSTOMER: {
    getCustomers: () => ({
      endPoint: '/api/v2/be/customer/customers',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getCustomer: code => ({
      endPoint: `/api/v2/be/customer/customers/${code}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertCustomer: () => ({
      endPoint: '/api/v2/be/customer/customers',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateCustomer: id => ({
      endPoint: `/api/v2/be/customer/customers/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteCustomer: id => ({
      endPoint: `/api/v2/be/customer/customers/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    importFromExcel: () => ({
      endPoint: '/api/v2/be/customer/customers/import',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    changeCustomerStatus: id => ({
      endPoint: `/api/v2/be/customer/customers/active/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    getCustomersNoOrder: () => ({
      endPoint: '/api/v2/be/customer/customers/no-order',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getCartCustomers: payload => ({
      endPoint: '/api/v2/be/cart/carts',
      method: 'GET',
      data: payload,
      headers: HEADERS.JWT_HEADER(),
    }),
    getUboCustomerPhone: params => ({
      endPoint: '/api/v2/be/ubo/check-customerphone',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
      params,
    }),
  },
  CUSTOMER_GROUP: {
    getCustomerGroups: () => ({
      endPoint: '/api/v2/be/customer/customer-group',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertCustomerGroup: () => ({
      endPoint: '/api/v2/be/customer/customer-group',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateCustomerGroup: id => ({
      endPoint: `/api/v2/be/customer/customer-group/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteCustomerGroup: id => ({
      endPoint: `/api/v2/be/customer/customer-group/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    addCustomersToGroup: () => ({
      endPoint: '/api/v2/be/customer/customer-group/add-customers',
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    getCustomerOfGroup: id => ({
      endPoint: `/api/v2/be/customer/customer-group/${id}/customers`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    removeCustomerFromGroup: id => ({
      endPoint: `/api/customer/v1/customer-groups/${id}/customers`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    importPriceTable: () => ({
      endPoint: '/api/customer/v1/customer-price-tables/from-excel',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    getPriceTable: code => ({
      endPoint: `/api/customer/v1/customer-price-tables/by-customers/${code}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    addItemToPriceTable: id => ({
      endPoint: `/api/customer/v1/customer-price-tables/${id}/product-prices`,
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    removeItemFromPriceTable: id => ({
      endPoint: `/api/customer/v1/customer-price-tables/${id}/product-prices`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateItemInPriceTable: id => ({
      endPoint: `/api/customer/v1/customer-price-tables/${id}/product-prices`,
      method: 'PATCH',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  PRODUCT: {
    getProducts: () => ({
      endPoint: '/api/v2/be/product/products',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getProductByCustomerId: id => ({
      endPoint: `/api/v2/be/price/price-table/detail/${id}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getProduct: code => ({
      endPoint: `/api/v2/be/product/${code}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertProduct: () => ({
      endPoint: '/api/v2/be/product/products',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateProduct: id => ({
      endPoint: `/api/v2/be/product/products/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteProduct: id => ({
      endPoint: `/api/v2/be/product/products/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    importFromExcel: () => ({
      endPoint: '/api/v2/be/product/products/import',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateProductImages: productId => ({
      endPoint: `/api/v2/be/product/products/image/${productId}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateProductCriteria: code => ({
      endPoint: `/api/v2/be/product/products/${code}/criteria-and-contract`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    getClientProduct: code => ({
      endPoint: `/api/v2/be/product/client/${code}`,
      method: 'GET',
      headers: HEADERS.DEFAULT_HEADER,
    }),
  },
  REQUEST: {
    getRequests: () => ({
      endPoint: '/api/product/v1/product-request',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    replyRequest: id => ({
      endPoint: `/api/product/v1/product-request/${id}/response`,
      method: 'PATCH',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  PRODUCT_UNIT: {
    getProductUnits: () => ({
      endPoint: '/api/v2/be/unit/units',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertProductUnit: () => ({
      endPoint: '/api/v2/be/unit/units',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    getProductUnit: id => ({
      endPoint: `/api/v2/be/unit/units/${id}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateProductUnit: id => ({
      endPoint: `/api/v2/be/unit/units/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteProductUnit: id => ({
      endPoint: `/api/v2/be/unit/units/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  PRODUCT_CONDITION: {
    getProductConditions: () => ({
      endPoint: '/api/v2/be/storage/storage-condition',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertProductConditions: () => ({
      endPoint: '/api/v2/be/storage/storage-condition',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteProductCondition: id => ({
      endPoint: `/api/v2/be/storage/storage-condition/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  DRIVER: {
    getDrivers: () => ({
      endPoint: '/api/v2/be/driver',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getDriver: id => ({
      endPoint: `/api/v2/be/driver/${id}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertDriver: () => ({
      endPoint: '/api/v2/be/driver',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateDriver: id => ({
      endPoint: `/api/v2/be/driver/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateDriverPhone: id => ({
      endPoint: `/api/v2/be/driver/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteDriver: id => ({
      endPoint: `/api/v2/be/driver/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    importFromExcel: () => ({
      endPoint: '/api/v2/be/driver/import',
      method: 'POST',
      headers: HEADERS.file_header(),
    }),
  },
  INVOICE: {
    importFromExcel: () => ({
      endPoint: '/api/v2/be/order/orders/import',
      method: 'POST',
      headers: HEADERS.file_header(),
    }),
    getInvoices: () => ({
      endPoint: '/api/v2/be/order/orders',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getInvoice: id => ({
      endPoint: `/api/v2/be/order/order-items/${id}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateInvoice: id => ({
      endPoint: `/api/v2/be/order/orders/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateInvoiceStatus: orderId => ({
      endPoint: `/api/v2/be/order/orders/change-order-status/${orderId}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteInvoice: id => ({
      endPoint: `/api/v2/be/order/orders/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertInvoice: () => ({
      endPoint: '/api/v2/be/order/orders',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    checkOrdered: () => ({
      endPoint: '/api/invoice/v1/invoice/ordered-customer-status',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    reportInvoices: () => ({
      endPoint: '/api/v2/be/order/orders/report',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertOrderItem: orderId => ({
      endPoint: `/api/v2/be/order/order-items/${orderId}`,
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateOrderItem: (id, orderId) => ({
      endPoint: `/api/v2/be/order/order-items/${orderId}/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateOrderItemStatus: orderId => ({
      endPoint: `/api/v2/be/order/order-items/status/${orderId}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateOrderItemStatus2Cancel: (orderId, itemId) => ({
      endPoint: `/api/v2/be/order/orders/cancel-item/${orderId}/${itemId}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteOrderItem: id => ({
      endPoint: `/api/v2/be/order/order-items/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    getInvoicesByCart: cartCode => ({
      endPoint: `/api/v2/be/order/orders/${cartCode}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    postInvoiceOrder: orderId => ({
      endPoint: `/api/v2/be/order/orders/${orderId}/order-items`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    exportOrderPDF: () => ({
      endPoint: '/api/v2/be/order/orders/pdf',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteInvoiceOrder: id => ({
      endPoint: `/api/v2/be/order/orders/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteInvoiceOrderItem: id => ({
      endPoint: `/api/v2/be/order/order-items/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    initSampleOrders: () => ({
      endPoint: '/api/v2/be//order/order-sample',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  ROUTING: {
    solve: () => ({
      endPoint: 'api/v2/solver/cvrp',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    calc: () => ({
      endPoint: 'api/v2/solver/calc',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateSolution: () => ({
      endPoint: `${process.env.REACT_APP_SOLVER_API}/vrpsolver/api/v1/routes/calculation`,
      method: 'POST',
      headers: HEADERS.DEFAULT_HEADER,
    }),
  },
  ROUTE: {
    getRoutes: driverID => ({
      endPoint: `/api/route-manager/v1/routesmanager/drivers/${driverID}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    changeDriver: (routeId, driverId) => ({
      endPoint: '/api/route-manager/v1/routesmanager/updateDriver/',
      method: 'PUT',
      params: { RouteID: routeId },
      payload: { ID: driverId },
      headers: HEADERS.JWT_HEADER(),
    }),
    getRouteTracking: () => ({
      endPoint: '/api/routes-tracking/v1/routes-tracking',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  SCHEDULE: {
    getSchedulesSample: () => ({
      endPoint: '/api/v2/scheduling/list-sample',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getSchedules: () => ({
      endPoint: '/api/v2/scheduling/list',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getSchedule: code => ({
      endPoint: `/api/v2/scheduling/detail/${code}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertSchedule: () => ({
      endPoint: '/api/v2/scheduling/create',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateSchedule: id => ({
      endPoint: `/api/route-manager/v1/routesmanager/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteSchedule: scheduleCode => ({
      endPoint: `/api/v2/scheduling/delete/${scheduleCode}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    importFromExcel: () => ({
      endPoint: 'manager',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    importSampleFromExcel: () => ({
      endPoint: '/api/v2/scheduling/import-schedule-sample',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    getNodesForSchedule: () => ({
      endPoint: '/api/v2/be/customer/customers-for-schedule',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getUboNodesForSchedule: () => ({
      endPoint: '/api/v2/scheduling/ubo/carts',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    syncUboNodesForSchedule: () => ({
      endPoint: '/api/v2/scheduling/ubo/syn',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    syncUboCategories: () => ({
      endPoint: '/api/v2/be/ubo/sync-category',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    syncUboProducts: () => ({
      endPoint: '/api/v2/be/ubo/sync-product',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    completedRoute: () => ({
      endPoint: '/api/v2/scheduling/completed-route',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  REPORT: {
    distanceReport: params => ({
      endPoint: '/api/route-manager/v1/routesmanager/drivers/reportalldrivers-distance',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
      params,
    }),
    weighReport: () => ({
      endPoint: '/api/route-manager/v1/routesmanager/drivers/reportdriver',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    weighsReport: () => ({
      endPoint: '/api/route-manager/v1/routesmanager/drivers/reportalldrivers',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    workingDaysReport: () => ({
      endPoint: '/api/route-manager/v1/routesmanager/drivers/reportalldrivers-workingdays',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    // orderInDayReport: () => ({
    //   endPoint: '/api/v2/order/orders/report',
    //   method: 'GET',
    //   headers: HEADERS.JWT_HEADER(),
    // }),
    getOrderReportExcel: () => ({
      endPoint: '/api/v2/be/order/orders/report',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getOrderReportPreview: () => ({
      endPoint: '/api/v2/be/order/orders/report-preview',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  MEDIA: {
    imageUpload: () => ({
      endPoint: '/api/upload-media/v1/images',
      method: 'POST',
      headers: HEADERS.file_header(),
    }),
    imageUploadv3: () => ({
      endPoint: '/api/upload-media/v3/images/noresize',
      method: 'POST',
      headers: HEADERS.file_header(),
    }),
  },
  PRICE_TABLE: {
    getPriceTables: () => ({
      endPoint: '/api/v2/be/price/price-table',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getPriceTable: id => ({
      endPoint: `/api/v2/be/price/price-table/detail/${id}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getPriceTableWhenSearch: (id, page, pageS) => ({
      endPoint: `/api/v2/be/price/price-table/detail/${id}?page=${page}&pageSize=${pageS}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertPriceTable: () => ({
      endPoint: '/api/v2/be/price/price-table',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updatePriceTable: id => ({
      endPoint: `/api/v2/be/price/price-table/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertPriceItem: priceTableId => ({
      endPoint: `/api/v2/be/price/many-price-item/${priceTableId}`,
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updatePriceItem: id => ({
      endPoint: `/api/v2/be/price/price-item/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deletePriceItem: id => ({
      endPoint: `/api/v2/be/price/price-item/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateStatusItem: id => ({
      endPoint: `/api/v2/be/price/price-table/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  CATEGORY: {
    getCategories: () => ({
      endPoint: '/api/v2/be/category/categories',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getCategory: id => ({
      endPoint: `/api/v2/be/category/categories/${id}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertCategory: () => ({
      endPoint: '/api/v2/be/category/categories',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateCategory: id => ({
      endPoint: `/api/v2/be/category/categories/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteCategory: id => ({
      endPoint: `/api/v2/be/category/categories/${id}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    importFromExcel: () => ({
      endPoint: '/api/product/v1/import/by_excel',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  SUPPLIER: {
    getSuppliers: () => ({
      endPoint: '/api/v2/be/supplier/suppliers',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    getSupplier: id => ({
      endPoint: `/api/v2/be/supplier/suppliers/${id}`,
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    insertSupplier: () => ({
      endPoint: '/api/v2/be/supplier/suppliers',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateSupplier: code => ({
      endPoint: `/api/v2/be/supplier/suppliers/${code}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateSupplierCriteria: code => ({
      endPoint: `/api/v2/be/supplier/suppliers/${code}/criteria-and-contract`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteSupplier: code => ({
      endPoint: `/api/v2/be/supplier/suppliers/${code}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    getClientSupplier: code => ({
      endPoint: `/api/v2/be/supplier/suppliers/client/${code}`,
      method: 'GET',
      headers: HEADERS.DEFAULT_HEADER,
    }),
  },
  MANAGA: {
    // api
    getAPIManaga: () => ({
      endPoint: '/api/v2/be/api/filter',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    createAPIManaga: () => ({
      endPoint: '/api/v2/be/api',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    changeStatusAPIManaga: id => ({
      endPoint: `/api/v2/be/api/active/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    updateAPIManaga: id => ({
      endPoint: `/api/v2/be/api/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    // role
    getRoleManaga: () => ({
      endPoint: '/api/v2/be/authorize/role/filter',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    createRoleManaga: () => ({
      endPoint: '/api/v2/be/authorize/role',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    editRoleManaga: id => ({
      endPoint: `/api/v2/be/authorize/role/${id}`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    activateRoleManaga: id => ({
      endPoint: `/api/v2/be/authorize/role/${id}/activate`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deactivateRoleManaga: id => ({
      endPoint: `/api/v2/be/authorize/role/${id}/deactivate`,
      method: 'PUT',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteApiOfRoleManaga: (roleId, apiId) => ({
      endPoint: `/api/v2/be/authorize/role/removeApi/${roleId}/${apiId}`,
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    deleteListApiOfRoleManaga: () => ({
      endPoint: '/api/v2/be/authorize/role/removeApis',
      method: 'DELETE',
      headers: HEADERS.JWT_HEADER(),
    }),
    addListApiOfRoleManaga: () => ({
      endPoint: '/api/v2/be/authorize/role/addApis',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    addApiOfRoleFromExcelManaga: () => ({
      endPoint: '/api/v2/be/authorize/role/import',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  SYN_DATA: {
    getDataForSynchronize: () => ({
      endPoint: '/api/v2/be/fast/items',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
    synchronizeFast: orderDate => ({
      endPoint: `/api/v2/be/fast/sync?orderDate=${orderDate}`,
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    Syncfast: () => ({
      endPoint: '/api/v2/be/fast/sync',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    Configurations: () => ({
      endPoint: '/api/v2/be/configurations',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    GetAllConfiguration: () => ({
      endPoint: '/api/v2/be/configurations',
      method: 'GET',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
  STAMP_API: {
    postStamp: offset => ({
      endPoint: `/api/v1/stamp/print?offset=${offset}`,
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
    getQRStamp: () => ({
      endPoint: '/api/v1/stamp/print/info-stamp',
      method: 'POST',
      headers: HEADERS.JWT_HEADER(),
    }),
  },
};
