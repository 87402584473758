import React from 'react'
import { Form, Button, Input, Checkbox } from 'antd'

const handleSubmit = (event, form, onSubmit) => {
  event.preventDefault()
  form.validateFields((error, values) => {
    if (!error) {
      onSubmit(values)
    }
  })
}

const LoginForm = ({ onSubmit, form, error, removeError }) => {
  const { getFieldDecorator } = form
  return (
    <Form onSubmit={event => handleSubmit(event, form, onSubmit)}>
      <Form.Item
        label="Tài khoản"
        validateStatus={error ? 'error' : undefined}
        help={
          error
            ? 'Đăng nhập không thành công.'
            : undefined
        }
      >
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: `${'Nhập tên người dùng (số điện thoại) của bạn'}!`,
            },
          ],
        })(
          <Input
            placeholder={`${'Tên người dùng'} ...`}
            onChange={() => removeError()}
          />,
        )}
      </Form.Item>
      <Form.Item
        label="Mật khẩu"
        validateStatus={error ? 'error' : undefined}
      >
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: `${'Nhập mật khẩu của bạn'}!`,
            },
          ],
        })(
          <Input
            type="password"
            placeholder={`${'Mật khẩu'} ...`}
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('remember', {
          valuePropName: 'checked',
          initialValue: true,
        })(<Checkbox>Ghi nhớ</Checkbox>)}
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          icon="login"
        >
          {'Đăng nhập'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create()(LoginForm)
