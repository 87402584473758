import React from 'react'
import { Link } from 'react-router-dom'
import ROUTER from '../constants/router'

const NotFound = () => (
  <div
    style={{
      width: '100%',
      height: '100vh',
      textAlign: 'center',
    }}
  >
    <p style={{ color: '#1890ff', fontSize: 120, margin: 0 }}>401</p>
    <p style={{ color: '#1890ff', fontSize: 50 }}>Bạn không có quyền vào trang này</p>
    {/* <Link to={ROUTER.HOME}>{trans('Return to Home Page')}</Link> */}
  </div>
)

export default NotFound
