import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'
import { findIndex } from '../../util/findIndex'

const apiState = {
  items: [],
  meta: {
    page: 0,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const api = (state = apiState, action) => produce(state, draft => {
  let index = -1
  switch (action.type) {
  case TYPES.GETTING_API_FOR_MANAGA:
    draft.isFetching = true
    break

  case TYPES.GET_API_FOR_MANAGA_SUCCESS: {
    const { apis, meta } = action.payload
    draft.items = apis
    draft.meta = {
      page: meta.page,
      pageSize: meta.page_size,
      total: meta.total
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_API_FOR_MANAGA_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.CHANGE_STATUS_API_SUCCESS: {
    index = findIndex(draft.items, action.id)
    draft.items[index].active = action.payload.active
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.UPDATE_API_SUCCESS: {
    index = findIndex(draft.items, action.id)
    draft.items[index] = action.payload
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.DELETE_API_OF_ROLE_SUCCESS: {
    index = findIndex(draft.items, action.apiId)
    draft.items.splice(index, 1)
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  case TYPES.DELETING_API_OF_ROLE:
    draft.isFetching = true
    break

  case TYPES.DETELE_API_OF_ROLE_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  default:
    return draft
  }
})

const roleState = {
  items: [],
  meta: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const role = (state = roleState, action) => produce(state, draft => {
  let index = -1
  switch (action.type) {
  case TYPES.GETTING_ROLE_FOR_MANAGA:
    draft.isFetching = true
    break

  case TYPES.GET_ROLE_FOR_MANAGA_SUCCESS: {
    const { roles, meta } = action.payload
    draft.items = roles
    draft.meta = {
      page: meta.page,
      pageSize: meta.page_size,
      total: meta.total
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_ROLE_FOR_MANAGA_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.ACTIVE_ROLE_SUCCESS: {
    index = findIndex(draft.items, action.id)
    draft.items[index].id = action.id
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.DEACTIVE_ROLE_SUCCESS: {
    index = findIndex(draft.items, action.id)
    draft.items[index].id = action.id
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }
  default:
    return draft
  }
})


export default combineReducers({ api, role })
