/* eslint react/prop-types: 0 */
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, notification } from 'antd'

import Filter from './components/filter'
import ScheduleList from './components/list'
import ROUTER from '../../constants/router'

import WithPageHOC from '../../hoc/page'
import select from '../../util/select'
import { getSchedules, deleteSchedule } from './actions'

class Schedule extends React.Component {
  constructor(props) {
    super(props)
    this.state = { filterOptions: {} }
  }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location
    let params
    if (search !== state.search) {
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
        props.getSchedules(JSON.parse(params))
      } else {
        props.getSchedules()
      }
      return { ...state, search, filterOptions: params }
    }

    return null
  }

  // componentDidMount() {
  //   const { meta } = this.props
  //   this.props.getSchedules({
  //     page: meta.current ? meta.current : 1,
  //     pageSize: meta.pageSize ? meta.pageSize : 10,
  //   })
  // }

  // onChange = (page, pageSize) => this.props.getSchedules({ page, pageSize });

  onChangePage = (page, pageSize) => {
    const { search } = this.props.history.location
    if (search !== '') {
      let params
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      }
      const obj = JSON.parse(params)
      params = { ...obj, page, pageSize }

      const urlParameters = []
      Object.entries(params).map(e => {
        if (e[1] !== undefined && e[1] !== '') {
          urlParameters.push(e.join('='))
        }
        return null
      })
      this.props.history.push(ROUTER.SCHEDULE.INDEX.concat(`?${urlParameters.join('&')}`))
    } else {
      this.props.history.push(ROUTER.SCHEDULE.INDEX.concat(`?page=${page}&pageSize=${pageSize}`))
    }
  }

  handleFilter = filterOptions => {
    this.setState({ filterOptions })
    this.props.getSchedules({
      ...filterOptions,
      page: 1,
      pageSize: 10,
    })
  }

  handleDelete = async scheduleCode => {
    await this.props.deleteSchedule(scheduleCode, {
      onSuccess: () => {
        notification.success({ message: 'Xóa lịch giao vận thành công' })
      },
      onError: error => notification.error({ message: 'Xóa lịch giao vận thất bại' }),
    })
    const { meta } = this.props
    const { filterOptions } = this.state
    this.props.getSchedules({
      ...filterOptions,
      page: meta.current ? meta.current : 1,
      pageSize: meta.pageSize ? meta.pageSize : 10,
    })
  };

  render() {
    const { history, schedules, meta, isFetching } = this.props
    return (
      <div>
        <Row gutter={24}>
          <Col span={24} lg={6}>
            <Filter
              history={history}
              onFilter={this.handleFilter}
            />
          </Col>
          <Col span={24} lg={18}>
            <ScheduleList
              schedules={schedules}
              isFetching={isFetching}
              meta={meta}
              onChange={this.onChangePage}
              onShowSizeChange={this.onChangePage}
              onDelete={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(
  state => ({
    schedules: select(state, ['scheduleReducer', 'list'], 'items'),
    isFetching: select(state, ['scheduleReducer', 'list'], 'isFetching'),
    meta: select(state, ['scheduleReducer', 'list'], 'meta'),
  }),
  dispatch => ({
    getSchedules: filterOptions => dispatch(getSchedules(filterOptions)),
    deleteSchedule: (scheduleCode, meta) => dispatch(deleteSchedule(scheduleCode, meta)),
  }),
)(WithPageHOC('schedule', 'route')(Schedule))
