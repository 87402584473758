/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'
// import { findIndex } from '../../util/findIndex'

const listState = {
  items: [],
  time: 0,
  meta: {
    total: 0,
    current: 0,
    pageSize: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const list = (state = listState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_INVOICES:
  case TYPES.INSERTING_INVOICE:
    draft.isFetching = true
    break

  case TYPES.GET_INVOICES_SUCCESS: {
    const { data, metaData } = action.payload
    draft.items = data
    draft.meta = {
      current: metaData.page,
      pageSize: metaData.pageSize,
      total: metaData.total
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_INVOICES_FAILURE:
  case TYPES.INSERT_INVOICE_SUCCESS:
  case TYPES.INSERT_INVOICE_FAILURE:
    draft.isFetching = false
    break
    // case TYPES.DELETE_INVOICE_ORDER_SUCCESS: {
    //   const list = draft.items
    //   const idx = findIndex(list, action.id)
    //   list.splice(idx, 1)

    //   draft.items = list
    //   draft.isFetching = false
    //   draft.didInvalidate = true
    //   break
    // }

  default:
    return draft
  }
})

const detailState = {
  item: {},
  cart: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const detail = (state = detailState, action) => produce(state, draft => {
  let item = null
  switch (action.type) {
  case TYPES.GETTING_INVOICE:
    draft.isFetching = true
    break

  case TYPES.GET_INVOICE_SUCCESS: {
    const { data } = action.payload
    draft.item = data
    draft.isFetching = false
    break
  }

  case TYPES.GET_CART_INFO:
    draft.cart = action.payload.data
    break

  case TYPES.GET_INVOICE_FAILURE:
    draft.isFetching = false
    break

  case TYPES.UPDATE_INVOICE_SUCCESS: {
    const requireTime = action.payload.requireTimeFrom
    item = draft.item
    const list = item.items.map(i => ({
      ...i,
      requireTime
    }))
    draft.item = {
      ...item,
      requireTime,
      items: list
    }
    break
  }

  default:
    return draft
  }
})

const listRouteState = {
  items: [],
  time: 0,
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const listForRouting = (state = listRouteState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_INVOICES:
    draft.isFetching = true
    break

  case TYPES.GET_INVOICES_SUCCESS: {
    draft.items = action.payload.invoices
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_INVOICES_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})

const checkState = {
  items: [],
  meta: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const checkResult = (state = checkState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.CHECKING_ORDERED:
    draft.isFetching = true
    break

  case TYPES.CHECK_ORDER_SUCCESS: {
    draft.items = action.payload
    draft.meta = action.meta
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.CHECK_ORDER_FAILURE: {
    draft.isFetching = false
    break
  }

  default:
    return draft
  }
})

export default combineReducers({ detail, list, listForRouting, checkResult })
