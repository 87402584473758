import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'

const gettingInvoices = () => ({ type: TYPES.GETTING_INVOICES })

const getInvoicesSuccess = payload => ({
  type: TYPES.GET_INVOICES_SUCCESS,
  payload,
})

const getInvoicesFailure = () => ({ type: TYPES.GET_INVOICES_FAILURE })

export const getInvoices = params => async dispatch => {
  const api = API.INVOICE.getInvoices()
  dispatch(gettingInvoices())
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200) {
    dispatch(
      getInvoicesSuccess(response.data),
    )
  } else {
    dispatch(getInvoicesFailure())
  }
}

export const insertingInvoice = () => ({ type: TYPES.INSERTING_INVOICE })

export const insertInvoiceSuccess = () => ({ type: TYPES.INSERT_INVOICE_SUCCESS })

export const insertInvoiceFailure = () => ({ type: TYPES.INSERT_INVOICE_FAILURE })

export const importInvoices = ({ payload, params }, meta) => async dispatch => {
  const api = API.INVOICE.importFromExcel()
  const { response, error } = await apiCall({
    ...api,
    payload,
    params,
  })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertInvoiceSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch(insertInvoiceFailure())
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

const gettingInvoice = () => ({ type: TYPES.GETTING_INVOICE })

const getInvoiceSuccess = payload => ({
  type: TYPES.GET_INVOICE_SUCCESS,
  payload,
})

const getInvoiceFailure = () => ({ type: TYPES.GET_INVOICE_FAILURE })

export const getInvoiceByCode = code => async dispatch => {
  const api = API.INVOICE.getInvoice(code)
  dispatch(gettingInvoice())
  const { response, error } = await apiCall({ ...api, payload: { orderCode: code } })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getInvoiceSuccess(response.data))
  } else {
    dispatch(getInvoiceFailure())
  }
}

export const getCartInfo = code => async dispatch => {
  const api = API.CART.getCart(code)
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.GET_CART_INFO, payload: response.data })
  }
}

export const updateInvoice = (id, payload, meta) => async dispatch => {
  dispatch({ type: TYPES.UPDATING_INVOICE })
  const api = API.INVOICE.updateInvoice(id)
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_INVOICE_SUCCESS, id, payload })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
      // dispatch({ type: TYPES. })
    }
  } else if (meta && meta.onError) {
    dispatch({ type: TYPES.UPDATE_INVOICE_FAILURE })
    meta.onError(error)
  }
}

export const updateInvoiceStatus = (id, meta) => async dispatch => {
  dispatch({ type: TYPES.UPDATING_INVOICE_STATUS })
  const api = API.INVOICE.updateInvoiceStatus(id)
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_INVOICE_STATUS_SUCCESS, id })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (meta && meta.onError) {
    dispatch({ type: TYPES.UPDATE_INVOICE_STATUS_FAILURE })
    meta.onError('Bạn không có thẩm quyền')
  }
}

export const updateOrderItem = (id, orderId, payload, meta) => async dispatch => {
  dispatch({ type: TYPES.UPDATING_ORDER_ITEM })
  const api = API.INVOICE.updateOrderItem(id, orderId)
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_ORDER_ITEM_SUCCESS, id, payload })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (meta && meta.onError) {
    dispatch({ type: TYPES.UPDATE_ORDER_ITEM_FAILURE })
    meta.onError(error)
  }
}

export const cancelItem = (orderId, itemId, meta) => async dispatch => {
  dispatch({ type: TYPES.UPDATING_ORDER_ITEM })
  const api = API.INVOICE.updateOrderItemStatus2Cancel(orderId, itemId)
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_ORDER_ITEM_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (meta && meta.onError) {
    dispatch({ type: TYPES.UPDATE_ORDER_ITEM_FAILURE })
    meta.onError(error)
  }
}

export const deleteOrderItem = (id, meta) => async dispatch => {
  dispatch({ type: TYPES.DELETING_ORDER_ITEM })
  const api = API.INVOICE.deleteOrderItem(id)
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.DELETE_ORDER_ITEM_SUCCESS, id })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (meta && meta.onError) {
    dispatch({ type: TYPES.DELETE_ORDER_ITEM_FAILURE })
    meta.onError(error)
  }
}

export const checkOrdered = params => async dispatch => {
  const api = API.CUSTOMER.getCustomersNoOrder()
  dispatch({ type: TYPES.CHECKING_ORDERED })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.CHECK_ORDER_SUCCESS,
      payload: response.data.data,
      meta: response.data.metadata
    })
  } else {
    dispatch({ type: TYPES.CHECK_ORDER_FAILURE })
  }
}

export const reportInvoices = (params, meta) => async () => {
  const api = API.INVOICE.reportInvoices(params)
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else if (meta && meta.onError) {
    meta.onError(error)
  }
}

export const Syncfast = (params, meta) => async () => {
  const payload = { orderCodes: params }
  const api = API.SYN_DATA.Syncfast()
  const { response, error } = await apiCall({ ...api, params: payload })
  if (!error && response.status === 200 && response.data.success === true) {
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else if (meta && meta.onError) {
    meta.onError(error)
  }
}

export const insertInvoiceOrder = (orderId, payload, meta) => async dispatch => {
  dispatch({ type: TYPES.UPDATING_INVOICE_ORDER })
  const api = API.INVOICE.postInvoiceOrder(orderId)
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_INVOICE_ORDER_SUCCESS, payload })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (meta && meta.onError) {
    dispatch({ type: TYPES.UPDATE_INVOICE_ORDER_FAILURE })
    meta.onError(error)
  }
}

export const exportOrderPDF = (params, meta) => async () => {
  const payload = { orderCodeStr: params }
  const api = API.INVOICE.exportOrderPDF()
  const { response, error } = await apiCall({ ...api, params: payload })
  if (!error && response.status === 200 && response.data.success === true) {
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data.data)
    }
  } else if (meta && meta.onError) {
    meta.onError(error)
  }
}

export const removeInvoiceOrder = (id, meta) => async dispatch => {
  const api = API.INVOICE.deleteInvoiceOrder(id)
  dispatch({ type: TYPES.DELETING_INVOICE_ORDER })
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.DELETE_INVOICE_ORDER_SUCCESS, id })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.DELETE_INVOICE_ORDER_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const removeInvoiceOrderItem = (id, meta) => async dispatch => {
  const api = API.INVOICE.deleteInvoiceOrderItem(id)
  dispatch({ type: TYPES.DELETING_INVOICE_ORDER_ITEM })
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.DELETE_INVOICE_ORDER_ITEM_SUCCESS, id })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.DELETE_INVOICE_ORDER_ITEM_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

