import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const findIndex = (list, id) => {
  let result = -1
  list.forEach((item, index) => {
    if (item.product.id === id) {
      result = index
    }
  })
  return result
}


const productState = {
  items: [],
  isFetching: false,
  didInvalidate: false,
  updatedAt: 0,
}

const priceReducer = (state = productState, action) => produce(state, draft => {
  let index = null
  switch (action.type) {
  case TYPES.GETTING_PRODUCTS_FOR_PRICE_TABLES:
    draft.isFetching = true
    break

  case TYPES.GET_PRODUCTS_FOR_PRICE_TABLES_FAILURE:
    draft.isFetching = false
    break

  case TYPES.GET_PRODUCTS_FOR_PRICE_TABLES_SUCCESS: {
    draft.isFetching = false
    draft.items = action.payload
    break
  }

  case TYPES.ADD_ITEM_TO_NEW_PRICETABLE: {
    index = findIndex(draft.items, action.payload.product.id)
    if (index < 0) {
      draft.items.push(action.payload)
    } else {
      draft.items[index].price = action.payload.price
    }
    break
  }

  case TYPES.INSERT_PRICE_TABLE_SUCCESS:
  case TYPES.CANCEL_CREATE_PRICE: {
    draft.items = []
    draft.didInvalidate = false
    break
  }

  case TYPES.REMOVE_ITEM_FROM_NEW_PRICETABLE: {
    index = findIndex(draft.items, action.id)
    if (index >= 0) {
      draft.items.splice(index, 1)
    }
    break
  }

  case TYPES.IMPORT_PRICE_TABLE_FROM_EXIST_ONE: {
    draft.items = action.payload
    draft.didInvalidate = true
    break
  }

  case TYPES.ADD_ITEMS_TO_NEW_PRICETABLE: {
    if (action.payload && action.payload.length) {
      draft.items = action.payload.concat(draft.items)
    }
    break
  }

  // case TYPES.CLEAR_EXIST_PRICE_TABLE:
  //   return state.merge({
  //     items: [],
  //     isFetching: false,
  //     didInvalidate: false,
  //   })

  case TYPES.CLEAR_EXIST_PRICE_TABLE_VALUE:
    draft.didInvalidate = false
    break

  default:
    return draft
  }
})

export default priceReducer
