import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'
import { findIndex } from '../../util/findIndex'

const vtState = {
  items: [],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const vehicleType = (state = vtState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_VEHICLE_TYPES:
  case TYPES.INSERTING_VEHICLE_TYPE:
    draft.isFetching = true
    break

  case TYPES.GET_VEHICLE_TYPES_SUCCESS: {
    draft.items = action.payload.data
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_VEHICLE_TYPES_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INSERT_VEHICLE_TYPE_SUCCESS:
  case TYPES.DELETE_VEHICLE_TYPE_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  default:
    return draft
  }
})

const puState = {
  items: [],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const productUnit = (state = puState, action) => produce(state, draft => {
  let index = -1
  switch (action.type) {
  case TYPES.GETTING_PRODUCT_UNITS:
  case TYPES.INSERTING_PRODUCT_UNIT:
    draft.isFetching = true
    break

  case TYPES.GET_PRODUCT_UNITS_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = false
    draft.items = action.payload.data
    break
  }

  case TYPES.INSERT_PRODUCT_UNIT_SUCCESS: {
    draft.didInvalidate = true
    draft.isFetching = false
    break
  }

  case TYPES.INSERT_PRODUCT_UNIT_FAILURE: {
    draft.didInvalidate = true
    draft.isFetching = false
    break
  }

  case TYPES.DELETE_PRODUCT_UNIT_SUCCESS: {
    index = findIndex(draft.items, action.id)
    draft.items.splice(index, 1)
    draft.didInvalidate = true
    draft.isFetching = false
    break
  }

  case TYPES.UPDATE_PRODUCT_UNIT_SUCCESS: {
    index = findIndex(draft.items, action.id)
    draft.items[index].name = action.payload.unitName
    draft.didInvalidate = true
    draft.isFetching = false
    break
  }

  case TYPES.GET_PRODUCT_UNITS_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

const pcState = {
  items: [],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const productCondition = (state = pcState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_PRODUCT_CONDITIONS:
  case TYPES.INSERTING_PRODUCT_CONDITION:
    draft.isFetching = true
    break

  case TYPES.GET_PRODUCT_CONDITIONS_SUCCESS: {
    draft.items = action.payload.data
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_PRODUCT_CONDITIONS_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INSERT_PRODUCT_CONDITION_SUCCESS:
  case TYPES.DELETE_PRODUCT_CONDITION_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  default:
    return draft
  }
})

const accountState = {
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const account = (state = accountState, action) => produce(state, draft => {
  let index = -1
  switch (action.type) {
  case TYPES.GETTING_ACCOUNT:
    draft.isFetching = true
    break

  case TYPES.GET_ACCOUNT_SUCCESS: {
    const { accounts, meta } = action.payload
    draft.items = accounts
    draft.meta = {
      current: meta.page,
      pageSize: meta.pageSize,
      total: meta.total
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_ACCOUNT_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }
  case TYPES.CHANGE_USER_STATUS: {
    index = findIndex(draft.items, action.payload.userId)
    draft.items[index].status = action.payload.status

    draft.didInvalidate = false
    draft.isFetching = false
    break
  }

  case TYPES.UPDATE_USER_PROFILE_SUCCESS: {
    index = findIndex(draft.items, action.id)
    draft.items[index].role = action.payload.role

    draft.didInvalidate = true
    draft.isFetching = false
    break
  }

  default:
    return draft
  }
})

export default combineReducers({ account, vehicleType, productCondition, productUnit })
