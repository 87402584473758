/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Input, Button, Form } from 'antd'
import { isBelowBreakpoint } from '../../../../util/windows'

const { Item } = Form
class FilterRow extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { productCode, productName } = values
        // const { page, pageS } = this.props
        this.props.getKeywordSearch({ productCode, productName })
        this.props.getPriceTableWhenSearch(this.props.priceTableId, { productCode, productName }, 1, 10)
      }
    })
  };

  handleReset = () => {
    this.props.form.resetFields()
    this.props.form.validateFields(err => {
      if (!err) {
        this.props.getKeywordSearch({ productCode: '', productName: '' })
        this.props.onFilter(this.props.priceTableId)
      }
    })
  };

  render() {
    const { form: { getFieldDecorator }, disabled } = this.props
    return (
      <Form onSubmit={this.handleSubmit}>
        <Col span={24} lg={8}>
          <Item label="Mã sản phẩm">
            {
              getFieldDecorator('productCode')(
                <Input disabled={disabled} allowClear placeholder="Mã sản phẩm" />,
              )
            }
          </Item>
        </Col>
        <Col span={24} lg={8}>
          <Item label="Tên sản phẩm">
            {
              getFieldDecorator('productName')(
                <Input disabled={disabled} allowClear placeholder="Tên sản phẩm" />,
              )
            }
          </Item>
        </Col>
        <Col span={12} lg={4} style={{ marginTop: isBelowBreakpoint() ? 1 : 40 }}>
          <Button disabled={disabled} icon="filter" type="primary" htmlType="submit" style={{ width: '100%' }}>
            {'Lọc'}
          </Button>
        </Col>
        <Col span={12} lg={4} style={{ marginTop: isBelowBreakpoint() ? 1 : 40 }}>
          <Button disabled={disabled} icon="close" onClick={this.handleReset} style={{ width: '100%' }}>
            {'Bỏ lọc'}
          </Button>
        </Col>
      </Form>
    )
  }
}

FilterRow.propTypes = {
  onFilter: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool
}

export default Form.create()(FilterRow)
