import React from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Switch } from 'antd'

import WithLoadingHOC from '../../../hoc/loading'

const columns = (onSetEditRole, onActiveRole, onDeactiveRole, onGetIdListAPI) => [
  {
    title: <b>Mã</b>,
    dataIndex: 'code',
    render: (value, record) => {
      const edit = () => {
        onSetEditRole(record.id, { name: record.name, code: record.code, note: record.note })
      }

      return (<Button className="btnCode" onClick={edit}>{record.code}</Button>)
    }
  },
  {
    title: <b>Tên</b>,
    dataIndex: 'name'
  },
  {
    title: <b>Ghi chú</b>,
    dataIndex: 'note'
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    align: 'center',
    render: (value, record) => (
      <Switch
        defaultChecked={value}
        onChange={value => (value === true ? onActiveRole(record.id) : onDeactiveRole(record.id))}
      />
    )
  },
  {
    title: <b>Danh sách API</b>,
    dataIndex: 'edit',
    align: 'center',
    render: (value, record) => {
      const list = () => {
        onGetIdListAPI({ id: record.id })
      }

      return (
        <Button
          icon="bars"
          shape="circle"
          type="primary"
          onClick={list}
        />
      )
    }
  }
]

const RoleTab = ({
  dataSource,
  meta: { total },
  onSetAddRole,
  onSetEditRole,
  onActiveRole,
  onDeactiveRole,
  onGetIdListAPI,
}) => (
  <Table
    className="tableCode"
    title={() => (
      <Button onClick={onSetAddRole}>Thêm vai trò mới</Button>
    )}
    columns={columns(onSetEditRole, onActiveRole, onDeactiveRole, onGetIdListAPI)}
    dataSource={dataSource}
    rowKey="id"
    size="small"
    scroll={{ x: 'max-content' }}
    rowClassName={(r, idx) => (idx % 2 === 0 ? 'even' : '')}
    pagination={{
      total,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40'],
      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} vai trò`,
    }}
  />
)

RoleTab.propTypes = {
  dataSource: PropTypes.array,
  meta: PropTypes.object,
  onSetAddRole: PropTypes.func,
  onSetEditRole: PropTypes.func,
  onActiveRole: PropTypes.func,
  onDeactiveRole: PropTypes.func,
  onGetIdListAPI: PropTypes.func,
}

RoleTab.defaultProps = {
  dataSource: [],
  meta: {},
  onSetAddRole: () => { },
  onSetEditRole: () => { },
  onActiveRole: () => { },
  onDeactiveRole: () => { },
  onGetIdListAPI: () => { },
}

export default WithLoadingHOC(RoleTab)
