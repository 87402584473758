import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ROUTER from '../../constants/router'

import CartsPage from './list'
import AddCartPage from './add'
import EditCartPage from './edit'
import CartMapPage from './map'
// import CartGroupPage from './group'
// import DetailGroupPage from './groupDetail'

const Cart = () => (
  <Switch>
    <Route path={ROUTER.CART.INDEX} component={CartsPage} exact />
    <Route path={ROUTER.CART.ADD} component={AddCartPage} exact />
    <Route path={ROUTER.CART.EDIT} component={EditCartPage} exact />
    <Route path={ROUTER.CART.MAP} component={CartMapPage} exact />
    {/* <Route path={ROUTER.CART_GROUP.INDEX} component={CartGroupPage} exact />
    <Route path={ROUTER.CART_GROUP.DETAIL} component={DetailGroupPage} exact /> */}
  </Switch>
)

export default Cart
