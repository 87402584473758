import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const synDataState = {
  listItemFast: [],
  itemTime: [],
  meta: {
    page: 0,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
}

const synData = (state = synDataState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GET_SYNCHRONIZE_DATA_SUCCESS:
    draft.listItemFast = action.payload.data.data
    draft.meta = {
      page: action.payload.data.metaData.page,
      pageSize: action.payload.data.metaData.pageSize,
      total: action.payload.data.metaData.total,
    }
    break
  case TYPES.GET_SYN_TIME_SUCCESS:
    draft.itemTime = action.payload.data.data
    break

  default:
    return draft
  }
})

export default combineReducers({ synData })
