import React from 'react'
import { Menu, Layout, Icon } from 'antd'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import ROUTER from '../../constants/router'
import SIDERS from './sidebar-config'
import { getTitle } from '../Header/actions'
import { isBelowBreakpoint } from '../../util/windows'
import select from '../../util/select'

const { SubMenu } = Menu
const { Sider } = Layout


class MenuBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { oldPath: '', openKey: '', collapsed: window.innerWidth < 1200 }
  }

  componentDidMount = () => {
    const path = this.props.location.pathname.slice(1)
    const link = path.split('/', 1)
    this.setState({ oldPath: link[0] })
    this.findCurrentOpenKey()
    this.props.getTitle(path)
  };

  findCurrentOpenKey= () => {
    const link = this.props.location.pathname.slice(1)
    const keypath = link.split('/', 1)
    const path = keypath[0]
    const user = this.props.user
    let foundKey = ''
    if (!this.state.collapsed) {
      SIDERS[user.role].forEach(item => {
      // 0. Nếu đã tìm thấy ( foundKey != '' ) thì return luôn
        if (foundKey !== '') {
          return foundKey
        }
        if (item.key === path) {
          foundKey = item.key
        } if (item.children) {
          item.children.forEach(children => {
            if (children.key === path) {
              foundKey = item.key
            }
          })
        }


        // 1. kiểm tra nếu key item = path => gán item.key cho foundKey


      // 2. Nếu không, và có children thì tìm trong children
      // if (item.children) {
      //   item.children.forEach(children => {
      //     // 2.1 Nếu thấy thì gán item.key cho foundKey
      //     if (children.key === path) {
      //       this.setState({ openKey: item.key })
      //     }
      //   })
      // }
      })
    }
    this.setState({ openKey: foundKey })
    return foundKey
  }

  menuItemSelected = e => {
    const path = e.key
    const coll = this.state.collapsed
    if (coll === true) {
      this.setState({ openKey: '' })
    }
    this.setState({ oldPath: path })
    this.props.getTitle(path)
  };

  subMenuSelected = e => {
    const path = e.key
    this.setState({ openKey: path })
  }

  // chooseOpenKey = () => {
  //   if (!this.state.collapsed) {
  //     const path = this.props.location.pathname.slice(1)
  //     SIDERS.forEach(item => {
  //       if (item.children) {
  //         item.children.forEach(children => {
  //           if (children.key === path) {
  //             this.setState({ openKey: item.key })
  //           }
  //         })
  //       }
  //     })
  //   }
  // }

  renderMenuItem = ({ key, to, icon, title }) => (
    <Menu.Item key={key} onClick={this.menuItemSelected}>
      <Link to={to || '/'}>
        <Icon type={icon} />
        <span style={{ marginRight: '10px' }}>{title}</span>
      </Link>
    </Menu.Item>
  );

  renderSubMenu = ({ key, icon, title, children }) => (
    <SubMenu
      key={key}
      onTitleClick={this.subMenuSelected}
      title={(
        <span>
          <Icon type={icon} />
          <span>{title}</span>
        </span>
      )}
    >
      {children.map(item => this.renderMenuItem(item))}
    </SubMenu>
  );

  onCollapse = collapsed => {
    if (collapsed) {
      this.setState({ collapsed, openKey: '' })
    } else {
      this.setState({ collapsed }, this.findCurrentOpenKey)
    }
  };

  render() {
    const { user } = this.props
    const menuList = SIDERS[user.role] ? SIDERS[user.role] : []
    return (
      <Sider collapsible collapsedWidth={isBelowBreakpoint() ? 40 : 80} collapsed={this.state.collapsed} onCollapse={this.onCollapse} theme="dark" style={{ boxShadow: '2px 0 6px rgba(0, 21, 41, 0.35)' }}>
        <div style={{ margin: 24, textAlign: 'center', backgroundColor: 'white' }}>
          <Link to={ROUTER.HOME}>
            <img src="/images/anfast.jpg" style={{ width: '80%' }} alt="" />
          </Link>
        </div>

        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.oldPath]}
          mode="inline"
          theme="dark"
          openKeys={[this.state.openKey]}
        >
          {menuList.map(item => (item.children ? this.renderSubMenu(item) : this.renderMenuItem(item)))}
          <Menu.Item key="help">
            <a href="https://doc.avf.anfast.com.vn/docs/web-admin/" target="_blank" rel="noopener noreferrer">
              <Icon type="question-circle" />
              <span>Hướng dẫn sử dụng</span>
            </a>
          </Menu.Item>
        </Menu>
      </Sider>
    )
  }
}
// admin 985
// manager 1967
// order 1966
// routing 1968


const mapDispatchToProps = dispatch => ({ getTitle: key => dispatch(getTitle(key)) })
const mapStateToProps = state => ({ user: select(state, ['authReducer'], 'user') })
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuBar))
