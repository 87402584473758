import React from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'

const InfoField = ({ title, content, extra }) => (
  <Row gutter={24} style={{ marginBottom: '5px' }}>
    <Col span={8}>
      {title}
      :
    </Col>
    <Col span={extra ? 10 : 14}>{React.isValidElement(content) ? content : <b>{content}</b>}</Col>
    <Col span={extra ? 6 : 2}>
      {extra}
    </Col>
  </Row>
)

InfoField.propTypes = { title: PropTypes.string }

InfoField.defaultProps = { title: null }

export default InfoField
