/* eslint-disable react/jsx-wrap-multilines */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Divider, notification, Button, Row, Col } from 'antd';
// import { json2excel } from 'js2excel'
// import BadgeIcon from '../../components/BadgeIcon'
import TextArea from 'antd/lib/input/TextArea';
import select from '../../util/select';
import ItemList from './components/ItemList';
import { getInvoices, getInvoiceByCode, updateInvoice, getCartInfo, Syncfast, exportOrderPDF } from './actions';
import WithLoading from '../../hoc/loading';
import CustomerCard from './components/Info/CustomerInfo';
import InvoiceCard from './components/Info/InvoiceInfo';
import HistoryOrder from './components/history-order-modal';
import ModalOrder from './components/ModalOrder';

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});

class Detail extends Component {
  state = { edit: false, visible: false, quantities: {}, currentId: null, invoice: {}, modalOrder: false };

  static getDerivedStateFromProps(props, prevState) {
    if (prevState.currentId !== props.match.params.id) {
      props.getInvoiceByCode(props.match.params.id);
      return {
        ...prevState,
        invoice: props.invoice,
        currentId: props.match.params.id,
      };
    }

    return null;
  }

  exportExcel = () => {
    const { invoice } = this.props;
    const itemInvoice = invoice;
    this.props.exportOrderPDF(itemInvoice.orderCode, {
      onSuccess: url => {
        window.open(url, 'download_report');
      },
      onError: errorCode => {
        notification.error({ message: `Không in được hóa đơn. ${errorCode}` });
      },
    });
  };

  onHistory = () => {
    const { invoice } = this.props;
    this.props.getInvoices({ customer_code: invoice.customerCode });
    this.toggleHistory();
  };

  toggleHistory = () => {
    this.setState(state => ({ visible: !state.visible }));
  };

  toggleModalOrder = () => {
    this.setState(prevState => ({ modalOrder: !prevState.modalOrder }));
  };

  SyncFast = () => {
    this.props.Syncfast(this.state.currentId, {
      onSuccess: () => {
        notification.success({ message: 'Đồng bộ thành công' });
        this.props.getInvoiceByCode(this.props.match.params.id);
      },
      onError: errorCode => {
        notification.error({ message: `Đồng bộ không thành công. ${errorCode}` });
      },
    });
  };

  render() {
    const { invoice, invoices, isFetching, getInvoiceByCode } = this.props;
    const { edit, modalOrder } = this.state;
    return (
      <React.Fragment>
        <Row gutter={24} style={{ marginBottom: '20px' }}>
          <CustomerCard invoice={invoice} onHistory={this.onHistory} />
          <InvoiceCard invoice={invoice} edit={edit} getInvoice={getInvoiceByCode} />
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card
              hoverable
              title="Danh sách mặt hàng"
              extra={
                <React.Fragment>
                  <Button
                    type="primary"
                    style={{ display: invoice.orderStatus === 'Hủy' ? 'none' : '', marginRight: '10px' }}
                    onClick={this.SyncFast}
                  >
                    Đồng bộ với Fast
                  </Button>
                  <Button type="primary" style={{ display: edit ? 'none' : '' }} onClick={this.exportExcel}>
                    Xuất hóa đơn
                  </Button>
                </React.Fragment>
              }
            >
              <Button
                style={{ marginBottom: '8px' }}
                onClick={() => this.toggleModalOrder()}
                disabled={
                  invoice.orderStatus === 'Hoàn Thành' ||
                  invoice.orderStatus === 'Đang Xử Lý' ||
                  invoice.orderStatus === 'Hủy' ||
                  invoice.orderStatus === 'Đã Lập Lịch' ||
                  invoice.orderStatus === 'Đang Vận Chuyển' ||
                  invoice.orderStatus === 'Đến Nơi' ||
                  invoice.orderStatus === 'Sự Cố'
                }
              >
                Thêm sản phẩm
              </Button>
              {invoice && invoice?.orderCode === this.props.match.params.id && invoice?.customerCode ? (
                <ModalOrder
                  onToggleModalOrder={this.toggleModalOrder}
                  modalOrder={modalOrder}
                  orderId={invoice.orderId}
                  orderCode={invoice.orderCode}
                  invoice={invoice}
                />
              ) : null}
              <ItemList
                products={invoice.items}
                invoice={invoice}
                edit={edit}
                footer={() => (
                  <div>
                    <b>Tổng cộng</b>
                    <b style={{ float: 'right', fontSize: '20px', color: 'red' }}>
                      {`${formatter.format(invoice.totalPrice)}`}
                    </b>
                  </div>
                )}
              />
              <Divider dashed />
              Ghi chú
              <TextArea value={invoice.note} />
            </Card>
          </Col>
        </Row>
        <HistoryOrder
          visible={this.state.visible}
          onCancel={this.onHistory}
          invoices={invoices}
          toggleHistory={this.toggleHistory}
          isFetching={isFetching}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  invoices: select(state, ['invoiceReducer', 'list'], 'items'),
  invoice: select(state, ['invoiceReducer', 'detail'], 'item'),
  cart: select(state, ['invoiceReducer', 'detail'], 'cart'),
  isFetching: select(state, ['invoiceReducer', 'list'], 'isFetching'),
});

const mapDispatchToProps = dispatch => ({
  getInvoices: params => dispatch(getInvoices(params)),
  getInvoiceByCode: code => dispatch(getInvoiceByCode(code)),
  getCartInfo: code => dispatch(getCartInfo(code)),
  updateInvoice: (id, payload, meta) => dispatch(updateInvoice(id, payload, meta)),
  Syncfast: (payload, meta) => dispatch(Syncfast(payload, meta)),
  exportOrderPDF: (params, meta) => dispatch(exportOrderPDF(params, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithLoading(Detail));
