/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button, TimePicker } from 'antd'
import moment from 'moment'

class ButtonSynData extends React.Component {
  state = { time: '' }

  static getDerivedStateFromProps(props, state) {
    const time = props.defaultTime
    if (time.length > 0 && props.form && !props.form.getFieldValue('time')) {
      for (let i = 0; i < time.length; i++) {
        if (time[i].name === 'TimeSync') {
          const timeDefault = JSON.parse(time[i].attributes)
          props.form.setFieldsValue({ time: moment(timeDefault.time || '17:00', 'HH:mm') })
        }
      }
    }
    return state
  }

  onChangeFrom = value => {
    const time = moment(value).format('HH:mm')
    this.setState({ time })
  }

  Configurations = () => {
    const attributeObj = { time: this.state.time }
    const attribute = JSON.stringify(attributeObj)
    this.props.Config(attribute)
  }

  render() {
    const { disabled } = this.props
    // const { isChooseDate, time, defaultTime } = this.state
    const { form: { getFieldDecorator } } = this.props

    return (
      <Form onSubmit={this.handleSubmit}>
        <div style={{ width: '100%', height: '100px' }}>
          <Button span={6} lg={3} icon="save" disabled={disabled} type="primary" onClick={this.Configurations} style={{ marginTop: '4px', float: 'right' }}>
            {'Lưu'}
          </Button>
          <Form.Item style={{ float: 'right' }}>
            {getFieldDecorator('time', {
              rules: [
                {
                  required: true,
                  message: 'Please input your time!',
                },
              ],
            })(<TimePicker onChange={this.onChangeFrom} format="HH:mm" allowClear={false} style={{ margin: '0 10px', width: '100px', minWidth: '100px' }} />)}
          </Form.Item>
          <h2 style={{ margin: '5px 0 0 5px', float: 'right' }}>Chọn giờ đồng bộ</h2>
        </div>
      </Form>
    )
  }
}

ButtonSynData.propTypes = {
  form: PropTypes.object.isRequired,
  defaultTime: PropTypes.array,
  disabled: PropTypes.bool,
}

ButtonSynData.defaultProps = {
  disabled: false,
  defaultTime: []
}

export default Form.create()(ButtonSynData)
