/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, notification, Tabs, Icon } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';

import WithLoadingHOC from '../../hoc/loading';
import WithPageHOC from '../../hoc/page';
import select from '../../util/select';
import { getProduct, updateProduct } from './actions';
import { getProductUnits, getProductConditions } from '../General/actions';
import { getCategories } from '../Category/actions';
import { getSuppliers } from '../Supplier/actions';
import ProductForm from './components/Form';
import ProductImage from '../ProductImage/index';
import ProductCriteria from '../ProductCriteria';

const { TabPane } = Tabs;

class EditProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.qrDownloadRef = React.createRef();
  }

  componentDidMount() {
    this.props.getProduct(this.props.match.params.id);
    this.props.getProductUnits({ pageSize: -1 });
    this.props.getCategories({ pageSize: -1 });
    this.props.getSuppliers({ pageSize: -1 });

    // this.generateQRDownloadLink();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;

    if (prevProps.product?.code === id) {
      this.generateQRDownloadLink();
    }
  }

  handleSubmit = payload => {
    const id = this.props.product.id;
    this.props.updateProduct(id, payload, {
      onSuccess: () => notification.success({ message: 'Sửa mặt hàng thành công' }),
      onError: error => notification.error({ message: `${error} - Sửa mặt hàng thất bại` }),
    });
  };

  generateQRDownloadLink = () => {
    if (this.qrDownloadRef && !this.qrDownloadRef.current.href) {
      const canvas = document
        .querySelector('.qrCodeWrapper canvas')
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      this.qrDownloadRef.current.href = canvas;
    }
  };

  render() {
    const { product, units, conditions, categories, suppliers } = this.props;
    const { id } = this.props.match.params;

    return (
      <div>
        <Row>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Sửa thông tin sản phẩm" key="1">
              <Row>
                <Col span={24} md={6} lg={8}>
                  <div
                    className="qrCodeWrapper"
                    style={{ width: 170, maxWidth: '100%', padding: 15, margin: '0 auto', textAlign: 'center' }}
                  >
                    <QRCodeCanvas
                      size={512}
                      style={{ height: 'auto', width: '100%', maxWidth: '100%', marginBottom: 20 }}
                      value={`${window.location.origin}/qr/${product.code}`}
                      id={`qr-image-${product.code}`}
                    />
                    <a
                      name="svgQRLink"
                      href
                      // href={`${window.location.origin}/qr/${product.code}`}
                      ref={this.qrDownloadRef}
                      download={`QR-${product.code}.png`}
                    >
                      <Icon type="download" />
                      &nbsp;Tải mã QR
                    </a>
                    <br />
                    <br />
                    <a
                      href={`${window.location.origin}/qr/${product.code}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginTop: 15 }}
                    >
                      <Icon type="eye" />
                      &nbsp;Xem thông tin
                    </a>
                  </div>
                </Col>
                <Col span={24} md={18} lg={16}>
                  <Card>
                    <h1>Sửa thông tin hàng hóa</h1>
                    <hr />
                    <ProductForm
                      editMode
                      product={product}
                      units={units}
                      conditions={conditions}
                      categories={categories}
                      suppliers={suppliers}
                      onSubmit={this.handleSubmit}
                    />
                  </Card>
                  {/* <Card hoverable={false}>
                    <ProductForm
                      title={trans('Edit Product')}
                      unUsedFields={unUsedFields}
                      onSubmit={this.onSubmit}
                      defaultValues={defaultValues}
                      // listSupplierInfo={listSupplierInfo}
                      // categories={categories}
                      relatedProductsInfo={relatedProductsInfo}
                    />
                  </Card> */}
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Ảnh sản phẩm" key="2">
              <Row>
                <Col span={24} offset={0}>
                  <Card hoverable={false}>
                    <ProductImage productId={id} />
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Giấy chứng nhận" key="3">
              <Row>
                <Col span={24} offset={0}>
                  <Card hoverable={false}>
                    <ProductCriteria />
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Row>
        {/* <Row type="flex" justify="center">
          <Col span={24} lg={14}>
            <Card>
              <h1>Sửa thông tin hàng hóa</h1>
              <hr />
              <ProductForm
                editMode
                product={product}
                units={units}
                conditions={conditions}
                categories={categories}
                onSubmit={this.handleSubmit}
              />
            </Card>
          </Col>
        </Row> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  units: select(state, ['generalReducer', 'productUnit'], 'items'),
  conditions: select(state, ['generalReducer', 'productCondition'], 'items'),
  product: select(state, ['productReducer', 'detail'], 'item'),
  isFetching: select(state, ['productReducer', 'detail'], 'isFetching'),
  categories: select(state, ['categoryReducer', 'list'], 'items'),
  suppliers: select(state, ['supplierReducer', 'list'], 'items'),
});

const mapDispatchToProps = dispatch => ({
  getProduct: code => dispatch(getProduct(code)),
  getProductUnits: params => dispatch(getProductUnits(params)),
  getProductConditions: () => dispatch(getProductConditions()),
  getCategories: params => dispatch(getCategories(params)),
  getSuppliers: params => dispatch(getSuppliers(params)),
  updateProduct: (id, payload, meta) => dispatch(updateProduct(id, payload, meta)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithLoadingHOC(WithPageHOC('product', 'data')(EditProductPage)));
