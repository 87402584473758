import React from 'react'
import { Row, Col, Card, Avatar } from 'antd'

const { Meta } = Card

const CountData = ({ count }) => (
  <Row type="flex" justify="center">
    <Col span={24} lg={5}>
      <Card hoverable>
        <Meta
          avatar={<Avatar size={48} style={{ backgroundColor: 'blue' }}>{count.countDriver}</Avatar>}
          title={<h2>Lái xe</h2>}
        />
      </Card>
    </Col>
    <Col span={24} lg={5}>
      <Card hoverable>
        <Meta
          avatar={<Avatar size={48} style={{ backgroundColor: 'green' }}>{count.countCustomer}</Avatar>}
          title={<h2>Khách hàng</h2>}
        />
      </Card>
    </Col>
    <Col span={24} lg={5}>
      <Card hoverable>
        <Meta
          avatar={<Avatar size={48} style={{ backgroundColor: 'orange' }}>{count.countProduct}</Avatar>}
          title={<h2>Hàng hoá</h2>}
        />
      </Card>
    </Col>
  </Row>
)

export default CountData
