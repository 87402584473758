/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select, Button, Icon, Table, InputNumber, message } from 'antd'
import Hotkeys from 'react-hot-keys'
import { changeAlias } from '../../../../util/formatText'
import { isBelowBreakpoint } from '../../../../util/windows'
import 'antd/dist/antd.css'

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
})
const { Option } = Select


class ItemList extends Component {
  constructor(props) {
    super(props)
    this.textSelect = React.createRef()
    this.state = {
      products: [],
      originProducts: [],
      selectedItemIds: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.products !== prevState.originProducts) {
      const { products } = nextProps
      return {
        products,
        originProducts: products
      }
    }
    return null
  }

  columns = (selectAll, onChangeQuantity, checkQuantity, onRemoveItem, onAddItem, products, toSelect, textSelect, onSearchProduct) => ([
    {
      title: () => (
        isBelowBreakpoint() ? <b>Mặt hàng</b>
          : (
            <Select
              placeholder="Chọn mặt hàng"
              style={{ width: '250px' }}
              showSearch
              onSearch={onSearchProduct}
              filterOption={false}
              onSelect={values => onAddItem(values, 1)}
              ref={textSelect}
              showAction={['click', 'focus']}
              id="searchProductInPriceTable"
            >
              {products.length !== undefined && products.map((item, index) => index < 20 && (
                <Option key={item.priceItemId} value={item.priceItemId}>{item.productName}</Option>
              ))}
            </Select>
          )
      ),
      width: '30%',
      key: 'productName',
      dataIndex: 'productName',
    },
    {
      title: <b>Đơn giá</b>,
      key: 'price',
      width: '20%',
      render: (value, record) => (
        `${formatter.format(record.price)}/ ${record.unitName} `
      )
    },
    {
      title: <b>Số lượng</b>,
      key: 'quantity',
      width: '20%',
      render: (value, record) => (
        <InputNumber
          autoFocus
          type="number"
          min={0}
          // max={1000}
          onFocus={selectAll}
          key="quantity"
          defaultValue={record.quantity}
          value={record.quantity || 0}
          required
          addonAfter={<div style={{ width: '20px' }}>{record.unitName}</div>}
          onChange={value => onChangeQuantity(value, record.priceItemId)}
          onBlur={e => checkQuantity(e, record.priceItemId)}
          onPressEnter={e => toSelect(e)}
        />
      )
    },
    {
      title: <b>Thành tiền</b>,
      key: 'total',
      width: '20%',
      align: 'right',
      footer: value => (
        <div>{`Summary: ${value.reduce((sum, record) => sum + record.totalPrice, 0)}`}</div>
      ),
      render: (value, record) => formatter.format(record.totalPrice)
    },
    {
      title: <b>Xóa</b>,
      width: '10%',
      align: 'center',
      render: (value, record) => (
        <Button
          key="delete"
          shape="circle"
          type="danger"
          onClick={() => onRemoveItem(record.priceItemId)}
        >
          <Icon type="delete" />
        </Button>
      )
    }
  ])

  checkQuantity = ({ target: { value } }) => {
    if (Number(value) <= 0) {
      message.warning('Số lượng bằng 0')
    }
  }

  toSelect = () => {
    // e.preventDefault()
    this.textSelect.current.focus()
  }

  selectAll = ({ target }) => {
    target.select()
  }

  onSearchProduct = value => {
    const { originProducts } = this.state
    const products = originProducts.filter(item => changeAlias(item.productName.toLowerCase()).indexOf(changeAlias(value.toLowerCase())) >= 0)
    this.setState({ products })
  }

  onKeyDown = (keyName, e, handle) => {
    e.preventDefault()

    const { selectedItemIds } = this.state
    if (handle.key === 'del' && selectedItemIds.length) {
      selectedItemIds.forEach(id => this.props.onRemoveItem(id))
      this.setState({ selectedItemIds: [] })
    }

    if (handle.key === 'F4') {
      this.toSelect()
    }
  }

  toggleRowSelect = id => {
    const { selectedItemIds } = this.state
    const itemIndex = selectedItemIds.indexOf(id)
    if (itemIndex >= 0) {
      delete selectedItemIds[itemIndex]
    } else {
      selectedItemIds.push(id)
    }

    this.setState({ selectedItemIds })
  }

  render() {
    const { dataSource, onRemoveItem, onAddItem, onSearchProduct, onChangeQuantity } = this.props
    let { products, selectedItemIds } = this.state
    products = products.slice(0, 20)

    return (
      isBelowBreakpoint()
        ? (
          <Hotkeys
            keyName="del,F4"
            filter={() => true}
            onKeyDown={this.onKeyDown}
          >
            <Select
              placeholder="Chọn mặt hàng"
              style={{ width: '100%' }}
              showSearch
              onSearch={onSearchProduct}
              filterOption={false}
              onSelect={values => onAddItem(values, 1)}
              ref={this.textSelect}
              showAction={['click', 'focus']}
            >
              {products.length !== undefined && products.map((item, index) => index < 20 && (
                <Option key={item.priceItemId} value={item.priceItemId}>{item.productName}</Option>
              ))}
            </Select>
            <Table
              size="small"
              scroll={{ x: 'max-content', y: 400 }}
              dataSource={dataSource}
              columns={this.columns(this.selectAll, onChangeQuantity, this.checkQuantity, onRemoveItem, onAddItem, products, this.toSelect, this.textSelect, this.onSearchProduct)}
              rowKey={record => record.priceItemId}
              rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
              pagination={false}
            />
          </Hotkeys>
        )
        : (
          <Hotkeys
            keyName="del,F4"
            filter={() => true}
            onKeyDown={this.onKeyDown}
          >
            <Table
              size="small"
              scroll={{ x: 'max-content', y: 400 }}
              dataSource={dataSource}
              columns={this.columns(this.selectAll, onChangeQuantity, this.checkQuantity, onRemoveItem, onAddItem, products, this.toSelect, this.textSelect, this.onSearchProduct)}
              rowKey={record => record.priceItemId}
              rowClassName={(r, idx) => {
                if (selectedItemIds.length && selectedItemIds.includes(r.priceItemId)) {
                  return 'invoiceStatus4'
                }
                return (idx % 2 ? 'whitesmoke' : '')
              }}
              pagination={false}
              onRow={record => ({ onClick: () => this.toggleRowSelect(record.priceItemId) })}
            />
          </Hotkeys>
        )
    )
  }
}

ItemList.propTypes = {
  dataSource: PropTypes.array,
  // products: PropTypes.array,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onChangeQuantity: PropTypes.func,
}

ItemList.defaultProps = {
  dataSource: [],
  products: [],
  onAddItem: () => {},
  onRemoveItem: () => {},
  onChangeQuantity: () => {},
}

export default ItemList
