
import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const listRouteState = {
  items: [],
  driverId: null,
  time: null,
  isFetching: false,
  didInvalidate: true,
}

const list = (state = listRouteState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_ROUTES:
    draft.isFetching = true
    break

  case TYPES.GET_ROUTES_SUCCESS: {
    const { items, driverId, time } = action.payload
    draft.items = items
    draft.driverId = driverId
    draft.time = time
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_ROUTES_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INVALIDATE_ROUTE_LIST:
    draft.didInvalidate = true
    break

  default:
    return draft
  }
})

const initialState = {
  routeTracking: [],
  item: { Routes: [] },
  isFetching: false,
  mapConfig: {
    center: {
      lat: 20.995271,
      lng: 105.780953,
    },
    zoom: 9,
  },
}

const detail = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_SCHEDULE:
  case TYPES.UPDATING_SCHEDULE:
  case TYPES.GETTING_ROUTE_TRACKING:
    draft.isFetching = true
    break

  case TYPES.GET_SCHEDULE_SUCCESS: {
    draft.item = action.payload.Data
    draft.isFetching = false
    break
  }

  case TYPES.GET_ROUTE_TRACKING_SUCCESS: {
    draft.routeTracking = action.payload
    draft.isFetching = false
    break
  }

  case TYPES.UPDATE_SCHEDULE_SUCCESS:
  case TYPES.UPDATE_SCHEDULE_FAILURE:
  case TYPES.GET_SCHEDULE_FAILURE:
  case TYPES.GET_ROUTE_TRACKING_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})

export default combineReducers({ list, detail })
