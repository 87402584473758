/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Select, Input, Button, Form } from 'antd';
import router from '../../../constants/router';

const { Option } = Select;
const { Item } = Form;

const statuses = [
  { name: 'Ngừng sử dụng', val: 'false' },
  { name: 'Sử dụng', val: 'true' },
];
class FilterRow extends React.Component {
  state = { keyword: [] };

  static getDerivedStateFromProps(props) {
    const { search } = props.history.location;
    const result = search
      .replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ')
      .split(' ')
      .splice(1);

    return { keyword: result };
  }

  handleSubmit = e => {
    e.preventDefault();
    const c_params = this.props.filterOptions === undefined ? {} : JSON.parse(this.props.filterOptions);
    c_params.page = '1';
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const urlParameters = [];
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '') {
            urlParameters.push(e.join('='));
          }
        });
        if (urlParameters[0] !== false) {
          this.props.history.push(router.PRODUCT.INDEX.concat(`?${urlParameters.join('&')}`));
        }
      } else {
        console.log(err);
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.history.push(router.PRODUCT.INDEX);
  };

  render() {
    const {
      disabled,
      categories,
      form: { getFieldDecorator },
    } = this.props;
    const { keyword } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Item label="Mã hàng hóa">
          {getFieldDecorator('productCode', {
            initialValue: keyword.indexOf('productCode') === -1 ? '' : keyword[keyword.indexOf('productCode') + 1],
          })(<Input disabled={disabled} placeholder="Mã hàng hóa" allowClear />)}
        </Item>
        <Item label="Tên hàng hóa">
          {getFieldDecorator('productName', {
            initialValue: keyword.indexOf('productName') === -1 ? '' : keyword[keyword.indexOf('productName') + 1],
          })(<Input disabled={disabled} placeholder="Tên hàng hóa" allowClear />)}
        </Item>
        <Item label="Nhóm hàng hóa">
          {getFieldDecorator('categoryCode', {
            initialValue: keyword.indexOf('categoryCode') === -1 ? '' : keyword[keyword.indexOf('categoryCode') + 1],
          })(
            <Select
              showSearch
              disabled={disabled}
              style={{ width: '100%' }}
              placeholder="Nhóm hàng hóa"
              allowClear
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {categories.map((item, index) => (
                <Option value={item.code} key={`cate${index + 1}`}>
                  {item.name}
                </Option>
              ))}
            </Select>,
          )}
        </Item>
        <Item label="Trạng thái">
          {getFieldDecorator('status', {
            initialValue: keyword.indexOf('status') === -1 ? '' : keyword[keyword.indexOf('status') + 1] === 'true',
          })(
            <Select
              showSearch
              disabled={disabled}
              style={{ width: '100%' }}
              placeholder="Trạng thái"
              allowClear
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {statuses.map((item, index) => (
                <Option value={item.val} key={`store${index + 1}`}>
                  {item.name}
                </Option>
              ))}
            </Select>,
          )}
        </Item>
        <Row gutter={24}>
          <Col span={12} lg={12}>
            <Button icon="filter" disabled={disabled} type="primary" htmlType="submit" style={{ width: '100%' }}>
              Lọc
            </Button>
          </Col>
          <Col span={12} lg={12}>
            <Button icon="close" disabled={disabled} onClick={this.handleReset} style={{ width: '100%' }}>
              Bỏ lọc
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

FilterRow.propTypes = {
  categories: PropTypes.array,
  conditions: PropTypes.array,
  onFilter: PropTypes.func,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

FilterRow.defaultProps = {
  categories: [],
  conditions: [],
  onFilter: () => {},
  disabled: false,
};

export default Form.create()(FilterRow);
