// eslint-disbale
import React from 'react';
// import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Table, InputNumber, Button, Form, Input, notification, Popconfirm, Typography, message } from 'antd';
import { updateOrderItem, deleteOrderItem, cancelItem, getInvoiceByCode, removeInvoiceOrderItem } from '../actions';
import './index.css';
import { ORDER_ITEM_STATUS } from '../../../constants/enum';

const Text = React.forwardRef((props, ref) => <Typography.Text {...props} ref={ref} />);

const EditableContext = React.createContext();
// export const ORDER_STATUS = ['Đặt Hàng', 'Đang Xử Lý', 'Đã Lập Lịch', 'Đang Vận Chuyển', 'Đến Nơi', 'Hoàn Thành', 'Huỷ', 'Trả Đơn Hàng']
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number' && this.props.dataIndex === 'quantity') {
      return <InputNumber step={1} min={0} autoFocus />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, inputType, record, index, children, ...restProps } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Không được để trống ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.codeRefArr = [];
    this.state = {
      data: '',
      editingKey: '',
      check: true,
      editQuantity: false,
      columns: [
        {
          title: 'STT',
          dataIndex: 'index',
          width: '80px',
          render: (t, r, index) => index + 1,
        },
        {
          title: (
            <span>
              Mã hàng
              <Button type="link" icon="copy" onClick={this.onCopyProductCode} />
            </span>
          ),
          dataIndex: 'productCode',
        },
        {
          title: 'Tên mặt hàng',
          dataIndex: 'productName',
        },
        {
          title: 'Đơn giá',
          dataIndex: 'unitName',
          render: (text, record) => `${formatter.format(record.pricePerUnit)}/${text}`,
        },
        {
          title: (
            <span>
              Số lượng
              <Button type="link" icon="copy" onClick={this.onCopyProductQuantity} />
            </span>
          ),
          dataIndex: 'quantity',
          editable: false,
          render: (text, record) => {
            const { editingKey } = this.state;
            const editable = this.isEditing(record); // true if record.orderItemId === this.state.editingKey
            return editable ? (
              <span>
                <EditableContext.Consumer>
                  <div>{text}</div>
                </EditableContext.Consumer>
              </span>
            ) : (
              <div>
                <span>{text}</span>
                {// props.isAdmin && (
                record.orderItemStatus === 'Đặt Hàng' ||
                record.orderItemStatus === 'Sự Cố' ||
                record.orderItemStatus === 'Đang Xử Lý' ||
                record.orderItemStatus === 'Đã Lập Lịch' ||
                record.orderItemStatus === 'Đang Vận Chuyển' ||
                record.orderItemStatus === 'Đến Nơi' ||
                record.orderItemStatus === 'Hủy' ||
                record.orderItemStatus === 'Hoàn Thành' ? (
                  <Button
                    style={{ padding: '3px', float: 'right' }}
                    type="primary"
                    disabled={editingKey !== ''}
                    icon="edit"
                    size="small"
                    title="Click để sửa"
                    onClick={() => this.editQuantity(record.orderItemId)}
                  />
                ) : null}
              </div>
            );
          },
        },
        {
          title: 'Trạng thái',
          dataIndex: 'orderItemStatus',
        },
        {
          title: 'Thành tiền',
          dataIndex: 'price',
        },
        {
          title: 'Cập nhật',
          dataIndex: 'update',
          render: (text, record) => {
            const editable = this.isEditing(record);
            return editable ? (
              <span>
                <EditableContext.Consumer>
                  {form => (
                    <div>
                      {this.state.editQuantity && (
                        <Button
                          type="default"
                          onClick={() => this.onUpdateQuantity(form, record.orderItemId)}
                          style={{ margin: 5 }}
                        >
                          Cập nhật số lượng
                        </Button>
                      )}
                      <Button type="default" onClick={() => this.back()} style={{ margin: 5 }}>
                        Quay lại
                      </Button>
                    </div>
                  )}
                </EditableContext.Consumer>
              </span>
            ) : (
              <div>
                {(record.orderItemStatus === 'Đặt Hàng' || record.orderItemStatus === 'Sự Cố') && (
                  <>
                    <Popconfirm
                      placement="bottom"
                      title="Hủy mặt hàng này?"
                      onConfirm={() => this.onCancelItem(record.orderItemId)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="default" style={{ margin: 5 }}>
                        Hủy mặt hàng
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      placement="bottom"
                      title="Xóa mặt hàng này?"
                      onConfirm={() => this.removeInvoiceOrderItem(record.orderItemId)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="default" style={{ margin: 5 }}>
                        Xóa mặt hàng
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </div>
            );
          },
        },
      ],
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.products) {
      return { data: props.products };
    }
    return null;
  }

  onCancelItem(itemId) {
    this.props.cancelItem(this.props.invoice.orderId, itemId, {
      onSuccess: () => {
        notification.success({ message: 'Đã hủy mặt hàng' });
        this.props.getInvoiceByCode(this.props.invoice.orderCode);
      },
      onError: errorCode => notification.error({ message: `${'Cập nhật đơn hàng thất bại'} ${errorCode}` }),
    });
  }

  onUpdateQuantity(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      // const { locale: { trans } } = this.props
      if (row.quantity <= 0) {
        this.setState({ check: false });
      } else {
        this.setState({ check: true });
        this.props.updateOrderItem(
          key,
          this.props.invoice.orderId,
          { quantity: row.quantity },
          {
            onSuccess: () => {
              notification.success({ message: 'Đã cập nhật số lượng' });
              this.props.getInvoiceByCode(this.props.invoice.orderCode);
              this.setState({ editingKey: '', editQuantity: false });
              this.deleteEditQuantity();
            },
            onError: errorCode => notification.error({ message: `${'Cập nhật số lượng thất bại'} ${errorCode}` }),
          },
        );
      }
    });
  }

  isEditing = record => record && record.orderItemId && record.orderItemId === this.state.editingKey;
  // isEditing = record => console.log(record)

  back = () => {
    this.setState({
      editingKey: '',
      editQuantity: false,
    });
    this.deleteEditQuantity();
  };

  removeInvoiceOrderItem = id => {
    this.props.removeInvoiceOrderItem(id, {
      onSuccess: () => this.props.getInvoiceByCode(this.props.invoice.orderCode),
      onError: error => notification.error({ message: `Xoá sản phẩm không thành công - ${error}` }),
    });
  };

  onCopyProductCode = () => {
    const { data } = this.state;
    const copyText = data.map(item => item.productCode).join('\n');
    navigator.clipboard.writeText(`${copyText}\n`);

    message.info('Đã copy tất cả mã sản phẩm');

    // if (this.codeRefArr.length) {
    //   this.codeRefArr[0].select();
    //   // this.codeRefArr.forEach(ref => {
    //   //   console.log(ref);
    //   //   ref.select();
    //   // });
    // }
  };

  onCopyProductQuantity = () => {
    const { data } = this.state;
    const copyText = data.map(item => item.quantity).join('\n');
    navigator.clipboard.writeText(`${copyText}\n`);

    message.info('Đã copy tất cả số lượng');
  };

  editQuantity(key) {
    // this.columns
    const updatedArray = [...this.state.columns];
    updatedArray[3].editable = true;
    this.setState({
      editingKey: key,
      columns: updatedArray,
      editQuantity: true,
    });
  }

  deleteEditQuantity() {
    const updatedArray = [...this.state.columns];
    updatedArray[3].editable = false;
    this.setState({ columns: updatedArray });
  }

  render() {
    const components = { body: { cell: EditableCell } };
    const { check } = this.state;
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'quantity' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });
    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={this.state.data ? this.state.data : []}
          columns={columns}
          rowClassName={record => `editable-row invoiceStatus${ORDER_ITEM_STATUS.indexOf(record.orderItemStatus)}`}
          rowKey="orderItemId"
          footer={this.props.footer}
          pagination={false}
        />
        {!check && (
          <p className="red" style={{ color: 'red' }}>
            Số lượng sản phẩm phải lớn hơn 0!!
          </p>
        )}
      </EditableContext.Provider>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

// const mapStateToProps = state => ({
// isAdmin: select(state, 'authReducer', 'isAdmin'),
// userRole: select(state, 'authReducer', 'roleName'),
// })

const mapDispatchToProps = dispatch => ({
  updateOrderItem: (id, orderId, payload, meta) => dispatch(updateOrderItem(id, orderId, payload, meta)),
  cancelItem: (orderId, itemId, meta) => dispatch(cancelItem(orderId, itemId, meta)),
  deleteOrderItem: (id, meta) => dispatch(deleteOrderItem(id, meta)),
  getInvoiceByCode: code => dispatch(getInvoiceByCode(code)),
  removeInvoiceOrderItem: (id, meta) => dispatch(removeInvoiceOrderItem(id, meta)),
});

export default connect(null, mapDispatchToProps)(EditableFormTable);
