import React, { useState, useCallback, useEffect } from 'react'
import update from 'immutability-helper'
import { Row, Button, Col } from 'antd'
import lodash from 'lodash'
import Card from './Card'
import Title from './Title'

const Container = React.forwardRef((props, ref) => {
  {
    const [cards, setCards] = useState([...props.homeLayout])
    useEffect(() => {
      if ((cards.length === 1 && props.homeLayout.length) || (cards.length !== props.homeLayout.length)) {
        setCards([...props.homeLayout])
      }
    }, [props.homeLayout])

    const moveImage = values => {
      if (values.length !== 0) {
        props.moveImage(values)
      }
    }

    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex]
        setCards(
          update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        )
      },
      [cards],
      moveImage(cards)

    )

    const handlerDel = index => {
      cards.splice(index, 1)
      setCards([...cards])
      props.removeImage(cards)
    }
    React.useImperativeHandle(ref, () => ({
      getHomeLayout() {
        return cards
      },
      addNewConfig(newCard) {
        setCards([newCard, ...cards])
      },
    }))

    const renderCard = (card, index) => (
      <Card
        key={index}
        index={index}
        // id={card.id}
        // title={card.Title}
        src={card}
        moveCard={moveCard}
        // image={card}
        del={handlerDel}
      />
    )

    return (
      <>
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Col style={{ marginRight: '30px' }}>
            <Title
              header="Ảnh sản phẩm"
              headerSize={1}
              textAlign="center"
            />
          </Col>
          <Col>
            { lodash.isEqual(cards, props.homeLayout) !== true ? (
              <Button
                style={{ marginBottom: '10px' }}
                shape="round"
                icon="save"
                type="primary"
                onClick={props.updateAppConfig}
              >
                Lưu
              </Button>
            ) : (
              <Button
                style={{ marginBottom: '10px' }}
                shape="round"
                icon="save"
                type="primary"
                onClick={props.updateAppConfig}
              >
            Lưu
              </Button>
            )
            }
          </Col>
        </Row>

        {cards.map((card, i) => renderCard(card, i))}
      </>
    )
  }
})

export default Container
