/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Select, DatePicker, Button, Form, Input } from 'antd'
import moment from 'moment'
import { ORDER_STATUS } from '../../../constants/enum'
import ROUTER from '../../../constants/router'


const { Option } = Select
const { Item } = Form

const CustomizedForm = Form.create({
  name: 'global_state',
  mapPropsToFields(props) {
    return { filterOptions: Form.createFormField({ ...props.filterOptions }) }
  },
})(props => {
  const { disabled, onSubmit, onReset, form: { getFieldDecorator } } = props
  return (
    <Form onSubmit={onSubmit}>
      <Col span={6}>
        <Item label="Ngày giao hàng">
          {
            getFieldDecorator('require_time')(
              <DatePicker
                allowClear
                style={{ width: '100%' }}
                disabled={disabled}
                placeholder="Ngày giao hàng"
                format="DD/MM/YYYY"
              />,
            )
          }
        </Item>
      </Col>
      <Col span={6}>
        <Item label="Ngày đơn hàng">
          {
            getFieldDecorator('order_date')(
              <DatePicker
                allowClear
                style={{ width: '100%' }}
                disabled={disabled}
                placeholder="Ngày đơn hàng"
                format="DD/MM/YYYY"
              />,
            )
          }
        </Item>
      </Col>
      <Col span={4}>
        <Item label="Mã đơn hàng">
          {
            getFieldDecorator('order_code')(
              <Input
                allowClear
                style={{ width: '100%' }}
                disabled={disabled}
                placeholder="Mã đơn hàng"
              />,
            )
          }
        </Item>
      </Col>
      <Col span={4}>
        <Item label="Mã giỏ hàng">
          {
            getFieldDecorator('cart_code')(
              <Input
                allowClear
                style={{ width: '100%' }}
                disabled={disabled}
                placeholder="Mã đơn hàng"
              />,
            )
          }
        </Item>
      </Col>
      <Col span={4}>
        <Item label="Mã khách hàng">
          {
            getFieldDecorator('customer_code')(
              <Input
                allowClear
                style={{ width: '100%' }}
                disabled={disabled}
                placeholder="Mã khách hàng"
              />,
            )
          }
        </Item>
      </Col>
      <Col span={6}>
        <Item label="Trạng thái">
          {
            getFieldDecorator('status')(
              <Select
                allowClear
                disabled={disabled}
                style={{ width: '100%' }}
                placeholder="Chọn trạng thái"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
              >
                {ORDER_STATUS ? ORDER_STATUS.map(item => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                )) : '' }
              </Select>,
            )
          }
        </Item>
      </Col>
      <Col span={16} />
      <Col span={4}>
        <Button icon="filter" disabled={disabled} type="primary" htmlType="submit" style={{ width: '100%' }}>
          {'Lọc'}
        </Button>
      </Col>
      <Col span={4}>
        <Button icon="close" disabled={disabled} onClick={onReset} style={{ width: '100%' }}>
          {'Bỏ lọc'}
        </Button>
      </Col>
    </Form>
  )
})
class FilterRow extends React.Component {
  state = { filterOptions: null, fields: {} }

  // static getDerivedStateFromProps(props, state) {
  //   // const { search } = props.history.location
  //   // let params
  //   // if (search !== state.search) {
  //   //   const filterOptions = search.split('?')[1]
  //   //   if (filterOptions !== undefined) {
  //   //     params = `{"${decodeURI(filterOptions)
  //   //       .replace(/"/g, '\\"').replace(/&/g, '","')
  //   //       .replace(/=/g, '":"')}"}`
  //   //     let values
  //   //     values = JSON.parse(params)
  //   //     const { require_time, order_code, cart_code, customer_code, status } = values
  //   //     props.form.setFieldsValue({ require_time: moment(require_time), order_code, cart_code, customer_code, status })
  //   //   } else {
  //   //     props.form.resetFields()
  //   //   }
  //   //   return { ...state, search, filterOptions: params }
  //   // }
  //   return state
  // }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let require_time
        if (values.require_time) {
          require_time = moment(values.require_time).format('YYYY-MM-DD')
          values = { ...values, require_time }
        }
        let order_date
        if (values.order_date) {
          order_date = moment(values.order_date).format('YYYY-MM-DD')
          values = { ...values, order_date }
        }
        const urlParameters = []
        Object.entries(values).map(e => {
          if (e[1] !== undefined && e[1] !== '' && e[1] !== null) {
            urlParameters.push(e.join('='))
          }
          return null
        })

        if (urlParameters[0] !== false) {
          this.props.history.push(ROUTER.INVOICE.INDEX.concat(`?${urlParameters.join('&')}`))
        }
      } else {
        console.log(err)
      }
    })
  };

  handleReset = () => {
    this.props.form.resetFields()
    this.props.history.push(ROUTER.INVOICE.INDEX)
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({ fields: { ...fields, ...changedFields } }))
  };

  render() {
    const { disabled } = this.props
    const { fields } = this.state
    return (
      <div>
        <CustomizedForm
          {...fields}
          disabled={disabled}
          onChange={this.handleFormChange}
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
        />
        <pre className="language-bash">{JSON.stringify(fields, null, 2)}</pre>
      </div>
    )
  }
}

FilterRow.propTypes = {
  onFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  // form: PropTypes.object.isRequired,
}

export default FilterRow
