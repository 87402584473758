import React, { Component } from 'react'
import { Card, Button, Col, DatePicker, notification, Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { ORDER_STATUS } from '../../../../constants/enum'
import { updateInvoice, updateInvoiceStatus } from '../../actions'
import InfoField from './InfoField'

class InvoiceCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requireTime: null,
      edit: false,
      orderDate: null,
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (state.requireTime === null && props.invoice.requireTime) {
      return { ...state, requireTime: props.invoice.requireTime }
    }
    if (state.orderDate === null && props.invoice.orderDate) {
      return { ...state, orderDate: props.invoice.orderDate }
    }
    return state
  }

  editRequireTime = () => {
    if (this.state.edit && this.props.invoice.requireTime !== this.state.requireTime) {
      const id = this.props.invoice.orderId
      const { requireTime } = this.state
      this.props.updateInvoice(id, {
        requireTimeFrom: requireTime,
        requireTimeTo: requireTime
      }, {
        onSuccess: () => {
          notification.success({ message: 'Đã cập nhật đơn hàng' })
          // this.props.getInvoiceByCode(this.props.match.params.id)
        },
        onError: error => notification.error({ message: `Sửa đơn hàng gặp lỗi ${error}! Vui lòng thử lại !` }),
      })
    }
    this.setState(prevState => ({ edit: !prevState.edit }))
  }

  editOderDate = () => {
    if (this.state.edit && this.props.invoice.orderDate !== this.state.orderDate) {
      const id = this.props.invoice.orderId
      const { orderDate } = this.state
      this.props.updateInvoice(id, { orderDate }, {
        onSuccess: () => {
          notification.success({ message: 'Đã cập nhật đơn hàng' })
          // this.props.getInvoiceByCode(this.props.match.params.id)
        },
        onError: error => notification.error({ message: `Sửa đơn hàng gặp lỗi ${error}! Vui lòng thử lại !` }),
      })
    }
    this.setState(prevState => ({ edit: !prevState.edit }))
  }

  handleChange = date => {
    const t = moment(date).format().replace('+07:00', 'Z')
    this.setState({ requireTime: t })
  }

  handleChange2 = date => {
    const t = moment(date).format().replace('+07:00', 'Z')
    this.setState({ orderDate: t })
  }

  nextStatus = id => {
    this.props.updateInvoiceStatus(
      id,
      {
        onSuccess: () => {
          notification.success({ message: 'Đã cập nhật trạng thái đơn hàng' })
          this.props.getInvoice(this.props.invoice.orderCode)
        },
        onError: error => notification.error({ message: `Sửa đơn hàng gặp lỗi ${error}! Vui lòng thử lại !` }),
      }
    )
  }

  editDay = () => {
    this.setState({ edit: true })
  }

  render() {
    const { edit } = this.state
    const { invoice } = this.props
    const requireTime = moment(invoice.requireTime).toISOString().replace('Z', '+07:00')
    const orderDate = moment(invoice.orderDate).toISOString().replace('Z', '+07:00')
    return (
      <Col span={12}>
        <Card
          title="Thông tin đơn hàng"
          extra={invoice.orderStatus === 'Sự Cố' ? (
            <Button title="Xử lý sự cố" type="danger" icon="warning">Xử lý sự cố</Button>
          ) : null}
        >
          <InfoField title="Mã đơn hàng" content={invoice.orderCode} />
          <InfoField
            title="Trạng thái"
            content={invoice.orderStatus}
            extra={
              ORDER_STATUS.indexOf(invoice.orderStatus) < 5 // "Đặt Hàng || Đang Xử Lý"
                ? (
                  <Popconfirm placement="top" title="Chuyển trạng thái đơn hàng?" onConfirm={() => this.nextStatus(invoice.orderId)} okText="Có" cancelText="Không">
                    {invoice.orderStatus === 'Đặt Hàng'
                      ? (<Button type="primary" style={{ padding: '0 3px', float: 'right' }} size="small" title="Click để chuyển trạng thái">Chuyển trạng thái</Button>)
                      : (<Button type="primary" style={{ padding: '0 3px', float: 'right' }} size="small" title="Click để chuyển trạng thái">Hoàn thành đơn</Button>)
                    }

                  </Popconfirm>
                ) : null
            }
          />
          <InfoField
            title="Ngày giao"
            content={edit ? (
              <div>
                <DatePicker
                  allowClear={false}
                  name="deliveryDate"
                  defaultValue={moment(requireTime)}
                  format="DD/MM/YYYY"
                  onChange={this.handleChange}
                  disabledDate={current => (
                    current
                    && current < Date.now() - 86400000
                  )}
                />

                <Button
                  style={{ padding: '3px', float: 'right' }}
                  type="primary"
                  icon="save"
                  size="small"
                  title="Click để lưu"
                  onClick={() => this.editRequireTime()}
                />
              </div>

            ) : (
              <div>
                <b>{moment(requireTime).format('DD/MM/YYYY')}</b>
                <Button
                  disabled={invoice.orderStatus !== 'Đặt Hàng'}
                  style={{ padding: '3px', float: 'right', marginRight: '10px' }}
                  type="primary"
                  icon="edit"
                  size="small"
                  title="Click để sửa"
                  onClick={() => this.editDay()}
                />

              </div>
            )}
          />
          <InfoField
            title="Ngày đơn hàng"
            content={edit ? (
              <div>
                <DatePicker
                  allowClear={false}
                  name="deliveryDate"
                  defaultValue={moment(orderDate)}
                  format="DD/MM/YYYY"
                  onChange={this.handleChange2}
                  disabledDate={current => (
                    current
                    && current < Date.now() - 86400000
                  )}
                />

                <Button
                  style={{ padding: '3px', float: 'right' }}
                  type="primary"
                  icon="save"
                  size="small"
                  title="Click để lưu"
                  onClick={() => this.editOderDate()}
                />
              </div>

            ) : (
              <div>
                <b>{moment(orderDate).format('DD/MM/YYYY')}</b>
                <Button
                  disabled={invoice.orderStatus !== 'Đặt Hàng'}
                  style={{ padding: '3px', float: 'right', marginRight: '10px' }}
                  type="primary"
                  icon="edit"
                  size="small"
                  title="Click để sửa"
                  onClick={() => this.editDay()}
                />

              </div>
            )}
          />
        </Card>
      </Col>
    )
  }
}

InvoiceCard.propTypes = { invoice: PropTypes.object }
InvoiceCard.defaultProps = { invoice: {} }

const mapDispatchToProps = dispatch => ({
  updateInvoice: (id, payload, meta) => dispatch(updateInvoice(id, payload, meta)),
  updateInvoiceStatus: (id, meta) => dispatch(updateInvoiceStatus(id, meta))
})

export default connect(null, mapDispatchToProps)(InvoiceCard)
