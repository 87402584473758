import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio, Form, Input, Select, Button, DatePicker, Row, Col } from 'antd'
import moment from 'moment'
import { backpage } from '../../../util/windows'

import WithLoading from '../../../hoc/loading'

import { DRIVER_STATUS } from '../../../constants/enum'

const { Item } = Form
const { Option } = Select
const RadioGroup = Radio.Group
const { TextArea } = Input

class DriverForm extends Component {
  state = { valueCode: '' };

  // onDelete = driver => {
  //   Modal.confirm({
  //     okText: 'Xoá lái xe',
  //     okButtonProps: { type: 'danger' },
  //     cancelText: 'Huỷ',
  //     cancelButtonProps: { type: 'primary' },
  //     title: `Bạn chắc chắn xoá lái xe ${driver.data.name}`,
  //     content: 'Tài khoản và mọi thông tin liên quan sẽ bị xoá khỏi hệ thống !',
  //     onOk: () => this.props.onDelete(driver),
  //   })
  // };
  disabledDate = current => current && current > moment().endOf('day');

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      values.code = this.state.valueCode
      if (!err) {
        if (this.props.editMode) {
          this.props.onSubmit(this.props.driver.data.id, { ...values, dateOfBirth: moment(values.dateOfBirth).format('YYYY-MM-DD') })
        } else {
          this.props.onSubmit({ ...values, dateOfBirth: moment(values.dateOfBirth).format('YYYY-MM-DD') })
        }
      }
    })
  };

  changeId = e => {
    const valueinput = e.target.value
    const part = /\s/g
    const value = valueinput.replace(part, '')
    this.setState({ valueCode: value })
  }

  render() {
    const {
      form: { getFieldDecorator },
      editMode,
      driver,
    } = this.props

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Mã lái xe">
              {getFieldDecorator('code', {
                initialValue: editMode ? driver.data.code : '',
                rules: [
                  {
                    required: true,
                    message: 'Nhập mã lái xe',
                  },
                ],
              })(<Input disabled={editMode} onChange={this.changeId} placeholder="Mã lái xe" />)}
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Họ và tên">
              {getFieldDecorator('name', {
                initialValue: editMode ? driver.data.name : '',
                rules: [
                  {
                    required: true,
                    message: 'Nhập tên lái xe',
                  },
                ],
              })(<Input type="text" placeholder="Họ và tên" />)}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Số thẻ căn cước/CMND">
              {getFieldDecorator('idCardNumber', {
                initialValue: editMode ? driver.data.idCardNumber : '',
                // rules: [
                //   {
                //     required: true,
                //     message: 'Nhập thẻ căn cước/CMND',
                //   },
                // ],
              })(<Input type="text" placeholder="Số thẻ căn cước/CMND" />)}
            </Item>
          </Col>

        </Row>
        <Row gutter={24}>
          <Col span={10} lg={12}>
            <Item label="Giới tính">
              {getFieldDecorator('gender', { initialValue: editMode ? driver.data.gender : '' })(
                <RadioGroup>
                  <Radio key={1} value="male">Nam</Radio>
                  <Radio key={2} value="female">Nữ</Radio>
                </RadioGroup>
              )}
            </Item>

          </Col>
          <Col span={14} lg={12}>
            <Item label="Ngày Sinh">
              {getFieldDecorator('dateOfBirth', {
                initialValue:
                  editMode && driver.data.dateOfBirth
                    ? moment(driver.data.dateOfBirth)
                    : moment(),
              })(<DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" disabledDate={this.disabledDate} />)}
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Email">
              {getFieldDecorator('email', {
                initialValue: editMode ? driver.data?.email || '' : '',
                rules: [
                  {
                    type: 'email',
                    message: 'Không đúng định dạng email!',
                  },
                ],
              })(<Input />)}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Số điện thoại">
              {getFieldDecorator('phoneNumber', {
                initialValue: editMode ? driver.data.phoneNumber : '',
                rules: [
                  {
                    required: true,
                    message: 'Nhập số điện thoại lái xe',
                  },
                  {
                    pattern: new RegExp(/^(0)[1-9]([0-9]{8}[0-9]?)\b/),
                    message: 'Sai định dạng số điện thoại',
                  },
                ],
              })(<Input type="text" placeholder="Số điện thoại" />)}
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          {/* {editMode ? (<Col span={12}>
            <Item label="Ngày bắt đầu làm">
              {getFieldDecorator('createdAt', {
                initialValue:
                  editMode && driver.data.createdAt
                    ? moment(driver.data.createdAt, 'DD-MM-YYYY')
                    : moment(),
              })(<DatePicker style={{ width: '100%' }} format="DD-MM-YYYY" />)}
            </Item>
          </Col>) : null} */}
          {editMode ? (
            <Col span={24} lg={12}>
              <Item label="Trạng thái">
                {getFieldDecorator('status', { initialValue: editMode ? driver.data.status === 'present' ? DRIVER_STATUS[0] : driver.data.status === 'absent' ? DRIVER_STATUS[1] : DRIVER_STATUS[2] : '' })(
                  <Select>
                    {DRIVER_STATUS.map((item, index) => (
                      <Option key={item} value={index === 0 ? 'present' : index === 1 ? 'absent' : 'stop_working'}>
                        {item}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Item>
            </Col>
          ) : null}
        </Row>
        <Item label="Ghi chú">
          {getFieldDecorator('note', { initialValue: editMode ? driver.data.note : '' })(<TextArea type="text" placeholder="Thông tin thêm ..." />)}
        </Item>
        <Item>
          <Button
            ghost
            style={{ border: 'none' }}
            type="danger"
            onClick={() => backpage()}
          >
              Quay lại
          </Button>

          <Button style={{ float: 'right' }} icon="plus" type="primary" htmlType="submit">
            {editMode ? 'Hoàn thành' : 'Thêm lái xe'}
          </Button>
        </Item>
      </Form>
    )
  }
}

DriverForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  editMode: PropTypes.bool,
  driver: PropTypes.object,
  form: PropTypes.object.isRequired,
  // vehicleTypes: PropTypes.array,
}

DriverForm.defaultProps = {
  onDelete: () => { },
  // vehicleTypes: [],
  editMode: false,
  driver: {},
}

export default WithLoading(Form.create()(DriverForm))
