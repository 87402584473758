/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notification, Modal, Table } from 'antd'
import { getSchedule, completedRoute } from './actions'
import select from '../../util/select'
import Config from '../../hoc/page'
import WithLoading from '../../hoc/loading'

import ScheduleComponents from './components/schedule-card'
import RouteList from './components/route-list'

const { ScheduleCard } = ScheduleComponents

class ScheduleDetail extends Component {
  constructor(props) {
    super(props)
    this.state = { visibleStatus: false, itemsProblem: [] }
  }

  componentDidMount() {
    this.props.getSchedule(this.props.match.params.id)
  }

  completedRoute = userId => {
    this.props.completedRoute({
      scheduleCode: this.props.match.params.id,
      userId,
    }, {
      onSuccess: () => {
        notification.success({ message: 'Xác nhận hoàn thành route thành công!' })
        this.props.getSchedule(this.props.match.params.id)
      },
      onError: () => {
        notification.error({ message: 'Xác nhận hoàn thành route thất bại!' })
      },
    })
  }

  onVisibleStatus = items => {
    this.setState({ visibleStatus: !this.state.visibleStatus, itemsProblem: items })
  }

  render() {
    const { schedule, isFetching } = this.props
    const { visibleStatus, itemsProblem } = this.state
    const routes = schedule.routes
    const dataTables = []
    if (routes && routes.length) {
      const nodesMaxlength = Math.max(...routes.map(r => r.nodes.length))
      // let nodesMaxlength = 0
      // schedule.routes.forEach(route => {
      // const nodes = []
      // schedule.nodes.forEach(node => {
      //   if (node.carrier.code === route.driver.code) {
      //     nodes.push(node)
      //   }
      // })
      // if (nodes.length > nodesMaxlength) {
      //   nodesMaxlength = nodes.length
      // }
      // routes.push({
      //   ...route,
      //   nodes
      // })
      // })
      routes.forEach(route => {
        const newObjData = { route }
        for (let i = 1; i <= nodesMaxlength; i++) {
          newObjData[`node${i}`] = i <= route.nodes.length ? route.nodes[i - 1] : null
        }
        dataTables.push(newObjData)
      })
    }
    const columns = () => [
      {
        title: 'Mã sản phẩm',
        key: 'productCode',
        render: (value, record) => record.productCode,
      }, {
        title: 'Tên',
        key: 'productName',
        render: (value, record) => record.productName,
      }, {
        title: 'Giá',
        key: 'price',
        render: (value, record) => record.price,
      }, {
        title: 'Số lượng',
        key: 'quantity',
        render: (value, record) => record.quantity,
      }, {
        title: 'Phản hồi',
        key: 'note',
        render: (value, record) => record.note,
      },
    ]

    return (
      <div>
        <ScheduleCard isFetching={isFetching} solution={schedule} />
        <RouteList
          dataTables={dataTables}
          viewDetail={this.viewDetail}
          completedRoute={this.completedRoute}
          onVisibleStatus={this.onVisibleStatus}
        />

        <Modal
          visible={visibleStatus}
          title="Sự cố sản phẩm"
          onOk={() => this.setState({ visibleStatus: false })}
          okText="Đóng"
          closable={false}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Table
            size="small"
            columns={columns()}
            dataSource={itemsProblem}
            pagination={false}
            rowKey={record => record.orderItemId}
            rowClassName={index => (index % 2 === 0 ? 'whitesmoke' : '')}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  schedule: select(state, ['scheduleReducer', 'detail'], 'item'),
  isFetching: select(state, ['scheduleReducer', 'detail'], 'isFetching'),
  mapConfig: select(state, 'scheduleReducer', 'mapConfig'),
})

const mapDispatchToProps = dispatch => ({
  getSchedule: scheduleCode => dispatch(getSchedule(scheduleCode)),
  completedRoute: (payload, meta) => dispatch(completedRoute(payload, meta)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Config('schedule', 'route')(WithLoading(ScheduleDetail)))
