import React from 'react'
import { Card, Row, Col } from 'antd'
import moment from 'moment'
import WithLoading from '../../../hoc/loading'
import { isBelowBreakpoint } from '../../../util/windows'

const InfoField = ({ label, data }) => (
  <Row>
    <Col span={8}>
      <p>{label}</p>
    </Col>
    <Col span={16}>
      {data !== undefined ? (
        <p style={{ fontWeight: 'bold' }}>{data}</p>
      ) : (
        <p style={{ fontStyle: 'italic' }}>Chưa cập nhật</p>
      )}
    </Col>
  </Row>
)

const SolutionCard = ({ solution }) => {
  const numberDrivers = solution.drivers?.length || 0
  return (
    <Card style={{ marginBottom: isBelowBreakpoint() ? 15 : 24 }} hoverable>
      <Row gutter={24}>
        <Col span={24} lg={12}>
          {' '}
          <InfoField label="Tên lịch" data={solution.name} />
          <InfoField
            label="Ngày giao"
            data={moment(solution.executionTime).format('DD/MM/YYYY')}
          />

          <InfoField
            label="Số lượng lái xe"
            data={numberDrivers}
          />

        </Col>

      </Row>
    </Card>
  )
}

export default { ScheduleCard: WithLoading(SolutionCard), InfoField }
