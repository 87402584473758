export const STORE_CONDITION = ['Bình thường', 'Nhiệt độ thấp', 'Hàng dễ vỡ', 'Dưới nước', 'Thực phẩm ăn liền'];

export const ORDER_STATUS = [
  'Đặt Hàng',
  'Đang Xử Lý',
  'Đã Lập Lịch',
  'Đang Vận Chuyển',
  'Đến Nơi',
  'Hủy',
  'Sự Cố',
  'Hoàn Thành',
];

export const ORDER_ITEM_STATUS = [
  'Đặt Hàng',
  'Đang Xử Lý',
  'Đã Lập Lịch',
  'Đang Vận Chuyển',
  'Đến Nơi',
  'Hủy',
  'Sự Cố',
  'Hoàn Thành',
];

export const ROUTE_STATUS = ['Chờ xác nhận', 'Đã xác nhận', 'Đang vận chuyển', 'Đã hoàn thành', 'Đã hủy'];

export const DRIVER_STATUS = ['Đang làm việc', 'Nghỉ phép', 'Đã nghỉ việc'];

export const ROLE = ['Quản lí', 'Lái xe', 'Khách hàng'];

export const ACTIVE = [true, false];
