/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, notification } from 'antd';
import WithPageHOC from '../../hoc/page';
import select from '../../util/select';
import SupplierList from './components/List';
import FilterRow from './components/Filter';
import ROUTER from '../../constants/router';
import { isBelowBreakpoint } from '../../util/windows';
import { getSuppliers, updateSupplier, actChangeStatus } from './actions';

class SuppliersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filterOptions: {} };
  }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location;
    let params;
    if (search !== state.search) {
      const filterOptions = search.split('?')[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
        props.getSuppliers(JSON.parse(params));
      } else {
        props.getSuppliers();
      }
      return { ...state, search, filterOptions: params };
    }
    return state;
  }

  handleAdd = () => this.props.history.push(ROUTER.SUPPLIER.ADD);

  handleFilter = filterOptions => {
    this.setState({ filterOptions });
    this.props.getSuppliers({
      ...filterOptions,
      page: 1,
      pageSize: 10,
    });
  };

  editSupplier = id => this.props.history.push(ROUTER.SUPPLIER.EDIT.replace(':id', id));

  changeStatus = (id, value) => {
    this.props.updateSupplier(
      id,
      { status: !value },
      {
        onSuccess: () => {
          this.props.changeStatus(id, value);
          notification.success({ message: 'Đã cập nhật trạng thái' });
        },
        onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
      },
    );
  };

  onChangePage = (page, pageSize) => {
    const { search } = this.props.history.location;
    if (search !== '') {
      let params;
      const filterOptions = search.split('?')[1];
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`;
      }
      const obj = JSON.parse(params);
      params = { ...obj, page, pageSize };

      const urlParameters = [];
      Object.entries(params).map(e => {
        if (e[1] !== undefined && e[1] !== '') {
          urlParameters.push(e.join('='));
        }
        return null;
      });
      this.props.history.push(ROUTER.SUPPLIER.INDEX.concat(`?${urlParameters.join('&')}`));
    } else {
      this.props.history.push(ROUTER.SUPPLIER.INDEX.concat(`?page=${page}&pageSize=${pageSize}`));
    }
  };

  render() {
    const { filterOptions } = this.state;
    const { suppliers, meta, isFetching, history } = this.props;
    return (
      <Fragment>
        <Row gutter={24}>
          <Col span={24} lg={6}>
            <FilterRow filterOptions={filterOptions} disabled={isFetching} history={history} />
          </Col>
          <Col span={24} lg={18}>
            <Row>
              <Button
                icon="plus"
                disabled={isFetching}
                onClick={this.handleAdd}
                style={{ marginTop: isBelowBreakpoint() ? 10 : 0 }}
              >
                Thêm Nhà cung cấp
              </Button>
            </Row>
            <SupplierList
              style={{ marginTop: 10 }}
              suppliers={suppliers}
              meta={meta}
              isFetching={isFetching}
              onEdit={this.editSupplier}
              onView={this.viewSupplier}
              onChangePage={this.onChangePage}
              onChangeSize={this.onChangePage}
              onChangeStatus={this.changeStatus}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  suppliers: select(state, ['supplierReducer', 'list'], 'items'),
  meta: select(state, ['supplierReducer', 'list'], 'meta'),
  isFetching: select(state, ['supplierReducer', 'list'], 'isFetching'),
});

const mapDispatchToProps = dispatch => ({
  getSuppliers: params => dispatch(getSuppliers(params)),
  updateSupplier: (id, payload, meta) => dispatch(updateSupplier(id, payload, meta)),
  changeStatus: (id, value) => dispatch(actChangeStatus(id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithPageHOC('supplier')(SuppliersPage));
