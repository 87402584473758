import React from 'react'
import PropTypes from 'prop-types'

import { Form, Input, Button } from 'antd'

class PasswordForm extends React.Component {
  state = { confirmDirty: false }

    handleSubmit = e => {
      e.preventDefault()
      this.props.form.validateFields((error, values) => {
        if (!error) {
          const { oldPass, newPass } = values
          this.props.onSubmit({
            oldPass,
            newPass,
          })
        }
      })
    };

    handleConfirmBlur = e => {
      const { value } = e.target
      this.setState(prevState => ({ confirmDirty: prevState.confirmDirty || !!value }))
    };

    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props
      if (value && value !== form.getFieldValue('newPass')) {
        callback('Mật khẩu chưa chính xác!')
      } else {
        callback()
      }
    };

    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    };

    render() {
      const { form: { getFieldDecorator } } = this.props
      return (
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Mật khẩu cũ">
            {getFieldDecorator('oldPass', {
              rules: [
                {
                  required: true,
                  message: `${'Nhập mật khẩu cũ'}!`,

                },
              ],
            })(<Input type="password" placeholder="Nhập mật khẩu cũ..." />)}
          </Form.Item>
          <Form.Item label="Mật khẩu mới" hasFeedback>
            {getFieldDecorator('newPass', {
              rules: [
                {
                  required: true,
                  message: `${'Nhập mật khẩu mới'}!`,
                },
                { validator: this.validateToNextPassword },
              ],
            })(<Input type="password" placeholder="Nhập mật khẩu mới..." />)}
          </Form.Item>
          <Form.Item label="Xác nhận mật khẩu mới" hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: `${'Xác nhận mật khẩu mới'}!`,
                },
                { validator: this.compareToFirstPassword },
              ],
            })(<Input type="password" placeholder="Nhập lại mật khẩu mới..." />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} icon="login">
              {'Cập nhật'}
            </Button>
          </Form.Item>
        </Form>
      )
    }
}

PasswordForm.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}


export default Form.create()(PasswordForm)
