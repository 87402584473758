/* eslint react/prop-types: 0 */
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, notification, Divider } from 'antd'

import WithLoadingHOC from '../../hoc/loading'
import WithPageHOC from '../../hoc/page'
import UploadForm from '../../components/UploadForm'
import select from '../../util/select'
import { insertCategory, importCategories } from './actions'
import CategoryForm from './components/Form'
// import router from '../../constants/router'
import ROUTER from '../../constants/router'
// import { isBelowBreakpoint, backpage } from '../../util/windows'

class AddCategoryPage extends React.Component {
  state = { isBatch: false };

  onChangeInsertType = ({ target: { value } }) => this.setState(() => ({ isBatch: value }));

  handleUpload = async ({ current: { files } }) => {
    const formData = new FormData()
    formData.append('file', files[0])
    this.props.importCategories(formData, {
      onSuccess: () => {
        notification.success({ message: 'Thêm thành công' })
        this.props.history.push(ROUTER.CATEGORY.INDEX)
      },
      onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
    })
  };

  handleSubmit = payload => {
    this.props.insertCategory(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm danh mục thành công' })
        this.props.history.push(ROUTER.CATEGORY.INDEX)
      },
      onError: error => notification.error({ message: `${error} - Thêm danh mục thất bại` }),
    })
  };

  render() {
    const { units, isFetching } = this.props
    const { isBatch } = this.state
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={14}>
            {/* <Radio.Group value={isBatch} buttonStyle="solid" onChange={this.onChangeInsertType}>
            <Radio.Button value={false}>Thêm một danh mục</Radio.Button>
            <Radio.Button value style={{ marginTop: isBelowBreakpoint() ? 5 : 0 }}>Thêm nhiều danh mục </Radio.Button>
          </Radio.Group> */}
            <Divider dashed />
            <Card>
              <h1>Thêm danh mục mới</h1>
              <hr />
              {isBatch ? (
                <UploadForm
                  isFetching={isFetching}
                  contentLoading="Đang xử lí dữ liệu"
                  supportContent={(
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://docs.google.com/spreadsheets/d/1YWiAi0Ki0mefipmaDMGwJMINwL9smv1MBLqQjUoyTAs/edit?usp=sharing"
                    >
                    Mẫu file excel
                    </a>
                  )}
                  onUpload={this.handleUpload}
                />
              ) : (
                <CategoryForm units={units} onSubmit={this.handleSubmit} />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  units: select(state, ['generalReducer', 'productUnit'], 'items'),
  conditions: select(state, ['generalReducer', 'productCondition'], 'items'),
  isFetching: select(state, ['generalReducer', 'productCondition'], 'isFetching'),
})

const mapDispatchToProps = dispatch => ({
  insertCategory: (payload, meta) => dispatch(insertCategory(payload, meta)),
  importCategories: (payload, meta) => dispatch(importCategories(payload, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithLoadingHOC(WithPageHOC('product', 'data')(AddCategoryPage)))
