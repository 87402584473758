import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../constants/router';

import SuppliersPage from './list';
import AddSupplierPage from './add';
import EditSupplierPage from './edit';

const Supplier = () => (
  <Switch>
    <Route path={ROUTER.SUPPLIER.INDEX} component={SuppliersPage} exact />
    <Route path={ROUTER.SUPPLIER.ADD} component={AddSupplierPage} exact />
    <Route path={ROUTER.SUPPLIER.EDIT} component={EditSupplierPage} exact />
  </Switch>
);

export default Supplier;
