import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const customerState = {
  customer: {},
  carts: [],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0
}

const customer = (state = customerState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.CLEAR_DATA_ORDER:
    draft.customer = {}
    break

  case TYPES.GETTING_CUSTOMER_FOR_ORDER:
  case TYPES.GETTING_CARTS_OF_CUSTOMER:
    draft.isFetching = true
    break

  case TYPES.GET_CUSTOMER_FOR_ORDER_FAILURE:
  case TYPES.GET_CARTS_OF_CUSTOMER_FAILURE:
    draft.isFetching = false
    break

  case TYPES.GET_CUSTOMER_FOR_ORDER_SUCCESS: {
    draft.isFetching = false
    draft.customer = action.payload
    break
  }

  case TYPES.GET_CARTS_OF_CUSTOMER_SUCCESS: {
    draft.isFetching = false
    draft.carts = action.payload
    break
  }

  default:
    return draft
  }
})

const cartState = {
  cart: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0
}

const cart = (state = cartState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_CART_FOR_ORDER:
    draft.isFetching = true
    break

  case TYPES.GET_CART_FOR_ORDER_FAILURE:
    draft.isFetching = false
    break

  case TYPES.GET_CART_FOR_ORDER_SUCCESS: {
    draft.isFetching = false
    draft.cart = action.payload
    break
  }

  case TYPES.DELETE_CART_FOR_ORDER:
    draft.cart = {}
    break

  default:
    return draft
  }
})

const productState = {
  items: [],
  priceTableId: '',
  meta: {
    current: 0,
    pageSize: 0,
    total: 0
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const product = (state = productState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.CLEAR_DATA_ORDER: {
    draft.items = []
    draft.priceTableId = ''
    break
  }

  case TYPES.GETTING_PRODUCTS_FOR_ORDER:
    draft.isFetching = true
    break

  case TYPES.GET_PRODUCTS_FOR_ORDER_FAILURE: {
    draft.isFetching = false
    draft.items = []
    draft.priceTableId = ''
    break
  }

  case TYPES.GET_PRODUCTS_FOR_ORDER_SUCCESS: {
    draft.isFetching = false
    draft.items = action.data.priceItem
    draft.priceTableId = action.data.priceTableId
    draft.meta = {
      current: action.meta.page,
      pageSize: action.meta.pageSize,
      total: action.meta.total
    }
    break
  }

  case TYPES.DELETE_PRODUCTS_FOR_ORDER: {
    draft.items = []
    draft.priceTableId = ''
    break
  }

  default:
    return draft
  }
})

const orderState = {
  item: { Items: [] },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const order = (state = orderState, action) => produce(state, draft => {
  switch (action.type) {
  // case TYPES.GET_PRODUCTS_FOR_ORDER_SUCCESS:
  //   draft.item = { Items: [] }
  //   break
  case TYPES.CLEAR_DATA_ORDER:
    draft.item = { Items: [] }
    break

  case TYPES.ADD_ITEM:
  case TYPES.SET_ORDER:
    draft.item = action.payload
    break

  case TYPES.GET_INVOICE_BY_ID_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = false
    draft.item = action.payload
    break
  }

  case TYPES.DELETE_ORDERS:
    draft.item = { Items: [] }
    break

  default:
    return draft
  }
})


export default combineReducers({ customer, cart, product, order })
