import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'

const gettingPriceTables = () => ({ type: TYPES.GETTING_PRICE_TABLES })

const getPriceTablesSuccess = payload => ({
  type: TYPES.GET_PRICE_TABLES_SUCCESS,
  payload,
})

const getPriceTablesFailure = () => ({ type: TYPES.GET_PRICE_TABLES_FAILURE })

export const getPriceTables = params => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTables()
  dispatch(gettingPriceTables())
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(
      getPriceTablesSuccess(response.data),
    )
  } else {
    dispatch(getPriceTablesFailure())
  }
}

export const insertingPriceTable = () => ({ type: TYPES.INSERTING_PRICE_TABLE })

export const insertPriceTableSuccess = () => ({ type: TYPES.INSERT_PRICE_TABLE_SUCCESS })

export const insertPriceTableFailure = () => ({ type: TYPES.INSERT_PRICE_TABLE_FAILURE })

export const importPriceTables = ({ payload, params }, meta) => async dispatch => {
  const api = API.PRICE_TABLE.importFromExcel()
  const { response, error } = await apiCall({
    ...api,
    payload,
    params,
  })
  if (!error && response.status === 200) {
    dispatch(insertPriceTableSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch(insertPriceTableFailure())
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const deletePriceTable = id => async () => {
  const api = API.PRICE_TABLE.deletePriceTable(id)
  await apiCall({ ...api })
}

const gettingPriceTable = () => ({ type: TYPES.GETTING_PRICE_TABLE })

const getPriceTableSuccess = payload => ({
  type: TYPES.GET_PRICE_TABLE_SUCCESS,
  payload,
})

const getPriceTableFailure = () => ({ type: TYPES.GET_PRICE_TABLE_FAILURE })

export const getPriceTableById = id => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTable(id)
  dispatch(gettingPriceTable())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getPriceTableSuccess(response.data))
  } else {
    dispatch(getPriceTableFailure())
  }
}

export const getExistPriceTable = (id, params) => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTable(id)
  dispatch({ type: TYPES.GETTING_EXIST_PRICE_TABLE_OF_GROUP })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_EXIST_PRICE_TABLE_OF_GROUP_SUCCESS,
      payload: response.data.data
    })
  } else {
    dispatch({ type: TYPES.GET_EXIST_PRICE_TABLE_OF_GROUP_FAILURE })
  }
}

export const deleteExistPriceTable = () => async dispatch => {
  dispatch({ type: TYPES.CLEAR_EXIST_PRICE_TABLE })
}

const updatingPriceTable = () => ({ type: TYPES.UPDATING_PRICE_TABLE })
const updatePriceTableSuccess = () => ({ type: TYPES.UPDATE_PRICE_TABLE_SUCCESS })
const updatePriceTableFailure = () => ({ type: TYPES.UPDATE_PRICE_TABLE_FAILURE })

export const updatePriceTable = (id, payload, meta) => async dispatch => {
  const api = API.PRICE_TABLE.updatePriceTable(id)
  dispatch(updatingPriceTable())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(updatePriceTableSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(updatePriceTableFailure())
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const checkOrdered = params => async dispatch => {
  const api = API.PRICE_TABLE.checkOrdered()
  dispatch({ type: TYPES.CHECKING_ORDERED })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.CHECK_ORDER_SUCCESS, payload: response.data, time: params.date })
  } else {
    dispatch({ type: TYPES.CHECK_ORDER_FAILURE })
  }
}

export const reportPriceTables = (params, meta) => async () => {
  const api = API.PRICE_TABLE.reportPriceTables(params)
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else if (meta && meta.onError) {
    meta.onError(error)
  }
}
const updatingStatusItem = () => ({ type: TYPES.UPDATE_STATUS_ITEM_FAILURE })
const updateStatusItemSuccess = () => ({ type: TYPES.UPDATE_STATUS_ITEM_FAILURE })
const updateStatusItemFailure = () => ({ type: TYPES.UPDATE_STATUS_ITEM_FAILURE })

export const updateStatusItem = (id, payload, meta) => async dispatch => {
  const api = API.PRICE_TABLE.updateStatusItem(id)
  dispatch(updatingStatusItem())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(updateStatusItemSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(updateStatusItemFailure())
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}
