import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ROUTER from '../../constants/router'

import CategorysPage from './list'
import AddCategoryPage from './add'
import EditCategoryPage from './edit'

const Category = () => (
  <Switch>
    <Route path={ROUTER.CATEGORY.INDEX} component={CategorysPage} exact />
    <Route path={ROUTER.CATEGORY.ADD} component={AddCategoryPage} exact />
    <Route path={ROUTER.CATEGORY.EDIT} component={EditCategoryPage} exact />
  </Switch>
)

export default Category
