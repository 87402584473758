import React from 'react';
import { Form, Input, Select, Button, InputNumber, Row, Col, Tag } from 'antd';
import { backpage } from '../../../util/windows';
import WYSIWYGEditor from '../../../components/WYSIWYGEditor';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

class ProductForm extends React.Component {
  state = {
    valueCode: '',
    OtherUnitOfProduct: [],
    // currentProductCode: '',
    statuses: [
      { name: 'Ngừng sử dụng', val: 0 },
      { name: 'Sử dụng', val: 1 },
    ],
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (
  //     nextProps.editMode
  //     && ((nextProps.product
  //       && prevState.OtherUnitOfProduct.length === 0
  //       && nextProps.product.OtherUnitOfProduct.length > 0)
  //       || (nextProps.product
  //         && nextProps.product.code !== prevState.currentProductCode))
  //   ) {
  //     return {
  //       currentProductCode: nextProps.product.code,
  //       OtherUnitOfProduct: nextProps.product.OtherUnitOfProduct ? nextProps.product.OtherUnitOfProduct : [],
  //     }
  //   }
  //   return null
  // }

  changecode = e => {
    const valueinput = e.target.value;
    const part = /\s/g;
    const value = valueinput.replace(part, '');
    this.setState({ valueCode: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      values.code = this.state.valueCode;
      if (!err) {
        // eslint-disable-next-line camelcase
        const { code, name, categoryId, supplierID, unitId, status, weightPerUnit, description } = values;
        this.props.onSubmit({
          code,
          name,
          categoryId,
          supplierID,
          unitId,
          status: !!status,
          weightPerUnit,
          description,
        });
        // const condition = this.props.conditions.find(item => item.ID === StoreCondition)
        // const OtherUnitOfProduct = this.state.OtherUnitOfProduct.filter(item => item.ID !== '')
      }
    });
  };

  onAddUnit = () => {
    const { OtherUnitOfProduct } = this.state;
    OtherUnitOfProduct.push({
      ID: '',
      WPU: 1,
      Name: '',
    });
    this.setState({ OtherUnitOfProduct });
  };

  onRemoveUnit = index => {
    this.setState(prevState => ({
      OtherUnitOfProduct: prevState.OtherUnitOfProduct.filter((item, idx) => idx !== index),
    }));
  };

  onChangeOtherUnit = (value, index, field) => {
    const { units } = this.props;
    const { OtherUnitOfProduct } = this.state;
    OtherUnitOfProduct[index][field] = value;
    if (field === 'ID') {
      const unit = units.find(item => item.ID === value);
      OtherUnitOfProduct[index].Name = unit.Name;
    }
    this.setState({ OtherUnitOfProduct });
  };

  renderOtherUnit = () => {
    const { units } = this.props;
    return this.state.OtherUnitOfProduct.map((item, index) => (
      <React.Fragment key={`${index + 1}`}>
        <Col span={12}>
          <Item label={index === 0 ? 'Đơn vị tính' : ''}>
            <Select
              placeholder="Chọn đơn vị"
              value={item.ID}
              onChange={value => this.onChangeOtherUnit(value, index, 'ID')}
              allowClear
            >
              {units
                ? units.map((element, index) => (
                    <Option value={element.ID} key={`${index + 1}`}>
                      {element.Name}
                    </Option>
                  ))
                : ''}
            </Select>
          </Item>
        </Col>
        <Col span={9}>
          <Item label={index === 0 ? 'Chuyển sang kg' : ''}>
            <InputNumber
              value={item.WPU}
              type="text"
              min={0}
              style={{ width: '100%' }}
              onChange={value => this.onChangeOtherUnit(value, index, 'WPU')}
              allowClear
            />
          </Item>
        </Col>
        <Col span={3}>
          <Item label={index === 0 ? 'Xoá' : ''}>
            {this.state.OtherUnitOfProduct.length > 1 && (
              <Button shape="circle" icon="minus" onClick={() => this.onRemoveUnit(index)} />
            )}
          </Item>
        </Col>
      </React.Fragment>
    ));
  };

  onEditorChange = value => {
    this.props.form.setFieldsValue({ description: value });
  };

  render() {
    const {
      units,
      // conditions,
      categories,
      suppliers,
      product,
      editMode,
      form: { getFieldDecorator },
    } = this.props;
    const { statuses } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            {editMode ? (
              <Item label="Mã hàng hóa">
                <Tag style={{ fontSize: '20px' }}>{product.code}</Tag>
              </Item>
            ) : (
              <Item label="Mã hàng hóa">
                {getFieldDecorator('code', {
                  initialValue: editMode ? product && product.code : null,
                  rules: [
                    {
                      required: true,
                      message: 'Hãy nhập mã hàng hóa',
                    },
                  ],
                })(<Input type="text" onChange={this.changecode} placeholder="Mã hàng hóa" allowClear />)}
              </Item>
            )}
          </Col>
          <Col span={24} lg={12}>
            <Item label="Tên hàng hóa">
              {getFieldDecorator('name', {
                initialValue: editMode ? product && product.name : '',

                rules: [{ required: true, message: 'Hãy nhập tên hàng hóa' }],
              })(<Input type="text" placeholder="Tên hàng hóa" allowClear />)}
            </Item>
          </Col>
        </Row>
        {/* <Divider>Đơn vị tính thông thường</Divider> */}
        <Row gutter={24}>
          <Col span={11} lg={12}>
            <Item label="Đơn vị tính">
              {getFieldDecorator('unitId', {
                initialValue: editMode ? product && product.unitId : null,
                rules: [
                  {
                    required: true,
                    message: 'Chọn đơn vị tính cho hàng hóa',
                  },
                ],
              })(
                <Select placeholder="Chọn đơn vị" showSearch allowClear>
                  {units
                    ? units.map((element, index) => (
                        <Option value={element.id} key={`${index + 1}`}>
                          {element.name}
                        </Option>
                      ))
                    : ''}
                  {/* <Option value={5}>khay</Option> */}
                </Select>,
              )}
            </Item>
          </Col>
          <Col span={13} lg={12}>
            <Item label="Chuyển sang kg">
              {getFieldDecorator('weightPerUnit', { initialValue: editMode ? product && product.weightPerUnit : 0 })(
                <InputNumber type="text" allowClear min={0} style={{ width: '100%' }} />,
              )}
            </Item>
          </Col>
          {/* <Divider>Đơn vị tính trong báo giá</Divider>
          {this.renderOtherUnit()}
          <Col span={24}>
            <Button type="primary" onClick={this.onAddUnit} style={{ width: '100%' }}>
              Thêm đơn vị tính
            </Button>
          </Col> */}
        </Row>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Nhóm hàng hóa">
              {getFieldDecorator('categoryId', {
                initialValue: editMode ? product && product.categoryId : null,
                rules: [
                  {
                    required: true,
                    message: 'Chọn nhóm hàng hóa',
                  },
                ],
              })(
                <Select placeholder="Nhóm hàng hóa" showSearch allowClear>
                  {categories.map((item, index) => (
                    <Option value={item.id} key={`${index + 1}`}>
                      {item.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Nhà cung cấp">
              {getFieldDecorator('supplierID', {
                initialValue: editMode ? product && product.supplierId : null,
                // rules: [
                //   {
                //     required: true,
                //     message: 'Chọn nhà cung cấp',
                //   },
                // ],
              })(
                <Select placeholder="Nhà cung cấp" showSearch allowClear>
                  {suppliers.map((item, index) => (
                    <Option value={item.id} key={`${item.id}-${index + 1}`}>
                      {item.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Trạng thái">
              {getFieldDecorator('status', { initialValue: editMode ? (product && product.status ? 1 : 0) : null })(
                <Select placeholder="Trạng thái" allowClear>
                  {statuses.map((item, index) => (
                    <Option key={`${index + 1}`} value={item.val}>
                      {item.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </Item>
          </Col>
          {/* <Col span={12}>
            <Item label="Điều kiện bảo quản">
              {getFieldDecorator('storageConditionId', {
                initialValue: editMode
                  ? product && product.storageCondition
                  : null,
              })(
                <Select placeholder="Điều kiện bảo quản" allowClear>
                  {conditions.map((item, index) => (
                    <Option value={item.id} key={`${index + 1}`}>
                      {item.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </Item>
          </Col> */}
        </Row>
        <Item label="Mô tả" name="description">
          {getFieldDecorator('description', { initialValue: editMode ? product?.description || '' : '' })(
            <TextArea style={{ display: 'none' }} />,
          )}

          <WYSIWYGEditor
            value={product?.description || ''}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onChange={this.onEditorChange}
          />
        </Item>
        {/* <Item label="Ghi chú">
          {getFieldDecorator('Note', { initialValue: editMode ? product.ProductInfo && product.ProductInfo.Note : '' })(<TextArea type="text" placeholder="Thông tin thêm ..." />)}
        </Item> */}
        <Button ghost style={{ border: 'none' }} type="danger" onClick={() => backpage()}>
          Quay lại
        </Button>
        <Item style={{ float: 'right' }}>
          <Button type="primary" htmlType="submit">
            {editMode ? 'Hoàn thành' : 'Thêm hàng hóa'}
          </Button>
        </Item>
      </Form>
    );
  }
}

export default Form.create()(ProductForm);
