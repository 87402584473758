/* eslint-disable no-unused-vars */
/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react'
import { Row, Col, notification, Button } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import { json2excel } from 'js2excel'
import InvoiceList from './components/List'
import InvoiceModal from './components/InvoiceModal'
import Filter from './components/Filter'
import FilterRow from './components/FilterRow'
import ReportModal from './components/report-modal'
import ResultModal from './components/result-modal'
import select from '../../util/select'
import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import { getResetTimeString } from '../../util/formatTime'
import { getInvoices, checkOrdered, reportInvoices, Syncfast, exportOrderPDF, removeInvoiceOrder } from './actions'
import { getCustomers } from '../Customer/actions'
import { getAccounts } from '../General/actions'

import WithPageHOC from '../../hoc/page'
import ROUTER from '../../constants/router'

class InvoicePage extends Component {
  state = {
    showOrderResult: false,
    showInvoiceCard: false,
    showReportModal: false,
    filterOptions: {},
    key: [],
    selectKeys: [],
    initializingSampleData: false,
  }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location
    let params
    if (search !== state.search) {
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
        props.getInvoices(JSON.parse(params))
      } else {
        props.getInvoices(props.user.role === 'sale' ? { page: 1, pageSize: 20, sale_in_charge_id: props?.user?.id } : { page: 1, pageSize: 20 })
      }
      return { ...state, search, filterOptions: params }
    }
    return state
  }

  componentDidMount() {
    this.props.getAccounts({ pageSize: -1 })
  }

  handleAdd = () => this.props.history.push(ROUTER.INVOICE.ADD);

  handleView = (e, invoice) => {
    e.preventDefault()
    this.setState({ invoice, showInvoiceCard: true })
  };

  handleCancel = () => {
    this.setState({
      showInvoiceCard: false,
      showOrderResult: false,
      showReportModal: false,

    })
    this.props.checkOrdered({ requireTime: moment(moment().toISOString()).format('YYYY-MM-DD'), page: 1, pageSize: 50 })
  };

  handleFilter = filterOptions => {
    this.setState({
      filterOptions: {
        ...filterOptions,
        // from_time: getResetTimeString(filterOptions.from_time),
      },
    })
    this.props.getInvoices({
      ...filterOptions,
      // from_time: getResetTimeString(filterOptions.from_time),
      page: 1,
      pageSize: 20,
    })
  };

  onChangePage = (page, pageSize) => {
    const { search } = this.props.history.location
    if (search !== '') {
      let params
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      }
      const obj = JSON.parse(params)
      params = { ...obj, page, pageSize }

      const urlParameters = []
      Object.entries(params).map(e => {
        if (e[1] !== undefined && e[1] !== '') {
          urlParameters.push(e.join('='))
        }
        return null
      })
      this.props.history.push(ROUTER.INVOICE.INDEX.concat(`?${urlParameters.join('&')}`))
    } else {
      this.props.history.push(ROUTER.INVOICE.INDEX.concat(this.props.user.role === 'sale' ? `?page=${page}&pageSize=${pageSize}&sale_in_charge_id=${this.props.user.id}` : `?page=${page}&pageSize=${pageSize}`))
    }
  }

  initSampleData = async () => {
    if (this.state.initializingSampleData) {
      return
    }
    this.setState({ initializingSampleData: true })
    const api = API.INVOICE.initSampleOrders()
    const { response, error } = await apiCall({ ...api })
    if (!error && response.status === 200) {
      this.props.history.push(ROUTER.INVOICE.INDEX)
      // this.onChangePage(1, 20)
    // } else {
    }
    this.setState({ initializingSampleData: false })
  }

  exportExcel = async () => {
    const { invoice } = this.state
    const data = await invoice.Items.map((item, index) => {
      const newItem = {}
      newItem.STT = index + 1
      newItem['Vật tư'] = item.ProductName
      newItem['ĐVT'] = item.UnitName
      newItem['Số lượng'] = item.Quantity
      return newItem
    })
    try {
      json2excel({
        data,
        name: invoice.CustomerCode,
        formateDate: 'yyyy/mm/dd',
      })
    } catch (e) {
      notification.error({ message: 'Lỗi ! Có thể trình duyệt của bạn không hỗ trợ ! Vui lòng liên hệ nhà phát triển !' })
    }
  };

  onShowDetail = id => this.props.history.push(ROUTER.INVOICE.DETAIL.replace(':id', id));

  checkOrdered = () => {
    this.setState({ showOrderResult: true })
  };

  onCheckOrdered = params => this.props.checkOrdered(params);

  handleReport = () => this.setState(() => ({ showReportModal: true }));

  handleReportInvoice = date => this.props.reportInvoices(
    { date },
    {
      onSuccess: url => window.open(url),
      onError: error => notification.error({ message: `${error} - Vui lòng thử lại sau !` }),
    },
  )

  setSelectkeys = key => {
    this.setState({ selectKeys: key })
  }

  SyncFast = () => {
    this.props.Syncfast(this.state.selectKeys.join(','), {
      onSuccess: () => {
        notification.success({ message: 'Đồng bộ thành công' })
        this.props.getInvoices()
        this.setState({ selectKeys: [] })
      },
      onError: errorCode => {
        notification.error({ message: `Đồng bộ không thành công. ${errorCode}` })
      },
    })
  }

  exportBill = () => {
    this.props.exportOrderPDF(this.state.selectKeys.join(','), {
      onSuccess: url => {
        window.open(url, 'download_report')
        this.setState({ selectKeys: [] })
      },
      onError: errorCode => {
        notification.error({ message: `Không in được hóa đơn. ${errorCode}` })
      },
    })
  }

  removeInvoiceOrder = id => {
    this.props.removeInvoiceOrder(id, {
      onSuccess: () => {
        notification.success({ message: 'Xóa đơn hàng thành công' })
        this.props.getInvoices({ ...this.state.filterOptions, page: this.props.meta.current, pageSize: this.props.meta.pageSize })
      },
      onError: error => notification.error({ message: `Xoá đơn hàng không thành công - ${error}` }),
    })
  }

  render() {
    const { invoice, showInvoiceCard, showOrderResult, showReportModal, filterOptions, key, selectKeys } = this.state
    const { invoices, customers, isFetching, meta, checkResult, isCheckingResult, history, checkResultMeta, accounts, user } = this.props
    const query = filterOptions ? JSON.parse(filterOptions).status : ''

    return (
      <Fragment>
        <Row gutter={24}>
          <FilterRow
            history={history}
            filterOptions={filterOptions}
            customers={customers}
            disabled={isFetching}
            accounts={accounts}
            user={user}
          />
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <InvoiceList
              key={key}
              selectKeys={selectKeys}
              setSelectkeys={this.setSelectkeys}
              style={{ marginTop: 10 }}
              invoices={invoices}
              isFetching={isFetching}
              meta={meta}
              onAdd={this.handleAdd}
              initSampleData={this.initSampleData}
              onView={this.handleView}
              onChangePage={this.onChangePage}
              onChangeSize={this.onChangePage}
              onShowDetail={this.onShowDetail}
              onCheckOrdered={this.checkOrdered}
              onReport={this.handleReport}
              removeInvoiceOrder={this.removeInvoiceOrder}
            />
          </Col>
        </Row>
        <Button disabled={selectKeys.length === 0 || query === 'Hủy'} shape="round" type="primary" onClick={this.SyncFast}>
          Đồng bộ với Fast
        </Button>
        <Button style={{ marginLeft: 15 }} disabled={selectKeys.length === 0} shape="round" type="primary" onClick={this.exportBill}>
          Xuất hóa đơn
        </Button>
        <InvoiceModal
          title={invoice && `${invoice.CustomerName} - ${moment(1000 * invoice.DeliveryTime).format('DD/MM/YYYY')}`}
          visible={showInvoiceCard}
          invoice={invoice}
          onCancel={this.handleCancel}
          onExportExcel={this.exportExcel}
        />
        <ResultModal
          visible={showOrderResult}
          isFetching={isCheckingResult}
          onCancel={this.handleCancel}
          onCheck={this.onCheckOrdered}
          dataSource={checkResult}
          meta={checkResultMeta}
        />
        <ReportModal
          visible={showReportModal}
          onCancel={this.handleCancel}
          onSubmit={this.handleReportInvoice}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isFetching: select(state, ['invoiceReducer', 'list'], 'isFetching'),
  checkResult: select(state, ['invoiceReducer', 'checkResult'], 'items'),
  checkResultMeta: select(state, ['invoiceReducer', 'checkResult'], 'meta'),
  invoices: select(state, ['invoiceReducer', 'list'], 'items'),
  meta: select(state, ['invoiceReducer', 'list'], 'meta'),
  isCheckingResult: select(state, ['invoiceReducer', 'checkResult'], 'isFetching'),
  customers: select(state, ['customerReducer', 'customer'], 'items'),
  accounts: select(state, ['generalReducer', 'account'], 'items'),
  user: select(state, 'authReducer', 'user'),
})

const mapDispatchToProps = dispatch => ({
  getInvoices: filterOptions => dispatch(getInvoices(filterOptions)),
  getCustomers: () => dispatch(getCustomers()),
  checkOrdered: params => dispatch(checkOrdered(params)),
  reportInvoices: (params, meta) => dispatch(reportInvoices(params, meta)),
  Syncfast: (params, meta) => dispatch(Syncfast(params, meta)),
  exportOrderPDF: (params, meta) => dispatch(exportOrderPDF(params, meta)),
  getAccounts: params => dispatch(getAccounts(params)),
  removeInvoiceOrder: (id, meta) => dispatch(removeInvoiceOrder(id, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithPageHOC('invoice', 'data')(InvoicePage))
