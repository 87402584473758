import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import ImageUploadFormItem from '../../../components/ImageUploadFormItem'
// import WithLocale from '../../../hoc/WithLocale'

const FormItem = Form.Item

class ProductImageForm extends Component {
  constructor() {
    super()
    this.state = {}

    this.imageUploadFormItemOnChange = this.imageUploadFormItemOnChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit() {
    // event.preventDefault()
    const { form, emitValue } = this.props
    // form.resetFields()
    form.validateFields((error, values) => {
      if (!error) {
        emitValue(values)
      }
    })
  }

  imageUploadFormItemOnChange(value) {
    const { form } = this.props
    form.setFieldsValue({ Image: value })
    this.onSubmit()
  }

  render() {
    const {
      form,
      // slug,
    } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={event => this.onSubmit(event)}>
        <FormItem style={{ textAlign: 'center' }}>
          {getFieldDecorator('Image', {
            rules: [
              {
                required: true,
                message: 'Vui lòng chọn 1 ảnh',
              },
            ],
          })(
            <ImageUploadFormItem
              // width="400px"
              // height="auto"
              width="216px"
              height="216px"
              outputWidth={0}
              outputHeight={0}
              onChange={value => this.imageUploadFormItemOnChange(value)}
            />,
          )}
        </FormItem>
        <FormItem style={{ textAlign: 'center' }} />
      </Form>
    )
  }
}

ProductImageForm.propTypes = {
  // locale: PropTypes.object.isRequired,
  slug: PropTypes.string,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

ProductImageForm.defaultProps = { slug: '' }

export default Form.create()(ProductImageForm)
