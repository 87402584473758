export default {
  LOGGING_IN: 'LOGGING_IN',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE: 'LOG_IN_FAILURE',
  LOG_OUT: 'LOG_OUT',

  SET_PAGE: 'SET_PAGE',
  SET_OPEN_KEY: 'SET_OPEN_KEY',

  // Notification
  GETTING_NOTIFICATIONS: 'GETTING_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

  GETTING_MORE_NOTIFICATIONS: 'GETTING_MORE_NOTIFICATIONS',
  GET_MORE_NOTIFICATIONS_SUCCESS: 'GET_MORE_NOTIFICATIONS_SUCCESS',
  GET_MORE_NOTIFICATIONS_FAILURE: 'GET_MORE_NOTIFICATIONS_FAILURE',

  INVALIDATE_NOTIFICATION: 'INVALIDATE_NOTIFICATION',

  // Account
  GETTING_ACCOUNT: 'GETTING_ACCOUNT',
  GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAILURE: 'GET_ACCOUNT_FAILURE',

  INSERTING_ACCOUNT: 'INSERTING_ACCOUNT',
  INSERT_ACCOUNT_SUCCESS: 'INSERT_ACCOUNT_SUCCESS',
  INSERT_ACCOUNT_FAILURE: 'INSERT_ACCOUNT_FAILURE',

  UPDATING_PASSWORD: 'UPDATING_PASSWORD',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

  RESETING_PASSWORD: 'RESETING_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  UPDATING_USER_PRODILE: 'UPDATING_USER_PRODILE',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',

  CHANGE_USER_STATUS: 'CHANGE_USER_STATUS',

  // Driver
  GETTING_DRIVER: 'GETTING_DRIVER',
  GET_DRIVER_SUCCESS: 'GET_DRIVER_SUCCESS',
  GET_DRIVER_FAILURE: 'GET_DRIVER_FAILURE',

  GETTING_DRIVERS: 'GETTING_DRIVERS',
  GET_DRIVERS_SUCCESS: 'GET_DRIVERS_SUCCESS',
  GET_DRIVERS_FAILURE: 'GET_DRIVERS_FAILURE',

  INSERTING_DRIVER: 'INSERTING_DRIVER',
  INSERT_DRIVER_SUCCESS: 'INSERT_DRIVER_SUCCESS',
  INSERT_DRIVER_FAILURE: 'INSERT_DRIVER_FAILURE',

  UPDATING_DRIVER: 'UPDATING_DRIVER',
  UPDATE_DRIVER_SUCCESS: 'UPDATE_DRIVER_SUCCESS',
  UPDATE_DRIVER_FAILURE: 'UPDATE_DRIVER_FAILURE',

  DELETING_DRIVER: 'DELETING_DRIVER',
  DELETE_DRIVER_SUCCESS: 'DELETE_DRIVER_SUCCESS',
  DELETE_DRIVER_FAILURE: 'DELETE_DRIVER_FAILURE',

  // Vehicle
  GETTING_VEHICLES: 'GETTING_VEHICLES',
  GET_VEHICLES_SUCCESS: 'GET_VEHICLES_SUCCESS',
  GET_VEHICLES_FAILURE: 'GET_VEHICLES_FAILURE',

  INSERTING_VEHICLE: 'INSERTING_VEHICLE',
  INSERT_VEHICLE_SUCCESS: 'INSERT_VEHICLE_SUCCESS',
  INSERT_VEHICLE_FAILURE: 'INSERT_VEHICLE_FAILURE',

  UPDATING_VEHICLE: 'UPDATING_VEHICLE',
  UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_FAILURE: 'UPDATE_VEHICLE_FAILURE',

  GETTING_VEHICLE: 'GETTING_VEHICLE',
  GET_VEHICLE_SUCCESS: 'GET_VEHICLE_SUCCESS',
  GET_VEHICLE_FAILURE: 'GET_VEHICLE_FAILURE',

  // Vehicle Type
  GETTING_VEHICLE_TYPES: 'GETTING_VEHICLE_TYPES',
  GET_VEHICLE_TYPES_SUCCESS: 'GET_VEHICLE_TYPES_SUCCESS',
  GET_VEHICLE_TYPES_FAILURE: 'GET_VEHICLE_TYPES_FAILURE',

  INSERTING_VEHICLE_TYPE: 'INSERTING_VEHICLE_TYPE',
  INSERT_VEHICLE_TYPE_SUCCESS: 'INSERT_VEHICLE_TYPE_SUCCESS',
  INSERT_VEHICLE_TYPE_FAILURE: 'INSERT_VEHICLE_TYPE_FAILURE',

  DELETING_VEHICLE_TYPE: 'DELETING_VEHICLE_TYPE',
  DELETE_VEHICLE_TYPE_SUCCESS: 'DELETE_VEHICLE_TYPE_SUCCESS',
  DELETE_VEHICLE_TYPE_FAILURE: 'DELETE_VEHICLE_TYPE_FAILURE',

  // Cart
  GETTING_CARTS: 'GETTING_CARTS',
  GET_CARTS_SUCCESS: 'GET_CARTS_SUCCESS',
  GET_CARTS_FAILURE: 'GET_CARTS_FAILURE',

  GETTING_CART: 'GETTING_CART',
  GET_CART_SUCCESS: 'GET_CART_SUCCESS',
  GET_CART_FAILURE: 'GET_CART_FAILURE',

  INSERTING_CART: 'INSERTING_CART',
  INSERT_CART_SUCCESS: 'INSERT_CART_SUCCESS',
  INSERT_CART_FAILURE: 'INSERT_CART_FAILURE',

  UPDATING_CART: 'UPDATING_CART',
  UPDATE_CART_SUCCESS: 'UPDATE_CART_SUCCESS',
  UPDATE_CART_FAILURE: 'UPDATE_CART_FAILURE',

  DELETING_CART: 'DELETING_CART',
  DELETE_CART_SUCCESS: 'DELETE_CART_SUCCESS',
  DELETE_CART_FAILURE: 'DELETE_CART_FAILURE',

  CHANGE_CART_STATUS_SUCCESS: 'CHANGE_CART_STATUS_SUCCESS',
  CHANGE_CART_STATUS_FAILURE: 'CHANGE_CART_STATUS_FAILURE',

  // Customer
  GETTING_CUSTOMERS: 'GETTING_CUSTOMERS',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',

  GETTING_CUSTOMER: 'GETTING_CUSTOMER',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAILURE: 'GET_CUSTOMER_FAILURE',

  INSERTING_CUSTOMER: 'INSERTING_CUSTOMER',
  INSERT_CUSTOMER_SUCCESS: 'INSERT_CUSTOMER_SUCCESS',
  INSERT_CUSTOMER_FAILURE: 'INSERT_CUSTOMER_FAILURE',

  UPDATING_CUSTOMER: 'UPDATING_CUSTOMER',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILURE: 'UPDATE_CUSTOMER_FAILURE',

  DELETING_CUSTOMER: 'DELETING_CUSTOMER',
  DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILURE: 'DELETE_CUSTOMER_FAILURE',

  CHANGE_CUSTOMER_STATUS: 'CHANGE_CUSTOMER_STATUS',
  SET_LIST_CART: 'SET_LIST_CART',
  SELECT_NODES_FOR_VRP: 'SELECT_NODES_FOR_VRP',

  // Customer Group
  GETTING_CUSTOMER_GROUPS: 'GETTING_CUSTOMER_GROUPS',
  GET_CUSTOMER_GROUPS_SUCCESS: 'GET_CUSTOMER_GROUPS_SUCCESS',
  GET_CUSTOMER_GROUPS_FAILURE: 'GET_CUSTOMER_GROUPS_FAILURE',

  INSERTING_CUSTOMER_GROUP: 'INSERTING_CUSTOMER_GROUP',
  INSERT_CUSTOMER_GROUP_SUCCESS: 'INSERT_CUSTOMER_GROUP_SUCCESS',
  INSERT_CUSTOMER_GROUP_FAILURE: 'INSERT_CUSTOMER_GROUP_FAILURE',

  UPDATING_CUSTOMER_GROUP: 'UPDATING_CUSTOMER_GROUP',
  UPDATE_CUSTOMER_GROUP_SUCCESS: 'UPDATE_CUSTOMER_GROUP_SUCCESS',
  UPDATE_CUSTOMER_GROUP_FAILURE: 'UPDATE_CUSTOMER_GROUP_FAILURE',

  DELETING_CUSTOMER_GROUP: 'DELETING_CUSTOMER_GROUP',
  DELETE_CUSTOMER_GROUP_SUCCESS: 'DELETE_CUSTOMER_GROUP_SUCCESS',
  DELETE_CUSTOMER_GROUP_FAILURE: 'DELETE_CUSTOMER_GROUP_FAILURE',

  ADDING_CUSTOMER_TO_GROUP: 'ADDING_CUSTOMER_TO_GROUP',
  ADD_CUSTOMER_TO_GROUP_SUCCESS: 'ADD_CUSTOMER_TO_GROUP_SUCCESS',
  ADD_CUSTOMER_TO_GROUP_FAILURE: 'ADD_CUSTOMER_TO_GROUP_FAILURE',

  GETTING_CUSTOMER_OF_GROUP: 'GETTING_CUSTOMER_OF_GROUP',
  GET_CUSTOMER_OF_GROUP_SUCCESS: 'GET_CUSTOMER_OF_GROUP_SUCCESS',
  GET_CUSTOMER_OF_GROUP_FAILURE: 'GET_CUSTOMER_OF_GROUP_FAILURE',

  REMOVING_CUSTOMER_FROM_GROUP: 'REMOVING_CUSTOMER_FROM_GROUP',
  REMOVE_CUSTOMER_FROM_GROUP_SUCCESS: 'REMOVE_CUSTOMER_FROM_GROUP_SUCCESS',
  REMOVE_CUSTOMER_FROM_GROUP_FAILURE: 'REMOVE_CUSTOMER_FROM_GROUP_FAILURE',

  GETTING_CART_CUSTOMERS: 'GETTING_CART_CUSTOMERS',
  GET_CART_CUSTOMERS_SUCCESS: 'GET_CART_CUSTOMERS_SUCCESS',
  GET_CART_CUSTOMERS_FAILURE: 'GET_CART_CUSTOMERS_FAILURE',

  IMPORTING_PRICE_TABLE: 'IMPORTING_PRICE_TABLE',
  IMPORT_PRICE_TABLE_SUCCESS: 'IMPORT_PRICE_TABLE_SUCCESS',
  IMPORT_PRICE_TABLE_FAILURE: 'IMPORT_PRICE_TABLE_FAILURE',
  IMPORT_PRICE_TABLE_FROM_EXIST_ONE: 'IMPORT_PRICE_TABLE_FROM_EXIST_ONE',
  CLEAR_EXIST_PRICE_TABLE: 'CLEAR_EXIST_PRICE_TABLE',

  // GETTING_PRICE_TABLE: 'GETTING_PRICE_TABLE',
  // GET_PRICE_TABLE_SUCCESS: 'GET_PRICE_TABLE_SUCCESS',
  // GET_PRICE_TABLE_FAILURE: 'GET_PRICE_TABLE_FAILURE',
  UPDATE_STATUS_ITEM_FAILURE: 'UPDATE_STATUS_ITEM_FAILURE',
  ADDING_ITEM_TO_PRICE_TABLE: 'ADDING_ITEM_TO_PRICE_TABLE',
  ADD_ITEM_TO_PRICE_TABLE_SUCCESS: 'ADD_ITEM_TO_PRICE_TABLE_SUCCESS',
  ADD_ITEM_TO_PRICE_TABLE_FAILURE: 'ADD_ITEM_TO_PRICE_TABLE_FAILURE',

  REMOVING_ITEM_FROM_PRICE_TABLE: 'REMOVING_ITEM_FROM_PRICE_TABLE',
  REMOVE_ITEM_FROM_PRICE_TABLE_SUCCESS: 'REMOVE_ITEM_FROM_PRICE_TABLE_SUCCESS',
  REMOVE_ITEM_FROM_PRICE_TABLE_FAILURE: 'REMOVE_ITEM_FROM_PRICE_TABLE_FAILURE',

  UPDATING_ITEM_IN_PRICE_TABLE: 'UPDATING_ITEM_IN_PRICE_TABLE',
  UPDATE_ITEM_IN_PRICE_TABLE_SUCCESS: 'UPDATE_ITEM_IN_PRICE_TABLE_SUCCESS',
  UPDATE_ITEM_IN_PRICE_TABLE_FAILURE: 'UPDATE_ITEM_IN_PRICE_TABLE_FAILURE',

  // Product
  GETTING_PRODUCTS: 'GETTING_PRODUCTS',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',

  GETTING_PRODUCT: 'GETTING_PRODUCT',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',

  INSERTING_PRODUCT: 'INSERTING_PRODUCT',
  INSERT_PRODUCT_SUCCESS: 'INSERT_PRODUCT_SUCCESS',
  INSERT_PRODUCT_FAILURE: 'INSERT_PRODUCT_FAILURE',

  UPDATING_PRODUCT: 'UPDATING_PRODUCT',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILURE: 'UPDATE_PRODUCT_FAILURE',

  DELETING_PRODUCT: 'DELETING_PRODUCT',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',

  UPDATING_PRODUCT_CRITERIA: 'UPDATING_PRODUCT_CRITERIA',
  UPDATE_PRODUCT_CRITERIA_SUCCESS: 'UPDATE_PRODUCT_CRITERIA_SUCCESS',
  UPDATE_PRODUCT_CRITERIA_FAILURE: 'UPDATE_PRODUCT_CRITERIA_FAILURE',

  CHANGE_PRODUCT_STATUS: 'CHANGE_PRODUCT_STATUS',
  GETTING_ALL_PRODUCTS: 'GETTING_ALL_PRODUCTS',
  GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
  GET_ALL_PRODUCTS_FAILURE: 'GET_ALL_PRODUCTS_FAILURE',

  // Request
  GETTING_REQUESTS: 'GETTING_REQUESTS',
  GET_REQUESTS_SUCCESS: 'GET_REQUESTS_SUCCESS',
  GET_REQUESTS_FAILURE: 'GET_REQUESTS_FAILURE',

  REPLYING_REQUEST: 'REPLYING_REQUEST',
  REPLY_REQUEST_SUCCESS: 'REPLY_REQUEST_SUCCESS',
  REPLY_REQUEST_FAILURE: 'REPLY_REQUEST_FAILURE',

  // Product Units
  GETTING_PRODUCT_UNITS: 'GETTING_PRODUCT_UNITS',
  GET_PRODUCT_UNITS_SUCCESS: 'GET_PRODUCT_UNITS_SUCCESS',
  GET_PRODUCT_UNITS_FAILURE: 'GET_PRODUCT_UNITS_FAILURE',

  GETTING_PRODUCT_UNIT: 'GETTING_PRODUCT_UNIT',
  GET_PRODUCT_UNIT_SUCCESS: 'GET_PRODUCT_UNIT_SUCCESS',
  GET_PRODUCT_UNIT_FAILURE: 'GET_PRODUCT_UNIT_FAILURE',

  INSERTING_PRODUCT_UNIT: 'INSERTING_PRODUCT_UNIT',
  INSERT_PRODUCT_UNIT_SUCCESS: 'INSERT_PRODUCT_UNIT_SUCCESS',
  INSERT_PRODUCT_UNIT_FAILURE: 'INSERT_PRODUCT_UNIT_FAILURE',

  UPDATING_PRODUCT_UNIT: 'UPDATING_PRODUCT_UNIT',
  UPDATE_PRODUCT_UNIT_SUCCESS: 'UPDATE_PRODUCT_UNIT_SUCCESS',
  UPDATE_PRODUCT_UNIT_FAILURE: 'UPDATE_PRODUCT_UNIT_FAILURE',

  DELETING_PRODUCT_UNIT: 'DELETING_PRODUCT_UNIT',
  DELETE_PRODUCT_UNIT_SUCCESS: 'DELETE_PRODUCT_UNIT_SUCCESS',
  DELETE_PRODUCT_UNIT_FAILURE: 'DELETE_PRODUCT_UNIT_FAILURE',

  // Product Condition
  GETTING_PRODUCT_CONDITIONS: 'GETTING_PRODUCT_CONDITIONS',
  GET_PRODUCT_CONDITIONS_SUCCESS: 'GET_PRODUCT_CONDITIONS_SUCCESS',
  GET_PRODUCT_CONDITIONS_FAILURE: 'GET_PRODUCT_CONDITIONS_FAILURE',

  GETTING_PRODUCT_CONDITION: 'GETTING_PRODUCT_CONDITION',
  GET_PRODUCT_CONDITION_SUCCESS: 'GET_PRODUCT_CONDITION_SUCCESS',
  GET_PRODUCT_CONDITION_FAILURE: 'GET_PRODUCT_CONDITION_FAILURE',

  INSERTING_PRODUCT_CONDITION: 'INSERTING_PRODUCT_CONDITION',
  INSERT_PRODUCT_CONDITION_SUCCESS: 'INSERT_PRODUCT_CONDITION_SUCCESS',
  INSERT_PRODUCT_CONDITION_FAILURE: 'INSERT_PRODUCT_CONDITION_FAILURE',

  UPDATING_PRODUCT_CONDITION: 'UPDATING_PRODUCT_CONDITION',
  UPDATE_PRODUCT_CONDITION_SUCCESS: 'UPDATE_PRODUCT_CONDITION_SUCCESS',
  UPDATE_PRODUCT_CONDITION_FAILURE: 'UPDATE_PRODUCT_CONDITION_FAILURE',

  DELETING_PRODUCT_CONDITION: 'DELETING_PRODUCT_CONDITION',
  DELETE_PRODUCT_CONDITION_SUCCESS: 'DELETE_PRODUCT_CONDITION_SUCCESS',
  DELETE_PRODUCT_CONDITION_FAILURE: 'DELETE_PRODUCT_CONDITION_FAILURE',

  // Invoice
  GETTING_INVOICES: 'GETTING_INVOICES',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',

  GETTING_INVOICE: 'GETTING_INVOICE',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',

  GET_CART_INFO: 'GET_CART_INFO',
  GET_CUSTOMER_INFO: 'GET_CUSTOMER_INFO',

  INSERTING_INVOICE: 'INSERTING_INVOICE',
  INSERT_INVOICE_SUCCESS: 'INSERT_INVOICE_SUCCESS',
  INSERT_INVOICE_FAILURE: 'INSERT_INVOICE_FAILURE',

  UPDATING_INVOICE: 'UPDATING_INVOICE',
  UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_FAILURE: 'UPDATE_INVOICE_FAILURE',

  UPDATING_INVOICE_STATUS: 'UPDATING_INVOICE_STATUS',
  UPDATE_INVOICE_STATUS_SUCCESS: 'UPDATE_INVOICE_STATUS_SUCCESS',
  UPDATE_INVOICE_STATUS_FAILURE: 'UPDATE_INVOICE_STATUS_FAILURE',

  CHECKING_ORDERED: 'CHECKING_ORDERED',
  CHECK_ORDER_SUCCESS: 'CHECK_ORDER_SUCCESS',
  CHECK_ORDER_FAILURE: 'CHECK_ORDER_FAILURE',

  UPDATING_INVOICE_ORDER: 'UPDATING_INVOICE_ORDER',
  UPDATE_INVOICE_ORDER_SUCCESS: 'UPDATE_INVOICE_ORDER_SUCCESS',
  UPDATE_INVOICE_ORDER_FAILURE: 'UPDATE_INVOICE_ORDER_FAILURE',

  DELETING_INVOICE_ORDER: 'DELETING_INVOICE_ORDER',
  DELETE_INVOICE_ORDER_SUCCESS: 'DELETE_INVOICE_ORDER_SUCCESS',
  DELETE_INVOICE_ORDER_FAILURE: 'DELETE_INVOICE_ORDER_FAILURE',

  DELETING_INVOICE_ORDER_ITEM: 'DELETING_INVOICE_ORDER_ITEM',
  DELETE_INVOICE_ORDER_ITEM_SUCCESS: 'DELETE_INVOICE_ORDER_ITEM_SUCCESS',
  DELETE_INVOICE_ORDER_ITEM_FAILURE: 'DELETE_INVOICE_ORDER_ITEM_FAILURE',

  // Order
  ADD_ITEM: 'ADD_ITEM',
  SET_ORDER: 'SET_ORDER',
  DELETE_ORDERS: 'DELETE_ORDERS',

  GETTING_CART_FOR_ORDER: 'GETTING_CART_FOR_ORDER',
  GET_CART_FOR_ORDER_SUCCESS: 'GET_CART_FOR_ORDER_SUCCESS',
  GET_CART_FOR_ORDER_FAILURE: 'GET_CART_FOR_ORDER_FAILURE',

  DELETE_CART_FOR_ORDER: 'DELETE_CART_FOR_ORDER',
  GETTING_CUSTOMER_FOR_ORDER: 'GETTING_CUSTOMER_FOR_ORDER',
  GET_CUSTOMER_FOR_ORDER_SUCCESS: 'GET_CUSTOMER_FOR_ORDER_SUCCESS',
  GET_CUSTOMER_FOR_ORDER_FAILURE: 'GET_CUSTOMER_FOR_ORDER_FAILURE',

  GETTING_CARTS_OF_CUSTOMER: 'GETTING_CARTS_OF_CUSTOMER',
  GET_CARTS_OF_CUSTOMER_SUCCESS: 'GET_CARTS_OF_CUSTOMER_SUCCESS',
  GET_CARTS_OF_CUSTOMER_FAILURE: 'GET_CARTS_OF_CUSTOMER_FAILURE',

  GETTING_PRICETABLE_FOR_ORDER: 'GETTING_PRICETABLE_FOR_ORDER',
  GETTING_PRODUCTS_FOR_ORDER: 'GETTING_PRODUCTS_FOR_ORDER',
  GET_PRODUCTS_FOR_ORDER_SUCCESS: 'GET_PRODUCTS_FOR_ORDER_SUCCESS',
  GET_PRODUCTS_FOR_ORDER_FAILURE: 'GET_PRODUCTS_FOR_ORDER_FAILURE',
  DELETE_PRODUCTS_FOR_ORDER: 'DELETE_PRODUCTS_FOR_ORDER',

  UPDATING_ORDER_ITEM: 'UPDATING_ORDER_ITEM',
  UPDATE_ORDER_ITEM_SUCCESS: 'UPDATE_ORDER_ITEM_SUCCESS',
  UPDATE_ORDER_ITEM_FAILURE: 'UPDATE_ORDER_ITEM_FAILURE',

  DELETING_ORDER_ITEM: 'DELETING_ORDER_ITEM',
  DELETE_ORDER_ITEM_SUCCESS: 'DELETE_ORDER_ITEM_SUCCESS',
  DELETE_ORDER_ITEM_FAILURE: 'DELETE_ORDER_ITEM_FAILURE',

  CLEAR_DATA_ORDER: 'CLEAR_DATA_ORDER',

  // Routing
  SOLVING_VRP: 'SOLVING_VRP',
  SOLVE_VRP_SUCCESS: 'SOLVE_VRP_SUCCESS',
  SOLVE_VRP_FAILURE: 'SOLVE_VRP_FAILURE',
  STOP_LOADING: 'STOP_LOADING',
  UPDATE_ROUTES: 'UPDATE_ROUTES',
  SHOW_NODE_INFO: 'SHOW_NODE_INFO',
  UPDATE_NODE_INFO: 'UPDATE_NODE_INFO',
  SORT_DRIVERS: 'SORT_DRIVERS',
  UPDATE_DRIVER_NOTE: 'UPDATE_DRIVER_NOTE',
  SELECT_INVOICES_FOR_VRP: 'SELECT_INVOICES_FOR_VRP',
  SELECT_DRIVERS_FOR_VRP: 'SELECT_DRIVERS_FOR_VRP',
  SELECT_SAMPLE_SCHEDULE: 'SELECT_SAMPLE_SCHEDULE',
  SELECT_TIME_FOR_VRP: 'SELECT_TIME_FOR_VRP',
  SET_NODE_POOL: 'SET_NODE_POOL',

  INSERTING_SOLUTION: 'INSERTING_SOLUTION',
  INSERT_SOLUTION_SUCCESS: 'INSERT_SOLUTION_SUCCESS',
  INSERT_SOLUTION_FAILURE: 'INSERT_SOLUTION_FAILURE',

  UPDATING_SOLUTION: 'UPDATING_SOLUTION',
  UPDATE_SOLUTION_SUCCESS: 'UPDATE_SOLUTION_SUCCESS',
  UPDATE_SOLUTION_FAILURE: 'UPDATE_SOLUTION_FAILURE',

  RESET_NODES_AND_DRIVERS_SUCCESS: 'RESET_NODES_AND_DRIVERS_SUCCESS',

  RESET_ROUTING_SAMPLE: 'RESET_ROUTING_SAMPLE',

  RESET_MODE_ROUTING: 'RESET_MODE_ROUTING',

  // Route
  GETTING_ROUTES: 'GETTING_ROUTES',
  GET_ROUTES_SUCCESS: 'GET_ROUTES_SUCCESS',
  GET_ROUTES_FAILURE: 'GET_ROUTES_FAILURE',

  INVALIDATE_ROUTE_LIST: 'INVALIDATE_ROUTE_LIST',

  GETTING_ROUTE_TRACKING: 'GETTING_ROUTE_TRACKING',
  GET_ROUTE_TRACKING_SUCCESS: 'GET_ROUTE_TRACKING_SUCCESS',
  GET_ROUTE_TRACKING_FAILURE: 'GET_ROUTE_TRACKING_FAILURE',

  GETTING_SCHEDULES: 'GETTING_SCHEDULES',
  GET_SCHEDULES_SUCCESS: 'GET_SCHEDULES_SUCCESS',
  GET_SCHEDULES_FAILURE: 'GET_SCHEDULES_FAILURE',

  GETTING_SAMPLE_SCHEDULES: 'GETTING_SAMPLE_SCHEDULES',
  GET_SCHEDULES_SAMPLE_SUCCESS: 'GET_SCHEDULES_SAMPLE_SUCCESS',
  GET_SCHEDULES_SAMPLE_FAILURE: 'GET_SCHEDULES_SAMPLE_FAILURE',

  IMPORT_SAMPLE_SCHEDULES_SUCCESS: 'IMPORT_SAMPLE_SCHEDULES_SUCCESS',

  INVALIDATE_CURRENT_SCHEDULE: 'INVALIDATE_CURRENT_SCHEDULE',

  UPDATING_SCHEDULE: 'UPDATING_SCHEDULE',
  UPDATE_SCHEDULE_SUCCESS: 'UPDATE_SCHEDULE_SUCCESS',
  UPDATE_SCHEDULE_FAILURE: 'UPDATE_SCHEDULE_FAILURE',

  GETTING_SCHEDULE: 'GETTING_SCHEDULE',
  GET_SCHEDULE_SUCCESS: 'GET_SCHEDULE_SUCCESS',
  GET_SCHEDULE_FAILURE: 'GET_SCHEDULE_FAILURE',

  GETTING_DISTANCE_REPORT: 'GETTING_DISTANCE_REPORT',
  GET_DISTANCE_REPORT_SUCCESS: 'GET_DISTANCE_REPORT_SUCCESS',
  GET_DISTANCE_REPORT_FAILURE: 'GET_DISTANCE_REPORT_FAILURE',

  GETTING_WEIGHT_REPORT: 'GETTING_WEIGHT_REPORT',
  GET_WEIGHT_REPORT_SUCCESS: 'GET_WEIGHT_REPORT_SUCCESS',
  GET_WEIGHT_REPORT_FAILURE: 'GET_WEIGHT_REPORT_FAILURE',

  GETTING_WORKING_DAY_REPORT: 'GETTING_WORKING_DAY_REPORT',
  GET_WORKING_DAY_REPORT_SUCCESS: 'GET_WORKING_DAY_REPORT_SUCCESS',
  GET_WORKING_DAY_REPORT_FAILURE: 'GET_WORKING_DAY_REPORT_FAILURE',

  GETTING_ORDER_IN_DAY_REPORT: 'GETTING_ORDER_IN_DAY_REPORT',
  GET_ORDER_IN_DAY_REPORT_SUCCESS: 'GET_ORDER_IN_DAY_REPORT_SUCCESS',
  GET_ORDER_IN_DAY_REPORT_FAILURE: 'GET_ORDER_IN_DAY_REPORT_FAILURE',
  GET_ORDER_REPORT_EXCEL: 'GET_ORDER_REPORT_EXCEL',

  // Price Table
  GETTING_PRICE_TABLES: 'GETTING_PRICE_TABLES',
  GET_PRICE_TABLES_SUCCESS: 'GET_PRICE_TABLES_SUCCESS',
  GET_PRICE_TABLES_FAILURE: 'GET_PRICE_TABLES_FAILURE',

  GETTING_PRICE_TABLE: 'GETTING_PRICE_TABLE',
  GET_PRICE_TABLE_SUCCESS: 'GET_PRICE_TABLE_SUCCESS',
  GET_PRICE_TABLE_FAILURE: 'GET_PRICE_TABLE_FAILURE',

  GETTING_PRICE_TABLES_OF_GROUP: 'GETTING_PRICE_TABLES_OF_GROUP',
  GET_PRICE_TABLES_OF_GROUP_SUCCESS: 'GET_PRICE_TABLES_OF_GROUP_SUCCESS',
  GET_PRICE_TABLES_OF_GROUP_FAILURE: 'GET_PRICE_TABLES_OF_GROUP_FAILURE',

  GETTING_PRICE_TABLE_OF_GROUP: 'GETTING_PRICE_TABLE_OF_GROUP',
  GETTING_EXIST_PRICE_TABLE_OF_GROUP: 'GETTING_EXIST_PRICE_TABLE_OF_GROUP',
  GET_PRICE_TABLE_OF_GROUP_SUCCESS: 'GET_PRICE_TABLE_OF_GROUP_SUCCESS',
  GET_EXIST_PRICE_TABLE_OF_GROUP_SUCCESS: 'GET_EXIST_PRICE_TABLE_OF_GROUP_SUCCESS',
  GET_PRICE_TABLE_OF_GROUP_FAILURE: 'GET_PRICE_TABLE_OF_GROUP_FAILURE',
  GET_EXIST_PRICE_TABLE_OF_GROUP_FAILURE: 'GET_EXIST_PRICE_TABLE_OF_GROUP_FAILURE',

  INSERTING_PRICE_TABLE: 'INSERTING_PRICE_TABLE',
  INSERT_PRICE_TABLE_SUCCESS: 'INSERT_PRICE_TABLE_SUCCESS',
  INSERT_PRICE_TABLE_FAILURE: 'INSERT_PRICE_TABLE_FAILURE',

  UPDATING_PRICE_TABLE: 'UPDATING_PRICE_TABLE',
  UPDATE_PRICE_TABLE_SUCCESS: 'UPDATE_PRICE_TABLE_SUCCESS',
  UPDATE_PRICE_TABLE_FAILURE: 'UPDATE_PRICE_TABLE_FAILURE',

  DELETING_PRICE_TABLE: 'DELETING_PRICE_TABLE',
  DELETE_PRICE_TABLE_SUCCESS: 'DELETE_PRICE_TABLE_SUCCESS',
  DELETE_PRICE_TABLE_FAILURE: 'DELETE_PRICE_TABLE_FAILURE',

  GETTING_PRODUCTS_FOR_PRICE_TABLES: 'GETTING_PRODUCTS_FOR_PRICE_TABLES',
  GET_PRODUCTS_FOR_PRICE_TABLES_FAILURE: 'GET_PRODUCTS_FOR_PRICE_TABLES_FAILURE',
  GET_PRODUCTS_FOR_PRICE_TABLES_SUCCESS: 'GET_PRODUCTS_FOR_PRICE_TABLES_SUCCESS',

  ADD_ITEM_TO_NEW_PRICETABLE: 'ADD_ITEM_TO_NEW_PRICETABLE',
  ADD_ITEMS_TO_NEW_PRICETABLE: 'ADD_ITEMS_TO_NEW_PRICETABLE',
  REMOVE_ITEM_FROM_NEW_PRICETABLE: 'REMOVE_ITEM_FROM_NEW_PRICETABLE',
  CANCEL_CREATE_PRICE: 'CANCEL_CREATE_PRICE',
  // Category
  GETTING_CATEGORIES: 'GETTING_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',

  GETTING_CATEGORY: 'GETTING_CATEGORY',
  GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_FAILURE: 'GET_CATEGORY_FAILURE',

  INSERTING_CATEGORY: 'INSERTING_CATEGORY',
  INSERT_CATEGORY_SUCCESS: 'INSERT_CATEGORY_SUCCESS',
  INSERT_CATEGORY_FAILURE: 'INSERT_CATEGORY_FAILURE',

  DELETING_CATEGORY: 'DELETING_CATEGORY',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

  UPDATING_CATEGORY: 'UPDATING_CATEGORY',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

  CHANGE_CATEGORY_STATUS: 'CHANGE_CATEGORY_STATUS',

  // Supplier
  GETTING_SUPPLIERS: 'GETTING_SUPPLIERS',
  GET_SUPPLIERS_SUCCESS: 'GET_SUPPLIERS_SUCCESS',
  GET_SUPPLIERS_FAILURE: 'GET_SUPPLIERS_FAILURE',

  GETTING_SUPPLIER: 'GETTING_SUPPLIER',
  GET_SUPPLIER_SUCCESS: 'GET_SUPPLIER_SUCCESS',
  GET_SUPPLIER_FAILURE: 'GET_SUPPLIER_FAILURE',

  INSERTING_SUPPLIER: 'INSERTING_SUPPLIER',
  INSERT_SUPPLIER_SUCCESS: 'INSERT_SUPPLIER_SUCCESS',
  INSERT_SUPPLIER_FAILURE: 'INSERT_SUPPLIER_FAILURE',

  DELETING_SUPPLIER: 'DELETING_SUPPLIER',
  DELETE_SUPPLIER_SUCCESS: 'DELETE_SUPPLIER_SUCCESS',
  DELETE_SUPPLIER_FAILURE: 'DELETE_SUPPLIER_FAILURE',

  UPDATING_SUPPLIER: 'UPDATING_SUPPLIER',
  UPDATE_SUPPLIER_SUCCESS: 'UPDATE_SUPPLIER_SUCCESS',
  UPDATE_SUPPLIER_FAILURE: 'UPDATE_SUPPLIER_FAILURE',

  UPDATING_SUPPLIER_CRITERIA: 'UPDATING_SUPPLIER_CRITERIA',
  UPDATE_SUPPLIER_CRITERIA_SUCCESS: 'UPDATE_SUPPLIER_CRITERIA_SUCCESS',
  UPDATE_SUPPLIER_CRITERIA_FAILURE: 'UPDATE_SUPPLIER_CRITERIA_FAILURE',

  CHANGE_SUPPLIER_STATUS: 'CHANGE_SUPPLIER_STATUS',

  // Notification
  GETTING_NODE_FOR_SCHEDULE: 'GETTING_NODE_FOR_SCHEDULE',
  GET_NODE_FOR_SCHEDULE_SUCCESS: 'GET_NODE_FOR_SCHEDULE_SUCCESS',
  GET_NODE_FOR_SCHEDULE_FAILURE: 'GET_NODE_FOR_SCHEDULE_FAILURE',

  // Managa
  GETTING_API_FOR_MANAGA: 'GETTING_API_FOR_MANAGA',
  GET_API_FOR_MANAGA_SUCCESS: 'GET_API_FOR_MANAGA_SUCCESS',
  GET_API_FOR_MANAGA_FAILURE: 'GET_API_FOR_MANAGA_FAILURE',

  CREATING_API: 'CREATING_API',
  CREATE_API_SUCCESS: 'CREATE_API_SUCCESS',
  CREATE_API_FAILURE: 'CREATE_API_FAILURE',

  CHANGE_STATUS_API_SUCCESS: 'CHANGE_STATUS_API_SUCCESS',

  UPDATING_API: 'UPDATING_API',
  UPDATE_API_SUCCESS: 'UPDATING_API',
  UPDATE_API_FAILURE: 'UPDATING_API',

  GETTING_ROLE_FOR_MANAGA: 'GETTING_ROLE_FOR_MANAGA',
  GET_ROLE_FOR_MANAGA_SUCCESS: 'GET_ROLE_FOR_MANAGA_SUCCESS',
  GET_ROLE_FOR_MANAGA_FAILURE: 'GET_ROLE_FOR_MANAGA_FAILURE',

  CREATING_ROLE: 'CREATING_ROLE',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',

  EDITING_ROLE: 'EDITING_ROLE',
  EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
  EDIT_ROLE_FAILURE: 'EDIT_ROLE_FAILURE',

  ACTIVE_ROLE_SUCCESS: 'ACTIVE_ROLE_SUCCESS',
  DEACTIVE_ROLE_SUCCESS: 'DEACTIVE_ROLE_SUCCESS',

  DELETING_API_OF_ROLE: 'DELETING_API_OF_ROLE',
  DELETE_API_OF_ROLE_SUCCESS: 'DELETE_API_OF_ROLE_SUCCESS',
  DETELE_API_OF_ROLE_FAILURE: 'DETELE_API_OF_ROLE_FAILURE',

  DELETE_LIST_API_OF_ROLE_SUCCESS: 'DELETE_LIST_API_OF_ROLE_SUCCESS',

  CREATING_API_OF_ROLE: 'CREATING_API_OF_ROLE',
  CREATE_API_OF_ROLE_SUCCESS: 'CREATE_API_OF_ROLE_SUCCESS',
  CREATE_API_OF_ROLE_FAILURE: 'CREATE_API_OF_ROLE_FAILURE',

  // synchorize data
  GETTING_SYNCHRONIZE_DATA: 'GETTING_SYNCHRONIZE_DATA',
  GET_SYNCHRONIZE_DATA_SUCCESS: 'GET_SYNCHRONIZE_DATA_SUCCESS',
  GET_SYNCHRONIZE_DATA_FAILURE: 'GET_SYNCHRONIZE_DATA_FAILURE',

  SYNCHRONIZE_FAST_DATA_SUCCESS: 'SYNCHRONIZE_FAST_DATA_SUCCESS',
  SYNCHRONIZE_FAST_DATA_FAILURE: 'SYNCHRONIZE_FAST_DATA_FAILURE',

  GETTING_SYN_TIME: 'GETTING_SYN_TIME',
  GET_SYN_TIME_SUCCESS: 'GET_SYN_TIME_SUCCESS',
  GET_SYN_TIME_FAILURE: 'GET_SYN_TIME_FAILURE',

  // stamp page
  GETTING_URL_STAMP: 'GETTING_URL_STAMP',
  GET_URL_STAMP_SUCCESS: 'GET_URL_STAMP_SUCCESS',
  GET_URL_STAMP_FAILURE: 'GET_URL_STAMP_FAILURE',

  GETTING_URL_QR_STAMP: 'GETTING_URL_QR_STAMP',
  GET_URL_QR_STAMP_SUCCESS: 'GET_URL_QR_STAMP_SUCCESS',
  GET_URL_QR_STAMP_FAILURE: 'GET_URL_QR_STAMP_FAILURE',

  // update images product
  UPDATE_PRODUCT_IMAGES: 'UPDATE_PRODUCT_IMAGES',
  UPDATE_PRODUCT_IMAGES_SUCCESS: 'UPDATE_PRODUCT_IMAGES_SUCCESSE',
  UPDATE_PRODUCT_IMAGES_FAILURE: 'UPDATE_PRODUCT_IMAGES_FAILURE',
};
