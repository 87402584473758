import React from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'antd'
import WithLoading from '../../../../hoc/loading'

const NodeList = ({ nodes, selectedNodes, onSelectNodes, onSplitNode, onMergeNode }) => {
  const rowSelection = {
    onChange: selectedRowKeys => {
      onSelectNodes(selectedRowKeys)
    },
    selectedRowKeys: selectedNodes.map(node => `${node.version}-${node.cartCode}`),
  }
  const rowClassName = record => (record.totalWeight > 100 ? 'node-highlight' : '')
  const rowKey = record => `${record.version}-${record.cartCode}`
  const columns = [
    {
      title: 'Mã giỏ hàng',
      key: 'cartCode',
      dataIndex: 'cartCode'
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Cân nặng',
      key: 'totalWeight',
      render: record => `${record.totalWeight} kg`
    },
    {
      title: 'Hành động',
      key: 'action',
      render: record => (
        <div>
          <Button size="small" onClick={() => onSplitNode(record.cartCode)}>Tách</Button>
          {record.version > 0 ? <Button style={{ marginLeft: '3px' }} size="small" onClick={() => onMergeNode(record.cartCode)}>Gộp</Button> : ''}
        </div>
      )
    },
  ]
  return (
    <div>
      <h2>
        {'Danh sách giỏ hàng'}
      </h2>
      <Table
        rowSelection={rowSelection}
        pagination={false}
        size="small"
        scroll={{ x: 'max-content' }}
        rowKey={rowKey}
        rowClassName={rowClassName}
        columns={columns}
        style={{ marginTop: 15 }}
        dataSource={nodes}
      />
    </div>
  )
}

NodeList.propTypes = {
  nodes: PropTypes.array.isRequired,
  selectedNodes: PropTypes.array.isRequired,
  onSelectNodes: PropTypes.func.isRequired,
}

export default WithLoading(NodeList)
