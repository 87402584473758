import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'

const renderHeader = (text, icon, size) => {
  switch (size) {
  case 1:
    return (
      <h1>
        {icon}
        {text}
      </h1>
    )
  case 2:
    return (
      <h2>
        {icon}
        {text}
      </h2>
    )
  case 3:
    return (
      <h3>
        {icon}
        {text}
      </h3>
    )
  case 4:
    return (
      <h4>
        {icon}
        {text}
      </h4>
    )
  case 5:
    return (
      <h5>
        {icon}
        {text}
      </h5>
    )
  case 6:
    return (
      <h6>
        {icon}
        {text}
      </h6>
    )
  default:
    return (
      <h1>
        {icon}
        {text}
      </h1>
    )
  }
}

const Title = ({ header, description, icon, textAlign, headerSize }) => (
  <Row style={{ textAlign: textAlign || 'center' }}>
    {renderHeader(header, icon, headerSize)}
    <p style={{ fontSize: 'small', fontStyle: 'italic' }}>{description}</p>
  </Row>
)

Title.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.node,
  textAlign: PropTypes.string,
  headerSize: PropTypes.number,
}

Title.defaultProps = {
  description: '',
  icon: <div />,
  textAlign: 'center',
  headerSize: 2,
}

export default Title
