/* eslint react/prop-types: 0 */

import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, notification, Button } from 'antd'
import GeneralComponent from './components/List'
import {
  getProductUnits,
  deleteProductUnit,
  insertProductUnit,
  updateProductUnit
} from './actions'
import WithLoadingHOC from '../../hoc/loading'
import Config from '../../hoc/page'
import select from '../../util/select'

const { SelfList } = GeneralComponent

class GeneralPage extends React.Component {
  componentDidMount() {
    this.props.getProductUnits({ page: 1, pageSize: 10 })
  }

  handleAddProductUnit = ({ name }) => this.props.insertProductUnit(
    { unitName: name },
    {
      onSuccess: () => {
        notification.success({ message: 'Thêm thành công' })
      },
      onError: () => notification.error({ message: 'Thêm thất bại. Đơn vị tính đã tồn tại' }),
    }
  );

  handleDeleteProductUnit = id => this.props.deleteProductUnit(id, {
    onSuccess: () => {
      notification.success({ message: 'Xóa thành công' })
    },
    onError: () => notification.error({ message: 'Xóa thất bại' }),
  });

  handleUpdateProductUnit = (id, payload) => this.props.updateProductUnit(id, payload, {
    onSuccess: () => {
      notification.success({ message: 'Cập nhật thành công' })
    },
    onError: () => notification.error({ message: 'Cập nhật thất bại' }),
  });

  render() {
    const {
      productUnits,
      isUnitFetching,
      deletedUnit
    } = this.props
    return (
      <Row gutter={24}>
        <Col span={4} />
        <Col span={24}>
          <SelfList
            isFetching={isUnitFetching}
            dataSource={productUnits}
            deleteStatus={deletedUnit}
            onSubmit={this.handleAddProductUnit}
            onDelete={this.handleDeleteProductUnit}
            onUpdate={this.handleUpdateProductUnit}
            placeholder="Đơn vị tính"
            title="Đơn vị tính"
          />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => ({
  productUnits: select(state, ['generalReducer', 'productUnit'], 'items'),
  vehicleTypes: select(state, ['generalReducer', 'vehicleType'], 'items'),
  productConditions: select(state, ['generalReducer', 'productCondition'], 'items'),
  isUnitFetching: select(state, ['generalReducer', 'productUnit'], 'isFetching'),
  isTypeFetching: select(state, ['generalReducer', 'vehicleType'], 'isFetching'),
  isConditionFetching: select(state, ['generalReducer', 'productCondition'], 'isFetching'),
})

const mapDispatchToProps = dispatch => ({
  getProductUnits: params => dispatch(getProductUnits(params)),
  insertProductUnit: (payload, meta) => dispatch(insertProductUnit(payload, meta)),
  deleteProductUnit: (id, meta) => dispatch(deleteProductUnit(id, meta)),
  updateProductUnit: (id, payload, meta) => dispatch(updateProductUnit(id, payload, meta)),
})


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithLoadingHOC(Config('item', 'general')(GeneralPage)))
