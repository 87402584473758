/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'
import { findIndex } from '../../util/findIndex'

const listState = {
  items: [],
  itemsForReview: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const list = (state = listState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_PRODUCTS:
  case TYPES.GETTING_ALL_PRODUCTS:
    draft.isFetching = true
    break

  case TYPES.GET_PRODUCTS_SUCCESS: {
    const { data, metaData } = action.payload
    draft.items = data
    draft.meta = {
      current: metaData.page,
      pageSize: metaData.pageSize,
      total: metaData.total,
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_ALL_PRODUCTS_SUCCESS: {
    draft.isFetching = false
    draft.itemsForReview = action.payload.data
    break
  }

  case TYPES.GET_ALL_PRODUCTS_FAILURE:
  case TYPES.GET_PRODUCTS_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  case TYPES.CHANGE_PRODUCT_STATUS: {
    const { id, value } = action
    const index = findIndex(draft.items, id)
    draft.items[index].status = !value
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

const detailState = {
  item: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const detail = (state = detailState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_PRODUCT:
  case TYPES.INSERTING_PRODUCT:
  case TYPES.UPDATING_PRODUCT:
  case TYPES.DELETING_PRODUCT:
    draft.isFetching = true
    break

  case TYPES.GET_PRODUCT_SUCCESS: {
    draft.item = action.payload.data
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INSERT_PRODUCT_SUCCESS:
  case TYPES.UPDATE_PRODUCT_SUCCESS:
  case TYPES.DELETE_PRODUCT_SUCCESS:
  case TYPES.GET_PRODUCT_FAILURE:
  case TYPES.INSERT_PRODUCT_FAILURE:
  case TYPES.UPDATE_PRODUCT_FAILURE:
  case TYPES.DELETE_PRODUCT_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  default:
    return draft
  }
})

const requestState = {
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const request = (state = requestState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_REQUESTS:
    draft.isFetching = true
    break

  case TYPES.GET_REQUESTS_SUCCESS: {
    draft.items = action.payload
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_REQUESTS_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  default:
    return draft
  }
})

export default combineReducers({ list, detail, request })
