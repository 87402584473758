import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'
import select from '../../util/select'

const gettingCarts = () => ({ type: TYPES.GETTING_CARTS })
const getCartsSuccess = payload => ({
  type: TYPES.GET_CARTS_SUCCESS,
  payload,
})
const getCartsFailure = () => ({ type: TYPES.GET_CARTS_FAILURE })

const gettingCart = () => ({ type: TYPES.GETTING_CART })
const getCartSuccess = payload => ({
  type: TYPES.GET_CART_SUCCESS,
  payload,
})
const getCartFailure = () => ({ type: TYPES.GET_CART_FAILURE })

const insertingCart = () => ({ type: TYPES.INSERTING_CART })
const insertCartSuccess = () => ({ type: TYPES.INSERT_CART_SUCCESS })
const insertCartFailure = () => ({ type: TYPES.INSERT_CART_FAILURE })

const updatingCart = () => ({ type: TYPES.UPDATING_CART })
const updateCartSuccess = () => ({ type: TYPES.UPDATE_CART_SUCCESS })
const updateCartFailure = () => ({ type: TYPES.UPDATE_CART_FAILURE })

const deletingCart = () => ({ type: TYPES.DELETING_CART })
const deleteCartSuccess = () => ({ type: TYPES.DELETE_CART_SUCCESS })
const deleteCartFailure = () => ({ type: TYPES.DELETE_CART_FAILURE })

export const getCarts = params => async dispatch => {
  const api = API.CART.getCarts()
  dispatch(gettingCarts())
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getCartsSuccess(response.data))
  } else {
    dispatch(getCartsFailure())
  }
}
export const getCart = code => async dispatch => {
  const api = API.CART.getCart(code)
  dispatch(gettingCart())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getCartSuccess(response.data.data))
  } else {
    dispatch(getCartFailure())
  }
}

export const insertCart = (payload, meta) => async dispatch => {
  const api = API.CART.insertCart()
  dispatch(insertingCart())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertCartSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(insertCartFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const importCarts = (payload, meta) => async dispatch => {
  const api = API.CART.importFromExcel()
  dispatch(insertingCart())
  const { response, error } = await apiCall({
    ...api,
    payload,
  })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertCartSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(insertCartFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const updateCart = (id, payload, meta) => async dispatch => {
  const api = API.CART.updateCart(id)
  dispatch(updatingCart())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    dispatch(updateCartSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(updateCartFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

const actChangeStatus = (id, payload) => ({
  type: TYPES.CHANGE_CART_STATUS_SUCCESS,
  id,
  payload
})

export const changeCartStatus = (id, payload, meta) => async dispatch => {
  const api = API.CART.changeStatusCart(id)
  dispatch(updatingCart())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    if (meta && meta.onSuccess) {
      dispatch(actChangeStatus(id, payload))
      meta.onSuccess()
    }
  } else {
    dispatch(updateCartFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const deleteCart = (id, meta) => async dispatch => {
  const api = API.CART.deleteCart(id)
  dispatch(deletingCart())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(deleteCartSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(deleteCartFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const importPriceTable = (params, payload, meta) => async dispatch => {
  const api = API.CART_GROUP.importPriceTable()
  dispatch({ type: TYPES.IMPORTING_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, params, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.IMPORT_PRICE_TABLE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch({ type: TYPES.IMPORT_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const getPriceTable = (code, params) => async dispatch => {
  const api = API.CART_GROUP.getPriceTable(code)
  dispatch({ type: TYPES.GETTING_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    const { Data, Page, PageSize, Total } = response.data
    dispatch({
      type: TYPES.GET_PRICE_TABLE_SUCCESS,
      payload: { data: Data, meta: { current: Page, pageSize: PageSize, total: Total } },
    })
  } else {
    dispatch({ type: TYPES.GET_PRICE_TABLE_FAILURE })
  }
}

export const getPriceTableIfNeed = (code, params) => (dispatch, getState) => {
  const state = getState()
  const isFetching = select(state, ['cartReducer', 'priceTable'], 'isFetching')
  const didInvalidate = select(state, ['cartReducer', 'priceTable'], 'didInvalidate')
  if (!isFetching && didInvalidate) {
    dispatch(getPriceTable(code, params))
  }
}

export const addItemToPriceTable = (id, payload, meta) => async dispatch => {
  const api = API.CART_GROUP.addItemToPriceTable(id)
  dispatch({ type: TYPES.ADDING_ITEM_TO_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.ADD_ITEM_TO_PRICE_TABLE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.ADD_ITEM_TO_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const removeItemFromPriceTable = (id, payload, meta) => async dispatch => {
  const api = API.CART_GROUP.removeItemFromPriceTable(id)
  dispatch({ type: TYPES.REMOVING_ITEM_FROM_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.REMOVE_ITEM_FROM_PRICE_TABLE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.REMOVE_ITEM_FROM_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const updateItemInPriceTable = (id, payload, meta) => async dispatch => {
  const api = API.CART_GROUP.updateItemInPriceTable(id)
  dispatch({ type: TYPES.UPDATING_ITEM_IN_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_ITEM_IN_PRICE_TABLE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.UPDATE_ITEM_IN_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}
