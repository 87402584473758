/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, notification, Radio, Divider, Button } from 'antd';

import * as XLSX from 'xlsx';
import WithLoadingHOC from '../../hoc/loading';
import WithPageHOC from '../../hoc/page';
import select from '../../util/select';
import { insertProduct, importProducts } from './actions';
import { getProductUnits, getProductConditions } from '../General/actions';
import { getCategories } from '../Category/actions';
import { getSuppliers } from '../Supplier/actions';
import ProductForm from './components/Form';
import ROUTER from '../../constants/router';
import { isBelowBreakpoint } from '../../util/windows';

class AddProductPage extends React.Component {
  state = {
    isBatch: false,
    Products: [],
  };

  componentDidMount() {
    this.props.getProductUnits();
    this.props.getProductConditions();
    this.props.getProductCategories();
    this.props.getProductSuppliers();
  }

  onChangeInsertType = ({ target: { value } }) => this.setState(() => ({ isBatch: value }));

  onImportExcel = event => {
    const { files } = event.target;
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(files[0]);
    }
  };

  importExcel = file => {
    const fileReader = new FileReader();

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let startAt = 0;
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const Sheet = workbook.Sheets[workbook.SheetNames[0]];
        let data = XLSX.utils.sheet_to_json(Sheet);
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`];
          if (flagCell !== undefined) {
            startAt = i;
            // 1
          }
        }

        if (startAt === 0) {
          notification.error({ message: 'Thêm mới file excel thất bại!', duration: 1 });
        } else {
          const arrayTotal = [];
          data = XLSX.utils.sheet_to_json(Sheet, { raw: true });
          notification.success({ message: 'Tải File thành công', duration: 1 });

          for (let i = 0; i < data.length; i++) {
            // const array = []
            // const arrayUn = []
            const string = data[i];
            if (string['Mặt hàng'] && string['Tên mặt hàng'] && string['ĐVT'] && string['NHÓM']) {
              arrayTotal.push({
                categoryName: string['NHÓM'].trim(),
                productCode: string['Mặt hàng'].trim(),
                productName: string['Tên mặt hàng'].trim(),
                unitName: string['ĐVT'].trim(),
                weightPerUnit: string['Quy đổi ra kg'] ? string['Quy đổi ra kg'] : undefined,
              });
            }
          }
          this.setState({ Products: arrayTotal });
        }
      } catch (e) {
        notification.error({ message: e, duration: 1 });
      }
    };
    fileReader.readAsBinaryString(file);
  };

  addProduct = () => {
    const products = this.state.Products;
    if (products.length === 0) {
      notification.error({ message: 'Bạn chưa chọn file' });
    } else {
      this.props.importProducts(this.state.Products, {
        onSuccess: total => {
          notification.success({ message: `Thêm thành công. ${total} ` });
          this.props.history.push(ROUTER.PRODUCT.INDEX);
        },
        onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
      });
    }
  };

  // handleUpload = async ({ current: { files } }) => {
  //   const formData = new FormData()
  //   formData.append('fileName', files[0])
  //   this.props.importProducts(formData, {
  //     onSuccess: () => {
  //       notification.success({ message: 'Thêm thành công' })
  //       this.props.history.push(ROUTER.PRODUCT.INDEX)
  //     },
  //     onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
  //   })
  // }

  handleSubmit = payload => {
    this.props.insertProduct(payload, {
      onSuccess: newProd => {
        notification.success({ message: 'Thêm mặt hàng thành công' });
        if (newProd) {
          this.props.history.push(ROUTER.PRODUCT.EDIT.replace(':id', newProd.code));
        } else {
          this.props.history.push(ROUTER.PRODUCT.INDEX);
        }
      },
      onError: error => notification.error({ message: `${error} - Thêm mặt hàng thất bại` }),
    });
  };

  render() {
    const { units, conditions, categories, suppliers } = this.props;
    const { isBatch } = this.state;

    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={14}>
            <Radio.Group value={isBatch} buttonStyle="solid" onChange={this.onChangeInsertType}>
              <Radio.Button value={false}>Thêm một mặt hàng</Radio.Button>
              <Radio.Button value style={{ marginTop: isBelowBreakpoint() ? 5 : 0 }}>
                Thêm nhiều mặt hàng
              </Radio.Button>
            </Radio.Group>
            <Divider dashed />
            <Card>
              <h1>Thêm hàng hóa mới</h1>
              <hr />
              {isBatch ? (
                <Fragment>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1E02hizUOLQQBwg7MgIfYwLZcEUslxF0w8-wR5jHmCm4/edit?usp=sharing"
                  >
                    Mẫu file excel
                  </a>
                  <br />
                  <br />
                  <input
                    id="fileUpload"
                    type="file"
                    ref={v => {
                      this.fileUpload = v;
                    }}
                    accept=".xlsx, .xls"
                    onChange={this.onImportExcel}
                  />
                  <Button onClick={this.addProduct}>Thêm hàng hóa từ File</Button>
                </Fragment>
              ) : (
                // <UploadForm
                //   isFetching={isFetching}
                //   contentLoading="Đang xử lí dữ liệu"
                //   supportContent={(
                //     <a
                //       target="_blank"
                //       rel="noopener noreferrer"
                //       href="https://docs.google.com/spreadsheets/d/1E02hizUOLQQBwg7MgIfYwLZcEUslxF0w8-wR5jHmCm4/edit?usp=sharing"
                //     >
                //     Mẫu file excel
                //     </a>
                //   )}
                //   onUpload={this.handleUpload}
                // />
                <ProductForm
                  units={units}
                  conditions={conditions}
                  onSubmit={this.handleSubmit}
                  categories={categories}
                  suppliers={suppliers}
                />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  units: select(state, ['generalReducer', 'productUnit'], 'items'),
  conditions: select(state, ['generalReducer', 'productCondition'], 'items'),
  isFetching: select(state, ['productReducer', 'detail'], 'isFetching'),
  categories: select(state, ['categoryReducer', 'list'], 'items'),
  suppliers: select(state, ['supplierReducer', 'list'], 'items'),
});

const mapDispatchToProps = dispatch => ({
  insertProduct: (payload, meta) => dispatch(insertProduct(payload, meta)),
  importProducts: (payload, meta) => dispatch(importProducts(payload, meta)),
  getProductUnits: () => dispatch(getProductUnits()),
  getProductConditions: () => dispatch(getProductConditions()),
  getProductCategories: () => dispatch(getCategories()),
  getProductSuppliers: () => dispatch(getSuppliers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithLoadingHOC(WithPageHOC('product', 'data')(AddProductPage)));
