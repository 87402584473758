import React, { Component } from 'react'
import { Card, Col, Button } from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import router from '../../../../constants/router'
import InfoField from './InfoField'

class CustomerCard extends Component {
  openHistory = () => {
    this.props.onHistory()
  }

  render() {
    const { invoice } = this.props
    return (
      <div>
        <Col span={12}>
          <Card
            title="Thông tin khách hàng"
            extra={(
              <Button onClick={this.openHistory}>Lịch sử đặt hàng</Button>
            )}
          >
            <InfoField
              title="Mã khách hàng"
              content={(
                <Link to={router.CUSTOMER.EDIT.replace(':id', invoice.customerCode)}>
                  {invoice.customerCode}
                </Link>
              )}
            />
            <InfoField title="Tên khách hàng" content={invoice.customerName} />
            <InfoField title="Mã giỏ hàng" content={invoice.cartCode} />
            <InfoField title="Tên giỏ hàng" content={invoice.cartName} />
            <InfoField title="Địa chỉ" content={invoice.address} />
          </Card>
        </Col>
      </div>
    )
  }
}

CustomerCard.propTypes = { invoice: PropTypes.object, onHistory: PropTypes.func }
CustomerCard.defaultProps = { invoice: {}, onHistory: () => {} }

export default (CustomerCard)
