/* eslint-disable react/no-danger */
import React from 'react';
import { Row, Col, Card, Descriptions, Divider, Typography } from 'antd';
// import { QRCodeCanvas } from 'qrcode.react';
// import Lightbox from 'yet-another-react-lightbox';
// import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import moment from 'moment';
import { apiCall } from '../../util/apiCall';
import { API } from '../../constants/api';
// import WithLoadingHOC from '../../hoc/loading';

class ClientProductPage extends React.Component {
  state = {
    product: undefined,
    supplier: undefined,
    lightboxOpened: false,
    photoIndex: 0,
    manufacturingDate: null,
    // isFetching: false,
  };

  componentDidMount() {
    const paramCode = this.props.match.params.code;
    let productCode = '';
    if (paramCode && paramCode.includes('-')) {
      const params = paramCode.split('-');
      productCode = params[0];
      const manufacturingDate = params[1] ? moment(Number(params[1] * 1000)) : null;
      this.setState({ manufacturingDate });
    } else {
      productCode = paramCode;
    }

    if (productCode) {
      this.getProduct(productCode);
    }
  }

  getProduct = async productCode => {
    const clientAPI = API.PRODUCT.getClientProduct(productCode);
    const { response, error } = await apiCall({
      ...clientAPI,
    });
    if (!error && response.status === 200 && response.data.data) {
      this.setState({ product: response.data.data });
      if (response.data.data.supplierCode) {
        this.getSupplier(response.data.data.supplierCode);
      }
    } else {
      this.setState({ product: null });
    }
  };

  getSupplier = async supplierCode => {
    const clientAPI = API.SUPPLIER.getClientSupplier(supplierCode);
    const { response, error } = await apiCall({
      ...clientAPI,
    });
    if (!error && response.status === 200 && response.data.data) {
      this.setState({ supplier: response.data.data });
    } else {
      this.setState({ supplier: null });
    }
  };

  openLightbox = idx => {
    this.setState({ lightboxOpened: true, photoIndex: idx });
  };

  closeLightbox = () => {
    this.setState({ lightboxOpened: false, photoIndex: 0 });
  };

  render() {
    const { product, manufacturingDate, supplier, lightboxOpened, photoIndex } = this.state;
    if (product === null) {
      return (
        <Row>
          <Col span={24}>
            <Card style={{ maxWidth: 800, margin: '0 auto', padding: 20 }}>
              <h4>Không tìm thấy sản phẩm!</h4>
            </Card>
          </Col>
        </Row>
      );
    }

    if (!product) {
      return (
        <Row>
          <Col span={24}>
            <Card style={{ maxWidth: 800, margin: '0 auto', padding: 20 }}>
              {/* <h4>Không tìm thấy sản phẩm!</h4> */}
            </Card>
          </Col>
        </Row>
      );
    }

    let criteria = product.criteria || [];
    if (supplier && supplier.criteria) {
      criteria = criteria.concat(supplier.criteria);
    }

    return (
      <div style={{ maxWidth: 1200, margin: '0 auto', padding: '20px 15px' }}>
        <h1>{product.name}</h1>
        <Divider />
        {/* <Col span={24} md={6} lg={8}>
            <div
              className="qrCodeWrapper"
              style={{ width: 150, maxWidth: '100%', padding: 15, margin: '0 auto', textAlign: 'center' }}
            >
              <QRCodeCanvas
                size={512}
                style={{ height: 'auto', width: '100%', maxWidth: '100%', marginBottom: 20 }}
                value={`${window.location.origin}/qr/${product.code}`}
                id={`qr-image-${product.code}`}
              />
            </div>
          </Col> */}
        <Row gutter={30}>
          {product.images?.length ? (
            <Col span={24} lg={10}>
              <img alt={product.name} src={product.images[0]} style={{ maxWidth: '100%', marginBottom: 10 }} />
            </Col>
          ) : null}
          <Col span={24} lg={product.images?.length ? 14 : 24}>
            <Descriptions title="Thông tin nhà cung cấp" column={1}>
              <Descriptions.Item label="Nhà cung cấp">{product.supplierName || 'An Việt Food'}</Descriptions.Item>
              <Descriptions.Item label="Địa chỉ">{supplier?.address || ''}</Descriptions.Item>
              <Descriptions.Item label="Email">
                {supplier?.email ? <a href={`mailto:${supplier.email}`}>{supplier.email}</a> : null}
              </Descriptions.Item>
              <Descriptions.Item label="Điện thoại">
                {supplier?.phone ? <a href={`tel:${supplier.phone}`}>{supplier.phone}</a> : null}
              </Descriptions.Item>
              <Descriptions.Item label="Website">
                {supplier?.website ? <a href={supplier.website}>{supplier.website}</a> : null}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <div>
          <Divider />
          <Typography.Title level={3}>Thông tin chi tiết</Typography.Title>
          {/* <Tabs>
            <Tabs.TabPane key="info" tab="Thông tin chi tiết"> */}
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ color: '#ff0000' }}>
              Công ty cổ phần sản xuất và thương mại An Việt cảm ơn quý khách đã sử dụng sản phẩm của chúng tôi!
            </h3>
            <img
              alt="An Việt Food"
              src="/images/avf-logo.png"
              style={{ maxWidth: '50%', width: 300, marginBottom: 20 }}
            />
          </div>
          <Descriptions column={1}>
            <Descriptions.Item label="Tên sản phẩm">{product.name}</Descriptions.Item>
            <Descriptions.Item label="Quy cách đóng gói">{product.unitName}</Descriptions.Item>
            {manufacturingDate && (
              <Descriptions.Item label="Ngày sản xuất">{manufacturingDate.format('DD/MM/YYYY')}</Descriptions.Item>
            )}
          </Descriptions>
          <p dangerouslySetInnerHTML={{ __html: product.description }} />
          {/* </Tabs.TabPane>
            <Tabs.TabPane key="criteria" tab="Cơ sở pháp lý"> */}
          <Divider />
          <Typography.Title level={3}>Cơ sở pháp lý</Typography.Title>
          {criteria.length
            ? criteria.map((c, idx) => (
                <div
                  onClick={() => this.openLightbox(idx)}
                  key={c.image_url}
                  style={{
                    display: 'inline-block',
                    width: 200,
                    textAlign: 'center',
                    cursor: 'pointer',
                    margin: '10px 15px 5px 0',
                  }}
                >
                  <img alt={c.name} src={c.image_url} style={{ maxWidth: '100%', marginBottom: 10 }} />
                  <h4>{c.name}</h4>
                </div>
              ))
            : null}
          {/* </Tabs.TabPane>
          </Tabs> */}
        </div>
        {lightboxOpened ? (
          <Lightbox
            onCloseRequest={this.closeLightbox}
            mainSrc={criteria[photoIndex].image_url}
            imageCaption={criteria[photoIndex].name}
            nextSrc={criteria[(photoIndex + 1) % criteria.length].image_url}
            prevSrc={criteria[(photoIndex + criteria.length - 1) % criteria.length].image_url}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + criteria.length - 1) % criteria.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % criteria.length,
              })
            }
          />
        ) : null}
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .ant-descriptions-item-label {
              width: 140px;
            }
            .ant-descriptions-item-content {
              color: rgba(0, 0, 0, 0.65);
              font-weight: bold;
            }
            .ant-descriptions-item-content a {
              font-weight: normal;
            }
          `,
          }}
        />
      </div>
    );
  }
}

export default ClientProductPage;
