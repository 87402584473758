/* eslint-disable no-unused-vars */
/* global google */
import React from 'react'

// const _ = require('lodash');
const { compose, withProps, lifecycle, setStatic } = require('recompose')
const { withScriptjs, withGoogleMap, GoogleMap, Marker } = require('react-google-maps')
const { SearchBox } = require('react-google-maps/lib/components/places/SearchBox')

const MapWithASearchBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDjbPIRyl83aFEuWTGUkmat0Z8somKmHVA&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '400px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const center = nextProps.center
      const { propCenter } = this.state
      if (center && (!propCenter || center.lat !== propCenter.lat || center.lng !== propCenter.lng)) {
        this.setState({
          propCenter: center,
          center,
        })
      }
    },
    componentWillMount() {
      const props = this.props
      const state = this.state
      const refs = {}
      const propCenter = props.center || {
        lat: 21.0456933,
        lng: 105.7828644,
      }

      this.setState({
        bounds: null,
        propCenter,
        center: propCenter,
        // markers: [{ position: propCenter }],
        onMapMounted: ref => {
          refs.map = ref
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref
        },
        onPlacesChanged: () => {
          // const { center } = this.state;
          const places = refs.searchBox.getPlaces()
          const bounds = new google.maps.LatLngBounds()
          const selectedPlace = places[0]

          if (selectedPlace.geometry.viewport) {
            bounds.union(selectedPlace.geometry.viewport)
          } else {
            bounds.extend(selectedPlace.geometry.location)
          }
          const nextCenter = selectedPlace.geometry.location

          this.setState({ center: nextCenter })
          props.onSearch(nextCenter.toJSON(), selectedPlace.address_components)
          // refs.map.fitBounds(bounds);
        },
        onDragEnd: newPosition => {
          props.onSearch(newPosition.latLng.toJSON())
        },
      })
    },
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  <GoogleMap ref={props.onMapMounted} defaultZoom={15} center={props.center} onBoundsChanged={props.onBoundsChanged}>
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Gõ địa chỉ Giỏ hàng"
        style={{
          boxSizing: 'border-box',
          border: '1px solid transparent',
          width: '350px',
          height: '40px',
          marginTop: '10px',
          padding: '0 12px',
          borderRadius: '3px',
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
          fontSize: '16px',
          outline: 'none',
          textOverflow: 'ellipses',
        }}
      />
    </SearchBox>
    {props.center ? (
      <Marker
        position={props.center}
        draggable
        clickable
        onDragEnd={props.onDragEnd}
      />
    ) : null}
  </GoogleMap>
))

export default MapWithASearchBox
