/* eslint-disable prefer-const */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
/* eslint-disable no-unreachable */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Row, Col, notification, message, Button, Divider } from 'antd'
import Hotkeys from 'react-hot-keys'
import moment from 'moment'
import select from '../../util/select'
import {
  getProducts,
  addItem,
  insertInvoice,
  importInvoices,
  getCustomer,
  clearData,
  getPriceTable
} from './actions'
import { getCustomers } from '../Customer/actions'
import OrderForm from './components/OrderForm'
import CustomerInfo from './components/CustomerInfo'
import router from '../../constants/router'
import { getAccounts } from '../General/actions'

class OrderPage extends Component {
  state = { dateRequireqTime: '', orderDate: '' };

  componentDidMount = () => {
    this.props.clearData()
    this.props.getCustomers(this.props.user.role === 'sale' ? { pageSize: -1, saleInChargeId: this.props.user.id } : { pageSize: -1 })
    this.props.getAccounts({ pageSize: -1 })
  };

  onChangeCustomer = code => {
    this.props.getCustomer(code)
  }

  onChangeQuantity = (value, id) => {
    const order = { ...this.props.order }
    const newValue = Number(value)

    order.Items = order.Items.map(item => {
      if (item.priceItemId === id) {
        return {
          ...item,
          quantity: newValue,
          totalPrice: item.price * newValue
        }
      }
      return item
    })
    this.props.addItem(order)
  };

  onAddItems = payload => {
    let { Items } = this.props.order
    let totalArray = [...Items]
    if (totalArray.length === 0) {
      totalArray = payload
    } else {
      for (let i = 0; i < payload.length; i++) {
        const idx = totalArray.findIndex(e => e.priceItemId === payload[i].priceItemId)
        if (idx >= 0) {
          totalArray[idx] = payload[i]
        } else {
          totalArray.push({
            price: payload[i].price,
            priceItemId: payload[i].priceItemId,
            productName: payload[i].productName,
            quantity: Number(payload[i].quantity),
            totalPrice: payload[i].totalPrice,
            unitId: payload[i].unitId,
            unitName: payload[i].unitName
          })
        }
      }
    }

    this.props.addItem({ Items: totalArray })
  }

  onAddItem = (priceItemId, Quantity) => {
    const order = { ...this.props.order }
    const { products } = this.props
    let isExist = false
    if (order.Items.find(item => item.priceItemId === priceItemId)) {
      order.Items = order.Items.map(item => {
        if (item.priceItemId === priceItemId) {
          isExist = true
          message.warn('Sản phẩm đã có trong đơn hàng')
        }
        return item
      })
    }
    if (!isExist) {
      const product = products.find(item => item.priceItemId === priceItemId)
      order.Items = order.Items.concat({
        priceItemId: product.priceItemId,
        productName: product.productName,
        quantity: Number(Quantity),
        unitId: product.unitId,
        unitName: product.unitName,
        price: product.pricePerUnit,
        totalPrice: product.pricePerUnit * Number(Quantity),
      })
      this.props.addItem(order)
    }
  }

  onRemoveItem = id => {
    const order = { ...this.props.order }
    order.Items = order.Items.filter(item => item.priceItemId !== id)
    this.props.addItem(order)
  };

  onChangeRequireTime = value => {
    this.setState({ dateRequireqTime: moment(value).utc().format() }) // .replace('+07:00', 'Z') })
  }

  onChangeorderDate = value => {
    this.setState({ orderDate: moment(value).utc().format() })
  }

  handleSubmit = () => {
    const customerId = this.props.customer.id
    const { order } = this.props
    const { dateRequireqTime, orderDate } = this.state
    const orderItemPayload = order.Items.map(item => ({
      priceItemId: item.priceItemId,
      quantity: Number(item.quantity)
    }))
    if (orderItemPayload.length) {
      // if(array.some(e => { return (e.tuoi === 20) }))
      if (orderItemPayload.some(e => (e.quantity === 0))) {
        message.warning('Có mặt hàng có số lượng bằng 0')
      } else {
        this.props.insertInvoice(
          {
            customerId,
            orderItemPayload,
            requireTimeFrom: dateRequireqTime || orderDate,
            requireTimeTo: dateRequireqTime || orderDate,
            orderDate,
          },
          {
            onSuccess: () => {
              notification.success({ message: 'Đơn hàng đã được tiếp nhận' })
              this.props.history.push(router.INVOICE.INDEX)
              this.props.addItem({ Items: [] })
            },
            onError: () => notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại !' }),
          },
        )
      }
    } else {
      message.warning('Giỏ hàng trống')
    }
  };

  getRequireTimeTo = dateRequireqTimeTo => {
    this.setState({ orderDate: dateRequireqTimeTo })
  }

  handleUpload = async ({ current: { files } }, date) => {
    const formData = new FormData()
    formData.append('file', files[0])
    this.props.importInvoices(
      {
        payload: formData,
        params: { date: moment(date).toISOString() },
      },
      {
        onSuccess: data => {
          notification.success({
            message: 'Chi tiết',
            description: (
              <div>
                <div>{`${data.Success && data.Success.length} đơn hàng được thêm`}</div>
                <div>{`${data.CustomerFail.length} mã khách hàng lỗi: ${data.CustomerFail.join(' , ')}`}</div>
                <div>{`${data.ProductFail.length} mã sản phẩm lỗi: ${data.ProductFail.join(' , ')}`}</div>
              </div>
            ),
            duration: 15,
          })
          this.props.history.push(router.INVOICE.INDEX)
          this.props.getInvoices({
            page: 1,
            page_size: 10,
          })
        },
        onError: error => {
          notification.error({ message: `${error} - Thêm thất bại ! Vui lòng thử lại !` })
        },
      },
    )
  };

  onChangePage = (page, pageSize) => {
    this.props.getPriceTable(
      this.props.priceTableId,
      {
        page,
        pageSize,
      }
    )
  };

  onKeyDownSendOrder = (keyName, e, handle) => {
    if (handle.key === 'a+l' && this.props.customer.cartCode !== undefined) {
      this.handleSubmit()
    }
  }

  render() {
    const { editMode, order, customers, isFetching, customer, addItem, products } = this.props

    return (
      <div>
        <React.Fragment>
          <h2>Thêm đơn hàng mới</h2>
          <Row gutter={24}>
            <Col span={24}>
              <CustomerInfo
                customers={customers}
                onChangeCustomer={this.onChangeCustomer}
                customer={customer}
                onGetProducts={this.props.getProducts}
                onGetPriceTable={this.props.getPriceTable}
                onAddItem={addItem}
                onChangeRequireTime={this.onChangeRequireTime}
                onGetRequireTimeTo={this.getRequireTimeTo}
                onChangeorderDate={this.onChangeorderDate}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ marginTop: 10 }}>
              <OrderForm
                products={products}
                customer={customer}
                editMode={editMode}
                order={order}
                disabled={isFetching}
                onAddItem={this.onAddItem}
                onAddItems={this.onAddItems}
                onRemoveItem={this.onRemoveItem}
                onFilterProduct={this.onFilterProduct}
                onChangeQuantity={this.onChangeQuantity}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Divider />
            <Hotkeys
              keyName="a+l"
              filter={() => true}
              onKeyDown={this.onKeyDownSendOrder}
            >
              <Col>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={this.handleSubmit}
                  disabled={customer.cartCode === undefined}
                >
                  {'Gửi đơn hàng'}
                </Button>
              </Col>
            </Hotkeys>
          </Row>
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customers: select(state, ['customerReducer', 'listAll'], 'items'),
  customer: select(state, ['orderReducer', 'customer'], 'customer'),
  products: select(state, ['orderReducer', 'product'], 'items'),
  meta: select(state, ['orderReducer', 'product'], 'meta'),
  priceTableId: select(state, ['orderReducer', 'product'], 'priceTableId'),
  order: select(state, ['orderReducer', 'order'], 'item'),
  isFetching: select(state, ['orderReducer', 'product'], 'isFetching'),
  user: select(state, 'authReducer', 'user'),
})

const mapDispatchToProps = dispatch => ({
  getProducts: (params, meta) => dispatch(getProducts(params, meta)),
  getPriceTable: (id, params) => dispatch(getPriceTable(id, params)),
  getCustomers: params => dispatch(getCustomers(params)),
  getCustomer: code => dispatch(getCustomer(code)),
  addItem: payload => dispatch(addItem(payload)),
  insertInvoice: (payload, meta) => dispatch(insertInvoice(payload, meta)),
  importInvoices: (payload, meta) => dispatch(importInvoices(payload, meta)),
  clearData: () => dispatch(clearData()),
  getAccounts: params => dispatch(getAccounts(params)),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((withRouter(OrderPage)))
