/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notification } from 'antd'

import { getSynData, synFast, Configurations, GetAllConfiguration } from './actions'
import select from '../../util/select'
import ButtonSynData from './components/ButtonSynData'
import SynFilter from './components/Filter'
import SynDataTable from './components/DataTable'

class SynDataPage extends Component {
  state = { filterOptions: {} }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location
    let params
    if (search !== state.search) {
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
        props.getSynData(JSON.parse(params))
      } else {
        props.getSynData()
      }
      return { ...state, search, filterOptions: params }
    }
    return state
  }

  componentDidMount() {
    this.props.GetAllConfiguration()
  }

  Configurations = value => {
    const values = {
      attribute: value,
      name: 'TimeSync'
    }
    this.props.Configurations(values, {
      onSuccess: () => {
        notification.success({ message: 'Lưu giờ đồng bộ thành công' })
      },
      onError: errorCode => {
        notification.error({ message: `Lưu giờ đồng bộ thất bại. ${errorCode}` })
      },
    })
  }


  render() {
    const { history, listItemFast, meta, itemTime } = this.props
    // console.log(meta)
    // console.log(history)
    return (
      <>
        <ButtonSynData Config={this.Configurations} defaultTime={itemTime} />
        <hr />
        <SynFilter history={history} />
        <br />
        {
          listItemFast && listItemFast.length
            ? <SynDataTable dataSource={listItemFast} meta={meta} />
            : ''
        }

      </>
    )
  }
}

const mapStateToProps = state => ({
  listItemFast: select(state, ['synDataReducer', 'synData'], 'listItemFast'),
  itemTime: select(state, ['synDataReducer', 'synData'], 'itemTime'),
  meta: select(state, ['synDataReducer', 'synData'], 'meta'),

})

const mapDispatchToProps = dispatch => ({
  getSynData: params => dispatch(getSynData(params)),
  synFast: (date, meta) => dispatch(synFast(date, meta)),
  Configurations: (payload, meta) => dispatch(Configurations(payload, meta)),
  GetAllConfiguration: () => dispatch(GetAllConfiguration())
})

export default connect(mapStateToProps, mapDispatchToProps)(SynDataPage)
