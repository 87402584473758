/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import appReducer from './Header/reducer';
import authReducer from './Auth/reducer';
import generalReducer from './General/reducer';
import routingReducer from './Schedule/routingReducer';
import scheduleReducer from './Schedule/reducer';
import routeReducer from './Route/reducer';
import reportReducer from './Report/reducer';
import driverReducer from './Driver/reducer';
import invoiceReducer from './Invoice/reducer';
import orderReducer from './Order/reducer';
import vehicleReducer from './Vehicle/reducer';
import customerReducer from './Customer/reducer';
import productReducer from './Product/reducer';
import dashboardReducer from './Dashboard/reducer';
import categoryReducer from './Category/reducer';
import supplierReducer from './Supplier/reducer';
import priceTableReducer from './PriceList/reducer';
import priceReducer from './Price/reducer';
import cartReducer from './Cart/reducer';
import managerReducer from './Manager/reducers';
import synDataReducer from './SynData/reducers';
import stampReducer from './Stamp/reducers';

export default combineReducers({
  authReducer,
  appReducer,
  generalReducer,
  scheduleReducer,
  reportReducer,
  routeReducer,
  routingReducer,
  driverReducer,
  invoiceReducer,
  orderReducer,
  vehicleReducer,
  productReducer,
  dashboardReducer,
  categoryReducer,
  supplierReducer,
  priceTableReducer,
  priceReducer,
  cartReducer,
  customerReducer,
  managerReducer,
  synDataReducer,
  stampReducer,
});
