import React from 'react';
import { Table, Switch } from 'antd';
import { Link } from 'react-router-dom';

import WithLoading from '../../../hoc/loading';
import router from '../../../constants/router';

const columns = (onView, onEdit, onChangeStatus) => [
  {
    title: 'Mã nhà cung cấp',
    dataIndex: 'code',
    render: (value, row) => <Link to={router.SUPPLIER.EDIT.replace(':id', row.code)}>{value}</Link>,
  },
  {
    title: 'Tên nhà cung cấp',
    dataIndex: 'name',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    align: 'center',
    render: (value, record) => (
      <Switch key={record.code} checked={value} onChange={() => onChangeStatus(record.code, value)} />
    ),
  },
];

const SupplierList = ({
  suppliers,
  // meta: { page_size: pageSize, current, total },
  onEdit,
  onView,
  // onChangePage,
  // onChangeSize,
  onChangeStatus,
}) => (
  <Table
    size="small"
    scroll={{ x: 'max-content' }}
    columns={columns(onView, onEdit, onChangeStatus)}
    style={{ marginTop: '10px' }}
    dataSource={suppliers}
    rowKey="code"
    rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
    pagination={{
      // current,
      // total,
      // pageSize,
      // onChange: onChangePage,
      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} NCC`,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40', '80'],
      // onShowSizeChange: onChangeSize,
    }}
  />
);

export default WithLoading(SupplierList);
