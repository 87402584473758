/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint react/prop-types: 0 */
import React from 'react'
import { connect } from 'react-redux'
import { Avatar, Divider, Modal, Layout, Icon, Button, notification } from 'antd'
import { withRouter } from 'react-router'


import UserDropdown from './components/user-dropdown'
// import NotificationDropdown from './components/notification-dropdown'

import select from '../../util/select'
import { logOut, setOpenKey, getNotifications, getNotificationsIfNeed, updateNotificationStatus } from './actions'
import { MAP_ROUTER } from '../../constants/notification'
// import { API } from '../../constants/api'
import ROUTER from '../../constants/router'
import { isBelowBreakpoint } from '../../util/windows'

const { Header } = Layout

class GlobalHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = { eventConsumerID: 0 }
  }
  // componentDidMount() {
  //   // this.props.getNotifications({
  //   //   count: 10,
  //   //   topic: 'admin',
  //   //   noti_group: [2, 3],
  //   // })
  //   const evtSrc = new EventSource('/event?topics=admin')
  //   evtSrc.onmessage = e => {
  //     if (e.data !== 'ping' && e.data !== 'hello') {
  //       const notificationData = JSON.parse(e.data)
  //       notification.open({
  //         message: renderNotification(notificationData),
  //         btn: (
  //           <Button type="primary" onClick={() => this.showDetail(notificationData)}>
  //             {' Xem chi tiết'}
  //           </Button>
  //         ),
  //         placement: 'bottomLeft',
  //       })
  //     }
  //     this.props.getNotifications({
  //       count: 10,
  //       topic: 'admin',
  //       noti_group: [2, 3],
  //     })
  //   }
  // }

  // static getDerivedStateFromProps(props) {
  //   props.getNotificationsIfNeed({
  //     count: 10,
  //     topic: 'admin',
  //     noti_group: [2, 3],
  //   })
  //   return null
  // }

  componentDidMount() {
    const eventSource = new EventSource('/order/sub')

    eventSource.onmessage = e => {
      const { eventConsumerID } = this.state
      const { history, location } = this.props
      const data = (JSON.parse(e.data))
      if (data.consumer_id) {
        if (eventConsumerID === data.consumer_id) {
          const pathName = location?.pathname
          if (!pathName || !pathName.includes('/invoice/add')) {
            notification.success({
              message: data.message || 'Có đơn hàng mới',
              // onClick: () => {
              //   history.push(ROUTER.INVOICE.INDEX)
              // },
              btn: (
                <Button type="primary" onClick={() => history.push(`${ROUTER.INVOICE.INDEX}?v=${data.event_id || Date.now()}`)}>
                  {'Xem'}
                </Button>
              ),
            })
          }
        } else if (!eventConsumerID) {
          this.setState({ eventConsumerID: data.consumer_id })
        }
      }
    }
  }

  showAll = () => {
    this.props.history.push(ROUTER.NOTIFICATION)
  }

  showDetail = noti => {
    this.props.history.push(MAP_ROUTER[noti.TypeCode](noti.InvoiceID))
    this.markRead(noti.ID)
  }

  markRead = id => {
    this.props.updateNotificationStatus(id, { Read: true })
  }

  onChangeProfile = () => {
    this.props.history.push(ROUTER.ACCOUNT.PASSWORD)
  }

  onChangeNotiType = key => {
    switch (key) {
    case 'invoice': {
      this.props.getNotifications({
        count: 10,
        topic: 'admin',
        noti_group: [2, 3],
      })
      break
    }
    case 'route': {
      this.props.getNotifications({
        count: 10,
        topic: 'admin',
        noti_group: [1],
      })
      break
    }
    case 'request': {
      this.props.getNotifications({
        count: 10,
        topic: 'admin',
        noti_group: [4, 5],
      })
      break
    }
    default:
      this.props.getNotifications({
        count: 10,
        topic: 'admin',
        noti_group: [2, 3],
      })
    }
  }

  logOut = () => {
    Modal.confirm({
      title: 'Bạn chắc chắn đăng xuất ?',
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        this.props.logOut()
      },
      onCancel() {
      },
    })
  }

  render() {
    const { user, notifications, title, history } = this.props
    return (
      <Header style={{
        background: '#fff',
        padding: 0,
        boxShadow: '0 1px 4px rgba(0, 21, 41, 0.08)',
      }}
      >
        {history.length > 2 && (
          <div style={{ float: 'left', marginLeft: '30px' }}>
            <a onClick={history.goBack}><Icon type="arrow-left" style={{ fontWeight: 'bold', fontSize: '18px' }} /></a>
          </div>
        )}

        <span style={{
          paddingLeft: '30px',
          fontSize: '24px',
          textOverflow: 'ellipsis',
          maxWidth: '500px',
          overflowY: 'visible',
          overflowX: 'hidden',
          display: ' inline-block',
          whiteSpace: 'nowrap'
        }}
        >
          {title}
        </span>

        <div
          style={{ float: 'right' }}
        >
          {/* <NotificationDropdown
            notifications={notifications}
            showAll={this.showAll}
            showDetail={this.showDetail}
            onChangeNotiType={this.onChangeNotiType}
          />
          <Divider type="vertical" /> */}
          {isBelowBreakpoint() ? null
            : (
              <span style={{ marginRight: 10 }}>
                {'Xin chào, '}
                <b>{user.name}</b>
            !
              </span>
            )}
          <UserDropdown onClick={this.onChangeProfile} logOut={this.logOut}>
            <Avatar
              src="/images/default_avatar.png"
            />
          </UserDropdown>
          <Divider type="vertical" />
        </div>
      </Header>
    )
  }
}

const mapStateToProps = state => ({
  currentPage: select(state, ['appReducer', 'app'], 'currentPage'),
  openKey: select(state, ['appReducer', 'app'], 'openKey'),
  notifications: select(state, ['appReducer', 'notification'], 'items'),
  user: select(state, 'authReducer', 'user'),
  title: select(state, ['appReducer', 'titleReducer'], 'title')
})

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOut()),
  setOpenKey: openKey => dispatch(setOpenKey(openKey)),
  getNotifications: params => dispatch(getNotifications(params)),
  getNotificationsIfNeed: params => dispatch(getNotificationsIfNeed(params)),
  updateNotificationStatus: (id, payload) => dispatch(updateNotificationStatus(id, payload)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalHeader))
