/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Button, Form, Select } from 'antd'
import { ACTIVE } from '../../../constants/enum'
import router from '../../../constants/router'

const { Item } = Form
const { Option } = Select
class FilterRow extends React.Component {
  state = { keyword: [] }

  static getDerivedStateFromProps(props) {
    const { search } = props.history.location
    const result = search.replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ').split(' ').splice(1)

    return { keyword: result }
  }

  handleSubmit = e => {
    e.preventDefault()
    const c_params = this.props.filterOptions === undefined ? {} : JSON.parse(this.props.filterOptions)
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const urlParameters = []
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '') {
            urlParameters.push(e.join('='))
          }
        })
        if (urlParameters[0] !== false) {
          this.props.history.push(router.CART.INDEX.concat(`?${urlParameters.join('&')}`))
        }
      } else {
        console.log(err)
      }
    })
  };

    handleReset = () => {
      this.props.form.resetFields()
      this.props.history.push(router.CART.INDEX)
    };

    render() {
      const { disabled, form: { getFieldDecorator } } = this.props
      const { keyword } = this.state

      return (
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col span={24} lg={4}>
              <Item label="Mã giỏ hàng">
                {
                  getFieldDecorator('cartCode', { initialValue: keyword.indexOf('cartCode') === -1 ? '' : keyword[keyword.indexOf('cartCode') + 1] })(
                    <Input
                      disabled={disabled}
                      placeholder="Mã giỏ hàng"
                      allowClear
                    />
                  )
                }
              </Item>
            </Col>
            <Col span={24} lg={8}>
              <Item label="Tên giỏ hàng">
                {
                  getFieldDecorator('cartName', { initialValue: keyword.indexOf('cartName') === -1 ? '' : keyword[keyword.indexOf('cartName') + 1] })(
                    <Input
                      disabled={disabled}
                      placeholder="Tên giỏ hàng"
                      allowClear
                    />
                  )
                }
              </Item>
            </Col>
            {/* <Col span={12} lg={4}> */}
            {/* <Item label="Mã khách hàng">
                {
                  getFieldDecorator('customerCode', { initialValue: keyword.indexOf('customerCode') === -1 ? '' : keyword[keyword.indexOf('customerCode') + 1] })(
                    <Input
                      disabled={disabled}
                      placeholder="Mã khách hàng"
                      allowClear
                    />,
                  )
                }
              </Item>
            </Col> */}
            <Col span={12} lg={4}>
              <Item label="Trạng thái">
                {
                  getFieldDecorator('active', { initialValue: keyword.indexOf('active') === -1 ? '' : keyword[keyword.indexOf('active') + 1] === 'true' })(
                    <Select
                      allowClear
                      disabled={disabled}
                      style={{ width: '100%' }}
                      placeholder="Chọn trạng thái"
                    >
                      {ACTIVE ? ACTIVE.map((item, index) => (
                        <Option value={item} key={`cart${index + 1}`}>
                          { item === true ? 'Đang phục vụ' : 'Ngừng phục vụ'}
                        </Option>
                      )) : '' }
                    </Select>,
                  )
                }
              </Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} lg={4}>
              <Button
                icon="filter"
                disabled={disabled}
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                {'Lọc'}
              </Button>
            </Col>
            <Col span={12} lg={4}>
              <Button icon="close" disabled={disabled} onClick={this.handleReset} style={{ width: '100%' }}>
                {'Bỏ lọc'}
              </Button>
            </Col>
          </Row>
        </Form>
      )
    }
}

FilterRow.propTypes = { disabled: PropTypes.bool.isRequired }

export default Form.create()(FilterRow)
