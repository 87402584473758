import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Modal, Select, notification } from 'antd'

const { Option } = Select
const methods = [
  {
    code: 1,
    name: 'GET'
  }, {
    code: 2,
    name: 'PUT'
  }, {
    code: 3,
    name: 'DELETE'
  }, {
    code: 4,
    name: 'POST'
  }
]

class APIForm extends React.Component {
  state = { valuePath: '' }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const { description, method } = values
        this.props.onCreateAPI({ description, path: this.state.valuePath, method })
        // this.props.form.resetFields()
      } else {
        notification.warning({ message: 'Không được để trống !!!' })
      }
    })
  };

  onUpdateAPI = e => {
    e.preventDefault()
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const { description, path, method } = values
        this.props.onUpdateAPI(this.props.editAPI.id, { description, path, method })
      } else {
        notification.warning({ message: 'Không được để trống !!!' })
      }
    })
  };

  afterClose = () => {
    this.props.form.resetFields()
  }

  changPath = e => {
    const value = e.target.value
    const valuePath = value.replace(/\s/g, '')
    this.setState({ valuePath })
  }

  render() {
    const { form: { getFieldDecorator }, visibleAPI, onToggleModalAPI, editAPI } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    }
    return (
      <Modal
        title={(editAPI && editAPI.id) ? 'Cập nhập API' : 'Thêm API mới'}
        visible={visibleAPI}
        onOk={(editAPI && editAPI.id) ? this.onUpdateAPI : this.handleSubmit}
        onCancel={onToggleModalAPI}
        okText={(editAPI && editAPI.id) ? 'Sửa' : 'Tạo mới'}
        cancelText="Hủy"
        afterClose={this.afterClose}
        destroyOnClose
      >
        <Form {...formItemLayout}>
          <Form.Item label="Đường dẫn">
            {getFieldDecorator('path', {
              rules: [
                {
                  required: true,
                  message: 'Nhập đường dẫn',
                },
              ],
              initialValue: (editAPI && editAPI.id) ? editAPI.payload.path : ''
            })(<Input placeholder="Đường dẫn" onChange={this.changPath} />)}
          </Form.Item>
          <Form.Item label="Phương thức">
            {getFieldDecorator('method', {
              rules: [
                {
                  required: true,
                  message: 'Chọn phương thức',
                },
              ],
              initialValue: (editAPI && editAPI.id) ? editAPI.payload.method : ''
            })(
              <Select
                placeholder="Phương thức"
                style={{ width: '100%' }}
              >
                {methods && methods.map(m => (
                  <Option value={m.name} key={m.code}>{m.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Mô tả">
            {getFieldDecorator('description', { initialValue: (editAPI && editAPI.id) ? editAPI.payload.description : '' })(<Input placeholder="Mô tả" />)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

APIForm.propTypes = {
  form: PropTypes.object.isRequired,
  onCreateAPI: PropTypes.func.isRequired,
  visibleAPI: PropTypes.bool,
  onToggleModalAPI: PropTypes.func.isRequired,
  onUpdateAPI: PropTypes.func.isRequired,
}

export default Form.create()(APIForm)
