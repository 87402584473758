/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const listState = {
  items: [],
  time: 0,
  meta: {
    total: 0,
    current: 0,
    pageSize: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const list = (state = listState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_PRICE_TABLES:
  case TYPES.INSERTING_PRICE_TABLE:
    draft.isFetching = true
    break

  case TYPES.GET_PRICE_TABLES_SUCCESS: {
    const { data, metaData } = action.payload
    draft.items = data
    draft.meta = {
      current: metaData.page,
      pageSize: metaData.pageSize,
      total: metaData.total
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_PRICE_TABLES_FAILURE:
  case TYPES.INSERT_PRICE_TABLE_SUCCESS:
  case TYPES.INSERT_PRICE_TABLE_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})

const existState = {
  item: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const exist = (state = existState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_EXIST_PRICE_TABLE_OF_GROUP:
    draft.isFetching = true
    break

  case TYPES.GET_EXIST_PRICE_TABLE_OF_GROUP_SUCCESS: {
    draft.item = action.payload
    draft.isFetching = false
    break
  }

  case TYPES.GET_EXIST_PRICE_TABLE_OF_GROUP_FAILURE:
    draft.isFetching = false
    break

  case TYPES.CLEAR_EXIST_PRICE_TABLE: {
    draft.item = {}
    draft.isFetching = false
    draft.didInvalidate = true
    draft.updatedAt = 0
    break
  }

  default:
    return draft
  }
})

const detailState = {
  item: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const detail = (state = detailState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_PRICE_TABLE:
    draft.isFetching = true
    break

  case TYPES.GET_PRICE_TABLE_SUCCESS: {
    draft.item = { items: action.payload.data }
    draft.isFetching = false
    break
  }

  case TYPES.GET_PRICE_TABLE_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})

const listRouteState = {
  items: [],
  time: 0,
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const listForRouting = (state = listRouteState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_PRICE_TABLES:
    draft.isFetching = true
    break

  case TYPES.GET_PRICE_TABLES_SUCCESS: {
    draft.items = action.payload.data
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_PRICE_TABLES_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})

const checkState = {
  items: [],
  time: 0,
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const checkResult = (state = checkState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.CHECKING_ORDERED:
    draft.isFetching = true
    break

  case TYPES.CHECK_ORDER_SUCCESS: {
    // items: action.payload,
    draft.time = action.time
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.CHECK_ORDER_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})

export default combineReducers({ list, exist, detail, listForRouting, checkResult })
