import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Select, Table, Button, Row, Switch, Popover, Popconfirm, notification } from 'antd'
import * as XLSX from 'xlsx'
// import _ from 'lodash'
// import UploadForm from '../../../components/UploadForm/index'
import { isBelowBreakpoint } from '../../../util/windows'

const { Option } = Select

const columns = (onDeleteApiOfRole, idRole, getRoles) => [
  {
    title: 'Đường dẫn',
    dataIndex: 'path',
  },
  {
    title: 'Phương thức',
    dataIndex: 'method',
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'active',
    align: 'center',
    render: value => (
      <Switch
        defaultChecked={value}
        disabled
      />
    )
  },
  {
    title: 'Xóa',
    dataIndex: 'delete',
    render: (value, record) => (
      <Popconfirm
        title="Bạn muốn xóa ?"
        placement="rightTop"
        onConfirm={() => {
          onDeleteApiOfRole(idRole, record.key)
          getRoles({ id: idRole })
        }}
      >
        <Button
          icon="delete"
          shape="circle"
          type="danger"
        />
      </Popconfirm>
    )
  },
]

class ListAPI extends React.Component {
  state = { selectedRowKeys: [], data: [], idRole: '', selectedItems: [], page: 1, page_size: 10, roleApis: [] }

  static getDerivedStateFromProps(props, state) {
    const { listAPI } = props
    if (listAPI && listAPI.payload) {
      const id = listAPI.payload.id
      const x = props.roles.filter(item => item.id === id)
      const result = x[0].apis.map(item => ({
        key: item.id,
        regexPath: item.regexPath,
        path: item.path,
        method: item.method,
        active: item.active,
        description: item.description
      }))

      return { data: result, idRole: id }
    }
    return state
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys })
  }

  handleChange = selectedItems => {
    this.setState({ selectedItems })
  }

  afterClose = () => {
    this.setState({ selectedRowKeys: [] })
    this.props.form.resetFields()
    this.setState({ page: 1 })
  }

  onchangepage = (page, page_size) => {
    this.setState({ page, page_size })
  }

  onImportExcel = event => {
    const { files } = event.target
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(files[0])
    }
  }

  importExcel = file => {
    const fileReader = new FileReader()

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let startAt = 0
        const { result } = event.target
        const workbook = XLSX.read(result, { type: 'binary' })
        const Sheet = workbook.Sheets[workbook.SheetNames[0]]
        let data = XLSX.utils.sheet_to_json(Sheet)
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`]
          if (flagCell !== undefined) {
            startAt = i
            // 1
          }
        }

        if (startAt === 0) {
          notification.error({ message: 'Thêm mới file excel thất bại!', duration: 1 })
        } else {
          const arrayTotal = []
          data = XLSX.utils.sheet_to_json(Sheet, { raw: true })
          notification.success({ message: 'Tải File thành công', duration: 1 })

          for (let i = 0; i < data.length; i++) {
            // const array = []
            // const arrayUn = []
            const string = data[i]
            if (string.role && string.path && string.method) {
              arrayTotal.push({
                role_code: string.role,
                api_path: string.path,
                api_method: string.method,
                api_description: string.description ? string.description : ''
              })
            }
          }
          this.setState({ roleApis: arrayTotal })
        }
      } catch (e) {
        notification.error({ message: e, duration: 1 })
      }
    }
    fileReader.readAsBinaryString(file)
  }

  addAPI = () => {
    this.props.uploadApiFromExcel(this.state.roleApis)
  }

  render() {
    const {
      form: { getFieldDecorator },
      visibleListAPI,
      onToggleModalList,
      apis,
      onDeleteApiOfRole,
      onDeleteListApiOfRole,
      getRoles,
    } = this.props
    const { selectedRowKeys, idRole, selectedItems } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const filteredOptions = apis.filter(o => !selectedItems.includes(o))
    const content = (
      <Fragment>
        <Form.Item>
          {getFieldDecorator('api')(
            <Select
              allowClear
              mode="multiple"
              placeholder="Chọn API"
              setFieldsValue={selectedItems}
              style={{ width: '350px', marginRight: '5px' }}
              onChange={this.handleChange}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              showSearch
            >
              {
                filteredOptions.map(item => (
                  <Option key={item.id} value={item.id}>{item.path}</Option>
                ))
              }
            </Select>
          )}
          <Popconfirm
            title="Bạn muốn thêm mới ?"
            placement="bottom"
            okText="Có"
            cancelText="Không"
            onConfirm={() => {
              this.props.onCreateApiOfRole({ api_ids: this.state.selectedItems, role_id: this.state.idRole })
              this.setState({ selectedItems: [] })
              this.props.form.resetFields()
            }}
          >
            <Button>Thêm</Button>
          </Popconfirm>
        </Form.Item>

      </Fragment>
    )

    const contentExcel = (
      <Fragment>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/spreadsheets/d/1PWzV27csZ1vCoDjQeEGvv7HWzDC823MtfRyDY34bJ4w/edit?usp=sharing"
        >
Mẫu file excel
        </a>
        <br />
        <br />
        <input
          id="fileUpload"
          type="file"
          ref={v => {
            this.fileUpload = v
          }}
          accept=".xlsx, .xls"
          onChange={this.onImportExcel}
        />
        <Button
          onClick={this.addAPI}
        >
Thêm API từ File

        </Button>
      </Fragment>

    )

    return (
      <div className="container">
        <div className="row">
          <Modal
            title="Danh sách API "
            width="85%"
            bodyStyle={{ overflowX: 'scroll' }}
            visible={visibleListAPI}
            onOk={onToggleModalList}
            onCancel={onToggleModalList}
            okText="Đóng"
            afterClose={this.afterClose}
            destroyOnClose
            closable
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <Table
              title={
                () => (
                  <Row>
                    <Popover
                      content={content}
                      title="Chọn API"
                      trigger="click"
                      placement="left"
                    >
                      <Button
                        icon="plus"
                        onClick={() => this.props.getAPIs({ page: -1 })}
                      >
                        {'Thêm API'}
                      </Button>
                    </Popover>
                    <Popover
                      content={contentExcel}
                      trigger="click"
                      placement="right"
                    >
                      <Button icon="file-excel" style={{ marginLeft: isBelowBreakpoint() ? 0 : '10px', marginTop: isBelowBreakpoint() ? 5 : 0 }}>
                        {'Thêm API từ Excel'}
                      </Button>
                    </Popover>
                    <Popconfirm
                      title="Bạn muốn xóa ?"
                      placement="rightTop"
                      onConfirm={selectedRowKeys.length === 0 ? '' : () => onDeleteListApiOfRole({ api_ids: this.state.selectedRowKeys, role_id: idRole })}
                    >
                      <Button
                        icon="delete"
                        style={{ marginLeft: isBelowBreakpoint() ? 0 : '10px', marginTop: isBelowBreakpoint() ? 5 : 0 }}
                        disabled={(selectedRowKeys.length === 0)}
                      >
                        {'Xóa nhiều'}
                      </Button>
                    </Popconfirm>

                  </Row>
                )
              }
              size="small"
              rowClassName={(r, idx) => (idx % 2 === 0 ? 'even' : '')}
              scroll={{ x: 'max-content' }}
              rowSelection={rowSelection}
              columns={columns(onDeleteApiOfRole, idRole, getRoles)}
              dataSource={this.state.data}
              pagination={{
                current: this.state.page,
                pageSize: this.state.page_size,
                onChange: this.onchangepage
              }}
            />
          </Modal>
        </div>
      </div>
    )
  }
}

ListAPI.propTypes = {
  form: PropTypes.object.isRequired,
  visibleListAPI: PropTypes.bool,
  onToggleModalList: PropTypes.func.isRequired,
  onDeleteApiOfRole: PropTypes.func.isRequired,
  onDeleteListApiOfRole: PropTypes.func.isRequired,
  onCreateApiOfRole: PropTypes.func.isRequired,
  uploadApiFromExcel: PropTypes.func.isRequired,
}

export default Form.create()(ListAPI)
