import { notification, Typography } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getSupplier, updateSupplierCriteria } from '../actions';
import CriteriaList from './components/List';

class SupplierCriteria extends Component {
  componentDidMount() {
    const { supplierCode, getSupplier } = this.props;
    getSupplier(supplierCode);
    return null;
  }

  add = values => {
    if (values.image_url) {
      const { supplierCode, supplierInfo, updateSupplierCriteria } = this.props;
      const currentCriteria = supplierInfo.criteria !== null ? supplierInfo.criteria : [];
      const Criteria = currentCriteria.map(criterion => ({
        name: criterion.name || '',
        image_url: criterion.image_url || '',
      }));
      Criteria.push({
        name: values.name || '',
        image_url: values.image_url || '',
      });
      const payload = { criteria: Criteria };

      updateSupplierCriteria(supplierCode, payload, {
        onSuccess: () => notification.success({ message: 'Thêm thành công' }),
        onError: () => notification.error({ message: 'Thêm thất bại' }),
      });
    }
  };

  remove = item => {
    const { supplierInfo, updateSupplierCriteria, supplierCode } = this.props;

    const Criteria = supplierInfo.criteria;
    const newCriteria = Criteria.filter(items => items.image_url !== item.image_url);
    const newPayload = { criteria: newCriteria };
    updateSupplierCriteria(supplierCode, newPayload, {
      onSuccess: () => notification.success({ message: 'Xoá thành công' }),
      onError: () => notification.error({ message: 'Xoá thất bại' }),
    });
  };

  render() {
    const { supplierInfo } = this.props;
    return (
      <div>
        <Typography.Title
          header="Giấy chứng nhận"
          level={2}
          // description="Chứng nhận của sản phẩm"
        />
        <CriteriaList
          dataSource={supplierInfo.criteria || []}
          slug={supplierInfo.Slug}
          onAdd={this.add}
          onRemove={this.remove}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ supplierReducer }) => ({
  supplierInfo: supplierReducer.detail.item,
  supplierCode: supplierReducer.detail.item?.code,
  // isFetching: supplierReducer.detail.isFetching,
});

const mapDispatchToProps = dispatch => ({
  getSupplier: supplierCode => dispatch(getSupplier(supplierCode)),
  updateSupplierCriteria: (supplierCode, payload, meta) =>
    dispatch(updateSupplierCriteria(supplierCode, payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierCriteria);
