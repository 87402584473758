/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';
import produce from 'immer';
import TYPES from '../../constants/actionTypes';

const findIndex = (suppliers, id) => {
  let result = -1;
  suppliers.forEach((supplier, index) => {
    if (supplier.id === id) {
      result = index;
    }
  });
  return result;
};

const listState = {
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
};

const list = (state = listState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TYPES.GETTING_SUPPLIERS:
        draft.isFetching = true;
        break;

      case TYPES.GET_SUPPLIERS_SUCCESS: {
        draft.items = action.payload.data;
        draft.meta = {
          total: action.payload.total,
          current: action.payload.page,
          pageSize: action.payload.page_size,
        };
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;
      }

      case TYPES.GET_SUPPLIERS_FAILURE: {
        draft.isFetching = false;
        draft.didInvalidate = true;
        break;
      }

      case TYPES.CHANGE_SUPPLIER_STATUS: {
        const { id, value } = action;
        const index = findIndex(draft.items, id);
        draft.items[index].status = !value;
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;
      }

      default:
        return draft;
    }
  });

const detailState = {
  item: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
};

const detail = (state = detailState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TYPES.GETTING_SUPPLIER:
      case TYPES.INSERTING_SUPPLIER:
      case TYPES.UPDATING_SUPPLIER:
      case TYPES.DELETING_SUPPLIER:
        draft.isFetching = true;
        break;

      case TYPES.GET_SUPPLIER_SUCCESS: {
        draft.item = action.payload.data;
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;
      }

      case TYPES.INSERT_SUPPLIER_SUCCESS:
      case TYPES.UPDATE_SUPPLIER_SUCCESS: {
        // draft.item = action.payload.data;
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;
      }
      case TYPES.DELETE_SUPPLIER_SUCCESS:
      case TYPES.GET_SUPPLIER_FAILURE:
      case TYPES.INSERT_SUPPLIER_FAILURE:
      case TYPES.UPDATE_SUPPLIER_FAILURE:
      case TYPES.DELETE_SUPPLIER_FAILURE: {
        draft.isFetching = false;
        draft.didInvalidate = true;
        break;
      }

      default:
        return draft;
    }
  });

export default combineReducers({ list, detail });
