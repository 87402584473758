import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'
import select from '../../util/select'

export const clearData = () => dispatch => {
  dispatch({ type: TYPES.CLEAR_DATA_ORDER })
}

export const getCart = code => async dispatch => {
  const api = API.CART.getCart(code)
  dispatch({ type: TYPES.GETTING_CART_FOR_ORDER })
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_CART_FOR_ORDER_SUCCESS,
      payload: response.data.data
    })
    dispatch({ type: TYPES.DELETE_ORDERS })
  } else {
    dispatch({ type: TYPES.GET_CART_FOR_ORDER_FAILURE })
  }
}

export const getCartsOfCustomer = params => async dispatch => {
  const api = API.CART.getCarts()
  dispatch({ type: TYPES.GETTING_CARTS_OF_CUSTOMER })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_CARTS_OF_CUSTOMER_SUCCESS,
      payload: response.data.data
    })
  } else {
    dispatch({ type: TYPES.GET_CARTS_OF_CUSTOMER_FAILURE })
  }
}

export const getCustomer = code => async dispatch => {
  const api = API.CUSTOMER.getCustomer(code)
  dispatch({ type: TYPES.GETTING_CUSTOMER_FOR_ORDER })
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_CUSTOMER_FOR_ORDER_SUCCESS,
      payload: response.data.data
    })
    dispatch({ type: TYPES.DELETE_CART_FOR_ORDER })
    dispatch({ type: TYPES.DELETE_PRODUCTS_FOR_ORDER })
    dispatch({ type: TYPES.DELETE_ORDERS })
  } else {
    dispatch({ type: TYPES.GET_CUSTOMER_FOR_ORDER_FAILURE })
  }
}

export const getPriceTable = (id, params) => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTable(id)
  dispatch({ type: TYPES.GETTING_PRODUCTS_FOR_ORDER })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_PRODUCTS_FOR_ORDER_SUCCESS,
      data: response.data.data,
      meta: response.data.metaData
    })
  } else {
    dispatch({ type: TYPES.GET_PRODUCTS_FOR_ORDER_FAILURE })
  }
}

export const getProducts = (params, meta) => async (dispatch, getState) => {
  const state = getState()
  const priceTableId = select(state, ['orderReducer', 'product'], 'priceTableId')
  dispatch({ type: TYPES.GETTING_PRICETABLE_FOR_ORDER })
  const api = API.PRICE_TABLE.getPriceTables()
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    const result = response.data.data
    if (result.length > 0) {
      if (meta && meta.onSuccess && response.data.data[0].priceTableId !== priceTableId) {
        meta.onSuccess(response.data.data[0].priceTableId)
        // dispatch({ type: TYPES.CLEAR_DATA_ORDER })
      } else if (meta && meta.onError && response.data.data[0].priceTableId === priceTableId) {
        meta.onSuccess(response.data.data[0].priceTableId)
      }
    } else if (meta && meta.onError && result.length === 0) {
      meta.onError('Chưa có bảng giá')
    } else {
      dispatch({ type: TYPES.GET_PRODUCTS_FOR_ORDER_FAILURE })
    }
  } else {
    dispatch({ type: TYPES.GET_PRODUCTS_FOR_ORDER_FAILURE })
  }
}

export const addItem = payload => ({
  type: TYPES.ADD_ITEM,
  payload,
})

export const removeItem = id => ({
  type: TYPES.REMOVE_ITEM_IN_ORDER,
  id,
})

export const setOrder = payload => ({
  type: TYPES.SET_ORDER,
  payload,
})

export const insertInvoice = (payload, meta) => async dispatch => {
  const api = API.INVOICE.insertInvoice()
  dispatch({ type: TYPES.INSERTING_INVOICE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.INSERT_INVOICE_SUCCESS })
    dispatch({ type: TYPES.CLEAR_DATA_ORDER })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.INSERT_INVOICE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

const insertInvoiceSuccess = { type: TYPES.INSERT_INVOICE_SUCCESS }

const insertInvoiceFailure = { type: TYPES.INSERT_INVOICE_FAILURE }

export const importInvoices = ({ payload, params }, meta) => async dispatch => {
  const api = API.INVOICE.importFromExcel()
  const { response, error } = await apiCall({
    ...api,
    payload,
    params,
  })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertInvoiceSuccess)
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch(insertInvoiceFailure)
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const getInvoiceById = id => async dispatch => {
  const api = API.INVOICE.getInvoices()
  dispatch({ type: TYPES.GETTING_INVOICE_BY_ID })
  if (id) {
    const { response, error } = await apiCall({ ...api, params: { id } })
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch({
        type: TYPES.GET_INVOICE_BY_ID_SUCCESS,
        payload: response.data.Data.length ? response.data.Data[0] : {},
      })
    } else {
      dispatch({ type: TYPES.GET_INVOICE_BY_ID_FAILURE })
    }
  } else {
    dispatch({ type: TYPES.GET_INVOICE_BY_ID_FAILURE })
  }
}

export const updateInvoice = (id, payload, meta) => async () => {
  const api = API.INVOICE.updateInvoice(id)
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (meta && meta.onError) {
    meta.onError(error)
  }
}
