/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notification, Row, Col } from 'antd'

import { getAccounts, createAccount, resetPassword, updateRole, changeUserStatus } from './actions'
import { getRoles } from '../Manager/actions'
import select from '../../util/select'
import AccountList from './components/AccountList'
import Filter from './components/filterAccount'
import AdminForm from './components/AdminForm'
import router from '../../constants/router'

class Account extends Component {
  state = { filterOptions: {}, visible: false, pageS: 10 }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location
    let params = { status: 'active' }
    if (search !== state.search) {
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
        params = JSON.parse(params)
        if (!params.status) {
          params.status = 'active'
        } else if (params.status === 'all') {
          delete params.status
        }
        props.getAccounts(params)
      } else {
        props.getAccounts(params)
      }
      return { ...state, search, filterOptions: params }
    }
    return state
  }

  componentDidMount() {
    this.props.getRoles({ pageSize: -1 })
    // this.props.getAccounts({ page: 1, pageSize: 10 })
  }

  onChangePage = (page, pageSize) => {
    const { search } = this.props.history.location
    if (search !== '') {
      let params
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      }
      const obj = JSON.parse(params)
      params = { ...obj, page, pageSize }

      const urlParameters = []
      Object.entries(params).map(e => {
        if (e[1] !== undefined && e[1] !== '') {
          urlParameters.push(e.join('='))
        }
      })
      this.props.history.push(router.GENERAL.ACCOUNT.concat(`?${urlParameters.join('&')}`))
    } else {
      this.props.history.push(router.GENERAL.ACCOUNT.concat(`?page=${page}&pageSize=${pageSize}`))
    }
  }

  handleRegister = values => {
    this.props.createAccount(values, {
      onSuccess: () => {
        notification.success({ message: 'Tạo tài khoản thành công' })
        this.toggleModal()
      },
      onError: mes => {
        notification.error({ message: `Tạo tài khoản thất bại. ${mes}` })
      },
    }, this.state.pageS)
  };

  handleFilter = filterOptions => {
    this.setState({ filterOptions })
    this.props.getAccounts({ ...filterOptions, Page: 1, PageSize: 10 })
  }

  toggleModal = () => {
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  handleUpdateRole = (id, payload) => {
    this.props.updateRole(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Cập nhật vai trò thành công' })
      },
      onError: () => notification.error({ message: 'Cập nhật vai trò thất bại' }),
    })
  };

  changeStatus = (id, payload) => {
    this.props.changeStatus(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật trạng thái' })
        this.props.getAccounts({ ...this.state.filterOptions, page: 1, pageSize: 10 })
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    })
  }

  render() {
    const { accounts, meta, isFetching, history, resetPassword, roles } = this.props
    const { visible } = this.state
    return (
      <React.Fragment>
        <AdminForm roles={roles} onRegister={this.handleRegister} toggleModal={this.toggleModal} visible={visible} />
        <Row gutter={24}>
          <Col span={24} lg={6}>
            <Filter
              disabled={isFetching}
              history={history}
              onfilter={this.handleFilter}
              roles={roles}
            />
          </Col>
          <Col span={24} lg={18}>
            <AccountList
              roles={roles}
              dataSource={accounts}
              meta={meta}
              isFetching={isFetching}
              onChange={this.onChangePage}
              onShowSizeChange={this.onChangePage}
              toggleModal={this.toggleModal}
              resetPassword={resetPassword}
              onUpdateRole={this.handleUpdateRole}
              onChangeStatus={this.changeStatus}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  roles: select(state, ['managerReducer', 'role'], 'items'),
  accounts: select(state, ['generalReducer', 'account'], 'items'),
  isFetching: select(state, ['generalReducer', 'account'], 'isFetching'),
  meta: select(state, ['generalReducer', 'account'], 'meta'),
})

const mapDispatchToProps = dispatch => ({
  getRoles: params => dispatch(getRoles(params)),
  getAccounts: params => dispatch(getAccounts(params)),
  createAccount: (payload, meta, pageS) => dispatch(createAccount(payload, meta, pageS)),
  resetPassword: (payload, meta) => dispatch(resetPassword(payload, meta)),
  updateRole: (id, payload, meta) => dispatch(updateRole(id, payload, meta)),
  changeStatus: (id, payload, meta) => dispatch(changeUserStatus(id, payload, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account)
