import React from 'react';
import { Form, Input, Button, Modal, Select, Row, Col } from 'antd';
import { backpage, isBelowBreakpoint } from '../../../util/windows';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

class SupplierForm extends React.Component {
  state = {
    valueCode: '',
    currentSupplierCode: '',
    statuses: [
      { name: 'Ngừng sử dụng', val: false },
      { name: 'Sử dụng', val: true },
    ],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.editMode &&
      (nextProps.supplier || (nextProps.supplier && nextProps.supplier.code !== prevState.currentSupplierCode))
    ) {
      return { currentSupplierCode: nextProps.supplier.code };
    }
    return null;
  }

  onDelete = (name, id) => {
    Modal.confirm({
      okText: 'Xoá nhà cung cấp',
      okButtonProps: { type: 'danger' },
      onOk: () => this.props.onDelete(id),
      cancelText: 'Huỷ',
      cancelButtonProps: { type: 'primary' },
      title: `Bạn chắc chắn xoá nhà cung cấp ${name}`,
      content: 'Mọi thông tin liên quan sẽ bị xoá khỏi hệ thống !',
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      values.code = this.state.valueCode || values.code;
      values.status = values.status === 'true' ? true : values.status === 'false' ? false : values.status;
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  changecode = e => {
    const valueinput = e.target.value;
    const part = /\s/g;
    const value = valueinput.replace(part, '');
    this.setState({ valueCode: value });
  };

  render() {
    const {
      supplier,
      editMode,
      form: { getFieldDecorator },
    } = this.props;
    const { statuses } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Mã nhà cung cấp">
              {getFieldDecorator('code', {
                initialValue: editMode ? supplier && supplier.code : '',
                rules: [
                  {
                    required: true,
                    message: 'Hãy nhập mã nhà cung cấp',
                  },
                ],
              })(<Input type="text" onChange={this.changecode} placeholder="Mã nhà cung cấp" allowClear />)}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Tên nhà cung cấp">
              {getFieldDecorator('name', {
                initialValue: editMode ? supplier && supplier.name : '',

                rules: [{ required: true, message: 'Hãy nhập tên nhà cung cấp' }],
              })(<Input type="text" placeholder="Tên nhà cung cấp" allowClear />)}
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Số điện thoại">
              {getFieldDecorator('phone', {
                initialValue: editMode ? supplier.phone : '',
                rules: [
                  // {
                  //   required: true,
                  //   message: 'Nhập số điện thoại',
                  // },
                  {
                    pattern: new RegExp(/^(0)[1-9]([0-9]{8}[0-9]?)\b/),
                    message: 'Sai định dạng số điện thoại',
                  },
                ],
              })(<Input type="text" style={{ width: '100%' }} placeholder="Số điện thoại" allowClear />)}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Email">
              {getFieldDecorator('email', {
                initialValue: editMode ? supplier.email : '',
                rules: [
                  {
                    type: 'email',
                    message: 'Địa chỉ email không chính xác',
                  },
                  // {
                  //   required: true,
                  //   message: 'Hãy nhập email',
                  // },
                ],
              })(<Input style={{ width: '100%' }} placeholder="Email" allowClear />)}
            </Item>
          </Col>
        </Row>

        {/* <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Số nhà">
              {getFieldDecorator('streetNumber', { initialValue: editMode ? supplier.streetNumber : '' })(
                <Input type="text" placeholder="Số nhà" allowClear />,
              )}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Đường/phố">
              {getFieldDecorator('street', { initialValue: editMode ? supplier.street : '' })(
                <Input type="text" placeholder="Phố" allowClear />,
              )}
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Quận/huyện">
              {getFieldDecorator('district', { initialValue: editMode ? supplier.district : '' })(
                <Input type="text" placeholder="Quận/huyện" allowClear />,
              )}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Tỉnh/thành phố">
              {getFieldDecorator('city', { initialValue: editMode ? supplier.city : 'Hà Nội' })(
                <Input type="text" allowClear />,
              )}
            </Item>
          </Col>
        </Row> */}
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Website">
              {getFieldDecorator('website', { initialValue: editMode ? supplier.website : '' })(
                <Input type="text" allowClear />,
              )}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Trạng thái">
              {getFieldDecorator('status', {
                initialValue: editMode ? (supplier ? `${supplier.status}` : null) : null,
              })(
                <Select placeholder="Trạng thái" allowClear>
                  {statuses.map((item, index) => (
                    <Option value={`${item.val}`} key={`${index + 1}`}>
                      {item.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </Item>
          </Col>
        </Row>
        {/* <Row gutter={24}>
          <Col span={24} lg={12}>
            <Item label="Vĩ độ">
              {getFieldDecorator('lat', {
                initialValue: editMode ? supplier.lat : null,
                // rules: [
                //   {
                //     required: true,
                //     message: 'Tọa độ không chính xác !',
                //   },
                // ],
              })(<Input placeholder="Nhập vĩ độ" allowClear />)}
            </Item>
          </Col>
          <Col span={24} lg={12}>
            <Item label="Kinh độ">
              {getFieldDecorator('lng', {
                initialValue: editMode ? supplier.lng : null,
                // rules: [
                //   {
                //     required: true,
                //     message: 'Tọa độ không chính xác !',
                //   },
                // ],
              })(<Input placeholder="Nhập kinh độ" allowClear />)}
            </Item>
          </Col>
        </Row> */}
        <Item label="Địa chỉ">
          {getFieldDecorator('address', { initialValue: editMode ? supplier.address : '' })(
            <Input type="text" placeholder="Địa chỉ ..." />,
          )}
        </Item>
        <Item label="Ghi chú">
          {getFieldDecorator('description', { initialValue: editMode ? supplier.description : '' })(
            <TextArea type="text" placeholder="Thông tin thêm ..." />,
          )}
        </Item>
        <Item>
          <Button ghost style={{ border: 'none' }} type="danger" onClick={() => backpage()}>
            Quay lại
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            icon="plus"
            style={{ float: isBelowBreakpoint() ? 'none' : 'right' }}
          >
            {editMode ? 'Hoàn thành' : 'Thêm nhà cung cấp'}
          </Button>
        </Item>
      </Form>
    );
  }
}

export default Form.create()(SupplierForm);
