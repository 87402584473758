import produce from 'immer'
import { combineReducers } from 'redux'
import TYPES from '../../constants/actionTypes'


const initialState = {
  currentPage: [''],
  openKey: [''],
}

const app = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.SET_PAGE:
    draft.currentPage = [action.payload]
    break

  case TYPES.SET_OPEN_KEY:
    draft.openKey = [action.payload]
    break

  default:
    return draft
  }
})

const notificationState = {
  items: [],
  total: 0,
  isFetching: false,
  didInvalidate: true,
  lastUpdated: 0,
}

const notification = (state = notificationState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_NOTIFICATIONS:
    draft.isFetching = true
    break

  case TYPES.GET_NOTIFICATIONS_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = false
    draft.items = action.payload.items
    draft.total = action.payload.total
    break
  }

  case TYPES.GET_NOTIFICATIONS_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INVALIDATE_NOTIFICATION:
    draft.didInvalidate = true
    break

  default:
    return draft
  }
})

const notificationListMoreState = {
  items: [],
  total: 0,
  isFetching: false,
  didInvalidate: true,
  lastUpdated: 0,
}

const notificationListMore = (state = notificationListMoreState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_MORE_NOTIFICATIONS:
    draft.isFetching = true
    break

  case TYPES.GET_MORE_NOTIFICATIONS_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = false
    draft.items = action.payload.items
    draft.total = action.payload.total
    break
  }

  case TYPES.GET_MORE_NOTIFICATIONS_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

const titleState = { title: '' }

const titleReducer = (state = titleState, action) => produce(state, draft => {
  if (action.type === 'SET_PAGE_TITLTE') {
    // ...state
    draft.title = action.value
  }
  return draft
})

export default combineReducers({ app, notification, notificationListMore, titleReducer })
