/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, notification, Divider } from 'antd';

import WithLoadingHOC from '../../hoc/loading';
import WithPageHOC from '../../hoc/page';
import select from '../../util/select';
import { insertSupplier } from './actions';
import SupplierForm from './components/Form';
// import router from '../../constants/router'
import ROUTER from '../../constants/router';
// import { isBelowBreakpoint, backpage } from '../../util/windows'

class AddSupplierPage extends React.Component {
  // state = { isBatch: false };

  // onChangeInsertType = ({ target: { value } }) => this.setState(() => ({ isBatch: value }));

  // handleUpload = async ({ current: { files } }) => {
  //   const formData = new FormData();
  //   formData.append('file', files[0]);
  //   this.props.importSuppliers(formData, {
  //     onSuccess: () => {
  //       notification.success({ message: 'Thêm thành công' });
  //       this.props.history.push(ROUTER.SUPPLIER.INDEX);
  //     },
  //     onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
  //   });
  // };

  handleSubmit = payload => {
    this.props.insertSupplier(payload, {
      onSuccess: newSupplier => {
        notification.success({ message: 'Thêm nhà cung cấp thành công' });
        this.props.history.push(ROUTER.SUPPLIER.EDIT.replace(':id', newSupplier.code));
      },
      onError: error => notification.error({ message: `${error} - Thêm nhà cung cấp thất bại` }),
    });
  };

  render() {
    // const { units, isFetching } = this.props;
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Divider dashed />
            <Card>
              <h1>Thêm nhà cung cấp mới</h1>
              <hr />
              <SupplierForm onSubmit={this.handleSubmit} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({ isFetching: select(state, ['generalReducer', 'productCondition'], 'isFetching') });

const mapDispatchToProps = dispatch => ({ insertSupplier: (payload, meta) => dispatch(insertSupplier(payload, meta)) });

export default connect(mapStateToProps, mapDispatchToProps)(WithLoadingHOC(WithPageHOC('supplier')(AddSupplierPage)));
