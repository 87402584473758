import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'

export const getCustomerProducts = id => async dispatch => {
  const api = API.PRODUCT.getProductByCustomerId(id)
  dispatch({ type: TYPES.GETTING_PRODUCTS_FOR_ORDER })
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200) {
    dispatch({
      type: TYPES.GET_PRODUCTS_FOR_PRICE_TABLES_SUCCESS,
      payload: response.data.Data,
    })
  } else {
    dispatch({ type: TYPES.GET_PRODUCTS_FOR_PRICE_TABLES_FAILURE })
  }
}

export const addItem = payload => ({
  type: TYPES.ADD_ITEM_TO_NEW_PRICETABLE,
  payload,
})

export const removeItem = id => ({
  type: TYPES.REMOVE_ITEM_FROM_NEW_PRICETABLE,
  id,
})

export const addItems = data => async dispatch => {
  dispatch({ type: TYPES.ADD_ITEMS_TO_NEW_PRICETABLE, payload: data })
}

export const cancelCreatePrice = () => ({ type: TYPES.CANCEL_CREATE_PRICE })

export const insertPriceTable = (payload, meta) => async dispatch => {
  const api = API.PRICE_TABLE.insertPriceTable()
  dispatch({ type: TYPES.INSERTING_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200) {
    dispatch({ type: TYPES.INSERT_PRICE_TABLE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.INSERT_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const insertPriceTableFromPreview = (payload, meta) => async dispatch => {
  const api = API.PRICE_TABLE.insertPriceTable()
  dispatch({ type: TYPES.INSERTING_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.INSERT_PRICE_TABLE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.INSERT_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

const insertPriceTableSuccess = { type: TYPES.INSERT_PRICE_TABLE_SUCCESS }

const insertPriceTableFailure = { type: TYPES.INSERT_PRICE_TABLE_FAILURE }

export const importPriceTables = ({ payload, params }, meta) => async dispatch => {
  const api = API.PRICE_TABLE.importFromExcel()
  const { response, error } = await apiCall({
    ...api,
    payload,
    params,
  })
  if (!error && response.status === 200) {
    dispatch(insertPriceTableSuccess)
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch(insertPriceTableFailure)
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const getPriceTableById = id => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTables()
  dispatch({ type: TYPES.GETTING_PRICE_TABLE })
  if (id) {
    const { response, error } = await apiCall({ ...api, params: { id } })
    if (!error && response.status === 200) {
      dispatch({
        type: TYPES.GET_PRICE_TABLE_SUCCESS,
        payload: response.data.Data.length ? response.data.Data[0] : {},
      })
    } else {
      dispatch({ type: TYPES.GET_PRICE_TABLE_FAILURE })
    }
  } else {
    dispatch({ type: TYPES.GET_PRICE_TABLE_FAILURE })
  }
}

export const updatePriceTable = (id, payload, meta) => async () => {
  const api = API.PRICE_TABLE.updatePriceTable(id)
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200) {
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (meta && meta.onError) {
    meta.onError(error)
  }
}

export const clearExistPriceTable = () => async dispatch => {
  dispatch({ type: TYPES.CLEAR_EXIST_PRICE_TABLE })
}

export const importPriceItems = data => async dispatch => {
  dispatch({ type: TYPES.CANCEL_CREATE_PRICE })
  dispatch({ type: TYPES.IMPORT_PRICE_TABLE_FROM_EXIST_ONE, payload: data })
}
