import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import router from '../../../constants/router';
import WithLoading from '../../../hoc/loading';

const columns = (onView, onEdit, onDelete, onChangeStatus) => [
  {
    title: 'Ảnh',
    dataIndex: 'images',
    key: 'Avatar',
    render: (value, record) => {
      const valueImage = !value ? '' : value[0];
      return (
        <Link to={router.PRODUCT.EDIT.replace(':id', record.code)}>
          <Avatar size={64} src={valueImage} />
        </Link>
      );
    },
  },
  {
    title: 'Mã hàng hóa',
    dataIndex: 'code',
    render: value => <Link to={router.PRODUCT.EDIT.replace(':id', value)}>{value}</Link>,
  },
  {
    title: 'Tên hàng hóa',
    dataIndex: 'name',
  },
  {
    title: 'Nhóm hàng hóa',
    dataIndex: 'categoryName',
    render: (value, record) => <Link to={router.CATEGORY.EDIT.replace(':id', record.CategoryCode)}>{value}</Link>,
  },
  {
    title: 'Nhà cung cấp',
    dataIndex: 'supplierName',
    render: (value, record) => <Link to={router.SUPPLIER.EDIT.replace(':id', record.supplierCode)}>{value}</Link>,
  },
  {
    title: 'Đơn vị',
    dataIndex: 'unitName',
    align: 'center',
  },
  {
    title: 'Khối lượng chuyển đổi',
    dataIndex: 'weightPerUnit',
    align: 'center',
    render: value => `${value} kg`,
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    align: 'center',
    render: (value, record) => (
      <Switch key={record.id} checked={value} onChange={() => onChangeStatus(record.id, value)} />
    ),
  },
];

const ProductList = ({
  products,
  meta: { pageSize, current, total },
  onEdit,
  onView,
  onDelete,
  onChangeStatus,
  onChangePage,
  onChangeSize,
}) => (
  <Table
    size="small"
    scroll={{ x: 'max-content' }}
    columns={columns(onView, onEdit, onDelete, onChangeStatus)}
    style={{ marginTop: '10px' }}
    dataSource={products}
    rowKey="id"
    rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
    pagination={{
      current,
      total,
      pageSize,
      onChange: onChangePage,
      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} hàng hóa`,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40', '80'],
      onShowSizeChange: onChangeSize,
    }}
  />
);

ProductList.propTypes = {
  products: PropTypes.array,
  meta: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeSize: PropTypes.func,
};

ProductList.defaultProps = {
  products: [],
  meta: {},
  onEdit: () => {},
  onView: () => {},
  onDelete: () => {},
  onChangeStatus: () => {},
  onChangePage: () => {},
  onChangeSize: () => {},
};

export default WithLoading(ProductList);
