import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form, Tag, Input, Button, notification } from 'antd'
import { isBelowBreakpoint } from '../../../util/windows'

const { Item } = Form

class GroupForm extends Component {
    state={
      editable: false,
      valueCode: ''
    }

    handleSubmit = e => {
      e.preventDefault()
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (!this.props.editMode) {
            values.code = this.state.valueCode
            this.props.onAdd(values, {
              onSuccess: () => {
                notification.success({ message: 'Thêm nhóm KH thành công' })
                this.props.form.resetFields()
              },
              onError: error => notification.error({ message: `Thêm nhóm KH không thành công - ${error}` }),
            })
          } else {
            this.props.onEdit(this.props.group.id, values)
            this.setState({ editable: false })
          }
        }
      })
    };

    changeId = e => {
      const valueinput = e.target.value
      const part = /\s/g
      const value = valueinput.replace(part, '')
      this.setState({ valueCode: value })
    }

    render() {
      const { form: { getFieldDecorator }, editMode, group } = this.props
      const { editable } = this.state
      return (

        <Form layout="inline" onSubmit={this.handleSubmit}>

          <Item label="Tên nhóm">
            {!editMode || (editMode && editable) ? getFieldDecorator('name', {
              initialValue: editMode ? group.name : '',
              rules: [
                {
                  required: true,
                  message: 'Nhập tên nhóm khách hàng',
                },
              ],
            })(<Input placeholder="Nhập tên nhóm" />) : <Tag style={{ fontSize: '20px' }}>{group.name}</Tag> }
          </Item>

          <Item label="Mã nhóm">
            {!editMode || (editMode && editable) ? getFieldDecorator('code', {
              initialValue: editMode ? group.group_code : '',
              rules: [
                {
                  required: true,
                  message: 'Nhập mã nhóm khách hàng',
                },
              ],
            })(<Input placeholder="Nhập mã nhóm" onChange={this.changeId} disabled={editMode} />) : <Tag style={{ fontSize: '20px' }}>{group.group_code}</Tag> }
          </Item>

          <Item style={{ marginLeft: isBelowBreakpoint() ? 80 : 0 }}>
            {editMode && !editable && <Button type="danger" onClick={() => this.setState({ editable: true })}>Sửa</Button>}
            {editMode && editable && <Button type="danger" onClick={() => this.setState({ editable: false })}>Hủy</Button>}
            {editMode && editable && (
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={this.handleSubmit}>
                {'Cập nhật'}
              </Button>
            )}
            {!editMode && (
              <Button style={{ width: '100%' }} icon="plus" type="primary" htmlType="submit" onClick={this.handleSubmit}>
                {'Thêm nhóm KH'}
              </Button>
            )}
          </Item>

        </Form>

      )
    }
}

GroupForm.propTypes = {
  form: PropTypes.object.isRequired,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  editMode: PropTypes.bool,
  group: PropTypes.object
}

GroupForm.defaultProps = {
  onAdd: () => {},
  onEdit: () => {},
  editMode: false,
  group: {}
}

export default Form.create()(GroupForm)
