import React from 'react'
import PropTypes from 'prop-types'
import { Table, Switch } from 'antd'
import { Link } from 'react-router-dom'

import WithLoading from '../../../hoc/loading'
import router from '../../../constants/router'

const columns = (onView, onEdit, onChangeStatus) => [
  {
    title: 'Mã danh mục',
    dataIndex: 'code',
    render: (value, row) => (
      <Link to={router.CATEGORY.EDIT.replace(':id', row.code)}>
        {value}
      </Link>
    ),
  },
  {
    title: 'Tên danh mục',
    dataIndex: 'name',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    align: 'center',
    render: (value, record) => (
      <Switch key={record.id} checked={value} onChange={() => onChangeStatus(record.id, value)} />
    ),
  },
]

const CategoryList = ({ categories, meta: { pageSize, current, total }, onEdit, onView, onChangePage, onChangeSize, onChangeStatus }) => (
  <Table
    size="small"
    scroll={{ x: 'max-content' }}
    columns={columns(onView, onEdit, onChangeStatus)}
    style={{ marginTop: '10px' }}
    dataSource={categories}
    rowKey="code"
    rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
    pagination={{
      current,
      total,
      pageSize,
      onChange: onChangePage,
      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} hàng hóa`,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40', '80'],
      onShowSizeChange: onChangeSize,
    }}
  />
)

CategoryList.propTypes = {
  categories: PropTypes.array,
  meta: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeSize: PropTypes.func,
  onChangeStatus: PropTypes.func
}

CategoryList.defaultProps = {
  categories: [],
  meta: {},
  onEdit: () => {},
  onView: () => {},
  onChangePage: () => {},
  onChangeSize: () => {},
  onChangeStatus: () => {}
}

export default WithLoading(CategoryList)
