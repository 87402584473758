import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const listState = {
  items: [],
  meta: {
    current: 1,
    total: 0,
    pageSize: 0,
  },
  isFetching: false,
  didInvalidate: false,
}

const list = (state = listState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_SCHEDULES:
    draft.isFetching = true
    break

  case TYPES.GET_SCHEDULES_SUCCESS: {
    draft.items = action.payload.data
    draft.meta = {
      current: action.payload.meta?.page || 1,
      pageSize: action.payload.meta?.pageSize || 10,
      total: action.payload.meta?.total || 0,
    }
    draft.isFetching = false
    break
  }

  case TYPES.GET_SCHEDULES_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})

const sampleState = {
  items: [],
  isFetching: false,
}

const sample = (state = sampleState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_SAMPLE_SCHEDULES:
    draft.isFetching = true
    break

  case TYPES.GET_SCHEDULES_SAMPLE_SUCCESS: {
    draft.items = action.payload.data
    draft.isFetching = false
    break
  }

  case TYPES.GET_SCHEDULES_SAMPLE_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})

const detailState = {
  item: {},
  mapConfig: {
    zoom: 13,
    center: {
      lat: 20.995271,
      lng: 105.780953,
    },
  },
  isFetching: false,
  didInvalidate: false,
}

const detail = (state = detailState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_SCHEDULE:
    draft.isFetching = true
    break

  case TYPES.GET_SCHEDULE_SUCCESS:
    draft.item = action.payload.data
    draft.isFetching = false
    break

  case TYPES.GET_SCHEDULE_FAILURE:
    draft.isFetching = false
    break

  default:
    return draft
  }
})


export default combineReducers({ list, sample, detail })
