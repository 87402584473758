/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Tabs, notification } from 'antd'

import APITab from './components/APITab'
import APIForm from './components/APIForm'
import ListAPI from './components/ListAPI'
import RoleTab from './components/RoleTab'
import RoleForm from './components/RoleForm'

import {
  getAPIs, createAPI, changeStatusAPI, updateAPI,
  getRoles, createRole, editRole, activeRole, deactiveRole,
  deleteApiOfRole, deleteListApiOfRole, createApiOfRole, addApiOfRoleFromExcel,
} from './actions'
import select from '../../util/select'

const { TabPane } = Tabs

class ManagerPage extends Component {
  state = {
    visibleAPI: false,
    pageSizeAPI: 10,
    visibleRole: false,
    pageSizeRole: 10,
    visibleListAPI: false,
    listAPI: {}
  }

  componentDidMount() {
    this.props.getAPIs({ page: -1 })
    this.props.getRoles({ pageSize: -1 })
  }

  // /////////////////    api     /////////////////////////
  toggleModalAPI = () => {
    this.setState({ visibleAPI: !this.state.visibleAPI })
  }

  onCreateAPI = values => {
    this.props.createAPI(values, {
      onSuccess: () => {
        notification.success({ message: 'Tạo API mới thành công' })
        this.toggleModalAPI()
      },
      onError: mes => {
        notification.error({ message: `Tạo API thất bại. ${mes}` })
      },
    }, this.state.pageSizeAPI)
  };

  changeStatusAPI = (id, payload) => {
    this.props.changeStatusAPI(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật trạng thái' })
        this.props.getAPIs({ page: -1 })
        this.props.getRoles({ pageSize: -1 })
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    })
  }

  updateAPI = (id, payload) => {
    this.props.updateAPI(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật API' })
        this.toggleModalAPI()
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    }, this.state.pageSizeAPI)
  }

  setAddAPI = () => {
    this.setState({ editAPI: {} }, () => this.toggleModalAPI())
  }

  setEditAPI = (id, payload) => {
    this.setState({ editAPI: { id, payload } }, () => this.toggleModalAPI())
  }

  // ////////////////   role    ////////////////
  toggleModalRole = () => {
    this.setState({ visibleRole: !this.state.visibleRole })
  }

  onCreateRole = values => {
    this.props.createRole(values, {
      onSuccess: () => {
        notification.success({ message: 'Tạo vai trò mới thành công' })
        this.toggleModalRole()
      },
      onError: mes => {
        notification.error({ message: `Tạo vai trò thất bại. ${mes}` })
      },
    }, this.state.pageSizeRole)
  }

  onEditRole = (id, payload) => {
    this.props.onEditRole(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật vai trò' })
        this.toggleModalRole()
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    })
  }

  setAddRole = () => {
    this.setState({ editRole: {} }, () => this.toggleModalRole())
  }

  setEditRole = (id, payload) => {
    this.setState({ editRole: { id, payload } }, () => this.toggleModalRole())
  }

  activeRole = id => {
    this.props.activeRole(id, {
      onSuccess: () => {
        notification.success({ message: 'Trạng thái đã được cập nhật' })
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    })
  }

  deactiveRole = id => {
    this.props.deactiveRole(id, {
      onSuccess: () => {
        notification.success({ message: 'Trạng thái đã được cập nhật' })
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    })
  }

  toggleModalList = () => {
    this.setState({ visibleListAPI: !this.state.visibleListAPI })
  }

  getIdListAPI = payload => {
    this.setState({ listAPI: { payload } }, () => this.toggleModalList())
  }

  deleteApiOfRole = (roleId, apiId) => {
    this.props.deleteApiOfRole(roleId, apiId, {
      onSuccess: () => {
        notification.success({ message: 'Đã xóa api' })
      },
      onError: error => notification.error({ message: `${error} - Xóa thất bại` }),
    })
  }

  getRoles = id => {
    this.props.getRoles(id)
  }

  getAPIs = page => {
    this.props.getAPIs(page)
  }

  deleteListApiOfRole = payload => {
    this.props.deleteListApiOfRole(payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã xóa api' })
      },
      onError: error => notification.error({ message: `${error} - Xóa thất bại` }),
    })
  }

  createApiOfRole = payload => {
    if (payload.api_ids.length === 0) {
      notification.error({ message: 'Chưa chọn API' })
    } else {
      this.props.createApiOfRole(payload, {
        onSuccess: () => {
          notification.success({ message: 'Thêm mới api thành công' })
        },
        onError: error => notification.error({ message: `${error} - Thêm thất bại` }),
      })
    }
  }

  uploadApiFromExcel = payload => {
    this.props.addApiOfRoleFromExcel(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm mới api thành công' })
      },
      onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
    })
  }

  render() {
    const { apis, isFetching, meta, roles, isFetchingRole, metaRole } = this.props
    const { visibleAPI, visibleRole, visibleListAPI } = this.state
    return (
      <Fragment>
        <Tabs style={{ width: '100%' }}>
          <TabPane tab="Vai trò" key="role">
            <RoleForm
              visibleRole={visibleRole}
              editRole={this.state.editRole}
              onToggleModalRole={this.toggleModalRole}
              onCreateRole={this.onCreateRole}
              onEditRole={this.onEditRole}
            />
            <ListAPI
              visibleListAPI={visibleListAPI}
              onToggleModalList={this.toggleModalList}
              listAPI={this.state.listAPI}
              onDeleteApiOfRole={this.deleteApiOfRole}
              apis={apis}
              getAPIs={this.getAPIs}
              roles={roles}
              getRoles={this.getRoles}
              isFetching={isFetching}
              uploadApiFromExcel={this.uploadApiFromExcel}
              onDeleteListApiOfRole={this.deleteListApiOfRole}
              onCreateApiOfRole={this.createApiOfRole}
            />
            <RoleTab
              dataSource={roles}
              meta={metaRole}
              isFetching={isFetchingRole}
              onSetAddRole={this.setAddRole}
              onSetEditRole={this.setEditRole}
              onActiveRole={this.activeRole}
              onDeactiveRole={this.deactiveRole}
              onGetIdListAPI={this.getIdListAPI}
            />
          </TabPane>
          <TabPane tab="API" key="api">
            <APIForm
              visibleAPI={visibleAPI}
              editAPI={this.state.editAPI}
              onToggleModalAPI={this.toggleModalAPI}
              onCreateAPI={this.onCreateAPI}
              onUpdateAPI={this.updateAPI}
            />
            <APITab
              dataSource={apis}
              meta={meta}
              isFetching={isFetching}
              onChangeStatusAPI={this.changeStatusAPI}
              onSetAddAPI={this.setAddAPI}
              onSetEditAPI={this.setEditAPI}
            />
          </TabPane>
        </Tabs>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  apis: select(state, ['managerReducer', 'api'], 'items'),
  isFetching: select(state, ['managerReducer', 'api'], 'isFetching'),
  meta: select(state, ['managerReducer', 'api'], 'meta'),
  roles: select(state, ['managerReducer', 'role'], 'items'),
  isFetchingRole: select(state, ['managerReducer', 'role'], 'isFetching'),
  metaRole: select(state, ['managerReducer', 'role'], 'meta'),
})

const mapDispatchToProps = dispatch => ({
  // api
  getAPIs: params => dispatch(getAPIs(params)),
  createAPI: (payload, meta, pageSizeAPI) => dispatch(createAPI(payload, meta, pageSizeAPI)),
  changeStatusAPI: (id, payload, meta) => dispatch(changeStatusAPI(id, payload, meta)),
  updateAPI: (id, payload, meta) => dispatch(updateAPI(id, payload, meta)),
  // role
  getRoles: params => dispatch(getRoles(params)),
  createRole: (payload, meta, pageSizeRole) => dispatch(createRole(payload, meta, pageSizeRole)),
  onEditRole: (id, payload, meta) => dispatch(editRole(id, payload, meta)),
  activeRole: (id, meta) => dispatch(activeRole(id, meta)),
  deactiveRole: (id, meta) => dispatch(deactiveRole(id, meta)),
  deleteApiOfRole: (roleId, apiId, meta) => dispatch(deleteApiOfRole(roleId, apiId, meta)),
  deleteListApiOfRole: (payload, meta) => dispatch(deleteListApiOfRole(payload, meta)),
  createApiOfRole: (payload, meta) => dispatch(createApiOfRole(payload, meta)),
  addApiOfRoleFromExcel: (payload, meta) => dispatch(addApiOfRoleFromExcel(payload, meta)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagerPage)
