/* eslint react/prop-types: 0 */
import React from 'react'
import { Row, Col, Card, notification, Tabs, Button } from 'antd'
import { connect } from 'react-redux'
import WithPageHOC from '../../hoc/page'
import CustomerForm from './components/customer-form'
import PriceTable from './components/price-table'
import UploadFrom from './components/price-table-modal'
import select from '../../util/select'
import {
  updateCustomer,
  deleteCustomer,
  getCustomer,
  getCustomerGroups,
  getPriceTables,
  getPriceTable,
  addItemToPriceTable,
  removeItemFromPriceTable,
  updateItemInPriceTable,
  importPriceTable,
} from './actions'
import { getAccounts, createAccount } from '../General/actions'
import { getCarts } from '../Cart/actions'
import { getProducts } from '../Product/actions'
import { getInvoices } from '../Invoice/actions'
import ROUTER from '../../constants/router'
import HistoryOrder from '../Invoice/components/history-order-modal'

class EditCustomerPage extends React.Component {
  state = {
    showImportModal: false,
    error: false,
    hasResult: false,
    result: {},
    editable: false,
    orderHistoryModalVisible: false
  }

  componentDidMount() {
    this.props.getProducts({ pageSize: -1 })
    this.props.getAccounts({ pageSize: -1 })
  }

  static getDerivedStateFromProps(props, state) {
    // const { meta } = props
    const cusCode = props.match.params.id
    if (cusCode && cusCode !== state.code) {
      props.getCustomer(cusCode)
      props.getCarts({ pageSize: 1000 })
      props.getCustomerGroups()
      props.getPriceTables({ customerCode: cusCode })
      return { code: cusCode }
    }
    return null
  }

  // onChangePage = (page, pageSize) => this.props.getPriceTable(this.props.customer.get('Code'), { page, pageSize })

  toggleImportModal = () => this.setState(prevState => ({ showImportModal: !prevState.showImportModal }))

  handleUpload = (params, payload) => {
    this.props.importPriceTable(params, payload, {
      onSuccess: result => {
        this.setState(
          () => ({ result, hasResult: true, error: false }),
          () => {
            notification.success({ message: 'Thêm thành công' })
          }
        )
      },
      onError: error => {
        this.setState(
          () => ({ result: { error }, hasResult: true, error: true }),
          () => {
            notification.success({ message: 'Thêm thất bại' })
          }
        )
      },
    })
  }

  handleSubmit = payload => {
    this.props.updateCustomer(this.props.customer.id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Sửa thông tin khách hàng thành công' })
        this.props.history.push(ROUTER.CUSTOMER.INDEX)
      },
      onError: error => notification.error({ message: `${error} - Sửa thông tin khách hàng thất bại` }),
    })
  }

  handleDelete = () => {
    this.props.history.push(ROUTER.CUSTOMER.INDEX)
    this.props.deleteCustomer(this.props.match.params.id, {
      onSuccess: () => {
        notification.success({ message: 'Xoá khách hàng thành công' })
      },
      onError: error => notification.error({ message: `${error} - Xóa khách hàng thất bại` }),
    })
  }

  onEditableCustomer = () => {
    this.setState(prevState => ({ editable: !prevState.editable }))
  }

  getPriceTables = params => {
    this.props.getPriceTables(params)
  }

  getPriceTable = id => {
    this.props.getPriceTable(id)
  }

  onUpdateCart = req => {
    this.props.updateCart(req)
  }

  openOrderHistory = () => {
    const { customer } = this.props
    this.props.getInvoices({ customer_code: customer.code })
    this.toggleOrderHistory()
  }

  toggleOrderHistory = () => {
    this.setState(state => ({ orderHistoryModalVisible: !state.orderHistoryModalVisible }))
  }

  render() {
    const {
      customer,
      products,
      carts,
      accounts,
      groups,
      priceTables,
      priceTable,
      isFetching,
      isPriceTableFetching,
      addItemToPriceTable,
      removeItemFromPriceTable,
      updateItemInPriceTable,
      getPriceTable,
      getPriceTables,
      createAccount,
      invoices,
      isLoadingPriceTables
    } = this.props
    const { showImportModal, editable, hasResult, error, result } = this.state
    return (
      <div>
        <Row>
          <Col span={24}>
            <Tabs>
              <Tabs.TabPane key="customer" tab="Thông tin">
                <Card
                  title={<h2>Thông tin khách hàng</h2>}
                  extra={(
                    <Button onClick={this.openOrderHistory}>Lịch sử đặt hàng</Button>
                  )}
                >
                  <CustomerForm
                    carts={carts}
                    groups={groups}
                    accounts={accounts}
                    isFetching={isFetching}
                    editMode
                    editable={editable}
                    customer={customer}
                    onSubmit={this.handleSubmit}
                    onDelete={this.handleDelete}
                    createAccount={createAccount}
                  />
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key="priceTable" tab="Bảng giá">
                <PriceTable
                  forCustomer
                  customerCode={customer.code}
                  carts={carts}
                  priceTables={priceTables}
                  priceTable={priceTable.data}
                  meta={priceTable.meta}
                  isFetching={isLoadingPriceTables}
                  products={products}
                  addItem={addItemToPriceTable}
                  khanh={false}
                  updateItem={updateItemInPriceTable}
                  removeItem={removeItemFromPriceTable}
                  isCustomerGroup
                  getPriceTables={getPriceTables}
                  getPriceTable={getPriceTable}
                  onOpenImportModal={this.toggleImportModal}
                  onOpenTabAddPrice={this.onOpenTabAddPrice}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <UploadFrom
            visible={showImportModal}
            onCloseModal={this.toggleImportModal}
            customerCode={customer.code}
            isFetching={showImportModal && isPriceTableFetching}
            showGroup={false}
            onUpload={this.handleUpload}
            hasResult={hasResult}
            error={error}
            result={result}
          />
          <HistoryOrder
            visible={this.state.orderHistoryModalVisible}
            onCancel={this.toggleOrderHistory}
            invoices={invoices}
            toggleHistory={this.toggleOrderHistory}
            isFetching={isFetching}
          />
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customer: select(state, ['customerReducer', 'customer'], 'editingCustomer'),
  carts: select(state, ['cartReducer', 'cart'], 'items'),
  accounts: select(state, ['generalReducer', 'account'], 'items'),
  groups: select(state, ['customerReducer', 'group'], 'items'),
  units: select(state, ['generalReducer', 'productUnit'], 'items'),
  products: select(state, ['productReducer', 'list'], 'items'),
  priceTables: select(state, ['customerReducer', 'customer'], 'editingPriceTables'),
  priceTable: select(state, ['customerReducer', 'customer'], 'editingPriceTable'),
  invoices: select(state, ['invoiceReducer', 'list'], 'items'),
  meta: select(state, ['customerReducer', 'priceTable'], 'meta'),
  didInvalidate: select(state, ['customerReducer', 'priceTable'], 'didInvalidate'),
  isFetching: select(state, ['customerReducer', 'customer'], 'isFetching'),
  isLoadingPriceTables: select(state, ['customerReducer', 'customer'], 'isFetching'),
  isLoadingOrderHistory: select(state, ['invoiceReducer', 'list'], 'isFetching'),
})

const mapDispatchToProps = dispatch => ({
  getCustomer: id => dispatch(getCustomer(id)),
  getCarts: params => dispatch(getCarts(params)),
  getCustomerGroups: params => dispatch(getCustomerGroups(params)),
  getProducts: params => dispatch(getProducts(params)),
  getPriceTable: id => dispatch(getPriceTable(id)),
  getPriceTables: params => dispatch(getPriceTables(params)),
  getInvoices: params => dispatch(getInvoices(params)),
  updateCustomer: (id, payload, meta) => dispatch(updateCustomer(id, payload, meta)),
  addItemToPriceTable: (payload, meta) => dispatch(addItemToPriceTable(payload, meta)),
  removeItemFromPriceTable: (id, payload, meta) => dispatch(removeItemFromPriceTable(id, payload, meta)),
  updateItemInPriceTable: (id, payload, meta) => dispatch(updateItemInPriceTable(id, payload, meta)),
  importPriceTable: (params, payload, meta) => dispatch(importPriceTable(params, payload, meta)),
  deleteCustomer: (id, meta) => dispatch(deleteCustomer(id, meta)),
  getAccounts: params => dispatch(getAccounts(params)),
  createAccount: (payload, meta) => dispatch(createAccount(payload, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithPageHOC('customer', 'data')(EditCustomerPage))
