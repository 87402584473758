import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Select, Modal } from 'antd'
import { changeAlias } from '../../../util/formatText'

const { Option } = Select
class AdminForm extends React.Component {
  state = { confirmDirty: false };

    handleSubmit = e => {
      e.preventDefault()
      this.props.form.validateFields((error, values) => {
        if (!error) {
          const { userName, password, role } = values
          if (this.props.customer) {
            this.props.onRegister({ userName, password, role: 'customer', avatar: '' })
          } else this.props.onRegister({ userName, password, role, avatar: '' })
          this.props.form.resetFields()
        }
      })
    };

    handleConfirmBlur = e => {
      const { value } = e.target
      this.setState(prevState => ({ confirmDirty: prevState.confirmDirty || !!value }))
    };

    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props
      if (value && value !== form.getFieldValue('password')) {
        callback('Không giống mật khẩu trên!')
      } else {
        callback()
      }
    };

    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    };

    render() {
      const { form, visible, toggleModal, customer, roles } = this.props
      const getFieldDecorator = form.getFieldDecorator
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 },
        },
      }
      return (
        <Modal
          title="Thêm tài khoản người dùng"
          visible={visible}
          onOk={this.handleSubmit}
          onCancel={toggleModal}
          okText="Tạo mới"
          cancelText="Hủy"
        >
          <Form {...formItemLayout}>
            <Form.Item label="Tên tài khoản">
              {getFieldDecorator('userName', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: `${'Nhập tên tài khoản'}!`,
                  },
                ],
              })(<Input placeholder="Tên tài khoản" />)}
            </Form.Item>
            <Form.Item label="Mật khẩu" hasFeedback>
              {getFieldDecorator('password', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: `${'Nhập mật khẩu'}!`,
                  },
                  { validator: this.validateToNextPassword },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label="Nhập lại mật khẩu" hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: `${'Nhập mật khẩu'}!`,
                  },
                  { validator: this.compareToFirstPassword },
                ],
              })(<Input.Password onBlur={this.handleConfirmBlur} />)}
            </Form.Item>
            {!customer && (
              <Form.Item label="Vai trò">
                {getFieldDecorator('role', {
                  rules: [
                    {
                      required: true,
                      message: 'Chọn vai trò',
                    },
                  ],
                })(
                  <Select
                    placeholder="Chọn vai trò"
                    showSearch
                    style={{ width: '200px' }}
                    filterOption={(input, option) => changeAlias(option.props.children.toLowerCase()).indexOf(
                      changeAlias(input.toLowerCase()),
                    ) >= 0}
                  >
                    {roles && roles.map(item => (
                      <Option value={item.code} key={item.code}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            )}
          </Form>
        </Modal>
      )
    }
}

AdminForm.propTypes = {
  form: PropTypes.object.isRequired,
  onRegister: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  customer: PropTypes.bool
}

export default Form.create()(AdminForm)
