/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable quotes */
/* eslint-disable semi */
/* eslint-disable react/no-array-index-key */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, notification, Button, Alert, Divider, Row, Col, DatePicker } from 'antd';
import * as XLSX from 'xlsx';

import moment from 'moment';

import { getQRStampUrl } from './actions';

class QRStampPage extends Component {
  constructor() {
    super();
    this.state = {
      errorFile: false,
      arrayStamp: [],
      // isPrintAgain: false,
      manufaturingDate: moment().add(1, 'd'),
      isPrinting: false,
      pdfFileUrl: '',
    };
    this.fileUpload = React.createRef();
  }

  importExcel = file => {
    const fileReader = new FileReader();
    const { manufaturingDate } = this.state;

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let numberOfDataCol = 0;
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const Sheet = workbook.Sheets[workbook.SheetNames[0]];
        const rawData = XLSX.utils.sheet_to_json(Sheet);
        for (let i = 1; i <= rawData.length; i++) {
          const flagCell = Sheet[`A${i}`];
          if (flagCell !== undefined) {
            numberOfDataCol = i;
            // 1
          }
        }

        if (!rawData?.length || numberOfDataCol === 0) {
          notification.error({ message: 'File excel không có dữ liệu hoặc không đọc được!', duration: 1 });
        } else {
          const arrayTotal = [];

          rawData.forEach(row => {
            const values = Object.values(row);
            if (values[0] && values[1] && values[2] && values[2] !== '0') {
              arrayTotal.push({
                product_code: values[0],
                product_name: values[1],
                quantity: Number.parseInt(values[2], 10),
                manufacturing_date: manufaturingDate.toISOString(),
              });
            }
          });

          if (arrayTotal.length) {
            notification.success({ message: `File in tem có ${arrayTotal.length} mặt hàng hợp lệ!`, duration: 2 });
            this.setState({ arrayStamp: arrayTotal });
          } else {
            notification.error({ message: 'File excel không có dữ liệu hoặc không đọc được!', duration: 1 });
          }
        }
      } catch (e) {
        notification.error({ message: e, duration: 5 });
      }
    };
    fileReader.readAsBinaryString(file);
  };

  onImportExcel = event => {
    const { files } = event.target;
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(files[0]);
    }
  };

  onUpload = () => {
    this.setState({ isPrinting: true, pdfFileUrl: '' });
    this.props.getQRStampUrl(
      { stamps: this.state.arrayStamp },
      {
        onSuccess: pdfUrl => {
          notification.success({ message: 'Tạo mới phiếu tem thành công', duration: 5 });
          this.setState({ arrayStamp: [], isPrinting: false, pdfFileUrl: pdfUrl });
        },
        onError: mes => {
          this.setState({ arrayStamp: [], isPrinting: false });
          notification.error({ message: `Tạo thất bại. ${mes}`, duration: 5 });
        },
      },
    );
  };

  // onChangeDoNotReprint = e => this.setState({ isPrintAgain: e.target.checked });

  render() {
    const { errorFile, arrayStamp, isPrinting, pdfFileUrl } = this.state;
    // const { changedDataMessages } = this.props;

    return (
      <Row type="flex" justify="center">
        <Col span={24} lg={14}>
          <Card>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col span={24}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/spreadsheets/d/175_Ve2XInPrf0vlfgbt5tBP6ahHXqePK/edit?usp=sharing"
                >
                  Mẫu file excel
                </a>
                {/* <Checkbox style={{ float: 'right' }} onChange={this.onChangeDoNotReprint}>
                  Không in lại
                </Checkbox> */}
              </Col>
            </Row>

            <hr />

            <Divider dashed />
            <Row gutter={15}>
              <Col span={24}>
                <input
                  id="fileUpload"
                  type="file"
                  ref={v => {
                    this.fileUpload = v;
                  }}
                  accept=".xlsx, .xls"
                  onChange={this.onImportExcel}
                />

                {errorFile && <Alert style={{ marginTop: 10 }} type="error" showIcon message="Chọn file để upload" />}
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12} style={{ marginTop: 10 }}>
                <b>Ngày Sản Xuất:</b>
                <DatePicker
                  allowClear={false}
                  style={{ width: '100%' }}
                  placeholder="Ngày sản xuất"
                  format="DD/MM/YYYY"
                  value={this.state.manufaturingDate}
                  onChange={val => this.setState({ manufaturingDate: val })}
                />
              </Col>
              <Col span={12} style={{ marginTop: 31 }}>
                <Button
                  onClick={() => this.onUpload()}
                  disabled={!arrayStamp.length}
                  loading={isPrinting}
                  style={{
                    backgroundColor: arrayStamp.length && '#1890ff',
                    color: arrayStamp.length && 'white',
                    borderRadius: '7px',
                    float: 'right',
                  }}
                >
                  Xuất file PDF
                </Button>
              </Col>
            </Row>
            {pdfFileUrl ? (
              <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 10 }}>
                <Col>
                  <strong>Xem File:&nbsp;</strong>
                  <a href={pdfFileUrl} target="_blank" rel="noopener noreferrer">
                    {pdfFileUrl}
                  </a>
                </Col>
              </Row>
            ) : null}
            {/* {changedDataMessages?.length ? (
              <>
                <Divider dashed />
                <Row type="flex" justify="space-between" align="middle">
                  <Col>
                    <strong>Số liệu thay đổi so với lần in trước:</strong>
                    <ul>
                      {changedDataMessages.map((item, index) => (
                        <li key={`changed-${index}-${item.length}`}>{item}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </>
            ) : null} */}
          </Card>
        </Col>
      </Row>
    );
  }
}

// const mapStateToProps = state => ({
//   url_qr_stamp: select(state, ['stampReducer', 'stamp'], 'url_qr_stamp'),
// });

const mapDispatchToProps = dispatch => ({
  getQRStampUrl: (payload, meta) => dispatch(getQRStampUrl(payload, meta)),
});

export default connect(null, mapDispatchToProps)(QRStampPage);
