/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, Divider, notification, Switch, Table, Button, Row, Col, Tabs, Select } from 'antd'
import { Link } from 'react-router-dom'
import router from '../../constants/router'
import {
  getCustomerOfGroup,
  addCustomersToGroup,
  removeCustomerFromGroup,
  getCustomers,
  updateGroup,
  getPriceTable,
  getPriceTables,
  addItemToPriceTable,
  changeCustomerStatus
} from './actions'
import { getExistPriceTable, deleteExistPriceTable } from '../PriceList/actions'
import { getProducts, getAllProducts } from '../Product/actions'
import { getCategories } from '../Category/actions'
import { insertPriceTableFromPreview, clearExistPriceTable } from '../Price/actions'
import select from '../../util/select'
import SelectCustomerForm from './components/SelectCustomerForm'
import GroupForm from './components/GroupForm'
import WithLoadingHOC from '../../hoc/loading'
import PriceTable from './components/price-table/index'
import AddForm from '../Price/index'
import UploadForm from './components/price-table-modal'
import { isBelowBreakpoint } from '../../util/windows'

const { Option } = Select
const columns = () => [
  {
    title: 'Mã khách hàng',
    dataIndex: 'code',
    key: 'code',
    render: (value, record) => (
      <Link to={router.CUSTOMER.EDIT.replace(':id', record.customer.code)}>{record.customer.code}</Link>
    )
  },
  {
    title: 'Tên khách hàng',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => record.customer.name
  },
  // {
  //   title: 'Địa chỉ',
  //   key: 'address',
  //   render: (value, record) => formatAddress(record.customer)
  // },
  // {
  //   title: 'Số điện thoại',
  //   dataIndex: 'phoneNumber',
  //   key: 'phoneNumber',
  //   render: (value, record) => record.customer.phoneNumber
  // },
  {
    title: 'Trạng thái',
    dataIndex: 'active',
    key: 'active',
    align: 'center',
    render: (value, record) => (
      <Switch key={record.customer.id} defaultChecked={record.customer.active} disabled />
      // <Switch
      // defaultChecked={value}
      // onChange={value => (value === true
      //   ? onDeactiveCustomer(record.code, value)
      //   : onActiveCustomer(record.code, value))
      // }
      // />
    ),
  }
]
class DetailGroup extends Component {
  state = {
    visible: false,
    groupID: '',
    isRegistering: false,
    importExist: false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.id !== state.groupID) {
      props.getPriceTables({ groupID: props.match.params.id })
      return { ...state, groupID: props.match.params.id }
    }
    return { ...state }
  }

  async componentDidMount() {
    const params = { pageSize: -1 }
    this.props.getCustomerOfGroup(this.props.match.params.id, params)
    this.props.getCustomers({ pageSize: -1 })
    this.props.getProducts({ pageSize: -1 })
    this.props.clearExistPriceTable()
  }

  handleSubmit = selected => {
    const params = {
      page: -1,
      pageSize: -1
    }
    const payload = {
      groupId: Number.parseInt(this.state.groupID, 10),
      customerId: selected
    }
    this.setState({ isRegistering: true })
    this.props.addCustomersToGroup(
      payload,
      {
        onSuccess: () => {
          notification.success({ message: 'Thêm khách hàng vào nhóm thành công' })
          this.props.getCustomerOfGroup(this.props.match.params.id, params)
        },
        onError: () => {
          notification.success({ message: 'Thêm khách hàng vào nhóm thất bại' })
        }
      }
    )
    this.setState({ isRegistering: false })
  };

  onPreviewSubmit = (payload, callback) => {
    this.props.createPriceTableFromPreview(
      payload,
      {
        onSuccess: () => {
          notification.success({ message: 'Tạo bảng giá thành công' })
          this.toggleReview()
          this.props.getPriceTables({ groupID: this.props.match.params.id })
          callback()
        },
        onError: () => {
          notification.error({ message: 'Tạo bảng giá thất bại' })
          callback()
        }
      }
    )
  }

  onEditGroup = (id, payload) => {
    const params = {
      page: -1,
      pageSize: -1
    }
    this.props.updateGroup(
      id,
      payload,
      {
        onSuccess: () => {
          notification.success({ message: 'Sửa thông tin nhóm khách hàng thành công' })
          this.props.getCustomerOfGroup(this.props.match.params.id, params)
        },
        onError: error => notification.error({ message: `${error} - Sửa thông tin nhóm khách hàng thất bại` }),
      }
    )
  }

  onChangePage = (page, pageSize) => this.props.getCustomerOfGroup(this.props.match.params.id, { page, pageSize });

  toggleReview = () => {
    const { visible } = this.state
    if (visible === false) {
      this.props.getAllProducts({ pageSize: -1 })
    }
    this.setState({ visible: !visible })
  }

  importedFromExist = () => { this.setState({ importExist: false }) }

  changeStatus = (id, payload) => {
    this.props.changeStatus(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật trạng thái' })
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    })
  }

  render() {
    const {
      customers,
      allCustomers,
      meta,
      group,
      priceTables,
      priceTable,
      existPriceTable,
      getPriceTables,
      getPriceTable,
      getExistPriceTable,
      deleteExistPriceTable,
      products,
      addItemToPriceTable,
      isLoadingPriceTables,
    } = this.props
    const { visible, groupID, isRegistering, importExist } = this.state
    const group1 = {}
    Object.assign(group1, group)
    return (
      <div>
        <Row>
          <Col span={24}>
            <Tabs onChange={key => { if (key === 'prices') { deleteExistPriceTable() } }}>
              <Tabs.TabPane key="customer" tab="Thông tin">
                <Card>
                  <GroupForm editMode group={group1} onEdit={this.onEditGroup} />
                  <Divider />
                  <SelectCustomerForm customers={allCustomers} onSubmit={this.handleSubmit} />
                  <Divider />
                  <Table
                    size="small"
                    scroll={{ x: 'max-content' }}
                    rowClassName={(r, idx) => (idx % 2 === 0 ? 'even' : '')}
                    dataSource={customers}
                    columns={columns(this.changeStatus)}
                    meta={meta}
                    loading={isRegistering}
                    rowKey={record => record.customer.id}
                    // onChangePage={this.onChangePage}
                    // onChangeSize={this.onChangePage}
                    pagination={{
                      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} khách hàng`,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '40', '80'],
                    }}
                  />
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key="priceTable" tab="Bảng giá">
                <PriceTable
                  groupID={groupID}
                  priceTables={priceTables}
                  priceTable={priceTable.data}
                  meta={priceTable.meta}
                  isFetching={isLoadingPriceTables}
                  products={products}
                  addItem={addItemToPriceTable}
                  isCustomerGroup={false}
                  getPriceTables={getPriceTables}
                  getPriceTable={getPriceTable}
                  getExistPriceTable={getExistPriceTable}
                  onOpenImportModal={this.toggleImportModal}
                  onOpenTabAddPrice={this.onOpenTabAddPrice}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="prices" tab="Báo giá">
                <Row gutter={24}>
                  <Col span={24} lg={12}>
                    <Button type="primary" style={{ marginBottom: isBelowBreakpoint() ? 5 : 0 }} onClick={this.toggleReview}>Thêm từ Excel</Button>
                  </Col>
                  <Col span={24} lg={12}>
                    <Row gutter={15}>
                      <Col span={24} lg={16}>
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Chọn bảng giá"
                          onSelect={value => this.setState(() => getExistPriceTable(value, { pageSize: -1 }))}
                        >
                          {priceTables.map(item => (
                            <Option key={item.priceTableId} value={item.priceTableId}>{item.priceTableName}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={24} lg={8}>
                        <Button
                          type="primary"
                          disabled={existPriceTable.priceTableId === undefined}
                          onClick={() => this.setState({ importExist: true })}
                        >
                          {'Tạo từ Bảng giá cũ'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <AddForm group={group1} importExist={importExist} importDone={this.importedFromExist} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <UploadForm
            priceTables={priceTables}
            group={group}
            products={products}
            visible={visible}
            onCloseModal={this.toggleReview}
            onSubmit={this.onPreviewSubmit}
          />
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  group: select(state, ['customerReducer', 'listByGroup'], 'groupInfo'),
  allCustomers: select(state, ['customerReducer', 'customer'], 'items'),
  customers: select(state, ['customerReducer', 'listByGroup'], 'items'),
  meta: select(state, ['customerReducer', 'listByGroup'], 'meta'),
  isFetching: select(state, ['customerReducer', 'listByGroup'], 'isFetching'),
  isLoadingPriceTables: select(state, ['customerReducer', 'customer'], 'isFetching'),
  priceTables: select(state, ['customerReducer', 'customer'], 'editingPriceTables'),
  priceTable: select(state, ['customerReducer', 'customer'], 'editingPriceTable'),
  products: select(state, ['productReducer', 'list'], 'items'),
  productsForReview: select(state, ['productReducer', 'list'], 'itemsForReview'),
  existPriceTable: select(state, ['priceTableReducer', 'exist'], 'item'),
})

const mapDispatchToProps = dispatch => ({
  getCustomers: params => dispatch(getCustomers(params)),
  getCustomerOfGroup: (id, params) => dispatch(getCustomerOfGroup(id, params)),
  addCustomersToGroup: (id, cid) => dispatch(addCustomersToGroup(id, cid)),
  updateGroup: (id, payload, meta) => dispatch(updateGroup(id, payload, meta)),
  removeCustomerFromGroup: (payload, meta) => dispatch(removeCustomerFromGroup(payload, meta)),
  getPriceTables: params => dispatch(getPriceTables(params)),
  getPriceTable: id => dispatch(getPriceTable(id)),
  getExistPriceTable: (id, params) => dispatch(getExistPriceTable(id, params)),
  clearExistPriceTable: () => dispatch(clearExistPriceTable()),
  deleteExistPriceTable: () => dispatch(deleteExistPriceTable()),
  getProducts: params => dispatch(getProducts(params)),
  getAllProducts: params => dispatch(getAllProducts(params)),
  getCategories: params => dispatch(getCategories(params)),
  addItemToPriceTable: (id, payload, meta) => dispatch(addItemToPriceTable(id, payload, meta)),
  createPriceTableFromPreview: (payload, meta) => dispatch(insertPriceTableFromPreview(payload, meta)),
  changeStatus: (id, payload, meta) => dispatch(changeCustomerStatus(id, payload, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithLoadingHOC(DetailGroup))
