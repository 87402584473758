import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { notification } from 'antd'

import SaveRouteForm from '../components/save-route-form'

import { insertSchedule } from '../actions'
import select from '../../../util/select'
import ROUTER from '../../../constants/router'

class SaveDataStep extends Component {
  saveSolution = formValue => {
    const { solution, drivers, time } = this.props
    const { name } = formValue
    const notBlankRoutes = solution.routes.filter(item => item.nodes.length > 0)
    // console.log('notBlankRoutes', notBlankRoutes)
    const routes = notBlankRoutes.map(item => {
      const driver = drivers.find(d => d.code === item.driverId)
      const nodes = item.nodes.map((itemNode, indexNode) => ({
        ...itemNode,
        carriers: [{ ...driver, position: indexNode, isArrived: false }],
        confirmers: itemNode.confirmers?.length > 0 ? itemNode.confirmers : [driver],
      }))
      return {
        // ...item,
        nodes,
        totalDistance: item.distance,
        totalTime: item.duration,
        totalWeight: item.weight,
        driver,
      }
    })

    // const nodes = []
    // notBlankRoutes.forEach((itemRoute, indexRoute) => {
    //   itemRoute.nodes.forEach((itemNode, indexNode) => {
    //     if (nodes.filter(node => node.cart.cartCode === itemNode.cart.cartCode).length === 0) {
    //       nodes.push({
    //         cart: itemNode.cart,
    //         carriers: [{ ...routes[indexRoute].driver, position: indexNode, isArrived: false }],
    //         confirmers: itemNode.confirmers?.length > 0 ? itemNode.confirmers : [itemRoute.driver],
    //         weight: itemNode.weight
    //       })
    //     } else {
    //       for (let i = 0; i < nodes.length; i++) {
    //         if (nodes[i].cart.cartCode === itemNode.cart.cartCode) {
    //           nodes[i].carriers.push({ ...itemRoute.driver, position: indexNode, isArrived: false })
    //           nodes[i].weight += itemNode.weight
    //         }
    //       }
    //     }
    //   })
    // })
    const dataSchedule = {
      drivers,
      name,
      executionTime: time,
      // nodes,
      routes
    }
    // console.log('dataSchedule', dataSchedule)
    this.props.insertSchedule(
      dataSchedule,
      {
        onSuccess: () => {
          notification.success({ message: 'Thêm lịch thành công' })
          this.props.history.push(ROUTER.SCHEDULE.INDEX)
        },

        onError: () => notification.error({ message: 'Thêm lịch thất bại' }),
      },
    )
  };

  render() {
    const { drivers, nodes, solution, time } = this.props
    return (
      <div>
        <SaveRouteForm
          time={time}
          drivers={drivers}
          nodes={nodes}
          solution={solution}
          onSubmit={this.saveSolution}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  solution: select(state, 'routingReducer', 'solution'),
  drivers: select(state, 'routingReducer', 'drivers'),
  nodes: select(state, 'routingReducer', 'nodes'),
  time: select(state, 'routingReducer', 'time'),
})

const mapDispatchToProps = dispatch => ({ insertSchedule: (payload, meta) => dispatch(insertSchedule(payload, meta)) })

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SaveDataStep))
