import { notification, Typography } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getProduct, updateProductCriteria } from '../Product/actions';
import CriteriaList from './components/List';

class ProductCriteria extends Component {
  componentDidMount() {
    const { productCode, getProduct } = this.props;
    getProduct(productCode);
    return null;
  }

  add = values => {
    if (values.image_url) {
      const { productCode, productInfo, updateProductCriteria } = this.props;
      const currentCriteria = productInfo.criteria ? productInfo.criteria : [];
      const Criteria = currentCriteria.map(criterion => ({
        name: criterion.name || '',
        image_url: criterion.image_url || '',
      }));
      Criteria.push({
        name: values.name || '',
        image_url: values.image_url || '',
      });
      const payload = { criteria: Criteria };

      updateProductCriteria(productCode, payload, {
        onSuccess: () => notification.success({ message: 'Thêm thành công' }),
        onError: () => notification.error({ message: 'Thêm thất bại' }),
      });
    }
  };

  remove = item => {
    const { productInfo, updateProductCriteria, productCode } = this.props;

    const Criteria = productInfo.criteria;
    const newCriteria = Criteria.filter(items => items.image_url !== item.image_url);
    const newPayload = { criteria: newCriteria };
    updateProductCriteria(productCode, newPayload, {
      onSuccess: () => notification.success({ message: 'Xoá thành công' }),
      onError: () => notification.error({ message: 'Xoá thất bại' }),
    });
  };

  render() {
    const { productInfo } = this.props;
    return (
      <div>
        <Typography.Title
          header="Giấy chứng nhận"
          level={2}
          // description="Chứng nhận của sản phẩm"
        />
        <CriteriaList
          dataSource={productInfo.criteria || []}
          slug={productInfo.Slug}
          onAdd={this.add}
          onRemove={this.remove}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ productReducer }) => ({
  productInfo: productReducer.detail.item,
  productCode: productReducer.detail.item?.code,
  // isFetching: productReducer.detail.isFetching,
});

const mapDispatchToProps = dispatch => ({
  getProduct: productCode => dispatch(getProduct(productCode)),
  updateProductCriteria: (productCode, payload, meta) => dispatch(updateProductCriteria(productCode, payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCriteria);
