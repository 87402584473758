import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { DRIVER_STATUS } from '../../../../constants/enum'

const DriverList = ({ drivers, selectedDrivers, onSelectDrivers }) => {
  const rowSelection = {
    onChange: selectedRowKeys => {
      onSelectDrivers(selectedRowKeys)
    },
    selectedRowKeys: selectedDrivers.map(selectedDrivers => selectedDrivers.id),
    getCheckboxProps: record => ({ disabled: record.status !== 'present' }),
  }
  const columns = [
    {
      title: 'Mã',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phone',
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: (text, record) => (record.status === 'present' ? DRIVER_STATUS[0] : record.status === 'absent' ? DRIVER_STATUS[1] : DRIVER_STATUS[2]),
    },
  ]

  // const totalDrivers = drivers.length
  const driverSource = drivers.filter(item => item.status !== 'stop_working').sort((a, b) => {
    if (a.code < b.code) {
      return -1
    }
    if (a.code > b.code) {
      return 1
    }
    return 0
  })

  return (
    <div>
      <h2>
        {'Danh sách tài xế '}
        <span style={{ fontSize: 14, fontStyle: 'italic' }}>
          {`( Tổng số: ${driverSource.length})`}
        </span>
      </h2>
      <Table
        rowSelection={rowSelection}
        size="small"
        scroll={{ x: 'max-content' }}
        dataSource={driverSource}
        columns={columns}
        rowKey={record => record.id}
        rowClassName={(r, index) => (index % 2 ? 'whitesmoke' : '')}
        pagination={false}
        // pagination={{
        //   total: totalDrivers,
        //   showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} tài xế`,
        //   showSizeChanger: true,
        //   defaultPageSize: 20,
        //   pageSizeOptions: ['10', '20', '50'],
        // }}
      />
    </div>
  )
}

DriverList.propTypes = {
  drivers: PropTypes.array,
  selectedDrivers: PropTypes.array,
  onSelectDrivers: PropTypes.func.isRequired,
}

DriverList.defaultProps = {
  drivers: [],
  selectedDrivers: [],
}

export default DriverList
