import React from 'react'
import PropTypes from 'prop-types'
import { Table, Switch } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import WithLoading from '../../../hoc/loading'
import router from '../../../constants/router'
// import { formatAddress } from '../../../util/formatAddress'

const columns = (onEdit, onChangeStatus) => [
  {
    title: 'Mã giỏ',
    dataIndex: 'code',
    ket: 'code',
    render: value => (
      <Link to={router.CART.EDIT.replace(':id', value)}>{value}</Link>
    )
  },
  {
    title: 'Tên giỏ',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'address',
    key: 'address'
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => moment(value).format('LL')
  },
  {
    title: 'Trạng thái',
    dataIndex: 'active',
    align: 'center',
    render: (value, record) => (
      <Switch key={record.id} checked={value} onChange={() => onChangeStatus(record.id, { active: !value })} />
    ),
  },
]

const CartList = ({
  carts,
  meta,
  onEdit,
  onChangePage,
  onChangeSize,
  onChangeStatus
}) => (
  <Table
    size="small"
    scroll={{ x: 'max-content' }}
    columns={columns(onEdit, onChangeStatus)}
    style={{ marginTop: '10px' }}
    dataSource={carts}
    rowKey={record => record.id}
    rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
    pagination={{
      current: meta.current,
      total: meta.total,
      pageSize: meta.pageSize,
      onChange: onChangePage,
      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} giỏ hàng`,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40', '80'],
      onShowSizeChange: onChangeSize,
    }}
  />
)

CartList.propTypes = {
  carts: PropTypes.array,
  meta: PropTypes.object,
  onEdit: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeSize: PropTypes.func,
  onChangeStatus: PropTypes.func
}

CartList.defaultProps = {
  carts: [],
  meta: {},
  onEdit: () => {},
  onChangePage: () => {},
  onChangeSize: () => {},
  onChangeStatus: () => {}
}

export default WithLoading(CartList)
