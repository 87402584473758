/* eslint react/prop-types: 0 */
import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import WithAuthenticationHOC from '../../hoc/authenticate';

import Header from '../Header';
import Sidebar from '../Sidebar';

import Homepage from '../Homepage';
import Dashboard from '../Dashboard';
import Customer from '../Customer';
import Driver from '../Driver';
import Product from '../Product';
import Invoice from '../Invoice';
import Schedule from '../Schedule';
import Manager from '../Manager';

import Report from '../Report';
import General from '../General';

import EditProfile from '../Auth/EditProfile';

import Category from '../Category';
import Supplier from '../Supplier';
import Cart from '../Cart';

import SynData from '../SynData';

import Stamp from '../Stamp';
import NotFound from '../NotFound';
import Unauthorized from '../Unauthorized';

import ROUTER from '../../constants/router';
import '../../assets/css/style.css';

const { Content } = Layout;

const AppLayout = () => (
  <Layout
    style={{
      minHeight: '100vh',
      background: '#ffffff',
    }}
  >
    <Sidebar />
    <Content>
      <Header />
      <Content
        style={{
          margin: '10px',
          padding: '25px',
        }}
      >
        <Cart />
        <Customer />
        {/* <Vehicle /> */}
        <Driver />
        <Product />
        <Invoice />
        <Schedule />
        {/* <PriceTable /> */}
        <Category />
        <Supplier />
        {/* <Routes /> */}
        <Report />
        <General />
        <Switch>
          <Route path={ROUTER.HOME} component={Dashboard} exact />
          <Route path={ROUTER.ACCOUNT.PASSWORD} component={EditProfile} exact />
          <Route path={ROUTER.NOT_FOUND} component={NotFound} />
          <Route path={ROUTER.UNAUTHORIZED} component={Unauthorized} />
        </Switch>
        <Manager />
        <SynData />
        <Stamp />
      </Content>
    </Content>
  </Layout>
);

export default WithAuthenticationHOC(true)(AppLayout);
