/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Form, InputNumber, Col, notification, message, Button } from 'antd';
import debounce from 'lodash/debounce';

import { changeAlias } from '../../../../util/formatText';
import { isBelowBreakpoint } from '../../../../util/windows';

class PriceForm extends Component {
  constructor(props) {
    super(props);
    this.searchProduct = debounce(this.searchProduct, 300);
  }

  state = {
    isAdding: false,
    dataProductWhenSearch: [],
  };

  onAddItem = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let { pricePerUnit, product } = values;
        product = JSON.parse(product);
        const payload = {
          items: [
            {
              price: pricePerUnit,
              productId: product.productId,
            },
          ],
        };
        if (pricePerUnit <= 0) {
          message.warning('Vui lòng nhập giá lớn hơn 0 !');
        } else if (this.isInPriceTable(product) === false) {
          this.setState({ isAdding: true });
          this.props.addItem(this.props.priceTable.priceTableId, payload, {
            onSuccess: () => {
              this.setState({ isAdding: false });
              notification.success({ message: 'Thêm thành công' });
              this.props.form.resetFields();
            },
            onError: () => {
              this.setState({ isAdding: false });
              notification.error({ message: 'Cập nhật thất bại' });
            },
          });
        } else {
          notification.error({ message: 'Mẳt hàng đã tồn tại' });
        }
      } else {
        message.warning('Hãy chọn 1 sản phẩm');
      }
    });
  };

  findIndex = (list, code) => {
    let result = -1;
    list.forEach((item, index) => {
      if (item.productCode === code) {
        result = index;
      }
    });
    return result;
  };

  isInPriceTable = product => {
    const { priceTable } = this.props;
    if (!priceTable.priceItem) {
      return false;
    }
    if (this.findIndex(priceTable.priceItem, product.productCode) !== -1) {
      return true;
    }
    return false;
  };

  searchProduct = value => {
    const { products } = this.props;

    const data = [];

    for (let i = 0; i < products.length; i++) {
      if (
        (products[i].code.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          changeAlias(products[i].name.toLowerCase()).indexOf(changeAlias(value.toLowerCase())) !== -1) &&
        data.length <= 50
      ) {
        data.push(products[i]);
      }
    }

    this.setState({ dataProductWhenSearch: data });
  };

  render() {
    const {
      price,
      editMode,
      form: { getFieldDecorator },
      disabled,
    } = this.props;
    const { isAdding, dataProductWhenSearch } = this.state;

    return (
      <Form>
        <Col span={24} lg={10}>
          <Form.Item label="Tên sản phẩm">
            {getFieldDecorator('product', {
              initialValue: null,
              rules: [
                {
                  required: true,
                  message: 'Chọn sản phẩm',
                },
              ],
            })(
              <Select showSearch disabled={disabled} onSearch={this.searchProduct} filterOption={false}>
                {dataProductWhenSearch.map((item, index) => (
                  <Select.Option
                    key={`${index + 1}`}
                    value={JSON.stringify({ productId: item.id, productCode: item.code })}
                  >
                    {`(${item.code}) ${item.name}`}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
        <Col span={24} lg={10}>
          <Form.Item label="Giá sản phẩm">
            {getFieldDecorator('pricePerUnit', {
              initialValue: editMode ? price.pricePerUnit : 0,
              rules: [
                {
                  required: true,
                  message: 'Nhập giá sản phẩm',
                },
              ],
            })(
              <InputNumber
                disabled={disabled}
                style={{ width: '100%' }}
                min={0}
                max={10000000}
                step={100}
                formatter={value => `đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/đ\s?|đ |(,*)/g, '')}
              />,
            )}
          </Form.Item>
        </Col>
        <Col span={6} lg={4}>
          <Button
            type="primary"
            disabled={disabled}
            style={{ marginTop: isBelowBreakpoint() ? 1 : 42 }}
            loading={isAdding}
            onClick={() => this.onAddItem()}
          >
            Thêm
          </Button>
        </Col>
      </Form>
    );
  }
}

PriceForm.propTypes = {
  products: PropTypes.array,
  price: PropTypes.object,
  editMode: PropTypes.bool,
  form: PropTypes.object,
  addItem: PropTypes.func,
  disabled: PropTypes.bool,
};

PriceForm.defaultProps = {
  products: [],
  price: {},
  editMode: false,
  form: {},
  addItem: () => {},
  disabled: false,
};

export default Form.create()(PriceForm);
