/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Button, Form, Select } from 'antd'
import ROUTER from '../../../constants/router'
import { changeAlias } from '../../../util/formatText'

const { Item } = Form
const { Option } = Select
class FilterRow extends React.Component {
  state = { keyword: [] }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location
    const { keyword } = state

    const result = search.replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ').split(' ').splice(1)
    if (result !== keyword) {
      return { keyword: result }
    }

    return null
  }
  // static getDerivedStateFromProps(props, state) {
  //   const { filterOptions } = props
  //   let values
  //   if (filterOptions !== state.filterOptions) {
  //     if (filterOptions !== undefined) {
  //       values = JSON.parse(filterOptions)
  //       const { customerCode, name, groupCode, cartCode } = values
  //       props.form.setFieldsValue({ customerCode, name, groupCode, cartCode })
  //     } else {
  //       props.form.setFieldsValue({ customerCode: undefined, name: undefined, groupCode: undefined, cartCode: undefined })
  //     }
  //     return { ...state, filterOptions }
  //   }
  //   return state
  // }

  handleSubmit = e => {
    e.preventDefault()
    const c_params = this.props.filterOptions === undefined ? {} : this.props.filterOptions
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const urlParameters = []
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '') {
            urlParameters.push(e.join('='))
          }
          return null
        })
        if (urlParameters[0] !== false) {
          this.props.history.push(ROUTER.CUSTOMER.INDEX.concat(`?${urlParameters.join('&')}`))
        }
      } else {
        console.log(err)
      }
    })
  };

  handleReset = () => {
    this.props.form.resetFields()
    this.props.history.push(`${ROUTER.CUSTOMER.INDEX}`)
  };

  render() {
    const { disabled, user, accounts, form: { getFieldDecorator } } = this.props
    const { keyword } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={24} lg={6}>
            <Item label="Tên khách hàng">
              {
                getFieldDecorator('name', { initialValue: keyword.indexOf('name') === -1 ? '' : keyword[keyword.indexOf('name') + 1] })(
                  <Input
                    disabled={disabled}
                    placeholder="Tên khách hàng"
                    allowClear
                  />,
                )
              }
            </Item>
          </Col>
          <Col span={24} lg={6}>
            <Item label="Mã khách hàng">
              {
                getFieldDecorator('customerCode', { initialValue: keyword.indexOf('customerCode') === -1 ? '' : keyword[keyword.indexOf('customerCode') + 1] })(
                  <Input
                    disabled={disabled}
                    placeholder="Mã khách hàng"
                    allowClear
                  />,
                )
              }
            </Item>
          </Col>
          <Col span={24} lg={6}>
            <Item label="Mã giỏ hàng">
              {
                getFieldDecorator('cartCode', { initialValue: keyword.indexOf('cartCode') === -1 ? '' : keyword[keyword.indexOf('cartCode') + 1] })(
                  <Input
                    disabled={disabled}
                    placeholder="Mã giỏ hàng"
                    allowClear
                  />,
                )
              }
            </Item>
          </Col>
          <Col span={24} lg={6}>
            <Item label="Tên nhóm">
              {
                getFieldDecorator('groupName', { initialValue: keyword.indexOf('groupName') === -1 ? '' : keyword[keyword.indexOf('groupName') + 1] })(
                  <Input
                    disabled={disabled}
                    placeholder="Tên nhóm"
                    allowClear
                  />,
                )
              }
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          {/* <Col span={24} lg={6}>
            <Item label="Hình thức thanh toán">
              {
                getFieldDecorator('paymentMethod')(
                  <Select
                    placeholder="Hình thức thanh toán"
                    allowClear
                  >
                    <Option key={1} value="Theo hợp đồng">Theo hợp đồng</Option>
                    <Option key={2} value="Thanh toán trực tiếp">Thanh toán trực tiếp</Option>
                  </Select>
                )
              }
            </Item>
          </Col> */}
          <Col span={24} lg={6}>
            <Item label="Khách buôn">
              {
                getFieldDecorator('isWholesale', { initialValue: keyword.indexOf('isWholesale') === -1 ? '' : keyword[keyword.indexOf('isWholesale') + 1] })(
                  <Select
                    placeholder="Khách buôn"
                    allowClear
                  >
                    <Option value="true">Khách buôn</Option>
                    <Option value="false">Khách lẻ</Option>
                    <Option value="">Tất cả</Option>
                  </Select>
                )
              }
            </Item>
          </Col>
          <Col span={24} lg={6}>
            <Item label="Nhân viên quản lý">
              {
                getFieldDecorator('saleInChargeId', { initialValue: user.role === 'sale' ? user.id : keyword.indexOf('saleInChargeId') === -1 ? '' : keyword[keyword.indexOf('saleInChargeId') + 1] })(
                  <Select
                    placeholder="Nhân viên quản lý"
                    showSearch
                    allowClear
                    filterOption={(input, option) => changeAlias(option.props.children.toLowerCase()).indexOf(
                      changeAlias(input.toLowerCase()),
                    ) >= 0}
                  >
                    {accounts.filter(e => e.role === 'sale').map(item => (
                      <Option key={item.id} value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                )
              }
            </Item>
          </Col>
          <Col span={24} lg={6}>
            <Item label="Trạng thái">
              {
                getFieldDecorator('customerStatus', { initialValue: keyword.indexOf('customerStatus') === -1 ? 'true' : keyword[keyword.indexOf('customerStatus') + 1] })(
                  <Select
                    placeholder="Trạng thái"
                    // allowClear
                  >
                    <Option value="true">Đang hoạt động</Option>
                    <Option value="false">Dừng hoạt động</Option>
                    <Option value="all">Tất cả</Option>
                  </Select>
                )
              }
            </Item>
          </Col>
          <Col span={12} lg={3}>
            <Button
              icon="filter"
              disabled={disabled}
              type="primary"
              htmlType="submit"
              style={{ width: '100%', marginTop: '42px' }}
            >
              {'Lọc'}
            </Button>
          </Col>
          <Col span={12} lg={3}>
            <Button icon="close" disabled={disabled} onClick={this.handleReset} style={{ width: '100%', marginTop: '42px' }}>
              {'Bỏ lọc'}
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

FilterRow.propTypes = { disabled: PropTypes.bool.isRequired }

export default Form.create()(FilterRow)
