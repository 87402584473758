import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Modal, notification } from 'antd'

class RoleForm extends React.Component {
  state = { }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const { code, name, note } = values
        this.props.onCreateRole({ code, name, note })
        this.props.form.resetFields()
      } else {
        notification.warning({ message: 'Không được để trống !!!' })
      }
    })
  };

  onEditRole = e => {
    e.preventDefault()
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const { code, name, note } = values
        this.props.onEditRole(this.props.editRole.id, { code, name, note })
      } else {
        notification.warning({ message: 'Không được để trống !!!' })
      }
    })
  };

  afterClose = () => {
    this.props.form.resetFields()
  }

  render() {
    const { form: { getFieldDecorator }, visibleRole, onToggleModalRole, editRole } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    }
    return (
      <Modal
        title={(editRole && editRole.id) ? 'Cập nhập vai trò' : 'Thêm vai trò mới'}
        visible={visibleRole}
        onOk={(editRole && editRole.id) ? this.onEditRole : this.handleSubmit}
        onCancel={onToggleModalRole}
        okText={(editRole && editRole.id) ? 'Sửa' : 'Tạo mới'}
        cancelText="Hủy"
        afterClose={this.afterClose}
        destroyOnClose
      >
        <Form {...formItemLayout}>
          <Form.Item label="Mã">
            {getFieldDecorator('code', {
              rules: [
                {
                  required: true,
                  message: 'Nhập mã vai trò',
                },
              ],
              initialValue: editRole && editRole.payload ? editRole.payload.code : ''
            })(<Input placeholder="mã" />)}
          </Form.Item>
          <Form.Item label="Tên">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Nhập tên vai trò',
                },
              ],
              initialValue: editRole && editRole.payload ? editRole.payload.name : ''
            })(<Input placeholder="tên" />)}
          </Form.Item>
          <Form.Item label="Ghi chú">
            {getFieldDecorator('note', { initialValue: editRole && editRole.payload ? editRole.payload.note : '' })(<Input placeholder="ghi chú" />)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

RoleForm.propTypes = {
  form: PropTypes.object.isRequired,
  visibleRole: PropTypes.bool,
  onToggleModalRole: PropTypes.func.isRequired,
  onCreateRole: PropTypes.func.isRequired,
  onEditRole: PropTypes.func.isRequired,
}

export default Form.create()(RoleForm)
