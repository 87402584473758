import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'
import select from '../../util/select'

const gettingCustomers = () => ({ type: TYPES.GETTING_CUSTOMERS })
const getCustomersSuccess = payload => ({
  type: TYPES.GET_CUSTOMERS_SUCCESS,
  payload,
})
const getCustomersFailure = () => ({ type: TYPES.GET_CUSTOMERS_FAILURE })

const gettingCustomer = () => ({ type: TYPES.GETTING_CUSTOMER })
const getCustomerSuccess = payload => ({
  type: TYPES.GET_CUSTOMER_SUCCESS,
  payload,
})
const getCustomerFailure = () => ({ type: TYPES.GET_CUSTOMER_FAILURE })

const insertingCustomer = () => ({ type: TYPES.INSERTING_CUSTOMER })
const insertCustomerSuccess = () => ({ type: TYPES.INSERT_CUSTOMER_SUCCESS })
const insertCustomerFailure = () => ({ type: TYPES.INSERT_CUSTOMER_FAILURE })

const updatingCustomer = () => ({ type: TYPES.UPDATING_CUSTOMER })
const updateCustomerSuccess = () => ({ type: TYPES.UPDATE_CUSTOMER_SUCCESS })
const updateCustomerFailure = () => ({ type: TYPES.UPDATE_CUSTOMER_FAILURE })

const deletingCustomer = () => ({ type: TYPES.DELETING_CUSTOMER })
const deleteCustomerSuccess = () => ({ type: TYPES.DELETE_CUSTOMER_SUCCESS })
const deleteCustomerFailure = () => ({ type: TYPES.DELETE_CUSTOMER_FAILURE })

export const getCustomersForVrp = params => async dispatch => {
  const api = API.CUSTOMER.getCustomersForSchedule()
  dispatch(gettingCustomers())
  const { response, error } = await apiCall({
    ...api,
    params,
  })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getCustomersSuccess(response.data))
  } else {
    dispatch(getCustomersFailure())
  }
}

export const getCustomers = params => async dispatch => {
  const api = API.CUSTOMER.getCustomers()
  dispatch(gettingCustomers())
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getCustomersSuccess(response.data))
  } else {
    dispatch(getCustomersFailure())
  }
}
export const getCustomer = code => async dispatch => {
  // const state = getState()
  // const isFetching = select(state, ['customerReducer', 'customer'], 'isFetching')
  // const didInvalidate = select(state, ['customerReducer', 'customer'], 'didInvalidate')
  // const oldCustomer = select(state, ['customerReducer', 'customer'], 'editingCustomer')
  const api = API.CUSTOMER.getCustomer(code)
  // if (!isFetching && (didInvalidate || null)) {
  // if (!isFetching && (didInvalidate || code !== oldCustomer.get('code'))) {
  dispatch(gettingCustomer())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getCustomerSuccess(response.data.data))
  } else {
    dispatch(getCustomerFailure())
  }
  // }
}

export const insertCustomer = (payload, meta) => async dispatch => {
  const api = API.CUSTOMER.insertCustomer()
  dispatch(insertingCustomer())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertCustomerSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (error && error.status === 409) {
    dispatch(insertCustomerFailure())
    if (meta && meta.onError) {
      meta.onError('Mã khách hàng đã tồn tại')
    }
  } else {
    dispatch(insertCustomerFailure())
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const importCustomers = (payload, meta) => async dispatch => {
  const param = { customers: payload }
  const api = API.CUSTOMER.importFromExcel()
  dispatch(insertingCustomer())
  const { response, error } = await apiCall({ ...api, payload: param })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertCustomerSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (error && error.status === 406) {
    const response = JSON.parse(error.response)
    const data = response.data
    const keys = Object.keys(data)
    dispatch(insertCustomerSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess(`${keys.length} khách hàng đã tồn tại`)
    }
  } else {
    dispatch(insertCustomerFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const updateCustomer = (id, payload, meta) => async dispatch => {
  const api = API.CUSTOMER.updateCustomer(id)
  dispatch(updatingCustomer())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    dispatch(updateCustomerSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(updateCustomerFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

const actChangeCustomerStatus = (id, payload) => ({
  type: TYPES.CHANGE_CUSTOMER_STATUS,
  id,
  payload
})

export const changeCustomerStatus = (id, payload, meta) => async dispatch => {
  const api = API.CUSTOMER.changeCustomerStatus(id)
  dispatch(updatingCustomer())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    if (meta && meta.onSuccess) {
      dispatch(actChangeCustomerStatus(id, payload))
      meta.onSuccess()
    }
  } else {
    dispatch(updateCustomerFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const deleteCustomer = (id, meta) => async dispatch => {
  const api = API.CUSTOMER.deleteCustomer(id)
  dispatch(deletingCustomer())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(deleteCustomerSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(deleteCustomerFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const getCarts = params => async dispatch => {
  const api = API.CART.getCarts()
  dispatch({ type: TYPES.GETTING_CARTS_OF_CUSTOMER })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.GET_CARTS_OF_CUSTOMER_SUCCESS, payload: response.data })
  } else {
    dispatch({ type: TYPES.GET_CARTS_OF_CUSTOMER_FAILURE })
  }
}

export const getCustomerGroups = params => async (dispatch, getState) => {
  const api = API.CUSTOMER_GROUP.getCustomerGroups()
  const state = getState()
  const isFetching = select(state, ['customerReducer', 'group'], 'isFetching')
  const didInvalidate = select(state, ['customerReducer', 'group'], 'didInvalidate')
  if (!isFetching && didInvalidate) {
    dispatch({ type: TYPES.GETTING_CUSTOMER_GROUPS })
    const { response, error } = await apiCall({ ...api, params })
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch({ type: TYPES.GET_CUSTOMER_GROUPS_SUCCESS, payload: response.data.data })
    } else {
      dispatch({ type: TYPES.GET_CUSTOMER_GROUPS_FAILURE })
    }
  }
}

export const insertCustomerGroup = (payload, meta) => async dispatch => {
  const api = API.CUSTOMER_GROUP.insertCustomerGroup()
  dispatch({ type: TYPES.INSERTING_CUSTOMER_GROUP })
  const { response, error } = await apiCall({ ...api, payload })

  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.INSERT_CUSTOMER_GROUP_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (error && error.status === 409) {
    dispatch({ type: TYPES.INSERT_CUSTOMER_GROUP_FAILURE })
    if (meta && meta.onError) {
      meta.onError('Mã nhóm khách hàng đã tồn tại')
    }
  } else {
    dispatch({ type: TYPES.INSERT_CUSTOMER_GROUP_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const updateGroup = (id, payload, meta) => async dispatch => {
  const api = API.CUSTOMER_GROUP.updateCustomerGroup(id)
  dispatch({ type: TYPES.UPDATING_CUSTOMER_GROUP })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_CUSTOMER_GROUP_SUCCESS, id, payload })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.UPDATE_CUSTOMER_GROUP_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const removeGroup = (id, meta) => async dispatch => {
  const api = API.CUSTOMER_GROUP.deleteCustomerGroup(id)
  dispatch({ type: TYPES.DELETING_CUSTOMER_GROUP })
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.DELETE_CUSTOMER_GROUP_SUCCESS, id })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.DELETE_CUSTOMER_GROUP_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const getCustomerOfGroup = (id, params) => async dispatch => {
  const api = API.CUSTOMER_GROUP.getCustomerOfGroup(id)
  dispatch({ type: TYPES.GETTING_CUSTOMER_OF_GROUP })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    const { data, metadata } = response.data
    dispatch({
      type: TYPES.GET_CUSTOMER_OF_GROUP_SUCCESS,
      payload: data,
      metadata,
      id
    })
  } else {
    dispatch({ type: TYPES.GET_CUSTOMER_OF_GROUP_FAILURE })
  }
}

export const addCustomersToGroup = (payload, meta) => async dispatch => {
  const api = API.CUSTOMER_GROUP.addCustomersToGroup()
  dispatch({ type: TYPES.ADDING_CUSTOMER_TO_GROUP })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.ADD_CUSTOMER_TO_GROUP_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch({ type: TYPES.ADD_CUSTOMER_TO_GROUP_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const removeCustomerFromGroup = id => async dispatch => {
  const api = API.CUSTOMER_GROUP.removeCustomerFromGroup(id)
  dispatch({ type: TYPES.REMOVING_CUSTOMER_FROM_GROUP })
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.REMOVE_CUSTOMER_FROM_GROUP_SUCCESS })
  } else {
    dispatch({ type: TYPES.REMOVE_CUSTOMER_FROM_GROUP_FAILURE })
  }
}

export const importPriceTable = (params, payload, meta) => async dispatch => {
  const api = API.CUSTOMER_GROUP.importPriceTable()
  dispatch({ type: TYPES.IMPORTING_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, params, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.IMPORT_PRICE_TABLE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else {
    dispatch({ type: TYPES.IMPORT_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const getPriceTablesByGroup = (id, params) => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTablesByGroup(id)
  dispatch({ type: TYPES.GETTING_PRICE_TABLES_OF_GROUP })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_PRICE_TABLES_OF_GROUP_SUCCESS,
      payload: response.data
    })
  } else {
    dispatch({ type: TYPES.GET_PRICE_TABLES_OF_GROUP_FAILURE })
  }
}

export const getPriceTables = params => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTables()
  dispatch({ type: TYPES.GETTING_PRICE_TABLES_OF_GROUP })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_PRICE_TABLES_OF_GROUP_SUCCESS,
      payload: response.data
    })
  } else {
    dispatch({ type: TYPES.GET_PRICE_TABLES_OF_GROUP_FAILURE })
  }
}

export const getPriceTable = (id, params) => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTable(id)
  dispatch({ type: TYPES.GETTING_PRICE_TABLE_OF_GROUP })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_PRICE_TABLE_OF_GROUP_SUCCESS,
      payload: response.data
    })
  } else {
    dispatch({ type: TYPES.GET_PRICE_TABLE_OF_GROUP_FAILURE })
  }
}

export const getPriceTableWhenSearch = (id, params, page, pageS) => async dispatch => {
  const api = API.PRICE_TABLE.getPriceTableWhenSearch(id, page, pageS)
  dispatch({ type: TYPES.GETTING_PRICE_TABLE_OF_GROUP })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({
      type: TYPES.GET_PRICE_TABLE_OF_GROUP_SUCCESS,
      payload: response.data
    })
  } else {
    dispatch({ type: TYPES.GET_PRICE_TABLE_OF_GROUP_FAILURE })
  }
}

export const getPriceTableIfNeed = id => (dispatch, getState) => {
  const state = getState()
  const isFetching = select(state, ['customerReducer', 'customer'], 'isFetching')
  const didInvalidate = select(state, ['customerReducer', 'customer'], 'didInvalidate')
  if (!isFetching && didInvalidate) {
    dispatch(getPriceTable(id))
  }
}

export const addItemToPriceTable = (id, payload, meta) => async dispatch => {
  const api = API.PRICE_TABLE.insertPriceItem(id)
  dispatch({ type: TYPES.ADDING_ITEM_TO_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getPriceTable(id))
    dispatch({ type: TYPES.ADD_ITEM_TO_PRICE_TABLE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.ADD_ITEM_TO_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const removeItemFromPriceTable = (id, meta) => async dispatch => {
  const api = API.PRICE_TABLE.deletePriceItem(id)
  dispatch({ type: TYPES.REMOVING_ITEM_FROM_PRICE_TABLE })
  const { response, error } = await apiCall(api)
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.REMOVE_ITEM_FROM_PRICE_TABLE_SUCCESS, id })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.REMOVE_ITEM_FROM_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const updateItemInPriceTable = (id, payload, meta) => async dispatch => {
  const api = API.PRICE_TABLE.updatePriceItem(id)
  dispatch({ type: TYPES.UPDATING_ITEM_IN_PRICE_TABLE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_ITEM_IN_PRICE_TABLE_SUCCESS, payload })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.UPDATE_ITEM_IN_PRICE_TABLE_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
  }
}

export const setListCart = list => async dispatch => {
  dispatch({ type: TYPES.SET_LIST_CART, list })
}
