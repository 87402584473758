import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ROUTER from '../../constants/router'

import SynDataPage from './SynData'

const SynData = () => (
  <Switch>
    <Route path={ROUTER.SYNDATA.INDEX} component={SynDataPage} exact />
  </Switch>
)

export default SynData
