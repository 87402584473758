/* eslint-disable import/no-unresolved */
import { Input } from 'antd';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';

// import { API_URLS } from '../../config/api';
// import { apiCall } from '../../utils/api';

import './styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const { TextArea } = Input;

class WYSIWYGEditor extends Component {
  constructor(props) {
    super(props);

    const htmlContent = props.value || '';
    const contentBlock = htmlToDraft(htmlContent);

    let editorState;
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      previousValue: props.value,
      editorState,
    };

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { previousValue } = prevState;
    if (nextProps.value !== previousValue) {
      const contentBlock = htmlToDraft(nextProps.value);
      let editorState;
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorState = EditorState.createWithContent(contentState);

        return {
          previousValue: nextProps.value,
          editorState,
        };
      }
    }

    return null;
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
    const { onChange } = this.props;
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const nofollowRegex = new RegExp(/(<a\s*(?!.*\brel=)[^>]*)(href="https?:\/\/)((?!ubofood.com)[^"]+)"([^>]*)>/g);
    html = html.replace(nofollowRegex, '$1$2$3"$4 rel="nofollow">');

    const centerImgRegex = /<div style="text-align:none;"><img/g;
    html = html.replace(centerImgRegex, '<div style="text-align:center;"><img');

    const fixHTMLToDraftImgRegex = /<p style="text-align:(none|center|left|right);"><\/p>\s<img([^>]*)\/>/g;
    html = html.replace(fixHTMLToDraftImgRegex, '<div style="text-align:$1;"><img$2/></div>');

    onChange(html);
  }

  // uploadCallback = (file, callback) =>
  //   // console.log(file);
  //   new Promise((resolve, reject) => {
  //     const reader = new window.FileReader();
  //     // console.log(reader);
  //     reader.onloadend = async () => {
  //       const formData = new FormData();
  //       formData.append('file', file);
  //       const api = API_URLS.MEDIA.imageUploadNoResize();
  //       const { response } = await apiCall({
  //         ...api,
  //         payload: formData,
  //       });
  //       // setValue("thumbnail", res.data);
  //       resolve({ data: { link: response?.data?.ImageUrl || '' } });
  //     };
  //     reader.readAsDataURL(file);
  //   });

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <TextArea hidden />
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          stripPastedStyles
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              // 'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'image',
              'remove',
              'history',
            ],
            // fontFamily: {
            //   options: [
            //     'Arial',
            //     'Calibri',
            //     'Georgia',
            //     'Impact',
            //     'Tahoma',
            //     'Times New Roman',
            //     'Verdana',
            //   ],
            //   className: undefined,
            //   component: undefined,
            //   dropdownClassName: undefined,
            // },
            image: {
              uploadCallback: this.uploadCallback,
              previewImage: true,
              alt: { present: true },
              defaultSize: {
                width: 200,
                height: 'auto',
              },
            },
          }}
        />
      </div>
    );
  }
}

WYSIWYGEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

WYSIWYGEditor.defaultProps = {
  value: '',
  onChange: () => {},
};

export default WYSIWYGEditor;
