/* eslint-disable import/named */
/* eslint-disable no-useless-constructor */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Divider, Row, Col, Button, notification } from 'antd'

import NodeList from '../components/node-list'
import NodeFilterForm from '../components/node-filter-form'
import MethodForm from '../components/method-form'

import { selectNodesForVrp, setFilterTime, getNodesForVrp, changeDataNode } from '../actions'

import select from '../../../util/select'
import { apiCall } from '../../../util/apiCall'
import { API } from '../../../constants/api'

class PrepareNode extends Component {
  constructor(props) {
    super(props)
  }

  onSyncUboOrders = async () => {
    const { time } = this.props
    const api = API.SCHEDULE.syncUboNodesForSchedule()
    const { response, error } = await apiCall({
      ...api,
      params: { delivery_date: time },
    })

    if (!error && response.status === 200) {
      notification.success({ message: 'Đồng bộ dữ liệu thành công' })
    } else {
      notification.error({ message: 'Đồng bộ dữ liệu thất bại' })
    }
  }

  filterNodes = (time, isUbo) => {
    this.props.setFilterTime(time.toISOString())
    if (isUbo) {
      this.props.getNodesForVrp({ requireTime: time.toISOString() }, isUbo)
    } else {
      this.props.getNodesForVrp({ requireTime: time.format('YYYY-MM-DD') })
    }
  };

  onSelectNodes = selectedNodes => {
    const { nodes } = this.props
    this.props.selectNodesForVrp(
      nodes.filter(node => selectedNodes.includes(`${node.version}-${node.cartCode}`)),
    )
  };

  onSplitNode = cartCode => {
    const { nodes } = this.props
    const resultSplit = []
    for (let i = 0; i < nodes.length; i++) {
      const item = { ...nodes[i] }
      if (item.cartCode === cartCode) {
        const thisNodes = nodes.filter(item => item.cartCode === cartCode)
        const currentNumberNode = thisNodes.length
        const newTotalWeight = thisNodes[0].totalWeight * currentNumberNode / (currentNumberNode + 1)
        item.totalWeight = newTotalWeight
        for (let j = 0; j < currentNumberNode + 1; j++) {
          const newItem = Object.assign({}, item)
          newItem.version = j
          resultSplit.push(newItem)
        }
        i += currentNumberNode - 1
      } else {
        resultSplit.push(item)
      }
    }
    this.props.changeDataNode(resultSplit)
  }

  onMergeNode = cartCode => {
    const { nodes } = this.props
    const resultMerge = []
    for (let i = 0; i < nodes.length; i++) {
      const item = nodes[i]
      if (item.cartCode === cartCode) {
        const thisNodes = nodes.filter(item => item.cartCode === cartCode)
        const currentNumberNode = thisNodes.length
        const newTotalWeight = thisNodes[0].totalWeight * currentNumberNode / (currentNumberNode - 1)
        item.totalWeight = newTotalWeight
        for (let j = 0; j < currentNumberNode - 1; j++) {
          const newItem = Object.assign({}, item)
          newItem.version = j
          resultMerge.push(newItem)
        }
        i += currentNumberNode - 1
      } else {
        resultMerge.push(item)
      }
    }
    this.props.changeDataNode(resultMerge)
  }

  render() {
    const { nodes, selectedNodes, isVrp, onChangeMethod, time, setFilterTime } = this.props
    return (
      <div>
        <Row gutter={24}>
          <Col span={24} lg={12}>
            <NodeFilterForm onSubmit={this.filterNodes} defaultTime={time} setFilterTime={setFilterTime} />
          </Col>
          <Col span={24} lg={6}>
            <MethodForm isVrp={isVrp} onChangeMethod={onChangeMethod} />
          </Col>
          <Col span={24} lg={6}>
            <Button type="danger" onClick={this.onSyncUboOrders} style={{ float: 'right' }}>Đồng bộ đơn Ubo</Button>
          </Col>
        </Row>

        <NodeList
          nodes={nodes}
          selectedNodes={selectedNodes}
          onSelectNodes={this.onSelectNodes}
          onSplitNode={this.onSplitNode}
          onMergeNode={this.onMergeNode}
        />
        <Divider />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  nodes: select(state, 'routingReducer', 'nodesInvoice'),
  selectedNodes: select(state, 'routingReducer', 'nodes'),
  time: select(state, 'routingReducer', 'time'),
})

const mapDispatchToProps = dispatch => ({
  getNodesForVrp: (params, isUbo) => dispatch(getNodesForVrp(params, isUbo)),
  changeDataNode: data => dispatch(changeDataNode(data)),
  selectNodesForVrp: selectedNodes => dispatch(selectNodesForVrp(selectedNodes)),
  setFilterTime: time => dispatch(setFilterTime(time)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrepareNode)
