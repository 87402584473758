/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Row, Col } from 'antd'

import CountData from './components/count-data'
import CountMeta from './components/count-meta'
import Chart from './components/chart'
import Pie from './components/pie'

import Actions from '../actions'
import select from '../../util/select'
import { isBelowBreakpoint } from '../../util/windows'
import SIDEBAR_ITEMS from '../Sidebar/sidebar-config'
import ROUTER from '../../constants/router'

const {
  getDrivers,
  getCustomers,
  getVehicles, getInvoices, getProducts, getSchedules,
  getVehicleTypes, getProductConditions, getProductUnits, getAccounts,
} = Actions

class DashBoard extends Component {
  state = {}

  componentDidMount() {
    const { user } = this.props
    const roleCode = user.role
    if (roleCode !== 'admin' && roleCode !== 'manager') {
      this.props.getCustomers()
      this.props.getDrivers({ pageSize: -1 })
      this.props.getProducts({ pageSize: -1 })
      this.props.getAccounts({ pageSize: -1 })
      this.props.getInvoices({ pageSize: -1 })
      this.props.getProductUnits()
    }
  }

  render() {
    const {
      user,
      countDriver, countInvoice, countCustomer, countProduct,
      countProductUnit, countAccount, invoice
    } = this.props
    const roleCode = user.role
    if (!roleCode || !SIDEBAR_ITEMS[roleCode] || !SIDEBAR_ITEMS[roleCode].length) {
      return <Redirect to={ROUTER.UNAUTHORIZED} />
    }

    if (roleCode !== 'admin' && roleCode !== 'manager') {
      return <Redirect to={SIDEBAR_ITEMS[roleCode][0].to} />
    }

    return (
      <div>
        <CountData count={{
          countDriver: countDriver.total,
          countCustomer: countCustomer.total,
          countProduct: countProduct.total,
        }}
        />
        <Row style={isBelowBreakpoint() ? { marginTop: 50, marginBottom: 50 } : { marginTop: 100, marginBottom: 100 }}>
          <Col span={24} lg={12}>
            <Chart />
          </Col>
          {/* <Col span={24} lg={6}>
            <Pie data={account} key="account" />
            <h2 style={{ textAlign: 'center' }}>{`${countAccount} Tài khoản `}</h2>
          </Col> */}
          <Col span={24} lg={6}>
            <Pie data={invoice} key="invoices" />
            <h2 style={{ textAlign: 'center' }}>{`${countInvoice.total} Đơn hàng`}</h2>
          </Col>
        </Row>
        <CountMeta count={{
          countProductUnit,
          countAccount
        }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  countDriver: select(state, 'driverReducer', 'meta'),
  countProduct: select(state, ['productReducer', 'list'], 'meta'),
  countInvoice: select(state, ['invoiceReducer', 'list'], 'meta'),
  countCustomer: select(state, ['customerReducer', 'customer'], 'meta'),
  countVehicle: select(state, 'vehicleReducer', 'meta'),

  countVehicleType: select(state, 'dashboardReducer', 'countVehicleType'),
  countProductCondition: select(state, 'dashboardReducer', 'countProductCondition'),
  countProductUnit: select(state, 'dashboardReducer', 'countProductUnit'),
  countAccount: select(state, 'dashboardReducer', 'countAccount'),

  user: select(state, 'authReducer', 'user'),
  account: select(state, 'dashboardReducer', 'account'),
  invoice: select(state, 'dashboardReducer', 'invoice'),
})

const mapDispatchToProps = dispatch => ({
  getCustomers: params => dispatch(getCustomers(params)),
  getDrivers: params => dispatch(getDrivers(params)),
  getInvoices: params => dispatch(getInvoices(params)),
  getProducts: params => dispatch(getProducts(params)),
  getVehicles: params => dispatch(getVehicles(params)),
  getSchedules: params => dispatch(getSchedules(params)),
  getAccounts: params => dispatch(getAccounts(params)),

  getVehicleTypes: () => dispatch(getVehicleTypes()),
  getProductConditions: () => dispatch(getProductConditions()),
  getProductUnits: () => dispatch(getProductUnits()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard)
