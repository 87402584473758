import React from 'react'
import PropTypes from 'prop-types'
import { Table, Switch } from 'antd'
import { Link } from 'react-router-dom'
// import { SearchOutlined } from '@ant-design/icons'
import WithLoading from '../../../hoc/loading'
import router from '../../../constants/router'

class CustomerList extends React.Component {
  state = { };

  render() {
    const {
      customers,
      meta,
      onChangePage,
      onChangeSize,
      onChangeStatus,
      setSelectkeys,
      selectKeys
    } = this.props

    const onSelectChange = selectedRowKeys => {
      setSelectkeys(selectedRowKeys)
    }
    const rowSelection = {
      selectedRowKeys: selectKeys,
      onChange: onSelectChange,
    }

    const columns = [
      {
        title: 'Mã khách hàng',
        dataIndex: 'code',
        width: '20%',
        render: value => (
          <Link to={router.CUSTOMER.EDIT.replace(':id', value)}>
            {value}
          </Link>
        )
      },
      {
        title: 'Tên khách hàng',
        width: '20%',
        dataIndex: 'name'
      },
      {
        title: 'Giỏ hàng',
        width: '20%',
        dataIndex: 'cartName',
      },
      {
        title: 'Nhóm KH',
        dataIndex: 'groupName',
        width: '15%',
        render: (value, record) => (
          <Link to={router.CUSTOMER_GROUP.DETAIL.replace(':id', record.groupId)}>
            {value}
          </Link>
        )
      },
      // {
      //   title: 'Hình thức thanh toán',
      //   width: '15%',
      //   dataIndex: 'paymentMethod',
      // },
      {
        title: 'Nhân viên quản lý',
        width: '15%',
        dataIndex: 'saleInChargeName',
      },
      {
        title: 'Trạng thái',
        dataIndex: 'active',
        width: '10%',
        align: 'center',
        render: (value, record) => (
          <Switch key={record.id} checked={value} onChange={() => onChangeStatus(record.id, { active: !value })} />
        ),
      }
    ]
    return (


    // const CustomerList = ({
    //   customers,
    //   meta,
    //   onEdit,
    //   onView,
    //   onChangePage,
    //   onChangeSize,
    //   onChangeStatus,
    // }) => (
      <Table
        size="small"
        scroll={{ x: 'max-content' }}
        columns={columns}
        style={{ marginTop: '10px' }}
        dataSource={customers}
        rowSelection={rowSelection}
        rowKey={record => record.id}
        rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
        pagination={{
          current: meta.current,
          total: meta.total,
          pageSize: meta.pageSize,
          onChange: onChangePage,
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} khách hàng`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '40', '80'],
          onShowSizeChange: onChangeSize,
        }}
      />
    // )
    )
  }
}

CustomerList.propTypes = {
  customers: PropTypes.array,
  meta: PropTypes.object,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeSize: PropTypes.func,
  onChangeStatus: PropTypes.func
}

CustomerList.defaultProps = {
  customers: [],
  meta: {},
  onEdit: () => {},
  onView: () => {},
  onChangePage: () => {},
  onChangeSize: () => {},
  onChangeStatus: () => {}
}

export default WithLoading(CustomerList)
