/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'
import { findIndex } from '../../util/findIndex'

let index = null

const findIndexPriceItem = (list, id) => {
  let result = -1
  list.forEach((item, index) => {
    if (item.priceItemId === id) {
      result = index
    }
  })
  return result
}

const initialState = {
  items: [],
  meta: {
    current: 0,
    pageSize: 10,
    total: 0,
  },
  editingCustomer: {
    address: {
      lat: 20.995271,
      lng: 105.780953,
    },
  },
  editingPriceTables: [],
  editingPriceTable: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
  cartCustomer: {
    list: [],
    params: {},
    isFetching: false,
  },
}

const customer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_CUSTOMERS:
  case TYPES.GETTING_CUSTOMER:
  case TYPES.INSERTING_CUSTOMER:
  case TYPES.DELETING_CUSTOMER:
    draft.isFetching = true
    break

  case TYPES.UPDATING_CUSTOMER:
    draft.isFetching = false
    break

  case TYPES.GET_CUSTOMERS_SUCCESS: {
    const { metadata } = action.payload
    draft.items = action.payload.data
    draft.meta = {
      current: metadata ? metadata.page : 0,
      pageSize: metadata ? metadata.page_size : 0,
      total: metadata ? metadata.total : 0,
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_CUSTOMER_SUCCESS: {
    draft.editingCustomer = action.payload
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GETTING_PRICE_TABLES_OF_GROUP: {
    draft.editingPriceTables = []
    draft.editingPriceTable = {}
    draft.isFetching = true
    break
  }

  case TYPES.GET_PRICE_TABLES_OF_GROUP_SUCCESS: {
    draft.editingPriceTables = action.payload.data !== null ? action.payload.data : []
    draft.isFetching = false
    break
  }

  case TYPES.INSERT_CUSTOMER_SUCCESS:
  case TYPES.UPDATE_CUSTOMER_SUCCESS:
  case TYPES.DELETE_CUSTOMER_SUCCESS:
  case TYPES.GET_CUSTOMERS_FAILURE:
  case TYPES.GET_CUSTOMER_FAILURE:
  case TYPES.DELETE_CUSTOMER_FAILURE:
  case TYPES.INSERT_CUSTOMER_FAILURE:
  case TYPES.UPDATE_CUSTOMER_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  case TYPES.GET_PRICE_TABLE_OF_GROUP_FAILURE: {
    draft.editingPriceTable = {
      ...state.editingPriceTable,
      isFetching: false
    }
    break
  }
  // return state.merge({
  //   ...state,
  //   editingPriceTable: {
  //     ...state.editingPriceTable,
  //     isFetching: false
  //   }
  // })
  case TYPES.GETTING_PRICE_TABLE_OF_GROUP: {
    draft.editingPriceTable = {
      isFetching: true,
      didInvalidate: false
    }
    break
  }

  case TYPES.GET_PRICE_TABLE_OF_GROUP_SUCCESS: {
    draft.editingPriceTable = {
      data: action.payload.data !== null ? action.payload.data : {},
      meta: {
        current: action.payload.metaData.page,
        pageSize: action.payload.metaData.pageSize,
        total: action.payload.metaData.total,
      },
      isFetching: false,
      didInvalidate: true,
    }
    break
  }

  case TYPES.CHANGE_CUSTOMER_STATUS: {
    const { id, payload } = action
    index = findIndex(draft.items, id)
    draft.items[index].active = payload.active
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.UPDATE_ITEM_IN_PRICE_TABLE_SUCCESS: {
    const editingPriceTable = draft.editingPriceTable
    const { priceItem } = editingPriceTable.data
    const idx = findIndexPriceItem(priceItem, action.payload.priceItemId)
    if (idx >= 0) {
      priceItem[idx].pricePerUnit = action.payload.newPrice
    }
    draft.editingPriceTable = editingPriceTable
    break
  }

  case TYPES.REMOVE_ITEM_FROM_PRICE_TABLE_SUCCESS: {
    const editingPriceTable2 = draft.editingPriceTable
    const priceItems = editingPriceTable2.data.priceItem
    const i = findIndexPriceItem(priceItems, action.id)
    priceItems.splice(i, 1)
    draft.editingPriceTable = editingPriceTable2
    break
  }

  case TYPES.GETTING_CART_CUSTOMERS: {
    draft.cartCustomer.isFetching = true
    draft.cartCustomer.list = []
    draft.cartCustomer.params = {}
    break
  }

  case TYPES.GET_CART_CUSTOMERS_SUCCESS: {
    draft.cartCustomer.isFetching = false
    draft.cartCustomer.list = action.payload.data
    draft.cartCustomer.params = action.params
    break
  }

  case TYPES.GET_CART_CUSTOMERS_FAILURE: {
    draft.cartCustomer.isFetching = false
    draft.cartCustomer.list = []
    draft.cartCustomer.params = {}
    break
  }

  case TYPES.IMPORT_SAMPLE_SCHEDULES_SUCCESS: {
    draft.cartCustomer = {
      list: [],
      params: {},
      isFetching: false,
    }

    break
  }

  default:
    return draft
  }
})

const groupState = {
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const group = (state = groupState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_CUSTOMER_GROUPS:
  case TYPES.INSERTING_CUSTOMER_GROUP:
  case TYPES.DELETING_CUSTOMER_GROUP:
    draft.isFetching = true
    break

  case TYPES.GET_CUSTOMER_GROUPS_SUCCESS: {
    draft.items = action.payload
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INSERT_CUSTOMER_GROUP_FAILURE:
  case TYPES.DELETE_CUSTOMER_GROUP_FAILURE:
  case TYPES.GET_CUSTOMER_GROUPS_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INSERT_CUSTOMER_GROUP_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  case TYPES.DELETE_CUSTOMER_GROUP_SUCCESS: {
    const list = draft.items
    const idx = findIndex(list, action.id)
    list.splice(idx, 1)

    draft.items = list
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  case TYPES.UPDATE_CUSTOMER_GROUP_SUCCESS: {
    if (draft.items.length > 0) {
      index = findIndex(draft.items, action.id)
      draft.items[index].name = action.payload.name
    }
    return draft
  }

  default:
    return draft
  }
})

const customerOfGroupState = {
  groupInfo: null,
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const listByGroup = (state = customerOfGroupState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.REMOVING_CUSTOMER_FROM_GROUP:
  case TYPES.GETTING_CUSTOMER_OF_GROUP:
    draft.isFetching = true
    break

  case TYPES.ADDING_CUSTOMER_TO_GROUP:
    draft.isFetching = false
    break

  case TYPES.GET_CUSTOMER_OF_GROUP_SUCCESS: {
    draft.groupInfo = action.payload.groupInfo
    draft.items = action.payload.customers
    draft.meta = {
      current: action.metadata.page,
      pageSize: action.metadata.pageSize,
      total: action.metadata.total
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_CUSTOMER_OF_GROUP_FAILURE:
  case TYPES.ADD_CUSTOMER_TO_GROUP_SUCCESS:
  case TYPES.ADD_CUSTOMER_TO_GROUP_FAILURE:
  case TYPES.REMOVE_CUSTOMER_FROM_GROUP_SUCCESS:
  case TYPES.REMOVE_CUSTOMER_FROM_GROUP_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.UPDATE_CUSTOMER_GROUP_SUCCESS: {
    draft.groupInfo = {
      ...draft.groupInfo,
      name: action.payload.name
    }
    break
  }

  default:
    return draft
  }
})

const allState = {
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const listAll = (state = allState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_CUSTOMERS:
    draft.isFetching = true
    break

  case TYPES.GET_CUSTOMERS_SUCCESS: {
    const { metadata } = action.payload

    draft.items = action.payload.data !== null ? action.payload.data : []
    draft.meta = {
      current: metadata ? metadata.page : 0,
      pageSize: metadata ? metadata.page_size : 0,
      total: metadata ? metadata.total : 0,
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_CUSTOMERS_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  default:
    return draft
  }
})

const priceTableState = {
  items: [],
  cartId: [],
  meta: {
    current: 1,
    pageSize: 1,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const priceTable = (state = priceTableState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_PRICE_TABLE:
  case TYPES.IMPORTING_PRICE_TABLE:
    draft.isFetching = true
    break

  case TYPES.GET_PRICE_TABLE_SUCCESS: {
    draft.items = action.payload.data
    draft.meta = action.payload.meta
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_PRICE_TABLE_FAILURE:
  case TYPES.IMPORT_PRICE_TABLE_FAILURE:
    draft.isFetching = false
    break

  case TYPES.IMPORT_PRICE_TABLE_SUCCESS:
  case TYPES.GET_CUSTOMER_SUCCESS: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  case TYPES.ADD_ITEM_TO_PRICE_TABLE_SUCCESS:
  case TYPES.REMOVE_ITEM_FROM_PRICE_TABLE_SUCCESS:
  case TYPES.UPDATE_ITEM_IN_PRICE_TABLE_SUCCESS:
    draft.didInvalidate = true
    break

  case TYPES.SET_LIST_CART:
    draft.cartId = action.list
    break

  case TYPES.INSERT_PRICE_TABLE_SUCCESS:
    draft.cartId = []
    break

  default:
    return draft
  }
})

export default combineReducers({
  customer,
  group,
  listByGroup,
  listAll,
  priceTable,
})
