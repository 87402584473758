/* eslint-disable array-callback-return */
/* eslint react/prop-types: 0 */
import React, { Component, Fragment } from 'react'
import { Button, Row, Col, Modal, notification } from 'antd'
import { connect } from 'react-redux'
import DriverList from './components/List'
import FilterRow from './components/Filter'
import ResetPasswordForm from './components/ResetPasswordForm'
import { getDrivers } from './actions'
import { updatePassword, resetPassword } from '../General/actions'
import WithPageHOC from '../../hoc/page'
import ROUTER from '../../constants/router'
import select from '../../util/select'
import { isBelowBreakpoint } from '../../util/windows'

class DriversPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      driver: {},
      showResetForm: false,
      filterOptions: {}
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location
    let params
    if (search !== state.search) {
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
        props.getDrivers(JSON.parse(params))
      } else {
        props.getDrivers()
      }
      return { ...state, search, filterOptions: params }
    }
    return state
  }

  handleAdd = () => {
    this.props.history.push(ROUTER.DRIVER.ADD)
  };

  onResetPassword = payload => {
    this.props.resetPassword(
      payload,
      {
        onSuccess: () => {
          notification.success({ message: 'Cập nhật mật khẩu thành công' })
        },
        onError: error => notification.error({ message: `${error} - Cập nhật mật khẩu thất bại !` }),
      }
    )
  }

  handleCancel = () => this.setState({ showResetForm: false });

  handleResetForm = () => {
    const { form } = this.formRef.props
    form.validateFields((errors, values) => {
      const { DriverID, NewPassword } = values
      this.props.resetDriverPassword(
        { DriverID, NewPassword },
        {
          onSuccess: () => {
            notification.success({ message: 'Cập nhật mật khẩu thành công' })
            this.handleCancel()
          },
          onError: error => notification.error({ message: `${error} - Cập nhật mật khẩu thất bại !` }),
        },
      )
    })
  };

  saveFormRef = formRef => {
    this.formRef = formRef
  };

  editDriver = driver => {
    this.props.history.push(ROUTER.DRIVER.EDIT.replace(':id', driver.code))
  }

  handleFilter = filterOptions => {
    this.setState({ filterOptions })
    this.props.getDrivers({
      ...filterOptions,
      page: 1,
      pageSize: 10,
    })
  };

  onChangePage = (page, pageSize) => {
    const { search } = this.props.history.location
    if (search !== '') {
      let params
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      }
      const obj = JSON.parse(params)
      params = { ...obj, page, pageSize }

      const urlParameters = []
      Object.entries(params).map(e => {
        if (e[1] !== undefined && e[1] !== '') {
          urlParameters.push(e.join('='))
        }
      })
      this.props.history.push(ROUTER.DRIVER.INDEX.concat(`?${urlParameters.join('&')}`))
    } else {
      this.props.history.push(ROUTER.DRIVER.INDEX.concat(`?page=${page}&pageSize=${pageSize}`))
    }
  }


  render() {
    const { driver, showResetForm, filterOptions } = this.state
    const { isFetching, meta, history } = this.props
    const { drivers } = this.props

    return (
      <Fragment>
        <Row gutter={24}>
          <Col span={24} lg={6}>
            <FilterRow
              history={history}
              disabled={isFetching}
              filterOptions={filterOptions}
            />
          </Col>
          <Col span={24} lg={18}>
            <Button icon="plus" onClick={this.handleAdd} style={{ marginTop: isBelowBreakpoint() ? 20 : 0 }}>
              Thêm lái xe
            </Button>
            <DriverList
              style={{ marginTop: 10 }}
              drivers={drivers}
              isFetching={isFetching}
              meta={meta}
              onEdit={this.editDriver}
              onDelete={this.deleteDriver}
              onView={this.viewDriver}
              onChangePage={this.onChangePage}
              onChangeSize={this.onChangePage}
              onResetPassword={this.onResetPassword}
            />
          </Col>
        </Row>
        <Modal
          title="Đặt lại mật khẩu cho lái xe"
          visible={showResetForm}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closable={false}
          centered
          footer={[
            <Button key="edit" type="primary" onClick={() => this.handleResetForm()}>
              Cập nhật
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Đóng
            </Button>,
          ]}
        >
          <ResetPasswordForm wrappedComponentRef={this.saveFormRef} driver={driver} />
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  drivers: select(state, 'driverReducer', 'items'),
  meta: select(state, 'driverReducer', 'meta'),
  isFetching: select(state, 'driverReducer', 'isFetching'),
})

const mapDispatchToProps = dispatch => ({
  getDrivers: filterOptions => dispatch(getDrivers(filterOptions)),
  // updatePassword: (payload, meta) => dispatch(updatePassword(payload, meta)),
  resetDriverPassword: (payload, meta) => dispatch(updatePassword(payload, meta)),
  resetPassword: (payload, meta) => dispatch(resetPassword(payload, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithPageHOC('driver', 'data')(DriversPage))
