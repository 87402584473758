import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const stampState = {
  url_items_stamp: '',
  isFetching: false,
  didInvalidate: true,
}

const stamp = (state = stampState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_URL_STAMP:
    draft.isFetching = true
    break

  case TYPES.GET_URL_STAMP_SUCCESS: {
    draft.url_items_stamp = action.payload.data.pd_url
    draft.changed_data_messages = action.payload.data.changed || []
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_URL_STAMP_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    draft.changed_data_messages = []
    break
  }

  default:
    return draft
  }
})


export default combineReducers({ stamp })
