/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'
import produce from 'immer'
import TYPES from '../../constants/actionTypes'
import { findIndex } from '../../util/findIndex'

const initialState = {
  items: [],
  meta: {
    current: 0,
    pageSize: 10,
    total: 0,
  },
  editingCart: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const cart = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_CARTS:
  case TYPES.GETTING_CART:
  case TYPES.INSERTING_CART:
  case TYPES.DELETING_CART:
    draft.isFetching = true
    break

  case TYPES.UPDATING_CART:
    draft.isFetching = false
    break

  case TYPES.GET_CARTS_SUCCESS: {
    const { metadata } = action.payload
    draft.items = action.payload.data
    draft.meta = {
      current: metadata.page,
      pageSize: metadata.page_size,
      total: metadata.total,
    }
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_CART_SUCCESS: {
    draft.editingCart = action.payload
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.INSERT_CART_SUCCESS:
  case TYPES.UPDATE_CART_SUCCESS:
  case TYPES.DELETE_CART_SUCCESS:
  case TYPES.GET_CARTS_FAILURE:
  case TYPES.GET_CART_FAILURE:
  case TYPES.DELETE_CART_FAILURE:
  case TYPES.INSERT_CART_FAILURE:
  case TYPES.UPDATE_CART_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = true
    break
  }

  case TYPES.CHANGE_CART_STATUS_SUCCESS: {
    const { id, payload } = action
    const index = findIndex(draft.items, id)
    draft.items[index].active = payload.active
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

export default combineReducers({ cart })
