/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, notification, Divider } from 'antd'
import mapSeries from 'promise-map-series'

import WithPageHOC from '../../hoc/page'
import CustomerList from './components/List'
// import CustomerCard from './components/Card'
import FilterRow from './components/Filter'
import MultiEditModal from './components/multi-edit-modal'

import { getCustomers, importCustomers, updateCustomer, changeCustomerStatus } from './actions'
import { getAccounts } from '../General/actions'

import ROUTER from '../../constants/router'

import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import select from '../../util/select'

class CustomersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMultiEdit: false,
      isEditingMultiCustomer: false,
      filterOptions: {},
      selectKeys: []
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { search } = props.history.location
    let params
    if (search !== state.search) {
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
        params = params ? JSON.parse(params) : {}
        if (!params.customerStatus) {
          params.customerStatus = 'true'
        } else if (params.customerStatus === 'all') {
          delete params.customerStatus
        }

        if (!params.saleInChargeId && props?.user?.role === 'sale') {
          params.saleInChargeId = props.user.id
        }
        props.getCustomers(params)
      } else {
        params = { customerStatus: 'true' }
        if (props.user.role === 'sale') params.saleInChargeId = props?.user?.id
        props.getCustomers(params)
      }
      return { ...state, search, filterOptions: params }
    }
    return state
  }

  componentDidMount() {
    this.props.getAccounts({ pageSize: -1 })
  }

  handleAdd = () => this.props.history.push(ROUTER.CUSTOMER.ADD);

  handleFilter = filterOptions => {
    this.setState({ filterOptions })
    this.props.getCustomers({
      ...filterOptions,
      page: this.props.meta.current,
      pageSize: this.props.meta.pageSize,
    })
  };

  handleMultiEdit = () => {
    this.setState({ showMultiEdit: true })
  };

  handleSubmitMultiEdit = async values => {
    const { customers } = this.props
    const { selectKeys } = this.state
    if (selectKeys && selectKeys.length && (values.paymentMethod || values.saleInChargeId)) {
      this.setState({ isEditingMultiCustomer: true })
      await mapSeries(selectKeys, cusKey => {
        const customer = customers.find(c => c.id === cusKey)
        const api = API.CUSTOMER.updateCustomer(cusKey)
        const payload = { ...customer, ...values }
        return apiCall({ ...api, payload })
      }).then(() => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ selectKeys: [], search: undefined })
        notification.success({ message: 'Cập nhật khách hàng thành công' })
      }).catch(() => {
        notification.success({ message: 'Có lỗi khi cập nhật. Hãy kiểm tra lại.' })
      })

      this.handleCloseMultiEdit()
      this.setState({ isEditingMultiCustomer: false })
    }

    return Promise.resolve()
  }

  handleCloseMultiEdit = () => this.setState({ showMultiEdit: false });

  editCustomer = customer => {
    this.props.history.push(ROUTER.CUSTOMER.EDIT.replace(':id', customer.code))
  };

  changeStatus = (id, payload) => {
    this.props.changeStatus(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật trạng thái' })
      },
      onError: error => notification.error({ message: `${error} - Cập nhật thất bại` }),
    })
  }

  onChangePage = (page, pageSize) => {
    const { search } = this.props.history.location
    if (search !== '') {
      let params
      const filterOptions = search.split('?')[1]
      if (filterOptions !== undefined) {
        params = `{"${decodeURI(filterOptions)
          .replace(/"/g, '\\"').replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      }
      const obj = JSON.parse(params)
      params = { ...obj, page, pageSize }

      const urlParameters = []
      Object.entries(params).map(e => {
        if (e[1] !== undefined && e[1] !== '') {
          urlParameters.push(e.join('='))
        }
        return null
      })
      this.props.history.push(ROUTER.CUSTOMER.INDEX.concat(`?${urlParameters.join('&')}`))
    } else {
      this.props.history.push(ROUTER.CUSTOMER.INDEX.concat(this.props.user.role === 'sale' ? `?page=${page}&pageSize=${pageSize}&saleInChargeId=${this.props.user.id}` : `?page=${page}&pageSize=${pageSize}`))
    }
  }

  setSelectkeys = key => {
    this.setState({ selectKeys: key })
  }

  render() {
    const { showMultiEdit, filterOptions, selectKeys, isEditingMultiCustomer } = this.state
    const { customers, meta, isFetching, history, user, accounts } = this.props
    return (
      <Fragment>
        <Row gutter={24}>
          <FilterRow
            history={history}
            disabled={isFetching}
            filterOptions={filterOptions}
            user={user}
            accounts={accounts}
          />
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <Button icon="plus" onClick={this.handleAdd}>
              Thêm khách hàng
            </Button>
            <CustomerList
              style={{ marginTop: 10 }}
              selectKeys={selectKeys}
              setSelectkeys={this.setSelectkeys}
              history={history}
              customers={customers}
              accounts={accounts}
              isFetching={isFetching}
              meta={meta}
              onView={this.viewCustomer}
              onEdit={this.editCustomer}
              onChangeStatus={this.changeStatus}
              onChangePage={this.onChangePage}
              onChangeSize={this.onChangePage}
            />
          </Col>
        </Row>

        <Button type="primary" disabled={selectKeys.length === 0} onClick={this.handleMultiEdit}>
          Sửa nhiều khách hàng
        </Button>
        <MultiEditModal
          visible={showMultiEdit}
          accounts={accounts}
          isFetching={isEditingMultiCustomer}
          onCloseModal={this.handleCloseMultiEdit}
          onSubmit={this.handleSubmitMultiEdit}
        />

        {/* <Modal
          title="Chi tiết khách hàng"
          visible={showCustomerModal}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          closable={false}
          footer={[
            <Button key="edit" type="primary" onClick={() => this.editCustomer(customer)}>
              Cập nhật
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Đóng
            </Button>,
          ]}
        >
          <CustomerCard customer={customer} />
        </Modal> */}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  customers: select(state, ['customerReducer', 'customer'], 'items'),
  meta: select(state, ['customerReducer', 'customer'], 'meta'),
  isFetching: select(state, ['customerReducer', 'customer'], 'isFetching'),
  user: select(state, 'authReducer', 'user'),
  accounts: select(state, ['generalReducer', 'account'], 'items'),

})

const mapDispatchToProps = dispatch => ({
  getCustomers: params => dispatch(getCustomers(params)),
  updateCustomer: (id, payload, meta) => dispatch(updateCustomer(id, payload, meta)),
  changeStatus: (id, payload, meta) => dispatch(changeCustomerStatus(id, payload, meta)),
  importCustomers: (payload, meta) => dispatch(importCustomers(payload, meta)),
  getAccounts: params => dispatch(getAccounts(params)),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithPageHOC('customer', 'data')(CustomersPage))
