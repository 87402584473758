/* eslint react/prop-types: 0 */
import React from 'react'
import { Row, Col, Card, notification, Divider } from 'antd'
import { connect } from 'react-redux'

import { insertCart, importCarts } from './actions'
import CartForm from './components/cart-form'
// import UploadForm from '../../components/UploadForm'
import WithPageHOC from '../../hoc/page'
import ROUTER from '../../constants/router'
import select from '../../util/select'

class AddCartPage extends React.Component {
  // state = { isBatch: false }

  onChangeInsertType = ({ target: { value } }) => this.setState(() => ({ isBatch: value }))

  handleSubmit = formData => {
    this.props.insertCart(formData, {
      onSuccess: () => {
        notification.success({ message: 'Thêm giỏ hàng thành công' })
        this.props.history.push(ROUTER.CART.INDEX)
      },
      onError: error => notification.error({ message: `${error} - Thêm giỏ hàng thất bại` }),
    })
  }

  handleUpload = async ({ current: { files } }) => {
    const formData = new FormData()
    formData.append('file', files[0])
    this.props.importCarts(formData, {
      onSuccess: () => {
        notification.success({ message: 'Thêm thành công' })
        this.props.history.push(ROUTER.CART.INDEX)
      },
      onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
    })
  }

  render() {
    // const { isBatch } = this.state
    // const { isFetching } = this.props
    return (
      <div>
        <Row>
          <Col span={24}>
            <Divider dashed />
            <Card title={<h1>Thêm giỏ hàng mới</h1>}>
              <CartForm onSubmit={this.handleSubmit} />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({ isFetching: select(state, ['customerReducer', 'customer'], 'isFetching') })

const mapDispatchToProps = dispatch => ({
  insertCart: (payload, meta) => dispatch(insertCart(payload, meta)),
  importCarts: (payload, meta) => dispatch(importCarts(payload, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithPageHOC('customer', 'data')(AddCartPage))
