/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, notification, Radio, Divider, Button } from 'antd'
import moment from 'moment'

import * as XLSX from 'xlsx'
import DriverForm from './components/Form'
import WithLoadingHOC from '../../hoc/loading'
import WithPageHOC from '../../hoc/page'

import { insertDriver, importDrivers } from './actions'
import { getVehicleTypes } from '../General/actions'


import ROUTER from '../../constants/router'
import select from '../../util/select'
import { isBelowBreakpoint } from '../../util/windows'

class AddDriverPage extends React.Component {
  state = {
    isBatch: false,
    Drivers: []
  };

  componentDidMount() {
    this.props.getVehicleTypes()
  }

  onChangeInsertType = ({ target: { value } }) => this.setState(() => ({ isBatch: value }));

  // handleUpload = async ({ current: { files } }) => {
  //   const formData = new FormData()
  //   formData.append('fileName', files[0])
  //   this.props.importDrivers(formData, {
  //     onSuccess: () => {
  //       notification.success({ message: 'Thêm thành công' })
  //       this.props.history.push(ROUTER.DRIVER.INDEX)
  //     },
  //     onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
  //   })
  // };

  handleSubmit = payload => {
    this.props.insertDriver(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm thành công' })
        this.props.history.push(ROUTER.DRIVER.INDEX)
      },
      onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
    })
  };

  onImportExcel = event => {
    const { files } = event.target
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(files[0])
    }
  }

  importExcel = file => {
    const fileReader = new FileReader()

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let startAt = 0
        const { result } = event.target
        const workbook = XLSX.read(result, { type: 'binary' })
        const Sheet = workbook.Sheets[workbook.SheetNames[0]]
        let data = XLSX.utils.sheet_to_json(Sheet)
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`]
          if (flagCell !== undefined) {
            startAt = i
            // 1
          }
        }

        if (startAt === 0) {
          notification.error({ message: 'Thêm mới file excel thất bại!', duration: 1 })
        } else {
          const arrayTotal = []
          data = XLSX.utils.sheet_to_json(Sheet, { raw: true })
          notification.success({ message: 'Tải File thành công', duration: 1 })

          for (let i = 0; i < data.length; i++) {
            // const array = []
            // const arrayUn = []
            const string = data[i]
            if (string['Mã Lái xe'] && string['Họ và tên']) {
              arrayTotal.push({
                code: string['Mã Lái xe'].trim(),
                dateOfBirth: string['Ngày tháng năm sinh'] ? moment(string['Ngày tháng năm sinh'], 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
                gender: string['Giới tính'] ? string['Giới tính'].trim() : '',
                idCardNumber: string['Số thẻ căn cước'] ? string['Số thẻ căn cước'].toString().trim() : '',
                name: string['Họ và tên'].trim(),
                phoneNumber: string['Số điện thoại'] ? (string['Số điện thoại']).replace(/[^0-9]/g, '').trim() : ''
              })
            }
          }
          this.setState({ Drivers: arrayTotal })
        }
      } catch (e) {
        notification.error({ message: e, duration: 1 })
      }
    }
    fileReader.readAsBinaryString(file)
  }

  addDriver = () => {
    this.props.importDrivers(this.state.Drivers, {
      onSuccess: code => {
        notification.success({ message: `Thêm lái xe thành công. ${code}` })
        this.props.history.push(ROUTER.DRIVER.INDEX)
      },
      onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
    })
  }

  render() {
    const { vehicleTypes } = this.props
    const { isBatch } = this.state
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={14}>
            <Radio.Group value={isBatch} buttonStyle="solid" onChange={this.onChangeInsertType}>
              <Radio.Button value={false}>Thêm một lái xe</Radio.Button>
              <Radio.Button value style={{ marginTop: isBelowBreakpoint() ? 5 : 0 }}>Thêm nhiều lái xe </Radio.Button>
            </Radio.Group>
            <Divider dashed />
            <Card>
              <h1>Thêm lái xe mới</h1>
              <hr />
              {isBatch ? (
                <Fragment>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1MlttxOLgI-Ty37xe6bb5koX29aGskL4qd3xOI6By6CA/edit?usp=sharing"
                  >
      Mẫu file excel
                  </a>
                  <br />
                  <br />
                  <input
                    id="fileUpload"
                    type="file"
                    ref={v => {
                      this.fileUpload = v
                    }}
                    accept=".xlsx, .xls"
                    onChange={this.onImportExcel}
                  />
                  <Button
                    onClick={this.addDriver}
                  >
      Thêm Lái Xe từ File

                  </Button>
                </Fragment>
              ) : (
                <DriverForm vehicleTypes={vehicleTypes} onSubmit={this.handleSubmit} />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  vehicleTypes: select(state, ['generalReducer', 'vehicleType'], 'items'),
  isFetching: select(state, 'driverReducer', 'isFetching'),
})

const mapDispatchToProps = dispatch => ({
  getVehicleTypes: () => dispatch(getVehicleTypes()),
  insertDriver: (payload, meta) => dispatch(insertDriver(payload, meta)),
  importDrivers: (payload, meta) => dispatch(importDrivers(payload, meta)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithLoadingHOC(WithPageHOC('driver', 'data')(AddDriverPage)))
