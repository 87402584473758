import produce from 'immer'
import TYPES from '../../constants/actionTypes'

const initialState = {
  isFetching: false,
  didInvalidate: true,
  isAuthenticated: false,
  error: false,
  user: {},
  forwardLocation: {},
}

const authReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.LOGGING_IN: {
    draft.isAuthenticated = false
    draft.isFetching = true
    draft.error = false
    break
  }

  case TYPES.LOG_IN_SUCCESS: {
    const { token, data } = action.payload
    // if (data.user && data.user.role !== 'admin') {
    //   draft.isAuthenticated = false
    //   draft.isFetching = false
    //   draft.error = true
    // } else
    // if (data.user === undefined && data.role !== 'admin') {
    //   draft.isAuthenticated = false
    //   draft.isFetching = false
    //   draft.error = true
    // } else {
    localStorage.setItem('aaJwt', token)
    draft.isAuthenticated = true
    draft.isFetching = false
    draft.user = data.user ? data.user : data
    draft.error = false
    // }
    break
  }

  case TYPES.LOG_IN_FAILURE: {
    draft.isAuthenticated = false
    draft.isFetching = false
    draft.error = true
    break
  }

  case TYPES.LOG_OUT:
    localStorage.clear()
    return initialState

  default:
    return draft
  }
})

export default authReducer
