import { Modal, Button, Form, Popconfirm, message, notification } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ItemList from '../../Order/components/OrderForm/ItemList'
// import { changeAlias } from '../../../util/formatText'
import select from '../../../util/select'
// import { INVOICE_STATUS } from '../../../../constants/enum'
import {
  getProducts,
  addItem,
  getCustomer,
  clearData,
  getPriceTable
} from '../../Order/actions'
import { getCustomers } from '../../Customer/actions'
import { insertInvoiceOrder, getInvoiceByCode } from '../actions'

class ModalOrder extends Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     customer: null,
  //     hasCustomer: false,
  //     date: moment().add(1, 'd'),
  //     currentPriceTableId: null
  //   }
  // }

  componentDidMount = () => {
    this.props.getProducts(
      {
        customerCode: this.props.invoice?.customerCode,
        date: moment(this.props.invoice?.requireTime).format('YYYY-MM-DD')
      },
      {
        onSuccess: newPriceTableId => {
          this.props.getPriceTable(newPriceTableId, { pageSize: -1 })
        },
        onError: error => {
          message.warning(error)
        }
      }
    )
    this.props.clearData()
  };

  onAddItem = (priceItemId, Quantity) => {
    const order = { ...this.props.order }
    const { products } = this.props
    let isExist = false
    if (order.Items.find(item => item.priceItemId === priceItemId)) {
      order.Items = order.Items.map(item => {
        if (item.priceItemId === priceItemId) {
          isExist = true
          message.warning('Sản phẩm đã có trong đơn hàng')
        }
        return item
      })
    }
    if (!isExist) {
      const product = products.find(item => item.priceItemId === priceItemId)
      order.Items = order.Items.concat({
        priceItemId: product.priceItemId,
        productName: product.productName,
        quantity: Number(Quantity),
        unitId: product.unitId,
        unitName: product.unitName,
        price: product.pricePerUnit,
        totalPrice: product.pricePerUnit * Number(Quantity),
      })
      this.props.addItem(order)
    }
  }

  onRemoveItem = id => {
    const order = { ...this.props.order }
    order.Items = order.Items.filter(item => item.priceItemId !== id)
    this.props.addItem(order)
  };

  handleSubmit = () => {
    const { order, orderId, orderCode } = this.props
    const orderItems = order.Items.map(item => ({
      priceItemId: item.priceItemId,
      quantity: item.quantity
    }))
    if (orderItems.length) {
      const payload = { orderItems }
      if (orderItems.some(e => (e.quantity === 0))) {
        message.warning('Có mặt hàng có số lượng bằng 0')
      } else {
        this.props.insertInvoiceOrder(

          orderId,
          payload,
          {
            onSuccess: () => {
              notification.success({ message: 'Đơn hàng đã được tiếp nhận' })
              this.props.getInvoiceByCode(orderCode)
              this.props.addItem({ Items: [] })
              this.props.onToggleModalOrder()
            },
            onError: () => notification.error({ message: 'Có lỗi xảy ra, vui lòng thử lại!' }),
          },
        )
      }
    } else {
      message.warning('Giỏ hàng trống')
    }
  };

  removeAllProduct = () => {
    const order = { Items: [] }
    this.props.addItem(order)
  }

  onChangeQuantity = (value, id) => {
    const order = { ...this.props.order }
    order.Items = order.Items.map(item => {
      if (item.priceItemId === id) {
        return {
          ...item,
          quantity: Number(value),
          totalPrice: item.price * Number(value)
        }
      }
      return item
    })
    this.props.addItem(order)
  };


  render() {
    const { modalOrder, onToggleModalOrder, products, order } = this.props
    // const { date } = this.state
    return (
      <Modal
        title="Thông tin sản phẩm"
        visible={modalOrder}
        onCancel={onToggleModalOrder}
        destroyOnClose
        isFetching
        width={1000}
        footer={null}
      >
        <ItemList
          products={products}
          onAddItem={this.onAddItem}
          dataSource={order.Items}
          onRemoveItem={this.onRemoveItem}
          onChangeQuantity={this.onChangeQuantity}
        />
        <Popconfirm
          title="Thêm sản phẩm"
          disabled={order.Items.length < 1}
          onConfirm={this.handleSubmit}
          okText="Có"
          cancelText="Không"
        >
          <Button
            type="primary"
            shape="round"
            style={{ width: '20%', marginLeft: '40%', marginTop: '15px', display: 'null' }}
            disabled={order.Items.length < 1}
          >
            {'Thêm sản phẩm'}
          </Button>
        </Popconfirm>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  customers: select(state, ['customerReducer', 'listAll'], 'items'),
  customer: select(state, ['orderReducer', 'customer'], 'customer'),
  products: select(state, ['orderReducer', 'product'], 'items'),
  meta: select(state, ['orderReducer', 'product'], 'meta'),
  priceTableId: select(state, ['orderReducer', 'product'], 'priceTableId'),
  order: select(state, ['orderReducer', 'order'], 'item'),
  isFetching: select(state, ['orderReducer', 'product'], 'isFetching'),

})

const mapDispatchToProps = dispatch => ({
  getProducts: (params, meta) => dispatch(getProducts(params, meta)),
  getPriceTable: (id, params) => dispatch(getPriceTable(id, params)),
  getCustomers: params => dispatch(getCustomers(params)),
  getCustomer: code => dispatch(getCustomer(code)),
  addItem: payload => dispatch(addItem(payload)),
  clearData: () => dispatch(clearData()),
  insertInvoiceOrder: (orderId, payload, meta) => dispatch(insertInvoiceOrder(orderId, payload, meta)),
  getInvoiceByCode: code => dispatch(getInvoiceByCode(code)),


})

export default Form.create()(connect(mapStateToProps, mapDispatchToProps)(ModalOrder))
