import produce from 'immer'
import { combineReducers } from 'redux'
import TYPES from '../../constants/actionTypes'

const distanceState = {
  items: [],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const distance = (state = distanceState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_DISTANCE_REPORT:
    draft.isFetching = true
    break

  case TYPES.GET_DISTANCE_REPORT_SUCCESS: {
    draft.items = action.payload.Data
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_DISTANCE_REPORT_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

const weightState = {
  items: [],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const weight = (state = weightState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_WEIGHT_REPORT:
    draft.isFetching = true
    break

  case TYPES.GET_WEIGHT_REPORT_SUCCESS: {
    draft.items = action.payload.Data
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_WEIGHT_REPORT_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

const dayState = {
  items: [],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const workingDays = (state = dayState, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_WORKING_DAY_REPORT:
    draft.isFetching = true
    break

  case TYPES.GET_WORKING_DAY_REPORT_SUCCESS: {
    draft.items = action.payload.Data
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_WORKING_DAY_REPORT_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

const orderinday = {
  data: [],
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
}

const orderInDay = (state = orderinday, action) => produce(state, draft => {
  switch (action.type) {
  case TYPES.GETTING_ORDER_IN_DAY_REPORT:
    draft.isFetching = true
    break

  case TYPES.GET_ORDER_IN_DAY_REPORT_SUCCESS: {
    draft.data = action.payload.data
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  case TYPES.GET_ORDER_IN_DAY_REPORT_FAILURE: {
    draft.isFetching = false
    draft.didInvalidate = false
    break
  }

  default:
    return draft
  }
})

export default combineReducers({ orderInDay, workingDays, distance, weight })
