import React from 'react'
import PropTypes from 'prop-types'
import { Table, Switch } from 'antd'
import { Link } from 'react-router-dom'
import WithLoading from '../../../../hoc/loading'
import ROUTE from '../../../../constants/router'

const columns = onChangeStatus => [
  {
    title: 'Mã KH',
    dataIndex: 'code',
    render: value => (
      <Link to={ROUTE.CUSTOMER.EDIT.replace(':id', value)}>{value}</Link>
    )
  },
  {
    title: 'Tên KH',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Nhóm KH',
    dataIndex: 'customerGroup.name',
    key: 'customerGroup',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'active',
    align: 'center',
    render: (value, record) => (
      <Switch key={record.id} checked={value} onChange={() => onChangeStatus(record.id, { active: !value })} />
    ),
  },
]

const CustomerList = ({
  customers,
  onChangeStatus
}) => (
  <Table
    size="small"
    scroll={{ x: 'max-content' }}
    columns={columns(onChangeStatus)}
    style={{ marginTop: '10px' }}
    dataSource={customers}
    rowKey={record => record.id}
    rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
    pagination={{
      showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} khách hàng`,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '40', '80'],
    }}
  />
)

CustomerList.propTypes = {
  customers: PropTypes.array,
  onChangeStatus: PropTypes.func
}

CustomerList.defaultProps = {
  customers: [],
  meta: {},
  onChangeStatus: () => {}
}

export default WithLoading(CustomerList)
