/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import DriverList from '../components/driver-list'

import { getDriversIfNeed, getDrivers } from '../../Driver/actions'
import { selectDriversForVrp, resetNodesAndDriversSuccess } from '../actions'

import select from '../../../util/select'

class PrepareDriver extends Component {
  state = {}

  static getDerivedStateFromProps(nextProps) {
    nextProps.getDriversIfNeed({ pageSize: -1 })
    return null
  }

  componentDidMount() {
    this.props.resetNodesAndDriversSuccess()
    this.props.getDrivers({ pageSize: -1 })
  }

  onSelectDrivers = selectedDrivers => {
    const drivers = this.props.drivers.filter(driver => selectedDrivers.includes(driver.id)).sort((a, b) => {
      if (a.code < b.code) {
        return -1
      }
      if (a.code > b.code) {
        return 1
      }
      return 0
    })
    this.props.selectDriversForVrp(drivers)
  };

  render() {
    const { drivers, selectedDrivers } = this.props
    return (
      <div>
        <DriverList
          drivers={drivers}
          selectedDrivers={selectedDrivers}
          onSelectDrivers={this.onSelectDrivers}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  drivers: select(state, 'driverReducer', 'items'),
  selectedDrivers: select(state, 'routingReducer', 'drivers'),
})

const mapDispatchToProps = dispatch => ({
  getDriversIfNeed: params => dispatch(getDriversIfNeed(params)),
  selectDriversForVrp: selectedDrivers => dispatch(selectDriversForVrp(selectedDrivers)),
  resetNodesAndDriversSuccess: () => dispatch(resetNodesAndDriversSuccess()),
  getDrivers: params => dispatch(getDrivers(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrepareDriver)
