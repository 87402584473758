/* eslint react/prop-types: 0 */
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, notification, Button } from 'antd'
import WithLoadingHOC from '../../hoc/loading'
import WithPageHOC from '../../hoc/page'
import select from '../../util/select'
import { getCategory, updateCategory, deleteCategory } from './actions'
import CategoryForm from './components/Form'
import ROUTER from '../../constants/router'
import { backpage } from '../../util/windows'

class EditCategoryPage extends React.Component {
  componentDidMount() {
    this.props.getCategory(this.props.match.params.id)
  }

  onDelete = id => {
    this.props.deleteCategory(id, {
      onSuccess: () => {
        this.props.history.push(ROUTER.CATEGORY.INDEX)
        notification.success({ message: 'Xoá danh mục thành công' })
      },
      onError: error => notification.error({ message: `${error}, "Cập nhật gặp lỗi !"` }),
    })
  }

  handleSubmit = payload => {
    const id = this.props.category.id
    this.props.updateCategory(id, payload, {
      onSuccess: () => notification.success({ message: 'Sửa danh mục thành công' }),
      onError: error => notification.error({ message: `${error} - Sửa danh mục thất bại` }),
    })
  };

  render() {
    const { category } = this.props
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={14}>
            <Card>
              <h1>Sửa thông tin danh mục</h1>
              <hr />
              <CategoryForm
                editMode
                category={category}
                onSubmit={this.handleSubmit}
                onDelete={this.onDelete}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  category: select(state, ['categoryReducer', 'detail'], 'item'),
  isFetching: select(state, ['categoryReducer', 'detail'], 'isFetching'),
})

const mapDispatchToProps = dispatch => ({
  getCategory: id => dispatch(getCategory(id)),
  updateCategory: (id, payload, meta) => dispatch(updateCategory(id, payload, meta)),
  deleteCategory: (id, meta) => dispatch(deleteCategory(id, meta))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithLoadingHOC(WithPageHOC('category', 'data')(EditCategoryPage)))
