import React from 'react'
import PropTypes from 'prop-types'
import { List, Popconfirm, Button, Row, Col, Form, Input, Card } from 'antd'
import WithLoadingHOC from '../../../hoc/loading'

const { Item } = Form


class SelfList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { edittingKey: '', content: '', valueCode: '' }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      values.name = this.state.valueCode
      if (!err) {
        this.props.onSubmit(values)
        this.props.form.resetFields()
      }
    })
  }

  changecode = e => {
    const valueinput = e.target.value
    const part = /\s/g
    const value = valueinput.replace(part, '')
    this.setState({ valueCode: value })
  }

  onEdit = item => {
    this.setState({ edittingKey: item.id, content: item.name })
  }

  onCancel = () => this.setState({ edittingKey: '' })

  onUpdate = () => {
    this.props.onUpdate(this.state.edittingKey, { unitName: this.state.content })
    this.setState({ edittingKey: '', content: '' })
  }

  onChangeContent = e => this.setState({ content: e.target.value })

  render() {
    const { dataSource, onDelete, isFetching, form: { getFieldDecorator }, placeholder, title } = this.props
    return (
      <Row type="flex" justify="center">
        <Col span={24} lg={14}>
          <Card title={<h3>{title}</h3>} hoverable>
            <List
              size="small"
              header={(
                <Form layout="inline" onSubmit={this.handleSubmit}>
                  <Item span={18} required>
                    {
                      getFieldDecorator('name', {
                        rules: [
                          {
                            required: true,
                            message: 'Tên là bắt buộc',
                          },
                        ],
                      })(<Input onChange={this.changecode} placeholder={placeholder} />)
                    }
                  </Item>
                  <Item span={6}>
                    <Button icon="plus" type="primary" htmlType="submit" disabled={isFetching}>
                  Thêm
                    </Button>
                  </Item>
                </Form>
              )}
              dataSource={dataSource}
              pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} đơn vị`,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '40', '80'],
              }}
              renderItem={(item, index) => (
                <List.Item
                  className={index % 2 ? 'whitesmoke' : ''}
                  style={{ paddingLeft: 10 }}
                  actions={[
                    <React.Fragment>
                      {item.id !== this.state.edittingKey
                  && (
                    <React.Fragment>
                      <Button type="primary" size="small" icon="edit" onClick={() => this.onEdit(item)}>
                        {'Sửa'}
                      </Button>
                      {' '}
                      <Popconfirm
                        key=""
                        title="Bạn chắc chắn xóa ?"
                        onConfirm={() => [onDelete(item.id), this.props.form.resetFields()]}
                        okText="OK"
                        cancelText="Cancel"
                      />
                    </React.Fragment>
                  )
                      }

                      {item.id === this.state.edittingKey
                    && (
                      <React.Fragment>
                        <Button type="default" size="small" onClick={() => this.onCancel()}>
                          {'Hủy'}
                        </Button>
                        {' '}
                        <Button type="default" size="small" onClick={values => this.onUpdate(values)}>
                          {'Cập nhật'}
                        </Button>
                      </React.Fragment>
                    )
                      }
                    </React.Fragment>,
                  ]}
                >
                  { item.id === this.state.edittingKey
                    ? <Input type="text" value={this.state.content || ''} onChange={this.onChangeContent} />
                    : item.name }
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    )
  }
}


SelfList.propTypes = {
  dataSource: PropTypes.array,
  onDelete: PropTypes.func,
  form: PropTypes.object,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  placeholder: PropTypes.string,
}

SelfList.defaultProps = {
  dataSource: [],
  form: {},
  onDelete: () => {},
  onSubmit: () => {},
  onUpdate: () => {},
  isFetching: false,
  placeholder: '',
}

export default { SelfList: WithLoadingHOC(Form.create()(SelfList)) }
