import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Input, Button, DatePicker, Form, Row, Col } from 'antd'
import router from '../../../constants/router'

const { Item } = Form

/* eslint-disable array-callback-return */
class FilterRow extends React.Component {
  state = { keyword: [] }

  static getDerivedStateFromProps(props) {
    const { search } = props.history.location
    const result = search.replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ').split(' ').splice(1)

    return { keyword: result }
  }

  handleSubmit = e => {
    e.preventDefault()
    const c_params = this.props.filterOptions === undefined ? {} : JSON.parse(this.props.filterOptions)
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const urlParameters = []
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '') {
            if (e[0] === 'executionTime') {
              urlParameters.push(`executionTime=${e[1].toISOString().slice(0, e[1].toISOString().indexOf('T')).concat('T00:00:00Z')}`)
            } else {
              urlParameters.push(e.join('='))
            }
          }
        })
        if (urlParameters[0] !== false) {
          this.props.history.push(router.SCHEDULE.INDEX.concat(`?${urlParameters.join('&')}`))
        }
      } else {
        console.log(err)
      }
    })
  };

  handleReset = () => {
    this.props.form.resetFields()
    this.props.history.push(router.SCHEDULE.INDEX)
  };

  render() {
    const { disabled, form: { getFieldDecorator } } = this.props
    const { keyword } = this.state

    return (
      <Form onSubmit={this.handleSubmit}>
        <Item label="Tên lịch">
          {
            getFieldDecorator('name', { initialValue: keyword.indexOf('name') === -1 ? '' : keyword[keyword.indexOf('name') + 1] })(
              <Input
                disabled={disabled}
                placeholder="Tên lịch"
              />,
            )
          }
        </Item>
        <Item label="Ngày giao">
          {
            getFieldDecorator('executionTime', { initialValue: keyword.indexOf('executionTime') === -1 ? undefined : moment(keyword[keyword.indexOf('executionTime') + 1]) })(
              <DatePicker
                style={{ width: '100%' }}
                disabled={disabled}
                format="DD/MM/YYYY"
                placeholder="Ngày giao"
              />,
            )
          }
        </Item>
        <Row gutter={24}>
          <Col span={12}>
            <Button icon="filter" disabled={disabled} type="primary" htmlType="submit" style={{ width: '100%' }}>
              {'Lọc'}
            </Button>
          </Col>
          <Col span={12}>
            <Button icon="close" disabled={disabled} onClick={this.handleReset} style={{ width: '100%' }}>
              {'Bỏ lọc'}
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

FilterRow.propTypes = {
  disabled: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
}

FilterRow.defaultProps = { disabled: false }

export default Form.create()(FilterRow)
