import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'

const gettingSynData = () => ({ type: TYPES.GETTING_SYNCHRONIZE_DATA })
const getSynDataSuccess = payload => ({
  type: TYPES.GET_SYNCHRONIZE_DATA_SUCCESS,
  payload,
})
const getSynDataFailure = () => ({ type: TYPES.GET_SYNCHRONIZE_DATA_FAILURE })

export const getSynData = params => async dispatch => {
  const api = API.SYN_DATA.getDataForSynchronize()

  dispatch(gettingSynData())
  const { response } = await apiCall({ ...api, params })
  if (response && response.status === 200 && response.data.success === true) {
    dispatch(getSynDataSuccess(response))
  } else {
    dispatch(getSynDataFailure())
  }
}


const synFastDataSuccess = payload => ({
  type: TYPES.SYNCHRONIZE_FAST_DATA_SUCCESS,
  payload,
})
const synFastDataFailure = () => ({ type: TYPES.SYNCHRONIZE_FAST_DATA_FAILURE })

export const synFast = (params, meta) => async dispatch => {
  const api = API.SYN_DATA.synchronizeFast(params)
  const { response } = await apiCall({ ...api })

  if (response && response.data && response.data.success === true) {
    dispatch(synFastDataSuccess(response))
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(synFastDataFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const Configurations = (params, meta) => async () => {
  // const payload = params
  const api = API.SYN_DATA.Configurations()
  const { response, error } = await apiCall({ ...api, payload: params })
  if (!error && response.status === 200 && response.data.success === true) {
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data)
    }
  } else if (meta && meta.onError) {
    meta.onError(error)
  }
}

const gettingSynTime = () => ({ type: TYPES.GETTING_SYN_TIME })
const getSynTimeSuccess = payload => ({
  type: TYPES.GET_SYN_TIME_SUCCESS,
  payload,
})
const getSynTimeFailure = () => ({ type: TYPES.GET_SYN_TIME_FAILURE })

export const GetAllConfiguration = params => async dispatch => {
  const api = API.SYN_DATA.GetAllConfiguration()
  dispatch(gettingSynTime())
  const { response } = await apiCall({ ...api, params })
  if (response && response.status === 200 && response.data.success === true) {
    dispatch(getSynTimeSuccess(response))
  } else {
    dispatch(getSynTimeFailure())
  }
}
