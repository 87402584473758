/* eslint react/prop-types: 0 */
import React, { Fragment } from 'react'
import { Row, Col, Card, notification, Radio, Divider, Button } from 'antd'
import { connect } from 'react-redux'

import * as XLSX from 'xlsx'
import { insertCustomer, importCustomers, getCustomerGroups } from './actions'
import { getCarts } from '../Cart/actions'
import { getAccounts, createAccount } from '../General/actions'
import CustomerForm from './components/customer-form'
import WithPageHOC from '../../hoc/page'

import ROUTER from '../../constants/router'
import select from '../../util/select'
import { isBelowBreakpoint } from '../../util/windows'

class AddCustomerPage extends React.Component {
  state = { isBatch: false, Customers: [] }

  componentDidMount() {
    this.props.getCarts({ pageSize: -1 })
    this.props.getAccounts({ pageSize: -1 })
    this.props.getCustomerGroups({ pageSize: -1 })
  }

  onChangeInsertType = ({ target: { value } }) => this.setState(() => ({ isBatch: value }))

  handleSubmit = formData => {
    this.props.insertCustomer(formData, {
      onSuccess: () => {
        notification.success({ message: 'Thêm khách hàng thành công' })
        this.props.history.push(ROUTER.CUSTOMER.INDEX)
      },
      onError: error => notification.error({ message: `${error} - Thêm khách hàng thất bại` }),
    })
  }

  // handleUpload = async ({ current: { files } }) => {
  //   const formData = new FormData()
  //   formData.append('fileName', files[0])
  //   this.props.importCustomers(formData, {
  //     onSuccess: () => {
  //       notification.success({ message: 'Thêm thành công' })
  //       this.props.history.push(ROUTER.CUSTOMER.INDEX)
  //     },
  //     onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
  //   })
  // }

  addCustomers = () => {
    const customer = this.state.Customers
    if (customer.length === 0) {
      notification.error({ message: 'Bạn chưa chọn file' })
    } else {
      this.props.importCustomers(customer, {
        onSuccess: code => {
          notification.success({ message: `${code ? `${code}. ` : ''}Thêm khách hàng thành công` })
          this.props.history.push(ROUTER.CUSTOMER.INDEX)
        },
        onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
      })
    }
    // if (customer.length = 0) {
    //   notification.error({ message: 'Bạn chưa chọn file' })
    // }
    // else {
    //   this.props.importCustomers(this.state.Customers, {
    //     onSuccess: () => {
    //       notification.success({ message: 'Thêm lái xe thành công' })
    //       this.props.history.push(ROUTER.DRIVER.INDEX)
    //     },
    //     onError: error => notification.error({ message: `Thêm thất bại - ${error}` }),
    //   })
    // }
  }

  onImportExcel = event => {
    const { files } = event.target
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(files[0])
    }
  }

  importExcel = file => {
    const fileReader = new FileReader()

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let startAt = 0
        const { result } = event.target
        const workbook = XLSX.read(result, { type: 'binary' })
        const Sheet = workbook.Sheets[workbook.SheetNames[0]]
        let data = XLSX.utils.sheet_to_json(Sheet)
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`]
          if (flagCell !== undefined) {
            startAt = i
            // 1
          }
        }

        if (startAt === 0) {
          notification.error({ message: 'Thêm mới file excel thất bại!', duration: 1 })
        } else {
          const arrayTotal = []
          data = XLSX.utils.sheet_to_json(Sheet, { raw: true })
          notification.success({ message: 'Tải File thành công', duration: 1 })

          for (let i = 0; i < data.length; i++) {
            // const array = [
            //   {
            //     cartInformation: {
            //       address: '',
            //       cartCode: '',
            //       lat: 0,
            //       lng: 0,
            //       phoneNumber: '',
            //     },
            //     code: '',
            //     customerGroupName: '',
            //     name: '',
            //   }
            // ]
            // const arrayUn = []
            const string = data[i]
            if (string['Mã khách hàng'] && string['Tên Khách hàng'] && string['Mã giỏ'] && string['Nhóm KH']) {
              arrayTotal.push({
                cartInformation: {
                  address: string['Địa chỉ'] ? string['Địa chỉ'].trim() : '',
                  cartCode: `${string['Mã giỏ']}`.trim(),
                  lat: string.Latitude ? Number.parseFloat(string.Latitude) : 0,
                  lng: string.Longitude ? Number.parseFloat(string.Longitude) : 0,
                  phoneNumber: string['Số điện thoại'] ? string['Số điện thoại'].trim() : '',
                },
                code: string['Mã khách hàng'].trim(),
                customerGroupName: `${string['Nhóm KH']}`.trim(),
                name: string['Tên Khách hàng'].trim(),
                paymentMethod: string['Hình thức thanh toán'] ? string['Hình thức thanh toán'].trim() : '',
                saleInChargeId: string['Nhân viên phụ trách'] ? Number.parseInt(string['Nhân viên phụ trách'], 10) : 0,
              })
            }
          }
          this.setState({ Customers: arrayTotal })
        }
      } catch (e) {
        notification.error({ message: e, duration: 1 })
      }
    }
    fileReader.readAsBinaryString(file)
  }

  render() {
    const { isBatch } = this.state
    const { carts, groups, accounts, createAccount } = this.props
    return (
      <div>
        <Row>
          <Col span={24}>
            <Radio.Group value={isBatch} buttonStyle="solid" onChange={this.onChangeInsertType}>
              <Radio.Button value={false}>Thêm một khách hàng</Radio.Button>
              <Radio.Button value style={{ marginTop: isBelowBreakpoint() ? 5 : 0 }}>Thêm nhiều khách hàng </Radio.Button>
            </Radio.Group>
            <Divider dashed />
            <Card>
              {isBatch ? (
                <Fragment>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1U82ZYDshXGuh8kT7MP16Ahb32IwSVFNIhpMEQcL5RMg/edit?usp=sharing"
                  >
    Mẫu file excel
                  </a>
                  <br />
                  <br />
                  <input
                    id="fileUpload"
                    type="file"
                    ref={v => {
                      this.fileUpload = v
                    }}
                    accept=".xlsx, .xls"
                    onChange={this.onImportExcel}
                  />
                  <Button
                    onClick={this.addCustomers}
                  >
    Thêm Khách Hàng từ File
                  </Button>
                </Fragment>
              // <UploadForm
              //   isFetching={isFetching}
              //   contentLoading="Đang xử lí dữ liệu"
              //   supportContent={(
              //     <a
              //       target="_blank"
              //       rel="noopener noreferrer"
              //       href="https://docs.google.com/spreadsheets/d/1i4FIMuMNgcVfTSg1PbruLDFQk9XEAbwMsfxsRVDMbjU/edit?usp=sharing"
              //     >
              //       Mẫu file excel
              //     </a>
              //   )}
              //   onUpload={this.handleUpload}
              // />
              ) : (
                <CustomerForm
                  onSubmit={this.handleSubmit}
                  carts={carts}
                  groups={groups}
                  accounts={accounts}
                  createAccount={createAccount}
                />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isFetching: select(state, ['customerReducer', 'customer'], 'isFetching'),
  carts: select(state, ['cartReducer', 'cart'], 'items'),
  accounts: select(state, ['generalReducer', 'account'], 'items'),
  groups: select(state, ['customerReducer', 'group'], 'items'),
})

const mapDispatchToProps = dispatch => ({
  insertCustomer: (payload, meta) => dispatch(insertCustomer(payload, meta)),
  importCustomers: (payload, meta) => dispatch(importCustomers(payload, meta)),
  getCarts: params => dispatch(getCarts(params)),
  getAccounts: params => dispatch(getAccounts(params)),
  createAccount: (payload, meta) => dispatch(createAccount(payload, meta)),
  getCustomerGroups: params => dispatch(getCustomerGroups(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithPageHOC('customer', 'data')(AddCustomerPage))
