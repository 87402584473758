/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import { Col, Row, Modal, notification } from 'antd'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import Card from '../Card/index'
import ImageForm from './Form'

import ImagesLayoutConfig from './ImagesLayoutConfig'
import { apiCall } from '../../../util/apiCall'
import { API } from '../../../constants/api'
import { splitNameFromImageURL } from '../../../util/image'

// import WithLoading from '../../../hoc/WithLoading';
// import WithLocale from '../../../hoc/WithLocale'

const { confirm } = Modal

class ProductImageList extends Component {
  constructor() {
    super()
    this.homeLayoutConfig = React.createRef()

    this.state = {
      configContent: { app: { homeLayout: [] } },
      images: [],
    }
  }

  componentDidMount() {
    this.getAppConfig()
  }

  getAppConfig = async () => {
    const api = API.PRODUCT.getProduct(this.props.productInfoId)
    const { response } = await apiCall(api)
    const configContent = response.data.data.images
    if (configContent) {
      this.setState({ images: configContent })
    }
  };

  moveImage = values => {
    const { images } = this.state
    const stringImage = JSON.stringify(images)
    const stringValues = JSON.stringify(values)
    if (stringImage !== stringValues) {
      this.setState({ images: values })
    }
  }

  updateAppConfig = () => {
    const { images } = this.state
    const convertedImages = images.map(img => (
      splitNameFromImageURL(img)
    ))
    confirm({
      title: 'Bạn có muốn lưu',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk: () => {
        // this.getAppConfig()
        this.props.updateProductImages({ images: convertedImages, code: this.props.idProduct }, {
          onSuccess: () => {
            notification.success({ message: 'Lưu ảnh cho sản phẩm thành công' })
          },
          onError: error => notification.error({ message: `${error} - Lưu ảnh thất bại` }),
        })
      },
    })
  };

  onAddImage = values => {
    // const { productInfo, updateProductImages, productInfoId } = this.props
    // const currentImages = productInfo.images
    // const { images } = this.state
    // this.setState({ images })
    this.homeLayoutConfig.current.addNewConfig(values.Image)
  }

  removeImage = images => {
    this.setState({ images })
  }

  // onSave = () => {
  //   const temp = [...this.homeLayoutConfig.current.getHomeLayout().map(item => ({ ...item }))]
  //   const payload = {
  //     ConfigContent: {
  //       app: {
  //         homeLayout: [...temp.map(item => {
  //           delete item.id
  //           return item
  //         })],
  //       },
  //     },
  //   }
  //   return (payload)
  // }


  render() {
    const { slug } = this.props
    return (
      <div>
        <Row>
          <Row />
          <Col span={24} lg={6} style={{ marginTop: '30px' }}>
            <Card>
              <ImageForm
                slug={slug}
                emitValue={values => {
                  this.onAddImage(values)
                }}
              />
            </Card>
          </Col>

          <DndProvider backend={Backend}>
            <ImagesLayoutConfig
              homeLayout={this.state.images}
              ref={this.homeLayoutConfig}
              updateAppConfig={this.updateAppConfig}
              removeImage={this.removeImage}
              moveImage={this.moveImage}
            // trans={trans}
            />
          </DndProvider>
        </Row>
      </div>

    )
  }
}

export default ProductImageList
