import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Switch, Button, notification, Popconfirm, Icon, Select, Modal } from 'antd'
import WithLoadingHOC from '../../../hoc/loading'

const columns = (resetPassword, onChangeStatus, onUpdateRole, roles) => [
  {
    title: <b>ID</b>,
    dataIndex: 'id',
  },
  {
    title: <b>Tài khoản</b>,
    dataIndex: 'name',
  },
  {
    title: <b>Vai trò</b>,
    dataIndex: 'role',
    render: (value, record) => {
      const { Option } = Select
      const ShowChangeRole = () => {
        const [role, setRole] = useState(record.role)
        const [modal, setModal] = useState(false)
        return (
          <Fragment>
            <Button
              type="primary"
              shape="circle"
              style={{ marginRight: '10px' }}
              onClick={() => setModal(true)}
            >
              <Icon type="edit" />
            </Button>

            <Modal
              title="Thay đổi vai trò"
              visible={modal}
              okText="Lưu"
              cancelText="Hủy"
              onOk={() => {
                onUpdateRole(record.id, {
                  name: record.name,
                  role,
                  status: record.status,
                })
                setModal(false)
              }}
              onCancel={() => setModal(false)}
            >
              <Select style={{ width: '100%' }} defaultValue={role} onChange={value => setRole(value)}>
                {roles && roles.map(item => (
                  <Option value={item.code} key={item.code}>{item.name}</Option>
                ))}
              </Select>
            </Modal>
          </Fragment>
        )
      }
      const currentRole = roles.find(item => item.code === value)
      return (
        <Fragment>
          <ShowChangeRole />
          {currentRole ? currentRole.name : value}
        </Fragment>
      )
    }
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    align: 'center',
    render: (value, record) => (
      <Popconfirm
        placement="top"
        title={`Thay đổi trạng thái ${record.name}`}
        onConfirm={() => onChangeStatus(record)}
        okText="Có"
        cancelText="Không"
      >
        <Switch
          key={record.id}
          // defaultChecked={record.status === 'active'}
          checked={record.status === 'active'}
        />
        {/* <Button key="del" icon={<DeleteOutlined />} shape="circle" type="danger" /> */}
      </Popconfirm>

    )
  },
  {
    title: <b>Reset Password</b>,
    dataIndex: 'id',
    align: 'center',
    render: value => (
      <Popconfirm
        title="Đặt lại mật khẩu?"
        okText="Có"
        cancelText="Không"
        onConfirm={() => resetPassword(
          { userId: value, newPassword: '123456' },
          {
            onSuccess: () => {
              notification.success({ message: 'Đặt lại mật khẩu thành công' })
            },
            onError: () => {
              notification.error({ message: 'Đặt lại mật khẩu thất bại' })
            }
          }
        )}
      >
        <Button
          shape="circle"
          icon="undo"
          type="danger"
        />
      </Popconfirm>
    )
  }
]

const AccountList = ({
  roles,
  dataSource,
  meta: { pageSize, current, total },
  onChange,
  onShowSizeChange,
  toggleModal,
  resetPassword,
  onChangeStatus,
  onUpdateRole
}) => {
  const onChangeAccountStatus = account => {
    onChangeStatus(account.id, account.status === 'active' ? { status: 'inactive', userId: account.id } : { status: 'active', userId: account.id })
  }
  return (
    <Table
      style={{ marginTop: 10 }}
      title={() => (
        <Button onClick={toggleModal}>Thêm tài khoản mới</Button>
      )}
      columns={columns(resetPassword, onChangeAccountStatus, onUpdateRole, roles)}
      dataSource={dataSource}
      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
      rowKey="id"
      size="small"
      scroll={{ x: 'max-content' }}
      pagination={{
        current,
        pageSize,
        total,
        onChange,
        onShowSizeChange,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40'],
        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} tài khoản`,
      }}
    />
  )
}


AccountList.propTypes = {
  roles: PropTypes.array,
  dataSource: PropTypes.array,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  toggleModal: PropTypes.func,
  resetPassword: PropTypes.func,
  onUpdateRole: PropTypes.func,
  onChangeStatus: PropTypes.func
}

AccountList.defaultProps = {
  roles: [],
  dataSource: [],
  meta: {},
  onChange: () => { },
  onShowSizeChange: () => { },
  toggleModal: () => { },
  resetPassword: () => { },
  onUpdateRole: () => {},
  onChangeStatus: () => {}
}

export default WithLoadingHOC(AccountList)
