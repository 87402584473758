export default {
  HOME: '/',
  DASHBOARD: '/dashboard',
  NOTIFICATION: '/notifications',
  AUTH: { LOGIN: '/login' },
  VEHICLE: {
    INDEX: '/vehicle',
    ADD: '/vehicle/add',
    EDIT: '/vehicle/:id/edit',
  },
  DRIVER: {
    INDEX: '/driver',
    ADD: '/driver/add',
    EDIT: '/driver/:id/edit',
  },
  CART: {
    INDEX: '/cart',
    ADD: '/cart/add',
    EDIT: '/cart/:id/edit',
    MAP: '/cart-map',
  },
  CUSTOMER: {
    INDEX: '/customer',
    ADD: '/customer/add',
    EDIT: '/customer/:id/edit',
    MAP: '/customer-map',
  },
  CUSTOMER_GROUP: {
    INDEX: '/customer-group',
    DETAIL: '/customer-group/:id',
  },
  PRODUCT: {
    INDEX: '/product',
    ADD: '/product/add',
    EDIT: '/product/:id/edit',
  },
  ROUTING: {
    VRP: '/routing',
    SAMPLE: '/routing-sample',
  },
  INVOICE: {
    INDEX: '/invoice',
    DETAIL: '/invoice/:id',
    ADD: '/invoice/add',
  },
  GENERAL: {
    INDEX: '/general',
    ACCOUNT: '/account',
  },
  ROUTE: { INDEX: '/routes' },
  SCHEDULE: {
    INDEX: '/schedule',
    DETAIL: '/schedule/:id',
    EDIT: '/schedule/:id/edit',
  },
  ACCOUNT: { PASSWORD: '/password' },
  REPORT: {
    DISTANCE: '/distance',
    WEIGHT: '/weight',
    WORKING_DAYS: '/working-days',
    INDAY: '/inday',
  },
  PRICE_TABLE: {
    INDEX: '/price-table',
    DETAIL: '/price-table/:id',
    ADD: '/price-table/add',
    // EDIT: '/price-list/:id/edit'
  },
  CATEGORY: {
    INDEX: '/category',
    ADD: '/category/add',
    EDIT: '/category/:id/edit',
  },
  SUPPLIER: {
    INDEX: '/supplier',
    ADD: '/supplier/add',
    EDIT: '/supplier/:id/edit',
  },
  MANAGA: { INDEX: '/manager' },
  SYNDATA: { INDEX: '/fast-database' },
  STAMP: { INDEX: '/stamp', QR: '/qr-stamp' },

  NOT_FOUND: '/404',
  UNAUTHORIZED: '/401',
  QR: '/qr/:code',
};
