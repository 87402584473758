import React from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Switch, Input, Icon } from 'antd'
import Highlighter from 'react-highlight-words'

import WithLoadingHOC from '../../../hoc/loading'

const columns = (onChangeStatusAPI, onSetEditAPI, getColumnSearchProps) => [
  {
    title: <b>Mô tả</b>,
    dataIndex: 'description'
  },
  {
    title: <b>Đường dẫn</b>,
    dataIndex: 'path',
    ...getColumnSearchProps('path'),
  },
  {
    title: <b>Phương thức</b>,
    dataIndex: 'method',
    align: 'center',
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'active',
    align: 'center',
    render: (value, record) => (
      <Switch
        defaultChecked={value}
        onChange={value => onChangeStatusAPI(record.id, { active: value })}
      />
    )
  },
  {
    title: <b>Chỉnh sửa</b>,
    dataIndex: 'edit',
    align: 'center',
    render: (value, record) => {
      const edit = () => {
        onSetEditAPI(record.id, { path: record.path, method: record.method, description: record.description })
      }

      return (
        <Button
          shape="circle"
          icon="edit"
          type="primary"
          onClick={edit}
        />
      )
    }
  }
]

class APITab extends React.Component {
  state = {
    searchText: '',
    searchedColumn: ''
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: text => (this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    )),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  };

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  };

  render() {
    const {
      dataSource,
      meta: { total },
      onChangeStatusAPI,
      onSetAddAPI,
      onSetEditAPI,
    } = this.props
    return (
      <Table
        title={() => (
          <Button onClick={onSetAddAPI}>Thêm API mới</Button>
        )}
        columns={columns(onChangeStatusAPI, onSetEditAPI, this.getColumnSearchProps)}
        dataSource={dataSource}
        rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
        rowKey="id"
        size="small"
        scroll={{ x: 'max-content' }}
        pagination={{
          // pageSize: 10,
          total,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '40'],
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} API`,
        }}
      />
    )
  }
}

// const APITab = ({
//   dataSource,
//   meta: { total },
//   onChangeStatusAPI,
//   onSetAddAPI,
//   onSetEditAPI,
// }) => (
//   <Table
//     title={() => (
//       <Button onClick={onSetAddAPI}>Thêm API mới</Button>
//     )}
//     columns={columns(onChangeStatusAPI, onSetEditAPI)}
//     dataSource={dataSource}
//     rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
//     rowKey="id"
//     size="small"
//     scroll={{ x: 'max-content' }}
//     pagination={{
//       // pageSize: 10,
//       total,
//       showSizeChanger: true,
//       pageSizeOptions: ['10', '20', '40'],
//       showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} API`,
//     }}
//   />
// )

APITab.propTypes = {
  dataSource: PropTypes.array,
  meta: PropTypes.object,
  onChangeStatusAPI: PropTypes.func,
  onSetAddAPI: PropTypes.func,
  onSetEditAPI: PropTypes.func,
}

APITab.defaultProps = {
  dataSource: [],
  meta: {},
  onChangeStatusAPI: () => { },
  onSetAddAPI: () => { },
  onSetEditAPI: () => { },
}

export default WithLoadingHOC(APITab)
