/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { Form, Select, Button } from 'antd'
import { isBelowBreakpoint } from '../../../../util/windows'

const FormItem = Form.Item
const Option = Select.Option

class SampleSolutionForm extends Component {
  constructor() {
    super()
    this.state = { enableRemoveSampleSolution: false, selectedRemoveSampleSolution: '' }
  }

    onSubmit = event => {
      event.preventDefault()
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit(values)
        }
      })
    };

    removeSampleSolution = () => {
      this.props.removeSampleSolution(this.state.selectedRemoveSampleSolution)
      this.props.form.setFieldsValue({ code: '' })
    }

    render() {
      const { form, sampleSolutions, disabled } = this.props
      const { getFieldDecorator } = form
      return (
        <div>
          <Form onSubmit={this.onSubmit} layout={isBelowBreakpoint() ? null : 'inline'} style={{ width: '50%', display: 'inline' }}>
            <FormItem label="Tuyến mẫu">
              {getFieldDecorator('code', {
                rules: [
                  { required: true, message: 'Hãy chọn tuyến mẫu!' },
                ],
              })(
                <Select
                  placeholder="Chọn tuyến mẫu"
                  style={{ width: isBelowBreakpoint() ? '100%' : '200px' }}
                  disabled={disabled}
                  onSelect={value => { this.setState({ enableRemoveSampleSolution: true, selectedRemoveSampleSolution: value }) }}
                >
                  {sampleSolutions && sampleSolutions.length ? sampleSolutions.map((item, index) => (
                    <Option key={`${index + 1}`} value={item.code}>
                      {item.name}
                    </Option>
                  )) : null}
                </Select>,
              )}
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabled}
              >
                Chọn
              </Button>
            </FormItem>
          </Form>
          <Button
            type="danger"
            disabled={!this.state.enableRemoveSampleSolution}
            style={{ float: 'right', display: 'inline' }}
            onClick={this.removeSampleSolution}
          >
                        Xóa tuyến mẫu
          </Button>
        </div>
      )
    }
}

export default Form.create()(SampleSolutionForm)
