import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'

// Category Action
const gettingCategories = () => ({ type: TYPES.GETTING_CATEGORIES })
const getCategoriesSuccess = payload => ({
  type: TYPES.GET_CATEGORIES_SUCCESS,
  payload,
})
const getCategoriesFailure = () => ({ type: TYPES.GET_CATEGORIES_FAILURE })

export const getCategories = params => async dispatch => {
  const api = API.CATEGORY.getCategories()
  dispatch(gettingCategories())
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getCategoriesSuccess(response.data))
  } else {
    dispatch(getCategoriesFailure())
  }
}

const gettingCategory = () => ({ type: TYPES.GETTING_CATEGORY })
const getCategorySuccess = payload => ({
  type: TYPES.GET_CATEGORY_SUCCESS,
  payload,
})
const getCategoryFailure = () => ({ type: TYPES.GET_CATEGORY_FAILURE })

export const getCategory = id => async dispatch => {
  const api = API.CATEGORY.getCategory(id)
  dispatch(gettingCategory())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(getCategorySuccess(response.data))
  } else {
    dispatch(getCategoryFailure())
  }
}

const insertingCategory = () => ({ type: TYPES.INSERTING_CATEGORY })
const insertCategorySuccess = () => ({ type: TYPES.INSERT_CATEGORY_SUCCESS })
const insertCategoryFailure = () => ({ type: TYPES.INSERT_CATEGORY_FAILURE })


export const insertCategory = (payload, meta) => async dispatch => {
  const api = API.CATEGORY.insertCategory()
  dispatch(insertingCategory())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertCategorySuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(insertCategoryFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const importCategories = (payload, meta) => async dispatch => {
  const api = API.CATEGORY.importFromExcel()
  dispatch(insertingCategory())
  const { response, error } = await apiCall({
    ...api,
    payload,
  })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertCategorySuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(insertCategoryFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}


const updatingCategory = () => ({ type: TYPES.UPDATING_CATEGORY })
const updateCategorySuccess = () => ({ type: TYPES.UPDATE_CATEGORY_SUCCESS })
const updateCategoryFailure = () => ({ type: TYPES.UPDATE_CATEGORY_FAILURE })

export const updateCategory = (id, payload, meta) => async dispatch => {
  const api = API.CATEGORY.updateCategory(id)
  dispatch(updatingCategory())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(updateCategorySuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(updateCategoryFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

const changeCategoryStatus = (id, value) => ({
  type: TYPES.CHANGE_CATEGORY_STATUS,
  id,
  value
})

export const actChangeStatus = (id, value) => async dispatch => {
  dispatch(changeCategoryStatus(id, value))
}

const deletingCategory = () => ({ type: TYPES.DELETING_CATEGORY })
const deleteCategorySuccess = () => ({ type: TYPES.DELETE_CATEGORY_SUCCESS })
const deleteCategoryFailure = () => ({ type: TYPES.DELETE_CATEGORY_FAILURE })


export const deleteCategory = (id, meta) => async dispatch => {
  const api = API.CATEGORY.deleteCategory(id)
  dispatch(deletingCategory())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(deleteCategorySuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(deleteCategoryFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

// Request

export const getRequests = params => async dispatch => {
  const api = API.REQUEST.getRequests()
  dispatch({ type: TYPES.GETTING_REQUESTS })
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.GET_REQUESTS_SUCCESS, payload: response.data.Data })
  } else {
    dispatch({ type: TYPES.GET_REQUESTS_FAILURE })
  }
}

export const replyRequest = (id, payload) => async dispatch => {
  const api = API.REQUEST.replyRequest(id)
  dispatch({ type: TYPES.REPLYING_REQUEST })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.REPLY_REQUEST_SUCCESS })
  } else {
    dispatch({ type: TYPES.REPLY_REQUEST_FAILURE })
  }
}
