import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../constants/router';

import StampPage from './Stamp';
import QRStampPage from './QRStamp';

const Stamp = () => (
  <Switch>
    <Route path={ROUTER.STAMP.INDEX} component={StampPage} exact />
    <Route path={ROUTER.STAMP.QR} component={QRStampPage} exact />
  </Switch>
);

export default Stamp;
