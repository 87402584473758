import React from 'react'

import { PieChart, Pie, Tooltip } from 'recharts'
import { isBelowBreakpoint } from '../../../util/windows'

// 400
const Size = isBelowBreakpoint() ? 200 : 250

// eslint-disable-next-line react/prop-types
const Pies = ({ data }) => (
  <PieChart width={Size} height={Size}>
    <Pie isAnimationActive={false} data={data} dataKey="value" cx="50%" cy="50%" outerRadius="70%" fill="#8884d8" label />
    <Tooltip />
  </PieChart>
)

export default Pies
