import React from 'react'
import { Card, Button, Table } from 'antd'
import { formatDuration } from '../../../util/vrp'
import WithLoading from '../../../hoc/loading'
import { isBelowBreakpoint } from '../../../util/windows'

const RouteList = ({ dataTables, completedRoute, onVisibleStatus }) => {
  const columns = []
  let isAllRouteCompleted = true
  dataTables.forEach(item => {
    item.route.nodes.forEach(node => {
      if (!node.isAllGoodsArrived || !node.cart.isConfirmed) {
        isAllRouteCompleted = false
      }
    })
  })
  if (!isAllRouteCompleted) {
    columns.push(
      {
        title: 'Hành động',
        dataIndex: 'action',
        align: 'center',
        render: (text, record) => {
          let isRouteCompleted = true
          record.route.nodes.forEach(node => {
            if (!node.isAllGoodsArrived || !node.cart.isConfirmed) {
              isRouteCompleted = false
            }
          })
          return isRouteCompleted ? ('') : (
            <Button type="primary" shape="round" onClick={() => { completedRoute(record.route.driver.userId) }}>Hoàn thành</Button>
          )
        },
      }
    )
  }
  columns.push(
    {
      title: 'Lái xe',
      dataIndex: 'DriverInfo',
      render: (text, record) => (
        <Card style={{ width: isBelowBreakpoint() ? 180 : null }}>
          <p>
            {record.route.driver.name}
            {' '}
            -
            {' '}
            {record.route.driver.code}
          </p>
          <p>{record.route.driver.phoneNumber}</p>
          <p>
            Khối lượng:
            {' '}
            {record.route.totalWeight}
            {' '}
            kg
          </p>
          <p>
            Tổng khoảng cách:
            {' '}
            {record.route.totalDistance / 1000}
            {' '}
            km
          </p>
          <p>
            Tổng thời gian:
            {' '}
            {formatDuration(record.route.totalTime)}
          </p>
        </Card>
      ),
    }
  )
  if (dataTables.length > 0) {
    Object.keys(dataTables[0]).forEach(key => {
      if (key !== 'route') {
        columns.push({
          title: key,
          dataIndex: key,
          align: 'center',
          render: (text, record) => {
            if (record[key] == null) {
              return ('')
            }
            const cardClassName = record[key].isAllGoodsArrived ? record[key].cart.isConfirmed ? 'completed' : 'shipped' : ''
            return (
              <>
                <Card className={cardClassName} style={{ marginBottom: '5px' }}>
                  <p>{record[key].cart.cartCode}</p>
                  <p>{record[key].cart.contact.phone}</p>
                  <p>{record[key].cart.contact.address}</p>
                  <p>
                    {record[key].weight}
                    {' '}
                    kg
                  </p>
                </Card>
                {record[key].cart.orders[0].status === 'Sự Cố' ? <Button onClick={() => onVisibleStatus(record[key].cart.orders[0].items)}>Sự Cố</Button> : <Button disabled>{record[key].cart.orders[0].status}</Button>}
              </>
            )
          },
        })
      }
    })
  }
  return (
    <Table
      className="table-schedule-detail"
      size="small"
      scroll={{ x: 'max-content' }}
      columns={columns}
      bordered
      dataSource={dataTables}
      rowKey={record => record.route.driver.code}
      rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
      pagination={{ pageSize: 20 }}
    />
  )
}


export default WithLoading(RouteList)
