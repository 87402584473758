import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ROUTER from '../../constants/router'
import select from '../../util/select'
// import { ROLE } from '../../config/auth';
import SIDEBAR_ITEMS from '../Sidebar/sidebar-config'

const HomePage = ({ user }) => {
  const roleCode = user.role
  if (roleCode && SIDEBAR_ITEMS[roleCode].length) {
    return <Redirect to={SIDEBAR_ITEMS[roleCode][0].to} />
  }

  return <Redirect to={ROUTER.UNAUTHORIZED} />
}

export default connect(state => ({ user: select(state, ['authReducer'], 'user') }))(HomePage)
