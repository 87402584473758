import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Card, Table, Popconfirm, Input, Divider } from 'antd'
import { Link } from 'react-router-dom'
import { changeAlias } from '../../../util/formatText'
import router from '../../../constants/router'
import WithLoadingHOC from '../../../hoc/loading'


class GroupList extends React.Component {
  state = { filterText: '' }

  onChangeFilter = e => {
    this.setState({ filterText: e.target.value })
  }

  showModalImportPriceTable = group => {
    this.props.onOpenModalImport(group)
  }

  render() {
    const { dataSource, removeGroup } = this.props
    const { filterText } = this.state
    const columns = [
      {
        title: 'Tên nhóm',
        dataIndex: 'name',
        render: (value, record) => (
          <Link to={router.CUSTOMER_GROUP.DETAIL.replace(':id', record.id)}>{value}</Link>
        )
      },
      {
        title: 'Xóa',
        render: (value, record) => (
          <Popconfirm placement="top" title={`Xóa nhóm KH ${record.name}`} onConfirm={() => removeGroup(record.id)} okText="Có" cancelText="Không">
            <Button
              key="del"
              icon="delete"
              shape="circle"
              type="danger"
            />
          </Popconfirm>
        )
      },
      {
        title: 'Import bảng giá',
        render: (value, record) => (
          <Button
            onClick={() => this.showModalImportPriceTable(record)}
            key="import"
            icon="import"
            shape="circle"
            type="primary"
          />
        )
      }
    ]
    const data = dataSource.filter(
      item => changeAlias(item.name.toLowerCase()).indexOf(changeAlias(filterText.toLowerCase()))
        >= 0 || changeAlias(item.group_code.toLowerCase()).indexOf(changeAlias(filterText.toLowerCase())) >= 0,
    )
    return (
      <Card
        style={{ marginTop: 10 }}
        title="Nhóm khách hàng"
      >
        <Row>
          <Col span={24} lg={12}>
            <Input value={filterText} style={{ width: '100%' }} onChange={this.onChangeFilter} placeholder="Lọc theo tên / mã nhóm" />
          </Col>
        </Row>
        <Divider dashed />
        <Row>
          <Table
            size="small"
            scroll={{ x: 'max-content' }}
            dataSource={data}
            columns={columns}
            rowKey={record => record.id}
            rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
            pagination={{
              showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} nhóm khách hàng`,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '40', '80'],
            }}
          />
        </Row>
      </Card>
    )
  }
}

GroupList.propTypes = {
  dataSource: PropTypes.array,
  removeGroup: PropTypes.func,
  // onFilterGroup: PropTypes.func
}

GroupList.defaultProps = {
  dataSource: [],
  removeGroup: () => {},
  // onFilterGroup: () => {},
}

export default WithLoadingHOC(GroupList)
