import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DatePicker, Divider, Table, Form, Button, Input, Modal } from 'antd'
import moment from 'moment'
import { changeAlias } from '../../../util/formatText'

const columns = (valueSearch, onChangeSearch) => [
  {
    title: <Input placeholder="Tìm kiếm khách hàng" value={valueSearch} onChange={onChangeSearch} />,
    dataIndex: 'name',
  },
  {
    title: 'Nhóm',
    dataIndex: 'groupName'
  },
  {
    title: 'SĐT',
    dataIndex: 'phoneNumber'
  }
]

class CustomerResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valueSearch: '',
      requireTime: moment().toISOString(),
      dataSource: props.dataSource
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataSource && nextProps.dataSource.length && (!prevState.dataSource || !prevState.dataSource.length)) {
      return { dataSource: nextProps.dataSource }
    }

    return {
      dataSource: !prevState.valueSearch ? nextProps.dataSource : nextProps.dataSource.filter(
        item => changeAlias(item.name.toLowerCase()).indexOf(changeAlias(prevState.valueSearch.toLowerCase())) >= 0,
      ),
    }
  }

  componentDidMount() {
    this.props.onCheck({ requireTime: moment().format('YYYY-MM-DD') })
    this.setState({ requireTime: moment().format('YYYY-MM-DD') })
  }

  onChangeSearch = ({ target: { value } }) => {
    this.setState(() => ({ valueSearch: value }))
    this.props.onCheck({
      requireTime: this.state.requireTime,
      page: this.props.meta.page,
      pageSize: this.props.meta.page_size,
      customerName: value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onCheck({ requireTime: moment(values.date).format('YYYY-MM-DD') })
        this.setState({ requireTime: moment(values.date).format('YYYY-MM-DD') })
      }
    })
  }

  onChangePage = (page, pageSize) => {
    this.props.onCheck({
      requireTime: this.state.requireTime,
      page,
      pageSize,
      customerName: this.state.valueSearch
    })
  }

  render() {
    const { valueSearch, dataSource } = this.state
    const { visible, onCancel, isFetching, meta, form: { getFieldDecorator } } = this.props
    return (
      <Modal
        width={1000}
        visible={visible}
        title="Khách hàng chưa đặt hàng"
        closable={false}
        centered
        footer={[
          <Button key="back" onClick={onCancel}>
              Đóng
          </Button>,
        ]}
      >
        <Form layout="inline" onSubmit={this.handleSubmit}>
          <Form.Item>
            <Form.Item label="Ngày cần kiểm tra">
              {
                getFieldDecorator('date', {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: 'Chọn ngày cần kiểm tra',
                    },
                  ],
                })(
                  <DatePicker format="DD/MM/YYYY" />,
                )
              }
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button icon="search" htmlType="submit" type="primary">
              Kiểm tra
            </Button>
          </Form.Item>
        </Form>

        <Divider />
        <Table
          size="small"
          loading={isFetching}
          columns={columns(valueSearch, this.onChangeSearch)}
          dataSource={dataSource}
          rowKey="code"
          bordered
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={{
            current: meta.page,
            pageSize: meta.page_size,
            total: meta.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} khách hàng`,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            onShowSizeChange: this.onChangePage,
            onChange: this.onChangePage,
          }}
        />
      </Modal>
    )
  }
}

CustomerResult.propTypes = {
  form: PropTypes.object,
  dataSource: PropTypes.array,
  meta: PropTypes.object,
  onCheck: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

CustomerResult.defaultProps = {
  onCheck: () => {},
  form: {},
  dataSource: [],
  meta: {},
}

export default Form.create()(CustomerResult)
