/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';
import produce from 'immer';
import TYPES from '../../constants/actionTypes';

const findIndex = (categories, id) => {
  let result = -1;
  categories.forEach((category, index) => {
    if (category.id === id) {
      result = index;
    }
  });
  return result;
};

const listState = {
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
};

const list = (state = listState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TYPES.GETTING_CATEGORIES:
        draft.isFetching = true;
        break;

      case TYPES.GET_CATEGORIES_SUCCESS: {
        draft.items = action.payload.data;
        draft.meta = {
          total: action.payload.metaData.total,
          current: action.payload.metaData.page,
          pageSize: action.payload.metaData.pageSize,
        };
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;
      }

      case TYPES.GET_CATEGORIES_FAILURE: {
        draft.isFetching = false;
        draft.didInvalidate = true;
        break;
      }

      case TYPES.CHANGE_CATEGORY_STATUS: {
        const { id, value } = action;
        const index = findIndex(draft.items, id);
        draft.items[index].status = !value;
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;
      }

      default:
        return draft;
    }
  });

const detailState = {
  item: {},
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
};

const detail = (state = detailState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TYPES.GETTING_CATEGORY:
      case TYPES.INSERTING_CATEGORY:
      case TYPES.UPDATING_CATEGORY:
      case TYPES.DELETING_CATEGORY:
        draft.isFetching = true;
        break;

      case TYPES.GET_CATEGORY_SUCCESS: {
        draft.item = action.payload.data;
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;
      }

      case TYPES.INSERT_CATEGORY_SUCCESS:
      case TYPES.UPDATE_CATEGORY_SUCCESS:
      case TYPES.DELETE_CATEGORY_SUCCESS:
      case TYPES.GET_CATEGORY_FAILURE:
      case TYPES.INSERT_CATEGORY_FAILURE:
      case TYPES.UPDATE_CATEGORY_FAILURE:
      case TYPES.DELETE_CATEGORY_FAILURE: {
        draft.isFetching = false;
        draft.didInvalidate = true;
        break;
      }

      default:
        return draft;
    }
  });

const requestState = {
  items: [],
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  updatedAt: 0,
};

const request = (state = requestState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TYPES.GETTING_REQUESTS:
        draft.isFetching = true;
        break;

      case TYPES.GET_REQUESTS_SUCCESS: {
        draft.items = action.payload;
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;
      }

      case TYPES.GET_REQUESTS_FAILURE: {
        draft.isFetching = false;
        draft.didInvalidate = true;
        break;
      }

      default:
        return draft;
    }
  });

export default combineReducers({ list, detail, request });
