import ROUTER from '../../constants/router';

export default {
  admin: [
    // {
    //   key: 'dashboard',
    //   to: ROUTER.DASHBOARD,
    //   icon: 'dashboard',
    //   title: 'Dashboard',
    // },
    {
      key: 'product-p',
      to: ROUTER.PRODUCT.INDEX,
      icon: 'shopping',
      title: 'Hàng Hóa',
      children: [
        {
          key: 'product',
          to: ROUTER.PRODUCT.INDEX,
          icon: '',
          title: 'Hàng hóa',
        },
        {
          key: 'category',
          to: ROUTER.CATEGORY.INDEX,
          icon: '',
          title: 'Nhóm hàng hóa',
        },
        {
          key: 'supplier',
          to: ROUTER.SUPPLIER.INDEX,
          icon: '',
          title: 'Nhà cung cấp',
        },
        {
          key: 'general',
          to: ROUTER.GENERAL.INDEX,
          icon: '',
          title: 'Đơn vị tính',
        },
      ],
    },
    {
      key: 'customer-p',
      to: ROUTER.CART.INDEX,
      icon: 'contacts',
      title: 'Khách hàng',
      children: [
        {
          key: 'cart',
          to: ROUTER.CART.INDEX,
          icon: '',
          title: 'Giỏ hàng',
        },
        {
          key: 'customer',
          to: ROUTER.CUSTOMER.INDEX,
          icon: '',
          title: 'Khách hàng',
        },
        {
          key: 'customer-group',
          to: ROUTER.CUSTOMER_GROUP.INDEX,
          icon: '',
          title: 'Nhóm khách hàng',
        },
      ],
    },
    {
      key: 'invoice',
      to: ROUTER.INVOICE.INDEX,
      icon: 'ordered-list',
      title: 'Đơn hàng',
    },
    {
      key: 'routing-p',
      to: ROUTER.SCHEDULE.INDEX,
      icon: 'share-alt',
      title: 'Giao vận',
      children: [
        {
          key: 'schedule',
          to: ROUTER.SCHEDULE.INDEX,
          icon: '',
          title: 'Lịch giao vận',
        },
        {
          key: 'routing',
          to: ROUTER.ROUTING.VRP,
          icon: '',
          title: 'Giao vận',
        },
        {
          key: 'routing-sample',
          to: ROUTER.ROUTING.SAMPLE,
          icon: '',
          title: 'Lịch mẫu',
        },
      ],
    },
    {
      key: 'driver',
      to: ROUTER.DRIVER.INDEX,
      icon: 'car',
      title: 'Lái xe',
    },
    {
      key: 'account',
      to: ROUTER.GENERAL.ACCOUNT,
      icon: 'user',
      title: 'Tài khoản',
    },
    {
      key: 'report',
      to: ROUTER.REPORT.INDAY,
      icon: 'file-text',
      title: 'Báo cáo',
      children: [
        {
          key: 'inday',
          to: ROUTER.REPORT.INDAY,
          icon: '',
          title: 'Báo cáo hàng ngày',
        },
      ],
    },
    {
      key: 'manager',
      to: ROUTER.MANAGA.INDEX,
      icon: 'setting',
      title: 'Phân quyền',
    },
    {
      key: 'fast-database',
      to: ROUTER.SYNDATA.INDEX,
      icon: 'database',
      title: 'Kết nối với Fast',
    },
    {
      key: 'stamp',
      to: ROUTER.STAMP.INDEX,
      icon: 'barcode',
      title: 'In tem',
    },
    {
      key: 'qr-stamp',
      to: ROUTER.STAMP.QR,
      icon: 'qrcode',
      title: 'In tem QR',
    },
  ],
  manager: [
    // {
    //   key: 'dashboard',
    //   to: ROUTER.DASHBOARD,
    //   icon: 'dashboard',
    //   title: 'Dashboard',
    // },
    {
      key: 'product-p',
      to: ROUTER.PRODUCT.INDEX,
      icon: 'shopping',
      title: 'Hàng Hóa',
      children: [
        {
          key: 'product',
          to: ROUTER.PRODUCT.INDEX,
          icon: '',
          title: 'Hàng hóa',
        },
        {
          key: 'category',
          to: ROUTER.CATEGORY.INDEX,
          icon: '',
          title: 'Nhóm hàng hóa',
        },
        {
          key: 'general',
          to: ROUTER.GENERAL.INDEX,
          icon: '',
          title: 'Đơn vị tính',
        },
      ],
    },
    {
      key: 'customer-p',
      to: ROUTER.CART.INDEX,
      icon: 'contacts',
      title: 'Khách hàng',
      children: [
        {
          key: 'cart',
          to: ROUTER.CART.INDEX,
          icon: '',
          title: 'Giỏ hàng',
        },
        {
          key: 'customer',
          to: ROUTER.CUSTOMER.INDEX,
          icon: '',
          title: 'Khách hàng',
        },
        {
          key: 'customer-group',
          to: ROUTER.CUSTOMER_GROUP.INDEX,
          icon: '',
          title: 'Nhóm khách hàng',
        },
      ],
    },
    {
      key: 'invoice',
      to: ROUTER.INVOICE.INDEX,
      icon: 'ordered-list',
      title: 'Đơn hàng',
    },
    {
      key: 'routing-p',
      to: ROUTER.SCHEDULE.INDEX,
      icon: 'share-alt',
      title: 'Giao vận',
      children: [
        {
          key: 'schedule',
          to: ROUTER.SCHEDULE.INDEX,
          icon: '',
          title: 'Lịch giao vận',
        },
        {
          key: 'routing',
          to: ROUTER.ROUTING.VRP,
          icon: '',
          title: 'Giao vận',
        },
        {
          key: 'routing-sample',
          to: ROUTER.ROUTING.SAMPLE,
          icon: '',
          title: 'Lịch mẫu',
        },
      ],
    },
    {
      key: 'driver',
      to: ROUTER.DRIVER.INDEX,
      icon: 'car',
      title: 'Lái xe',
    },
    {
      key: 'account',
      to: ROUTER.GENERAL.ACCOUNT,
      icon: 'user',
      title: 'Tài khoản',
    },
    {
      key: 'report',
      to: ROUTER.REPORT.INDAY,
      icon: 'file-text',
      title: 'Báo cáo',
      children: [
        {
          key: 'inday',
          to: ROUTER.REPORT.INDAY,
          icon: '',
          title: 'Báo cáo hàng ngày',
        },
      ],
    },
    {
      key: 'manager',
      to: ROUTER.MANAGA.INDEX,
      icon: 'setting',
      title: 'Phân quyền',
    },
    {
      key: 'fast-database',
      to: ROUTER.SYNDATA.INDEX,
      icon: 'database',
      title: 'Kết nối với Fast',
    },
    {
      key: 'stamp',
      to: ROUTER.STAMP.INDEX,
      icon: 'barcode',
      title: 'In tem',
    },
    {
      key: 'qr-stamp',
      to: ROUTER.STAMP.QR,
      icon: 'qrcode',
      title: 'In tem QR',
    },
  ],
  'order-manager': [
    {
      key: 'invoice',
      to: ROUTER.INVOICE.INDEX,
      icon: 'ordered-list',
      title: 'Đơn hàng',
    },
    {
      key: 'customer-p',
      to: ROUTER.CART.INDEX,
      icon: 'contacts',
      title: 'Khách hàng',
      children: [
        {
          key: 'cart',
          to: ROUTER.CART.INDEX,
          icon: '',
          title: 'Giỏ hàng',
        },
        {
          key: 'customer',
          to: ROUTER.CUSTOMER.INDEX,
          icon: '',
          title: 'Khách hàng',
        },
        {
          key: 'customer-group',
          to: ROUTER.CUSTOMER_GROUP.INDEX,
          icon: '',
          title: 'Nhóm khách hàng',
        },
      ],
    },
    {
      key: 'product-p',
      to: ROUTER.PRODUCT.INDEX,
      icon: 'shopping',
      title: 'Hàng Hóa',
      children: [
        {
          key: 'product',
          to: ROUTER.PRODUCT.INDEX,
          icon: '',
          title: 'Hàng hóa',
        },
        {
          key: 'category',
          to: ROUTER.CATEGORY.INDEX,
          icon: '',
          title: 'Nhóm hàng hóa',
        },
        {
          key: 'general',
          to: ROUTER.GENERAL.INDEX,
          icon: '',
          title: 'Đơn vị tính',
        },
      ],
    },
    {
      key: 'report',
      to: ROUTER.REPORT.INDAY,
      icon: 'file-text',
      title: 'Báo cáo',
      children: [
        {
          key: 'inday',
          to: ROUTER.REPORT.INDAY,
          icon: '',
          title: 'Báo cáo hàng ngày',
        },
      ],
    },
    {
      key: 'fast-database',
      to: ROUTER.SYNDATA.INDEX,
      icon: 'database',
      title: 'Kết nối với Fast',
    },
    {
      key: 'stamp',
      to: ROUTER.STAMP.INDEX,
      icon: 'barcode',
      title: 'In tem',
    },
    {
      key: 'qr-stamp',
      to: ROUTER.STAMP.QR,
      icon: 'qrcode',
      title: 'In tem QR',
    },
  ],
  'routing-manager': [
    {
      key: 'routing-p',
      to: ROUTER.SCHEDULE.INDEX,
      icon: 'share-alt',
      title: 'Giao vận',
      children: [
        {
          key: 'schedule',
          to: ROUTER.SCHEDULE.INDEX,
          icon: '',
          title: 'Lịch giao vận',
        },
        {
          key: 'routing',
          to: ROUTER.ROUTING.VRP,
          icon: '',
          title: 'Giao vận',
        },
        {
          key: 'routing-sample',
          to: ROUTER.ROUTING.SAMPLE,
          icon: '',
          title: 'Lịch mẫu',
        },
      ],
    },
    {
      key: 'driver',
      to: ROUTER.DRIVER.INDEX,
      icon: 'car',
      title: 'Lái xe',
    },
  ],
  sale: [
    {
      key: 'invoice',
      to: ROUTER.INVOICE.INDEX,
      icon: 'ordered-list',
      title: 'Đơn hàng',
    },
    {
      key: 'customer-p',
      to: ROUTER.CART.INDEX,
      icon: 'contacts',
      title: 'Khách hàng',
      children: [
        {
          key: 'cart',
          to: ROUTER.CART.INDEX,
          icon: '',
          title: 'Giỏ hàng',
        },
        {
          key: 'customer',
          to: ROUTER.CUSTOMER.INDEX,
          icon: '',
          title: 'Khách hàng',
        },
        {
          key: 'customer-group',
          to: ROUTER.CUSTOMER_GROUP.INDEX,
          icon: '',
          title: 'Nhóm khách hàng',
        },
      ],
    },
  ],
};
