/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Steps, Card, Button, Icon, Row, message, notification } from 'antd'

import PrepareDriverStep from './steps/prepare-driver'
import PrepareNodeStep from './steps/prepare-node'

import Solution from './steps/solve'

import SaveDataStep from './steps/save'

import select from '../../util/select'
import WithPageHOC from '../../hoc/page'

const { Step } = Steps

class Routing extends Component {
  constructor() {
    super()
    this.state = {
      currentStep: 0,
      isVrp: true,
    }
  }

  nextPrevSteps = action => {
    switch (action) {
    case 'next':
      if (this.validateSelectedData()) {
        this.setState(prevState => ({ currentStep: prevState.currentStep < 3 ? prevState.currentStep + 1 : prevState.currentStep }))
      }
      break
    case 'prev':
      this.setState(prevState => ({ currentStep: prevState.currentStep > 0 ? prevState.currentStep - 1 : prevState.currentStep }))
      break
    default:
      break
    }
  };

  validateSelectedData = () => {
    const { selectedDrivers, selectedNodes, selectedRoutes, nodePool } = this.props
    const { currentStep } = this.state
    if (currentStep === 0 && selectedDrivers.length === 0) {
      message.warning('Chọn ít nhất một lái xe')
      return false
    } if (currentStep === 1 && selectedNodes.length === 0) {
      message.warning('Chọn ít nhất một khách hàng')
      return false
    }
    if (currentStep === 2 && selectedRoutes.length === 0) {
      message.warning('Chọn ít nhất một tuyến đường')
      return false
    }
    if (currentStep === 2 && selectedNodes.length === nodePool.length) {
      message.warning('Chọn ít nhất một tuyến đường')
      return false
    }
    if (currentStep === 2 && selectedNodes.length > nodePool.length && nodePool.length > 0) {
      notification.warning({
        message: 'Cảnh báo!!!',
        description:
          `Còn ${nodePool.length} giỏ chưa giao`,
      })
      return true
    }
    return true
  };

  onChangeMethod = ({ target: { value } }) => this.setState({ isVrp: value });

  renderComponent = () => {
    const { currentStep, isVrp } = this.state
    const { history } = this.props
    switch (currentStep) {
    case 0:
      return <PrepareDriverStep />
    case 1:
      return <PrepareNodeStep isVrp={isVrp} onChangeMethod={this.onChangeMethod} />
    case 2:
      return <Solution isVrp={isVrp} history={history} />
    case 3:
      return <SaveDataStep />
    default:
      return <div />
    }
  };

  render() {
    const { currentStep } = this.state

    return (
      <div>
        <Steps current={currentStep}>
          <Step title="Chuẩn bị lái xe" description="Lấy thông tin lái xe" />
          <Step title="Chuẩn bị đơn hàng" description="Lấy thông tin đơn hàng" />
          <Step title="Chỉnh sửa tuyến đường" description="Tối ưu & chỉnh sửa lộ trình" />
          <Step title="Xuất dữ liệu" description="Xuất dữ liệu" />
        </Steps>
        <Row
          style={{ textAlign: 'right' }}
        >
          <Button
            disabled={currentStep === 0}
            style={{ marginRight: 10 }}
            type="primary"
            ghost="ghost"
            onClick={() => this.nextPrevSteps('prev')}
          >
            <Icon type="left" />
                      Trước
          </Button>
          <Button
            disabled={currentStep === 3}
            type="primary"
            ghost="ghost"
            onClick={() => this.nextPrevSteps('next')}
          >
                      Sau
            <Icon type="right" />
          </Button>
        </Row>
        <Card
          style={{
            width: '100%',
            margin: '10px 0',
          }}
        >
          {this.renderComponent()}
        </Card>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedDrivers: select(state, 'routingReducer', 'drivers'),
  selectedNodes: select(state, 'routingReducer', 'nodes'),
  selectedRoutes: select(state, 'routingReducer', 'routes'),
  nodePool: select(state, 'routingReducer', 'nodePool'),
})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithPageHOC('routing-vrp', 'routing')(Routing))
