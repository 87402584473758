/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable quotes */
/* eslint-disable semi */
/* eslint-disable react/no-array-index-key */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, notification, Button, Alert, Divider, Row, Col, Checkbox, DatePicker } from 'antd';
import * as XLSX from 'xlsx';

import { getUrlStamp } from './actions';
import select from '../../util/select';

class StampPage extends Component {
  constructor() {
    super();
    this.state = {
      errorFile: false,
      arrayStamp: [],
      isPrintAgain: false,
      isPrinting: false,
      pdfFileUrl: '',
      selectedDate: null,

    };
    this.fileUpload = React.createRef();
  }

  importExcel = file => {
    const fileReader = new FileReader();

    fileReader.onload = event => {
      try {
        /** convert sheet to json */
        let startAt = 0;
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const Sheet = workbook.Sheets[workbook.SheetNames[0]];
        let data = XLSX.utils.sheet_to_json(Sheet);
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`];
          if (flagCell !== undefined) {
            startAt = i;
            // 1
          }
        }

        if (startAt === 0) {
          notification.error({ message: 'Thêm mới file excel thất bại!', duration: 1 });
        } else {
          const arrayTotal = [];
          data = XLSX.utils.sheet_to_json(Sheet, { raw: true });
          // xoa do an co khoi luong = 0
          for (let i = 0; i < data.length; i++) {
            const filtered = data[i];

            Object.keys(filtered).forEach(key => (filtered[key] === 0 || key === '__EMPTY_1') && delete filtered[key]);
          }

          // xu ly tao mang moi
          for (let i = 0; i < data.length; i++) {
            const string = data[i];
            const array1 = [];
            const array2 = [];
            // ktra thuc pham co khoi luong lon hon 0
            if (Object.keys(string).length > 2) {
              array1.push({
                product_name: Object.values(string)[0],
                unit: Object.values(string)[1],
                rowNumberArray_1: i + 1,
              });

              for (let ar = 2; ar < Object.keys(string).length - 1; ar++) {
                const index_1 = Object.keys(string)[ar].indexOf('(');
                const index_2 = Object.keys(string)[ar].indexOf(')');
                const code = Object.keys(string)[ar].slice(index_1 + 1, index_2);
                const customerName = Object.keys(string)
                  [ar].slice(index_2 + 1)
                  .trim();
                array2.push({
                  cart_code: code.replace(/\s+/g, ''),
                  customer_name: customerName,
                  quantity: `${Object.values(string)[ar]}`,
                  rowNumberArray_2: i + 1,
                });
              }
            }

            // merge name & unit vao object
            for (let i = 0; i < array2.length; i++) {
              if (array1[0].rowNumberArray_1 === array2[i].rowNumberArray_2) {
                array2[i].product_name = array1[0].product_name;
                array2[i].unit = array1[0].unit;
              }
            }

            // ktra neu dong cuoi la number thi xoa
            if (
              array2.length !== 0 &&
              typeof array2[0].unit !== 'number' &&
              typeof array2[0].product_name !== 'number'
            ) {
              for (let i = 0; i < array2.length; i++) {
                arrayTotal.push(array2[i]);
              }
            }
          }

          // xoa rowNumberArray_2
          for (let i = 0; i < arrayTotal.length; i++) {
            delete arrayTotal[i].rowNumberArray_2;
          }

          notification.success({ message: 'Đã đọc file in tem', duration: 2 });
          this.setState({ arrayStamp: arrayTotal });
        }
      } catch (e) {
        notification.error({ message: e, duration: 5 });
      }
    };
    fileReader.readAsBinaryString(file);
  };

  onImportExcel = event => {
    const { files } = event.target;
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(files[0]);
    }
  };

  onDateChange = (date, dateString) => {
    this.setState({ selectedDate: dateString });
  };

  onUpload = () => {
    const { selectedDate } = this.state;
    const formattedDate = selectedDate ? new Date(selectedDate).toISOString() : null;
    this.setState({ isPrinting: true, pdfFileUrl: '' });
    this.props.getUrlStamp(
      0,
      { stamps: this.state.arrayStamp, not_print_again: this.state.isPrintAgain, date: formattedDate },
      {
        onSuccess: pdfUrl => {
          notification.success({ message: 'Tạo mới phiếu tem thành công', duration: 5 });
          this.setState({ arrayStamp: [], isPrinting: false, pdfFileUrl: pdfUrl });
        },
        onError: mes => {
          this.setState({ arrayStamp: [], isPrinting: false });
          notification.error({ message: `Tạo thất bại. ${mes}`, duration: 5 });
        },
      },
    );
  };

  onChange = e => this.setState({ isPrintAgain: e.target.checked });

  render() {
    const { errorFile, arrayStamp, isPrinting, pdfFileUrl } = this.state;
    const { changedDataMessages } = this.props;

    return (
      <Row type="flex" justify="center">
        <Col span={24} lg={14}>
          <Card>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col span={24}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1vVzO4ye7MTycrHX5WtFa47V1AekKmMiO3DA-FxqscHg/edit?usp=sharing"
                >
                  Mẫu file excel
                </a>
                <DatePicker
                  style={{ marginLeft: '10px' }}
                  onChange={this.onDateChange}
                  placeholder="Chọn ngày"
                />
                <Checkbox style={{ float: 'right' }} onChange={this.onChange}>
                  Không in lại
                </Checkbox>
              </Col>
            </Row>

            <hr />

            <Divider dashed />
            <Row type="flex" justify="space-between" align="middle">
              <Col>
                <input
                  id="fileUpload"
                  type="file"
                  ref={v => {
                    this.fileUpload = v;
                  }}
                  accept=".xlsx, .xls"
                  onChange={this.onImportExcel}
                />

                {errorFile && <Alert style={{ marginTop: 10 }} type="error" showIcon message="Chọn file để upload" />}
              </Col>
              <Col>
                {!arrayStamp.length ? (
                  <Button disabled={!arrayStamp.length}>Xuất file PDF</Button>
                ) : (
                  <Button
                    onClick={() => this.onUpload()}
                    disabled={!arrayStamp.length}
                    loading={isPrinting}
                    style={{
                      backgroundColor: arrayStamp.length && '#1890ff',
                      color: arrayStamp.length && 'white',
                      borderRadius: '7px',
                    }}
                  >
                    Xuất file PDF
                  </Button>
                )}
              </Col>
            </Row>
            {pdfFileUrl ? (
              <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 10 }}>
                <Col>
                  <strong>Xem File:&nbsp;</strong>
                  <a href={pdfFileUrl} target="_blank" rel="noopener noreferrer">
                    {pdfFileUrl}
                  </a>
                </Col>
              </Row>
            ) : null}
            {changedDataMessages?.length ? (
              <>
                <Divider dashed />
                <Row type="flex" justify="space-between" align="middle">
                  <Col>
                    <strong>Số liệu thay đổi so với lần in trước:</strong>
                    <ul>
                      {changedDataMessages.map((item, index) => (
                        <li key={`changed-${index}-${item.length}`}>{item}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </>
            ) : null}
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  url_items_stamp: select(state, ['stampReducer', 'stamp'], 'url_items_stamp'),
  changedDataMessages: select(state, ['stampReducer', 'stamp'], 'changed_data_messages'),
});

const mapDispatchToProps = dispatch => ({
  getUrlStamp: (offset, payload, meta) => dispatch(getUrlStamp(offset, payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StampPage);
