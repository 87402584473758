/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notification, Row } from 'antd'

import WithPageHOC from '../../hoc/page'
import select from '../../util/select'
import GroupList from './components/GroupList'
import UploadModal from './components/price-table-modal'
import GroupForm from './components/GroupForm'
import { insertPriceTableFromPreview } from '../Price/actions'
import { getProducts } from '../Product/actions'
import { getCustomerGroups, insertCustomerGroup, removeGroup, importPriceTable, getPriceTables } from './actions'

class CustomerGroup extends Component {
  state = {
    visibleUploadModal: false,
    group: {}
  };

  static getDerivedStateFromProps(nextProps) {
    nextProps.getCustomerGroups()
    return null
  }

  handleAdd = (formData, meta) => this.props.insertCustomerGroup(formData, meta);

  removeGroup = id => this.props.removeGroup(id, {
    onSuccess: () => this.props.getCustomerGroups(),
    onError: error => notification.error({ message: `Xoá nhóm không thành công - ${error}` }),
  });

  setStateGroupImport = group => {
    this.props.getPriceTables({ groupID: group.id })
    this.setState({ group })
    this.toggleReview()
  }

  toggleReview = () => {
    const { visibleUploadModal } = this.state
    if (visibleUploadModal === false) {
      this.props.getProducts({ pageSize: -1 })
    }
    this.setState({ visibleUploadModal: !visibleUploadModal })
  }

  onPreviewSubmit = (payload, callback) => {
    this.props.createPriceTableFromPreview(
      payload,
      {
        onSuccess: () => {
          notification.success({ message: 'Tạo bảng giá thành công' })
          this.toggleReview()
          callback()
        },
        onError: () => {
          notification.success({ message: 'Tạo bảng giá thất bại. Có thể file bị lỗi trùng Mã sản phẩm.' })
          callback()
        }
      }
    )
  }

  render() {
    const {
      groups,
      isFetching,
      products,
      priceTables
    } = this.props
    const { visibleUploadModal, group } = this.state

    return (
      <div>
        <Row gutter={24}>
          <GroupForm onAdd={this.handleAdd} />
        </Row>
        <Row gutter={24}>
          <GroupList
            dataSource={groups}
            isFetching={isFetching}
            removeGroup={this.removeGroup}
            onOpenModalImport={this.setStateGroupImport}
          />
        </Row>
        <UploadModal
          priceTables={priceTables}
          group={group}
          products={products}
          visible={visibleUploadModal}
          onCloseModal={this.toggleReview}
          onSubmit={this.onPreviewSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  groups: select(state, ['customerReducer', 'group'], 'items'),
  products: select(state, ['productReducer', 'list'], 'items'),
  isFetching: select(state, ['customerReducer', 'group'], 'isFetching'),
  isLoadingPriceTable: select(state, ['customerReducer', 'priceTable'], 'isFetching'),
  priceTables: select(state, ['customerReducer', 'customer'], 'editingPriceTables'),
})

const mapDispatchToProps = dispatch => ({
  getCustomerGroups: () => dispatch(getCustomerGroups()),
  insertCustomerGroup: (payload, meta) => dispatch(insertCustomerGroup(payload, meta)),
  removeGroup: (id, meta) => dispatch(removeGroup(id, meta)),
  importPriceTable: (params, payload, meta) => dispatch(importPriceTable(params, payload, meta)),
  getProducts: params => dispatch(getProducts(params)),
  createPriceTableFromPreview: (payload, meta) => dispatch(insertPriceTableFromPreview(payload, meta)),
  getPriceTables: params => dispatch(getPriceTables(params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithPageHOC('customer-group', 'data')(CustomerGroup))
