import * as Sync from './sync'
import { apiCall } from '../../util/apiCall'
import { API } from '../../constants/api'
import TYPES from '../../constants/actionTypes'
import select from '../../util/select'

export const getProductUnits = params => async (dispatch, getState) => {
  const state = getState()
  const isFetching = select(state, ['generalReducer', 'productUnit'], 'isFetching')
  const didInvalidate = select(state, ['generalReducer', 'productUnit'], 'didInvalidate')
  const api = API.PRODUCT_UNIT.getProductUnits()
  if (!isFetching && didInvalidate) {
    dispatch(Sync.gettingProductUnits())
    const { response, error } = await apiCall({ ...api, params })
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(Sync.getProductUnitsSuccess(response.data))
    } else {
      dispatch(Sync.getProductUnitsFailure())
    }
  }
}

export const getProductUnit = id => async dispatch => {
  const api = API.PRODUCT_UNIT.getProductUnit(id)
  dispatch(Sync.gettingProductUnit())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(Sync.getProductUnitSuccess(response.data))
  } else {
    dispatch(Sync.getProductUnitFailure())
  }
}

export const insertProductUnit = (payload, meta) => async dispatch => {
  const api = API.PRODUCT_UNIT.insertProductUnit()
  dispatch(Sync.insertingProductUnit())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(Sync.insertProductUnitSuccess())
    if (meta && meta.onSuccess) {
      dispatch(getProductUnits())
      meta.onSuccess()
    }
  } else {
    dispatch(Sync.insertProductUnitFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const updateProductUnit = (id, payload, meta) => async dispatch => {
  const api = API.PRODUCT_UNIT.updateProductUnit(id)
  dispatch({ type: TYPES.UPDATING_PRODUCT_UNIT })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_PRODUCT_UNIT_SUCCESS, id, payload })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.UPDATE_PRODUCT_UNIT_FAILURE })
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const deleteProductUnit = (id, meta) => async dispatch => {
  const api = API.PRODUCT_UNIT.deleteProductUnit(id)
  dispatch({ type: TYPES.DELETING_PRODUCT_UNIT })
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.DELETE_PRODUCT_UNIT_SUCCESS, id })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(Sync.deleteProductUnitFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const getVehicleTypes = () => async (dispatch, getState) => {
  const state = getState()
  const isFetching = select(state, ['generalReducer', 'vehicleType'], 'isFetching')
  const didInvalidate = select(state, ['generalReducer', 'vehicleType'], 'didInvalidate')
  const api = API.VEHICLE_TYPE.getVehicleTypes()
  if (!isFetching && didInvalidate) {
    dispatch(Sync.gettingVehicleTypes())
    const { response, error } = await apiCall({ ...api })
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(Sync.getVehicleTypesSuccess(response.data))
    } else {
      dispatch(Sync.getVehicleTypesFailure())
    }
  }
}

export const insertVehicleType = (payload, meta) => async dispatch => {
  const api = API.VEHICLE_TYPE.insertVehicleType()
  dispatch(Sync.insertingVehicleType())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(Sync.insertVehicleTypeSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(Sync.insertVehicleTypeFailure())
    if (meta && meta.onSuccess) {
      meta.onError()
    }
  }
}

export const deleteVehicleType = (id, meta) => async dispatch => {
  const api = API.VEHICLE_TYPE.deleteVehicleType(id)
  dispatch(Sync.deletingVehicleType())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(Sync.deleteVehicleTypeSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(Sync.deleteVehicleTypeFailure())
    if (meta && meta.onSuccess) {
      meta.onError()
    }
  }
}

export const getProductConditions = () => async (dispatch, getState) => {
  const state = getState()
  const isFetching = select(state, ['generalReducer', 'productCondition'], 'isFetching')
  const didInvalidate = select(state, ['generalReducer', 'productCondition'], 'didInvalidate')
  const api = API.PRODUCT_CONDITION.getProductConditions()
  if (!isFetching && didInvalidate) {
    dispatch(Sync.gettingProductConditions())
    const { response, error } = await apiCall(api)
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(Sync.getProductConditionsSuccess(response.data))
    } else {
      dispatch(Sync.getProductConditionsFailure())
    }
  }
}

export const getProductConditionsIfNeed = () => async (dispatch, getState) => {
  const state = getState()
  const isFetching = select(state, ['generalReducer', 'productCondition'], 'isFetching')
  const didInvalidate = select(state, ['generalReducer', 'productCondition'], 'didInvalidate')
  if (!isFetching && didInvalidate) {
    dispatch(getProductConditions())
  }
}

export const insertProductCondition = (payload, meta) => async dispatch => {
  const api = API.PRODUCT_CONDITION.insertProductConditions()
  dispatch(Sync.insertingProductCondition())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(Sync.insertProductConditionSuccess())
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch(Sync.insertProductConditionFailure())
    if (meta && meta.onSuccess) {
      meta.onError()
    }
  }
}

export const deleteProductCondition = (id, meta) => async dispatch => {
  const api = API.PRODUCT_CONDITION.deleteProductCondition(id)
  dispatch(Sync.deletingProductCondition())
  const { response, error } = await apiCall({ ...api })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(Sync.deleteProductConditionSuccess())
    if (meta && meta.onSuccess) {
      dispatch(getProductConditions())
      meta.onSuccess()
    }
  } else {
    dispatch(Sync.deleteProductConditionFailure())
    if (meta && meta.onSuccess) {
      meta.onError()
    }
  }
}


/** ACCOUNT */
const gettingAccounts = () => ({ type: TYPES.GETTING_ACCOUNT })

const getAccountsSuccess = payload => ({
  type: TYPES.GET_ACCOUNT_SUCCESS,
  payload,
})

const getAccountsFailure = () => ({ type: TYPES.GET_ACCOUNT_FAILURE })

export const getAccounts = params => async dispatch => {
  const api = API.ACCOUNT.getAccounts()

  dispatch(gettingAccounts())
  const { response, error } = await apiCall({ ...api, params })
  if (!error && response.status === 200 && response.data.success === true) {
    const { data, metaData } = response.data
    dispatch(getAccountsSuccess({ accounts: data, meta: metaData }))
  } else {
    dispatch(getAccountsFailure())
  }
}

const insertingAccount = () => ({ type: TYPES.INSERTING_ACCOUNT })

const insertAccountSuccess = () => ({ type: TYPES.INSERT_ACCOUNT_SUCCESS })

const insertAccountFailure = () => ({ type: TYPES.INSERT_ACCOUNT_FAILURE })

export const createAccount = (payload, meta, pageS) => async dispatch => {
  const api = API.ACCOUNT.createAccount()
  dispatch(insertingAccount())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch(insertAccountSuccess())
    dispatch(getAccounts({ pageSize: pageS }))
    if (meta && meta.onSuccess) {
      meta.onSuccess(response.data.data.id)
    }
  } else if (response.data.success === false) {
    dispatch(insertAccountFailure())
    if (meta && meta.onError) {
      // 'Tên tài khoản và mật khẩu phải có độ dài ít nhất 5 kí tự';
      meta.onError('Tên tài khoản đã có người sử dụng')
    }
  }
}

export const updateUser = (payload, meta) => async dispatch => {
  const api = API.ACCOUNT.updatePassword()
  dispatch({ type: TYPES.UPDATING_USER_PRODILE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_USER_PROFILE_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (error) {
    dispatch({ type: TYPES.UPDATE_USER_PROFILE_FAILURE })
    if (meta && meta.onSuccess) {
      meta.onError('Cập nhật không thành công')
    }
  }
}

export const updateRole = (id, payload, meta) => async dispatch => {
  const api = API.ACCOUNT.updateUser(id)
  dispatch({ type: TYPES.UPDATING_USER_PRODILE })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_USER_PROFILE_SUCCESS, id, payload })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (error) {
    dispatch({ type: TYPES.UPDATE_USER_PROFILE_FAILURE })
    if (meta && meta.onSuccess) {
      meta.onError('Cập nhật không thành công')
    }
  }
}


const updatingUser = () => ({ type: TYPES.UPDATING_USER_PRODILE })
const updateUserFailure = () => ({ type: TYPES.UPDATE_USER_PROFILE_FAILURE })

const actChangeUserStatus = payload => ({
  type: TYPES.CHANGE_USER_STATUS,
  payload
})

export const changeUserStatus = (id, payload, meta) => async dispatch => {
  const api = API.ACCOUNT.updateStatusUser()
  dispatch(updatingUser())
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data && response.data.success === true) {
    if (meta && meta.onSuccess) {
      dispatch(actChangeUserStatus(payload))
      meta.onSuccess()
    }
  } else {
    dispatch(updateUserFailure())
    if (meta && meta.onError) {
      meta.onError()
    }
  }
}

export const updatePassword = (payload, meta) => async dispatch => {
  const api = API.ACCOUNT.updatePassword()
  dispatch({ type: TYPES.UPDATING_PASSWORD })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.UPDATE_PASSWORD_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else {
    dispatch({ type: TYPES.UPDATE_PASSWORD_FAILURE })
    if (meta && meta.onError) {
      meta.onError(error)
    }
    // if (meta && meta.onSuccess) {
    //   meta.onError(error.status === 400 ? 'Tên tài khoản và mật khẩu phải có độ dài ít nhất 5 kí tự' : 'Tên tài khoản đã có người sử dụng')
    // }
  }
}

export const resetPassword = (payload, meta) => async dispatch => {
  const api = API.ACCOUNT.resetPassword()
  dispatch({ type: TYPES.RESETING_PASSWORD })
  const { response, error } = await apiCall({ ...api, payload })
  if (!error && response.status === 200 && response.data.success === true) {
    dispatch({ type: TYPES.RESET_PASSWORD_SUCCESS })
    if (meta && meta.onSuccess) {
      meta.onSuccess()
    }
  } else if (error) {
    dispatch({ type: TYPES.RESET_PASSWORD_FAILURE })
    if (meta && meta.onSuccess) {
      meta.onError(error.status === 400 ? 'Tên tài khoản và mật khẩu phải có độ dài ít nhất 5 kí tự' : 'Tên tài khoản đã có người sử dụng')
    }
  }
}
