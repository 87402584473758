import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, notification } from 'antd'
import select from '../../util/select'
import PasswordForm from './components/PasswordForm'

import { updatePassword, updateUser } from '../General/actions'
import { logOut } from '../Header/actions'

class ChangePassword extends React.Component {
    changePassword = payload => {
      this.props.changePassword(payload, {
        onSuccess: () => {
          notification.success({ message: 'Đổi mật khẩu thành công' })
          this.props.logOut()
        },
        onError: () => {
          notification.error({ message: 'Đổi mật khẩu thất bại' })
        },
      })
    };

    updateProfile = payload => {
      const { user } = this.props
      this.props.updateProfile(
        user.id,
        payload,
        {
          onSuccess: () => {
            notification.success({ message: 'Sửa thông tin thành công' })
            window.reload()
          },
          onError: () => {
            notification.success({ message: 'Sửa thông tin thất bại' })
          },
        },
      )
    }

    render() {
      // const { user } = this.props
      return (
        <Row type="flex" justify="center">
          <Col span={24} lg={10}>
            <Card>
              {/* <ProfileForm user={user} onUpdateProfile={this.updateProfile} />
              <Divider /> */}
              <PasswordForm onSubmit={this.changePassword} />
            </Card>
          </Col>
        </Row>
      )
    }
}

export default connect(
  state => ({ user: select(state, 'authReducer', 'user') }),
  dispatch => ({
    changePassword: (payload, meta) => dispatch(updatePassword(payload, meta)),
    updateProfile: (id, payload, meta) => dispatch(updateUser(id, payload, meta)),
    logOut: () => dispatch(logOut()),
  }),
)(ChangePassword)
