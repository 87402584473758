import { apiCall } from '../../util/apiCall';
import { API } from '../../constants/api';
import TYPES from '../../constants/actionTypes';

const gettingUrl = () => ({ type: TYPES.GETTING_URL_STAMP });

const getUrlSuccess = payload => ({
  type: TYPES.GET_URL_STAMP_SUCCESS,
  payload,
});

const getUrlFailure = () => ({ type: TYPES.GET_URL_STAMP_FAILURE });

export const getUrlStamp = (offset, payload, meta) => async dispatch => {
  const api = API.STAMP_API.postStamp(offset);

  dispatch(gettingUrl());
  const { response } = await apiCall({ ...api, payload });

  if (response.status === 200 && response.data.success === true && response.data.pd_url) {
    const { data } = response;
    dispatch(getUrlSuccess({ data }));
    if (data.pd_url) {
      window.open(data.pd_url, '_blank');
      meta.onSuccess(data.pd_url);
    } else {
      meta.onError('File đã in');
    }
  } else {
    dispatch(getUrlFailure());
    meta.onError('Lỗi tạo file tem');
  }
};

const gettingQRStampUrl = () => ({ type: TYPES.GETTING_URL_QR_STAMP });

const getQRStampUrlSuccess = payload => ({
  type: TYPES.GET_URL_QR_STAMP_SUCCESS,
  payload,
});

const getQRStampUrlFailure = () => ({ type: TYPES.GET_URL_QR_STAMP_FAILURE });

export const getQRStampUrl = (payload, meta) => async dispatch => {
  const api = API.STAMP_API.getQRStamp();

  dispatch(gettingQRStampUrl());
  const { response } = await apiCall({ ...api, payload });

  if (response?.status === 200 && response.data?.success && response.data?.pd_url) {
    const { data } = response;
    dispatch(getQRStampUrlSuccess({ data }));
    if (data.pd_url) {
      window.open(data.pd_url, '_blank');
      meta.onSuccess(data.pd_url);
    } else {
      meta.onError('File đã in');
    }
  } else {
    dispatch(getQRStampUrlFailure());
    meta.onError('Lỗi tạo file mã QR');
  }
};
