/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Button, Form, Select } from 'antd'
import router from '../../../constants/router'

const { Item } = Form
const { Option } = Select

class Filter extends React.Component {
  state = { keyword: [] }

  static getDerivedStateFromProps(props) {
    const { search } = props.history.location
    const result = search.replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ').split(' ').splice(1)

    return { keyword: result }
  }

  handleSubmit = e => {
    e.preventDefault()
    const c_params = this.props.filterOptions === undefined ? {} : JSON.parse(this.props.filterOptions)
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const urlParameters = []
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '') {
            urlParameters.push(e.join('='))
          }
        })
        if (urlParameters[0] !== false) {
          this.props.history.push(router.GENERAL.ACCOUNT.concat(`?${urlParameters.join('&')}`))
        }
      } else {
        console.log(err)
      }
    })
  };

    handleReset = () => {
      this.props.form.resetFields()
      this.props.history.push(router.GENERAL.ACCOUNT)
    };

    render() {
      const { disabled, roles, form: { getFieldDecorator } } = this.props
      const { keyword } = this.state
      return (
        <Form onSubmit={this.handleSubmit}>
          <Item label="Tên tài khoản">
            {
              getFieldDecorator('name', { initialValue: keyword.indexOf('name') === -1 ? '' : keyword[keyword.indexOf('name') + 1] })(
                <Input
                  allowClear
                  disabled={disabled}
                  placeholder="Tên tài khoản"
                />
              )
            }
          </Item>
          <Item label="Vai trò">
            {
              getFieldDecorator('role', { initialValue: keyword.indexOf('role') === -1 ? '' : keyword[keyword.indexOf('role') + 1] })(
                <Select
                  allowClear
                  placeholder="Vai trò"
                >
                  {
                    roles.map(({ name, code }) => (
                      <Option key={`${name}${code}`} value={code}>
                        {name}
                      </Option>
                    ))
                  }
                </Select>
              )
            }
          </Item>
          <Item label="Trạng thái">
            {
              getFieldDecorator('status', { initialValue: keyword.indexOf('status') === -1 ? 'active' : keyword[keyword.indexOf('status') + 1] })(
                <Select
                  placeholder="Trạng thái"
                >
                  <Option value="active">Đang hoạt động</Option>
                  <Option value="inactive">Dừng hoạt động</Option>
                  <Option value="all">Tất cả</Option>
                </Select>
              )
            }
          </Item>
          <Row gutter={24}>
            <Col span={12}>
              <Button
                disabled={disabled}
                icon="filter"
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                {'Lọc'}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                disabled={disabled}
                icon="close"
                onClick={this.handleReset}
                style={{ width: '100%' }}
              >
                {'Bỏ lọc'}
              </Button>
            </Col>
          </Row>
        </Form>
      )
    }
}

Filter.propTypes = { disabled: PropTypes.bool.isRequired }

export default Form.create()(Filter)
