/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import select from '../../util/select';

import ImageList from './components/List';
import { getProduct, updateProductImages } from '../Product/actions';

// import selectImmer from '../../utils/selectImmer'
import { splitNameFromImageURL } from '../../util/image';

class ProductImage extends Component {
  constructor() {
    super();
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps) {
    const { productId } = nextProps;
    if (nextProps.productId !== productId) {
      // nextProps.invalidateCurrentProductInfo()
      nextProps.getProduct(productId);
    }

    return null;
  }

  addImageToProduct = values => {
    const {
      productInfo,
      updateProductImages,
      pushNotification,
      locale: { trans },
    } = this.props;
    const currentImages = productInfo.images;
    const imageData = JSON.parse(values.Image);
    const Images = currentImages.map(image => {
      const convertedImage = {};
      Object.keys(image).forEach(key => {
        convertedImage[key] = splitNameFromImageURL(image[key]);
      });

      return convertedImage;
    });
    Images.push({
      Title: values.Title,
      ListingUrl: imageData.Listing,
      DetailUrl: imageData.Detail,
      ThumbnailUrl: imageData.Thumbnail,
      MobiListingUrl: imageData.MobiListing,
      MobiDetailUrl: imageData.MobiDetail,
      MobiThumbnailUrl: imageData.MobiThumbnail,
    });

    updateProductImages(
      { Images, Code: productInfo.Code },
      {
        onSuccess: () => {
          pushNotification({
            message: trans('Added Product Image Successfullly'),
            type: 'success',
          });
        },
        onError: errorCode =>
          pushNotification({
            message: `${trans('Added Product Image Failed')} - ${errorCode}`,
            type: 'error',
          }),
      },
    );
  };

  delete = image => {
    const {
      productInfo,
      updateProductImages,
      pushNotification,
      locale: { trans },
    } = this.props;
    const Images = productInfo.Images;
    const newImages = Images.filter(item => item.ListingUrl !== image.ListingUrl).map(image => {
      const convertedImage = {};
      Object.keys(image).forEach(key => {
        convertedImage[key] = splitNameFromImageURL(image[key]);
      });

      return convertedImage;
    });
    updateProductImages(
      {
        Images: newImages,
        Code: productInfo.Code,
      },
      {
        onSuccess: () => {
          pushNotification({
            message: trans('Deleted Product Image Successfullly'),
            type: 'success',
          });
        },
        onError: errorCode =>
          pushNotification({
            message: `${trans('Deleted Product Image Failed')} - ${errorCode}`,
            type: 'error',
          }),
      },
    );
  };

  render() {
    const { productInfo, updateProductImages } = this.props;
    return (
      <div>
        <ImageList
          productInfoId={this.props.productId}
          idProduct={productInfo.id}
          updateProductImages={updateProductImages}
          productInfo={productInfo}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  productInfo: select(state, ['productReducer', 'detail'], 'item'),
  // units: select(state, ['generalReducer', 'productUnit'], 'items'),
  // units: select(state, ['generalReducer', 'productUnit'], 'items'),
  // isFetching: selectImmer(state, ['productInfoReducer', 'current'], 'isFetching'),
  // didInvalidate: selectImmer(state, ['productInfoReducer', 'current'], 'didInvalidate'),
});

const mapDispatchToProps = dispatch => ({
  getProduct: id => dispatch(getProduct(id)),
  updateProductImages: (payload, meta) => dispatch(updateProductImages(payload, meta)),
  // getProductUnits: params => dispatch(getProductUnits(params)),
  // getProductConditions: () => dispatch(getProductConditions()),
  // getCategories: params => dispatch(getCategories(params)),
  // updateProduct: (id, payload, meta) => dispatch(updateProduct(id, payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductImage);
