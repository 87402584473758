/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Input, Button, Form } from 'antd';
import router from '../../../constants/router';

const { Item } = Form;

class FilterRow extends React.Component {
  state = { keyword: [] };

  static getDerivedStateFromProps(props) {
    const { search } = props.history.location;
    const result = search
      .replace(/[&/\\#,+()$~%.'":*?<>{}=]/g, ' ')
      .split(' ')
      .splice(1);

    return { keyword: result };
  }

  handleSubmit = e => {
    e.preventDefault();
    const c_params = this.props.filterOptions === undefined ? {} : JSON.parse(this.props.filterOptions);
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const urlParameters = [];
        Object.entries(Object.assign(c_params, values)).map(e => {
          if (e[1] !== undefined && e[1] !== '') {
            urlParameters.push(e.join('='));
          }
        });
        if (urlParameters[0] !== false) {
          this.props.history.push(router.SUPPLIER.INDEX.concat(`?${urlParameters.join('&')}`));
        }
      } else {
        console.log(err);
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.history.push(router.SUPPLIER.INDEX);
  };

  render() {
    const {
      disabled,
      form: { getFieldDecorator },
    } = this.props;
    const { keyword } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Item label="Mã nhà cung cấp">
          {getFieldDecorator('code', {
            initialValue: keyword.indexOf('code') === -1 ? '' : keyword[keyword.indexOf('code') + 1],
          })(<Input disabled={disabled} placeholder="Mã nhà cung cấp" allowClear />)}
        </Item>
        <Item label="Tên nhà cung cấp">
          {getFieldDecorator('name', {
            initialValue: keyword.indexOf('name') === -1 ? '' : keyword[keyword.indexOf('name') + 1],
          })(<Input disabled={disabled} placeholder="Tên nhà cung cấp" allowClear />)}
        </Item>
        <Row gutter={24}>
          <Col span={12}>
            <Button icon="filter" disabled={disabled} type="primary" htmlType="submit" style={{ width: '100%' }}>
              {'Lọc'}
            </Button>
          </Col>
          <Col span={12}>
            <Button icon="close" disabled={disabled} onClick={this.handleReset} style={{ width: '100%' }}>
              {'Bỏ lọc'}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

FilterRow.propTypes = {
  onFilter: PropTypes.func,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

FilterRow.defaultProps = {
  onFilter: () => {},
  disabled: false,
};

export default Form.create()(FilterRow);
