/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react'
import { Table, Card, Button, Divider, Row, Input, Form, notification, InputNumber, Switch } from 'antd'
import { connect } from 'react-redux'
import select from '../../../../util/select'
import UploadForm from '../price-table-modal/update'
import PriceForm from './price-form'
import {
  getPriceTable,
  addItemToPriceTable,
  removeItemFromPriceTable,
  updateItemInPriceTable, getPriceTableWhenSearch,

} from '../../actions'
import { getExistPriceTable, updatePriceTable, updateStatusItem } from '../../../PriceList/actions'
import { formatCurrency } from '../../../../util/formatCurrency'
import WithLoadingHOC from '../../../../hoc/loading'
import FilterItem from './FilterItem'

const EditableContext = React.createContext()
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number' && this.props.dataIndex === 'pricePerUnit') {
      return <InputNumber step={1} min={1} />
    }
    return <Input />
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Vui lòng nhập ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class DetailPriceTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      pageS: 10,
      priceTable: {},
      keyword: {},
      edit: false,
      editingKey: '',
      editPrice: false,
      visible: false,
      columns: [
        {
          title: 'Mã mặt hàng',
          dataIndex: 'productCode',
        },
        {
          title: 'Tên mặt hàng',
          dataIndex: 'productName',
        },
        {
          title: 'Đơn Giá',
          dataIndex: 'pricePerUnit',
          width: 150,
          editable: false,
          align: 'right',
          render: (text, record) => {
            const { editingKey } = this.state
            const editable = this.isEditing(record) // true if record.orderItemId === this.state.editingKey
            return editable ? (
              <span>
                <EditableContext.Consumer>
                  <div>{formatCurrency(text)}</div>
                </EditableContext.Consumer>
              </span>
            ) : (
              <div>
                <span>{formatCurrency(text)}</span>
                {
                  // props.isAdmin && (
                  <Button
                    style={{ padding: '3px', float: 'right' }}
                    type="primary"
                    disabled={editingKey !== '' || props.forCustomer}
                    icon="edit"
                    size="small"
                    title="Click để sửa"
                    onClick={() => this.editPrice(record.priceItemId)}
                  />
                }
              </div>
            )
          }
        },
        {
          title: 'Đơn vị',
          dataIndex: 'unitName',
          align: 'center'
        },
        {
          title: 'Trạng Thái',
          dataIndex: 'priceItemStatus',
          render: (value, record) => (
            <Switch defaultChecked={value} onChange={() => this.onChangeStatus(this.state.priceTable.priceTableId, record.priceItemId, { status: !value })} />
          )
        },
        {
          title: 'Tùy chọn',
          align: 'center',
          dataIndex: 'priceItemId',
          render: (text, record) => {
            const editable = this.isEditing(record)
            return editable ? (
              <span>
                <EditableContext.Consumer>
                  {form => (
                    <div>
                      {this.state.editPrice
                        && (
                          <Button
                            type="default"
                            onClick={() => this.onUpdatePrice(form, record.priceItemId)}
                            style={{ margin: 5 }}
                          >
                            {'Cập nhật đơn giá'}
                          </Button>
                        )
                      }
                      <Button
                        type="default"
                        onClick={() => this.back()}
                        style={{ margin: 5 }}
                      >
                        {'Hủy'}
                      </Button>
                    </div>
                  )}
                </EditableContext.Consumer>
              </span>
            ) : ''
          }
        },
      ]
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.priceTable !== null && props.priceTable.priceTableId !== undefined && props.priceTable.priceTableId !== state.priceTable.priceTableId) {
      // props.getExistPriceTable(props.priceTable.priceTableId, { pageSize: -1 })
      return { ...state, priceTable: props.priceTable, data: props.priceTable }
    }
    if (props.priceTableId === null) {
      return { ...state, priceTableId: null, priceTable: {}, data: '' }
    }
    if (props.dataSource
      && props.dataSource.data
      && props.dataSource.data.priceItem
    ) {
      const priceTable = {}
      Object.assign(priceTable, props.dataSource.data)
      return {
        ...state,
        priceTable: {
          ...priceTable,
          // priceItem: priceTable.priceItem.filter(
          //   item => changeAlias(item.productName.toLowerCase()).indexOf(changeAlias(state.filterText.toLowerCase())) >= 0
          //     || changeAlias(item.productCode.toLowerCase()).indexOf(changeAlias(state.filterText.toLowerCase())) >= 0,
          // )
        }
      }
    }
    return null
  }

  onUpdatePrice(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return
      }
      // const { locale: { trans } } = this.props
      if (row.pricePerUnit > 0) {
        this.props.updateItemInPriceTable(
          key,
          {
            newPrice: row.pricePerUnit,
            priceItemId: key
          },
          {
            onSuccess: () => {
              notification.success({ message: 'Đã cập nhật đơn giá' })
              this.setState({ editingKey: '', editPrice: false })
              this.deleteEditPrice()
            },
            onError: errorCode => notification.error({ message: `${'Cập nhật đơn giá thất bại'} ${errorCode}` }),
          },
        )
      }
    })
  }

  onDeleteItem = id => {
    this.props.removeItemFromPriceTable(
      id,
      {
        onSuccess: () => {
          notification.success({ message: 'Đã xóa mặt hàng khỏi bảng giá' })
        },
        onError: errorCode => notification.error({ message: `${'Không thể xóa'} ${errorCode}` }),
      },
    )
  }

  isEditing = record => record && record.priceItemId && record.priceItemId === this.state.editingKey

  back = () => {
    this.setState({
      editingKey: '',
      editPrice: false,
    })
    this.deleteEditPrice()
  }

  onChangePage = (page, pageSize) => {
    this.setState({ page, pageS: pageSize }, () => this.props.getPriceTableWhenSearch(
      this.state.priceTable.priceTableId,
      this.state.keyword,
      page,
      pageSize,
    ))
  }

  getKeywordSearch =keyword => {
    this.setState({ keyword })
  }

  togglePriceModal = priceTableId => {
    this.props.toggleModal(priceTableId)
  }

  editApplyDate = () => {
    if (this.state.edit && this.props.priceTable.applyDate !== this.state.priceTable.applyDate) {
      const id = this.props.priceTable.priceTableId
      const { applyDate } = this.state.priceTable
      this.props.updatePriceTable(id, { applyDate }, {
        onSuccess: () => {
          notification.success({ message: 'Đã cập nhật đơn hàng' })
        },
        onError: error => notification.error({ message: `Sửa đơn hàng gặp lỗi ${error}! Vui lòng thử lại !` }),
      })
    }
    this.setState(prevState => ({ edit: !prevState.edit }))
  }

  onChangeStatus = (idPriceTable, idItem, value) => {
    const payload = {
      priceItemId: idItem,
      status: value.status
    }
    this.props.updateStatusItem(idPriceTable, payload, {
      onSuccess: () => {
        notification.success({ message: 'Sửa trạng thái thành công' })
      },
      onError: error => notification.error({ message: `Không sửa được trạng thái ${error}! Vui lòng thử lại !` }),
    })
  }


  toggleModal = () => {
    this.setState(state => ({ visible: !state.visible }))
  }

  editPrice(key) {
    // this.columns
    const updatedArray = [...this.state.columns]
    updatedArray[2].editable = true
    this.setState({
      editingKey: key,
      columns: updatedArray,
      editPrice: true
    })
  }

  deleteEditPrice() {
    const updatedArray = [...this.state.columns]
    updatedArray[2].editable = false
    this.setState({ columns: updatedArray })
  }


  render() {
    const {
      forCustomer,
      meta,
      groupID,
      products,
      existPriceTable,
      getExistPriceTable,
      addItem,
      getPriceTable,
      updatePriceTable
    } = this.props
    const { priceTable, visible, page, pageS } = this.state
    const components = { body: { cell: EditableCell } }
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'pricePerUnit' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      }
    })
    return (
      <React.Fragment>
        <Card
          title="Chi tiết bảng giá"
          extra={
            priceTable.priceTableId !== undefined && !forCustomer
          }
        >
          <Row gutter={24}>
            <PriceForm
              products={products}
              addItem={addItem}
              priceTable={priceTable}
              disabled={!(priceTable.priceTableId !== undefined && !forCustomer)}
            />
          </Row>
          <Divider dashed />
          <Row gutter={24}>
            <FilterItem
              page={page}
              pageS={pageS}
              priceTableId={priceTable.priceTableId}
              getKeywordSearch={this.getKeywordSearch}
              getPriceTableWhenSearch={this.props.getPriceTableWhenSearch}
              onFilter={getPriceTable}
              disabled={priceTable.priceTableId === undefined}
            />
          </Row>
          <EditableContext.Provider value={this.props.form}>
            <Table
              components={components}
              loading={this.props.dataSource.isFetching}
              size="small"
              scroll={{ x: 'max-content' }}
              dataSource={priceTable.priceItem !== undefined ? priceTable.priceItem : []}
              columns={columns}
              rowKey="priceItemId"
              pagination={{
                current: meta.current,
                total: meta.total,
                pageSize: meta.pageSize,
                onChange: this.onChangePage,
                showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mặt hàng`,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '40', '80'],
                onShowSizeChange: this.onChangePage,
              }}
            />
          </EditableContext.Provider>
        </Card>
        {visible && (
          <UploadForm
            visible={visible}
            groupID={groupID}
            products={products}
            priceTableId={priceTable.priceTableId}
            priceTable={existPriceTable}
            onCloseModal={this.toggleModal}
            onSubmit={updatePriceTable}
            addItem={addItem}
            getExistPriceTable={getExistPriceTable}
          />
        )}
      </React.Fragment>
    )
  }
}
const EditableFormTable = Form.create()(DetailPriceTable)


const mapStateToProps = state => ({
  dataSource: select(state, ['customerReducer', 'customer'], 'editingPriceTable'),
  existPriceTable: select(state, ['priceTableReducer', 'exist'], 'item')
})

const mapDispatchToProps = dispatch => ({
  getPriceTable: (id, params) => dispatch(getPriceTable(id, params)),
  getPriceTableWhenSearch: (id, params, page, pageS) => dispatch(getPriceTableWhenSearch(id, params, page, pageS)),
  getExistPriceTable: (id, params) => dispatch(getExistPriceTable(id, params)),
  addItemToPriceTable: (id, payload, meta) => dispatch(addItemToPriceTable(id, payload, meta)),
  removeItemFromPriceTable: (id, meta) => dispatch(removeItemFromPriceTable(id, meta)),
  updateItemInPriceTable: (id, payload, meta) => dispatch(updateItemInPriceTable(id, payload, meta)),
  updatePriceTable: (id, payload, meta) => dispatch(updatePriceTable(id, payload, meta)),
  updateStatusItem: (id, payload, meta) => dispatch(updateStatusItem(id, payload, meta))
})

export default WithLoadingHOC(connect(mapStateToProps, mapDispatchToProps)(EditableFormTable))
