import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, Modal, Select } from 'antd'
import { backpage, isBelowBreakpoint } from '../../../util/windows'

const { Item } = Form
const { Option } = Select
// const { TextArea } = Input

class CategoryForm extends React.Component {
  state = {
    valueCode: '',
    currentCategoryCode: '',
    statuses: [
      { name: 'Ngừng sử dụng', val: false },
      { name: 'Sử dụng', val: true }
    ]
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.editMode
      && ((nextProps.category || (nextProps.category && nextProps.category.code !== prevState.currentCategoryCode)))) {
      return { currentCategoryCode: nextProps.category.code }
    }
    return null
  }

  onDelete = (name, id) => {
    Modal.confirm({
      okText: 'Xoá danh mục',
      okButtonProps: { type: 'danger' },
      onOk: () => this.props.onDelete(id),
      cancelText: 'Huỷ',
      cancelButtonProps: { type: 'primary' },
      title: `Bạn chắc chắn xoá danh mục ${name}`,
      content: 'Mọi thông tin liên quan sẽ bị xoá khỏi hệ thống !',
    })
  };

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      values.code = this.state.valueCode
      if (!err) {
        const { code, name, status } = values
        this.props.onSubmit({ code, name, status })
      }
    })
  };

  changecode = e => {
    const valueinput = e.target.value
    const part = /\s/g
    const value = valueinput.replace(part, '')
    this.setState({ valueCode: value })
  }

  render() {
    const {
      category,
      editMode,
      form: { getFieldDecorator },
    } = this.props
    const { statuses } = this.state
    return (
      <Form onSubmit={this.handleSubmit}>
        <Item label="Mã danh mục">
          {getFieldDecorator('code', {
            initialValue: editMode ? category && category.code : '',
            rules: [
              {
                required: true,
                message: 'Hãy nhập mã danh mục',
              },
            ],
          })(<Input type="text" onChange={this.changecode} placeholder="Mã danh mục" allowClear />)}
        </Item>
        <Item label="Tên danh mục">
          {getFieldDecorator('name', {
            initialValue: editMode ? category && category.name : '',

            rules: [{ required: true, message: 'Hãy nhập tên danh mục' }],
          })(<Input type="text" placeholder="Tên danh mục" allowClear />)}
        </Item>
        <Item label="Trạng thái">
          {getFieldDecorator('status', {
            initialValue: editMode
              ? category && category.status
              : null
          })(
            <Select placeholder="Trạng thái" allowClear>
              {statuses.map((item, index) => (
                <Option value={item.val} key={`${index + 1}`}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        {/* <Item label="Ghi chú">
          {getFieldDecorator('note', { initialValue: editMode ? category : '' })(<TextArea type="text" placeholder="Thông tin thêm ..." />)}
        </Item> */}
        <Item>

          <Button
            ghost
            style={{ border: 'none' }}
            type="danger"
            onClick={() => backpage()}
          >
              Quay lại
          </Button>

          <Button type="primary" htmlType="submit" icon="plus" style={{ float: isBelowBreakpoint() ? 'none' : 'right' }}>
            {editMode ? 'Hoàn thành' : 'Thêm danh mục'}
          </Button>
        </Item>
      </Form>
    )
  }
}

CategoryForm.propTypes = {
  category: PropTypes.object,
  editMode: PropTypes.bool,
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func
}

CategoryForm.defaultProps = {
  category: {},
  form: {},
  editMode: false,
  onSubmit: () => {},
  onDelete: () => {}
}

export default Form.create()(CategoryForm)
